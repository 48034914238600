import { HandleRoutes } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import { NotifyProvider } from './contexts/NotifyContext';
import { useEffect } from 'react';
import { checkVersion } from './services/cache';

function App() {
  useEffect(() => { checkVersion() },[]);

  return (
    <NotifyProvider>
      <AuthProvider>
        <HandleRoutes />
      </AuthProvider>
    </NotifyProvider>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import FolderIcon from "../../shared-components/assets/folder.svg";
import FileIcon from "../../shared-components/assets/file.svg";
import { useNotify } from "../../contexts/NotifyContext";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { MoreVerticalIcon } from "../../shared-components/utils/icons";
import { ClosingFolderDir, ClosingFolderFile, ClosingFolderType } from "../../shared-types";
import { useAuth } from "../../contexts/AuthContext";
import { deleteClosingFolder } from "../../services/closingFolder";
import JSZip from "jszip";

interface RecursiveComponentProps {
  paths: string[];
  breadcrumbs: string[];
  navigateTo: (to: string[]) => void;
  data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile;
  filter: string;
  updateFolders: () => void;
  onDownload: (data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => void;
}

export const RecursiveClosingFolder: React.FC<RecursiveComponentProps> = ({
  paths,
  data,
  breadcrumbs,
  navigateTo,
  updateFolders,
  filter,
  onDownload
}) => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();
  const hrefFileRef = useRef<HTMLAnchorElement>(null);
  const [state, setState] = useState<'expanded' | 'collapsed' | 'hidden'>('hidden')

  const currentPath = [...breadcrumbs, data.slug!];

  useEffect(() => {
    setState(
      paths.join(',') === breadcrumbs.join(',') ? 'collapsed' :
        currentPath.every((p, i) => i < paths.length && paths[i] === p) ? 'expanded' :
          'hidden'
    )
  }, [paths])

  async function handleDelete() {
    const onDelete = async () => {
      if (!user || !data._id) return;

      const res = await deleteClosingFolder(currentPath, user.token, true)

      if (res.result) {
        toast.success(res.response)
        updateFolders()
      }
      else toast.error(res.response)
    }

    showMessage((
      <div className="text-sm text-gray-600">
        <p>Tem certeza que deseja excluir {data.type === 'dir' ? 'esta pasta' : 'este arquivo'}?</p>
        {(data.type === 'dir' && (data.items ?? []).length > 0) && (
          <div className="text-xs mt-2">
            <strong>Importante:</strong> <span className="text-gray-500">Existem arquivos dentro desta pasta. Ao confirmar, todos arquivos internos serão excluídos</span>
          </div>
        )}
      </div>
    ), {
      title: 'Confirmar Exclusão',
      actionButton: {
        text: 'Confirmar',
        theme: 'danger',
        onClick: onDelete
      }
    })
  }

  return (
    <>
      {state === 'collapsed' ? (
        <div className="bg-gradient-light rounded-lg text-gray-700 flex flex-col">
          <header className="flex gap-2 items-center justify-between text-xs px-2.5 pt-2.5">
            <span className="flex items-center text-primary-700 font-semibold gap-1">
              <img
                src={data.type === "dir" ? FolderIcon : FileIcon}
                alt={`icone de ${data.type}`}
                className="pb-1"
              />
              <span className="uppercase text-[10px] leading-none">
                {data.type === "dir" ? "Pasta" : "Arquivo"}
              </span>
            </span>
            <div>
              <Dropdown
                classNames={{
                  list: `
                    absolute right-0 z-10
                    -mt-1 w-56 origin-top-right rounded-md
                    bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none pb-2
                  `,
                }}
                trigger={(
                  <div className="flex items-center justify-between rounded-md  text-sm backdrop-blur-[25px]">
                    <MoreVerticalIcon className="-rotate-90 text-primary-800" />
                  </div>
                )}
              >
                {data.type === 'dir' && (
                  <button
                    type="button"
                    className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                    onClick={() => navigateTo(currentPath)}
                  >Acessar</button>
                )}
                {(data.type === 'dir' && (data.items ?? []).length > 0) && (
                  <button
                    type="button"
                    className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                    onClick={() => onDownload(data)}
                  >Download</button>
                )}
                {(data.type === 'file' && data.src) && (
                  <button
                    type="button"
                    className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                    onClick={() => {
                      if(hrefFileRef.current) hrefFileRef.current.click();
                    }}
                  >Acessar</button>  
                )}
                <button
                  type="button"
                  className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                  onClick={handleDelete}
                >Excluir</button>
              </Dropdown>
            </div>
          </header>
          <div
            className="text-sm pt-2 px-4 pb-5 flex-1 flex flex-col cursor-pointer gap-0.5 hover:bg-gray-50/20"
            onClick={() => {
              if (data.type === 'dir') navigateTo(currentPath)
              else {
                if (!data.src) {
                  toast.error('Não foi possível acessar este arquivo')
                  return;
                }

                if (hrefFileRef.current) hrefFileRef.current.click()
              }
            }}
          >
            <strong className="text-primary-800">{data.title}</strong>
            {data.type === "dir" ? (
              <span className="text-primary-400 text-xs">
                {(data.items ?? []).length} arquivo(s) {""}
              </span>
            ) : (
              <span className="text-primary-400 text-xs">{data.size ?? 0}kb</span>
            )}
          </div>
          {data.type === 'file' && data.src && (
            <a href={data.src} target="_blank" className="hide" ref={hrefFileRef} />
          )}
        </div>
      ) : (state === 'expanded' && data.type === 'dir') ? (
        <>
          {(data.items && data.items.length > 0) ?
            (
              filter.length > 0 && paths.join(',') === currentPath.join(',') ? (
                <>
                  {
                    data.items.filter(subItem => subItem.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).length > 0 ? (
                      <>
                        {
                          data.items.filter(subItem => subItem.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).map((item, index) => (
                            <RecursiveClosingFolder
                              key={index}
                              paths={paths}
                              navigateTo={navigateTo}
                              breadcrumbs={currentPath}
                              data={item}
                              filter={filter}
                              updateFolders={updateFolders}
                              onDownload={(data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => onDownload(data)}
                            />
                          ))
                        }
                      </>
                    ) : (
                      <div className="
                        text-sm text-center text-gray-700/80 w-full min-h-[calc(70vh-17rem)] bg-primary-400/5 hover:bg-primary-400/10
                        flex flex-1 self-center items-center justify-center
                        mx-auto
                        md:col-span-2 lg:col-span-3 xl:col-span-4
                      ">
                        Não existe nenhuma pasta/arquivo com esse filtro!
                      </div>
                    )
                  }
                </>
              ) : data.items.map((item, index) => (
                <RecursiveClosingFolder
                  key={index}
                  paths={paths}
                  navigateTo={navigateTo}
                  breadcrumbs={currentPath}
                  data={item}
                  filter={filter}
                  updateFolders={updateFolders}
                  onDownload={(data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => onDownload(data)}
                />
              ))
            ) : (
              <div className="
                text-sm text-center text-gray-700/80 w-full min-h-[calc(70vh-17rem)] bg-primary-400/5 hover:bg-primary-400/10
                flex flex-1 self-center items-center justify-center
                mx-auto
                md:col-span-2 lg:col-span-3 xl:col-span-4
              ">
                Essa pasta não possui nenhum arquivo!
              </div>
            )}
        </>
      ) : <></>}
    </>
  );
};
import { ResultAndResponse, User } from "../types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api"
import { cacheClient } from "./client";

interface ResponseUserType extends ResultAndResponse{
  data?: User
}
interface DataCreateUserType{
  name: string,
  password: string,
  email: string
}
export const createUser = async (data: DataCreateUserType, token: string) : Promise<ResponseUserType> => {
  try{
    const response = await api.post('/user', data, headerBearer(token));
    return {
      result: true,
      response: 'Usuário cadastrado',
      data: response.data
    };
  }catch(e: any){
    console.error(e);
    
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado'
    });
  }
}
//#region CACHE USER BY EMAIL
let cacheUsersByEmail : Record<string, User[]>= {}
const addUserByEmailOnCache = (token: string, user: User) => {
  if(cacheUsersByEmail[token]) cacheUsersByEmail[token] = [
    ...cacheUsersByEmail[token].filter((cached) => cached.email !== user.email),
    user
  ]
  else cacheUsersByEmail[token] = [user]
}
export const clearCacheFromUserByEmail = (token: string) => cacheUsersByEmail[token] = []
const searchUserOnCache = (token: string, email: string) : User | undefined => {
  let finded : User | undefined = undefined
  if(cacheUsersByEmail[token]){
    finded = cacheUsersByEmail[token].find((user) => user.email === email);
  }
  if(!finded){
    if(cacheClient && cacheClient[token] && cacheClient[token].users){
      finded = cacheClient[token].users!.find((user) => user.email === email);
    }
  }
  return finded;
}
//#endregion CACHE USER BY EMAIL
export const searchUserByEmail = async (email: string, token: string) : Promise<ResponseUserType> => {
  try{
    const findedUser = searchUserOnCache(token, email)
    if(findedUser){
      console.log('[cached-search-user-by-email]')  
      return {
        result: true,
        response: 'Usuário localizado',
        data: findedUser
      }
    }
  }catch(e){}
  try{
    const { data } = await api.get(`/user-email/${email}`, headerBearer(token));
    console.log('[requested-search-user-by-email]')
    if(data.result){
      if(data.data) addUserByEmailOnCache(token, data.data)

      return {
        ...data,
        response: 'Usuário localizado'
      };
    }

    return data;
  }catch(e){
    console.error(e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar localizar o usuário'
    });
  }
}
export const updateUser = async (fields: { name: string, whatsapp?: string, picture: string }, token: string) : Promise<ResponseUserType> => {
  try{
    const { data } = await api.put('/update-user', fields, headerBearer(token))
    if(!data.result) throw new Error(
      data.response
    )

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar atualizar o usuário'
    })
  }
}
import { clearCacheDashboards } from "../shared-components/services/dashboard";
import { DashboardType, ResultAndResponse } from "../shared-types";
import { api, headerBearer, handleErrorResultAndResponse } from "./api";

interface DashboardResponse extends ResultAndResponse{
  data?: DashboardType;
}
interface CreateDashboardRequest extends Omit<DashboardType, 'id' | 'slug' | 'updated_at' | 'created_at'>{
  id?: string
}
export const createOrUpdateDashboard = async (token: string, fields: CreateDashboardRequest) : Promise<DashboardResponse> => {
  try{
    const { data } = await api.post('/dashboard', fields, headerBearer(token))
    
    if(!data.result) throw new Error(
      data.response
    );

    clearCacheDashboards();

    return data;
  }catch(e){
    console.error(e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível criar a Dashboard'
    })
  }
}
export const deleteDashboard = async (id: string,token: string) : Promise<ResultAndResponse> => {
  try{
    const { data } = await api.delete(`/dashboard/${id}`, headerBearer(token))

    if(!data.result) throw new Error(data.response);

    clearCacheDashboards();

    return data;
  }catch(e){
    console.error(e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar excluir a dashboard'
    })
  }
}

export * from "../shared-components/services/dashboard";
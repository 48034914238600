import { useAuth } from "../../../contexts/AuthContext";
import { PossiblePermissions } from "../../../shared-types";
import { NotificationToType } from "../../../shared-types/notification.type";
import { CardWhoNotify } from "../CardWhoNotify";

interface FSSelectToTypeProps{
  toType: NotificationToType | undefined,
  setToType: React.Dispatch<React.SetStateAction<NotificationToType | undefined>>
}
export const FSSelectToType = ({ setToType, toType }:FSSelectToTypeProps) => {
  const { user } = useAuth();
  
  return (
    <div className="mb-4">
      <h3 className="mb-4 text-lg font-semibold">Quem você deseja notificar?</h3>
      <div className="grid md:grid-cols-2 gap-4">
        {(user?.permitions_slug ?? []).includes(PossiblePermissions.ADMIN_HUB) && (
          <CardWhoNotify
            title="Todos da plataforma"
            description="Irá notificar todos os usuários de todas as empresas"
            value="broadcast_hub"
            {...{ toType, setToType }}
          />
        )}
        <CardWhoNotify
          title="Todos da empresa"
          description={`Irá notificar todos os usuários da ${user?.client_name ?? 'Empresa'}`}
          value="broadcast"
          {...{ toType, setToType }}
        />
        <CardWhoNotify
          title="Todos com permissão"
          description="Você deverá selecionar as permissões para filtrar os usuários notificados"
          value="hub_perms"
          {...{ toType, setToType }}
        />
        <CardWhoNotify
          title="Selecionados"
          description="Você deverá selecionar os usuários a serem notificados"
          value="hub_ids"
          {...{ toType, setToType }}
        />
        <CardWhoNotify
          title="Todos de um Workflow"
          description={`Você deverá selecionar um workflow que possua autenticação para disparar para todos seus usuários`}
          value="broadcast_flow_auth"
          {...{ toType, setToType }}
        />
        <CardWhoNotify
          title="Todos com permissão no Workflow"
          description={`Você deverá selecionar as permissões de um workflow qualquer para filtrar os usuários notificados`}
          value="flow_perms"
          {...{ toType, setToType }}
        />
        <CardWhoNotify
          title="Selecionados do Workflow"
          description={`Você deverá selecionar os usuários de um workflow que possua autenticação a serem notificados`}
          value="flow_auth_ids"
          {...{ toType, setToType }}
        />
      </div>
    </div>
  )
}
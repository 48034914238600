import { wf } from "../shared-components/services/conn/api";
import { ClosingFolderDir, ClosingFolderFile, ClosingFolderType, ResultAndResponse } from "../shared-types";
import { handleErrorResultAndResponse, headerBearer } from "./api"

interface ClosingFoldersResponse extends ResultAndResponse{
  data?: ClosingFolderType[]
}
interface ClosingFolderResponse extends ResultAndResponse{
  data?: ClosingFolderType
}

let cacheClosingFolders : Record<string, {
  folders: ClosingFolderType[],
  isAll: boolean
}> = { }
export const getClosingFolders = async (token: string) : Promise<ClosingFoldersResponse> => {
  return {
    result: false,
    response: 'Bloqueado',
  }

  try{
    if(cacheClosingFolders[token] && cacheClosingFolders[token].isAll){
      console.log('[cached-closing-folders]')

      return {
        result: true,
        response: 'Pastas carregadas',
        data: cacheClosingFolders[token].folders
      }
    }
  }catch(e){}

  try{
    console.log('[requested-closing-folders]')
    const { data } = await wf.get('/closing-folders', headerBearer(token))

    if(data.result && data.data) cacheClosingFolders[token] = {
      folders: data.data,
      isAll: true
    }

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao buscar as pastas'
    })
  } 
}
export const getClosingFolder = async (period: string, token: string) : Promise<ClosingFolderResponse> => {
  return {
    result: false,
    response: 'Bloqueado',
  }
  
  if(!period) throw new Error(
    'É obrigatório selecionar o período que deseja acessar'
  )

  try{
    if(cacheClosingFolders[token]){
      const findedCache = cacheClosingFolders[token].folders.find(
        (folder) => folder.title === period
      )
      if(findedCache){
        console.log('[cached-closing-folder]')
  
        return {
          result: true,
          response: 'Pasta carregada',
          data: findedCache
        }
      }
    }
  }catch(e){}

  try{
    console.log('[requested-closing-folder]')
    const { data } = await wf.get(`/closing-folders/${period}`, headerBearer(token))

    if(data.result && data.data){
      if(Array.isArray(data.data)) throw new Error(
        'Formato inesperado de resposta'
      )

      if(cacheClosingFolders[token]){
        cacheClosingFolders[token].folders = cacheClosingFolders[token].folders.map(
          (folder) => folder.title === data.data.title ? data.data : folder
        )
      }else cacheClosingFolders[token] = {
        folders: [data.data],
        isAll: false
      }
    }

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao buscar as pastas'
    })
  } 
}
export const deleteClosingFolder = async (path: string[], token: string, force_delete?: boolean) : Promise<ResultAndResponse> => {
  try{
    const { data } = await wf.post('/closing-folders/delete', {
      path, force_delete
    }, headerBearer(token))

    if(data.result){
      if(cacheClosingFolders[token]) delete cacheClosingFolders[token]
    }
    
    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao tentar excluir o arquivo/pasta'
    })
  }
}
export const appendClosingFolder = async (path: string[], items: (Omit<ClosingFolderDir, "user_id"> | Omit<ClosingFolderFile, "user_id">)[], token: string) : Promise<ResultAndResponse> => {
  try{
    const { data } = await wf.post('/closing-folders', {
      path, items
    }, headerBearer(token))

    if(data.result){
      if(cacheClosingFolders[token]) delete cacheClosingFolders[token]
    }

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar adicionar o arquivo/pasta'
    })
  }
}
import { disablePOS, getCompanyPOS } from "../../services/pos";
import { shortclass } from "../../styles/styles";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { MoreVerticalIcon } from "../../shared-components/utils/icons";
import { CompanyType, POSType } from "../../types/conciliation.type";
import { useNotify } from "../../contexts/NotifyContext";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { ModalAddPOS } from "./ModalAddPOS";

interface CardManagePOSProps{
  company?: CompanyType,
  setCompany: React.Dispatch<React.SetStateAction<CompanyType | undefined>>
  companies: CompanyType[]
}
export const CardManagePOS = ({ company, setCompany, companies } : CardManagePOSProps) => {
  const { toast } = useNotify();
  const { user } = useAuth();
  
  const [isOpenModalAddPOS, setIsOpenModalAddPOS] = useState(false);
  const [pos, setPOS] = useState<POSType[]>([]);

  useEffect(() => { loadPOS() }, [company, user]);

  async function loadPOS(){
    if (!company || !user) return;
    const res = await getCompanyPOS(company.nome_fantasia, user.token);

    if(!res.result){
      toast.error(res.response);
      return;
    }

    setPOS((res.data ?? []).sort((a: any, b: any) => {
      const nameA = a.business_unit.name.toLowerCase();
      const nameB = b.business_unit.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }));
  }
  function handleSelect(selected_company: string){
    if (!selected_company) {
      setPOS([]);
      setCompany(undefined);
      return;
    }

    const findedCompany = companies.find(comp => comp.nome_fantasia === selected_company);
    if(!findedCompany){
      toast.error('Empresa inválida')
      return;
    }
    setCompany(findedCompany);
  };
  
  return (
    <div className="
      flex flex-col rounded-lg backdrop-blur-[25px] bg-white
      shadow-md dark:border-gray-700 dark:bg-gray-800 justify-between gap-2
      mb-6 w-full min-h-[20rem] pt-5 text-primary-800
    ">
      <div className="flex justify-between mr-3">
        <div className="flex items-center justify-between gap-2 px-6">
          <h5 className="text-lg font-semibold dark:text-white">POS</h5>
        </div>
        <select
          id="companies"
          value={company ? company.nome_fantasia : undefined}
          onChange={(event) => handleSelect(event.target.value)}
          className="bg-gray-50 border max-w-xs border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Selecione a empresa</option>
          {companies.map((company, index) => (
            <option
              key={index}
              value={company.nome_fantasia}
            >{company.nome_fantasia}</option>
          ))}
        </select>
      </div>
      {!company ? (
        <div className="text-gray-600 p-6 mt-2 text-[13px] text-center flex items-center justify-center bg-gray-50 h-full flex-1">
          <span className="max-w-[12rem] block">Selecione uma empresa para poder ver os POS</span>
        </div>
      ):pos.length === 0 ? (
        <div className="text-gray-600 p-6 mt-2 text-[13px] text-center flex items-center justify-center bg-gray-50 h-full flex-1">
          <span className="max-w-[12rem] block">Essa empresa não possui POS</span>
        </div>
      ):(
        <div className="overflow-y-auto max-h-96 flex-1">
          {pos.map((pos, index) => (
            <div
              className="relative border-b last:border-b-0 border-gray-200/50 hover:bg-gray-100/20 hover:rounded-lg cursor-pointer px-2 mx-4"
              key={index}
            >
              <div className="flex items-center py-1.5">
                <div className="flex-1 flex justify-between items-center">
                  <div className="flex flex-col items-start">
                    <span className="whitespace-nowrap text-gray-600">
                      {pos.business_unit?.name} - {pos.point_of_sale?.id}
                    </span>
                    {!pos.point_of_sale?.active && (
                      <span className="rounded bg-gray-200/25 px-2 py-0.5 text-xs font-medium text-primary-400">
                        Inativo
                      </span>
                    )}
                  </div>
                  <Dropdown
                    trigger={<MoreVerticalIcon color="rgb(31 71 127 / 0.8)" />}
                    classNames={{ wrapper: "block mt-2 text-left" }}
                    orientation={null}
                    styles={{ list: { transform: "translateX(-12rem)" } }}
                  >
                    <button
                      type="button"
                      className={shortclass.dropdownItem}
                      onClick={async () => {
                        if (!user || !pos.point_of_sale) return;

                        try {
                          await disablePOS(pos.point_of_sale.id, user?.token);
                          setPOS((previous) => previous.filter((item: any) => item.point_of_sale.id !== pos.point_of_sale!.id));
                          toast.success("POS desativado");
                        } catch (error) {
                          toast.error("Não foi possível desativar");
                        }
                      }}
                    >
                      {pos.point_of_sale?.active ? "Desativar" : "Ativar"}
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {company && (
        <>
          <div className="w-full flex justify-center mb-4">
            <button
              onClick={() => setIsOpenModalAddPOS(true)}
              className={`
                flex justify-center 
                rounded-lg border border-transparent 
                py-2 px-4
                text-sm font-medium 
                max-w-[150px]
                focus:outline-none focus:ring-2 focus:ring-offset-2
                text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
              `}
            >Adicionar POS</button>
          </div>
          <ModalAddPOS
            isOpen={isOpenModalAddPOS}
            setIsOpen={setIsOpenModalAddPOS}
            companies={companies}
            company={company}
            setPOS={setPOS}
          />
        </>
      )}
    </div>
  )
}
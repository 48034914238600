import { useEffect, useRef, useState } from "react";
import { Wrapper } from "../../shared-components/Wrapper"
import { ChevronDownIcon, DownloadAltIcon, InfoIcon, PlusIcon, SearchIcon, TrashIcon } from "../../shared-components/utils/icons"
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { useParams } from "react-router-dom";
import { GalleryItemType, GalleryType } from "../../types/gallery.type";
import { filesize } from 'filesize';
import { shortclass } from "../../styles/styles";
import moment from "moment";
import { Spinner, Tooltip } from "flowbite-react";
import { SlideOver } from "../../shared-components/utils/SlideOver";
import { ChevronLeftIcon, ChevronRightIcon } from "../../components/SvgIcons";
import { ResultAndResponse, User } from "../../shared-types";
import { getGalleryItems, deleteGalleryItem } from "../../services/galleryItems";
import { SlideOverAddItem } from "../../components/Gallery/SlideOverAddItem";
import { Modal } from "../../shared-components/utils/Modal";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { hubRoutes } from "../../shared-types/utils/routes";

interface FileOnDeleteType {
  id: string,
  name: string,
  status: 'pendente' | 'em andamento' | 'finalizado' | 'erro',
  message?: string
}
export const ShowGallery = () => {
  const divSearchRef = useRef<HTMLDivElement>(null);

  const { user } = useAuth();
  const { toast, showMessage } = useNotify();
  const { id } = useParams();

  const [filter, setFilter] = useState<{ search?: string }>({});
  const [galleryInfo, setGalleryInfo] = useState<GalleryType>();
  const [galleryItems, setGalleryItems] = useState<GalleryItemType[]>([]);
  const [showingGalleryItems, setShowingGalleryItems] = useState<string[]>([])
  const [hasItemSelected, setHasItemSelected] = useState(false);

  const [selectedFileInfo, setSelectedFileInfo] = useState<GalleryItemType | null>(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [perPage, _] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filesOnDelete, setFilesOnDelete] = useState<FileOnDeleteType[]>([]);

  useEffect(() => { loadGallery() }, [user, id])

  useEffect(() => {
    setShowingGalleryItems(
      galleryItems.filter(
        (item) => !filter.search || item.name.toLowerCase().includes(filter.search.toLowerCase())
      ).map((item) => item.id)
    )
    if (filter.search && pageIndex !== 0) {
      setPageIndex(0)
    }
  }, [galleryItems, filter])

  useEffect(() => {
    const totalItems = galleryItems.length;
    calcTotalPages(totalItems);

    setCanPreviousPage(pageIndex > 0);
    setCanNextPage(pageIndex < totalPages - 1);
  }, [galleryItems, perPage, pageIndex, totalPages]);

  useEffect(() => {
    setCanPreviousPage(pageIndex > 0);
    setCanNextPage(pageIndex < totalPages - 1);
  }, [pageIndex, totalPages]);

  async function loadGallery() {
    if (!user || !id) return

    const responseGallery = await getGalleryItems(user.token, id);

    if (!responseGallery.result) {
      toast.error(responseGallery.response);
      return;
    }

    if (!responseGallery.data) return;

    setGalleryItems(responseGallery.data.items ?? [])

    setGalleryInfo({
      ...responseGallery.data,
      items: undefined
    })
  }

  const calcTotalPages = (totalItems: number) => {
    const totalPages = Math.ceil(totalItems / perPage);
    setTotalPages(totalPages);
  };

  const previousPage = () => {
    setPageIndex((prevState) => {
      let newIndex = prevState - 1;
      if (newIndex >= 0) return newIndex;
      return prevState;
    });
  };

  const nextPage = () => {
    setPageIndex((prevState) => {
      let newIndex = prevState + 1;

      if (newIndex >= totalPages || newIndex < 0) return prevState;

      return newIndex;
    });
  };

  const goToPage = (index: number) => {
    if (index >= totalPages || index < 0) return;
    setPageIndex(index);
  };

  function handleDeleteConfirmation(id: string, name: string) {
    showMessage((
      <p className="mt-2 mb-4 text-gray-600">Deseja realmente excluir o arquivo "{name}"?</p>
    ), {
      title: 'Confirmação de exclusão',
      actionButton: {
        onClick: () => handleDeleteFile(id),
        theme: 'primary',
        text: 'Confirmar'
      }
    })
  };

  async function handleDeleteFile(id: string, notify = true): Promise<ResultAndResponse> {
    if (!user || !galleryInfo) return {
      result: false,
      response: 'Usuário ou galeria não identificado'
    };

    const res = await deleteGalleryItem(user.token, galleryInfo.id, id);
    if (!res.result) {
      if (notify) toast.error(res.response);
      return res;
    }

    setGalleryItems((prevState) => prevState.filter((state) => state.id !== id));
    if (notify) toast.success(res.response);
    return res;
  }

  function handleDeleteMultiple() {
    filesOnDelete.forEach(async (file) => {
      setFilesOnDelete((prevState) => prevState.map((state) => (state.id === file.id ? {
        ...state,
        status: 'em andamento',
      } : state)))

      const res = await handleDeleteFile(file.id, false);

      setFilesOnDelete((prevState) => prevState.map((state) => (state.id === file.id ? {
        ...state,
        status: res.result ? 'finalizado' : 'erro',
        message: res.response
      } : state)))

      if (res.result) {
        setTimeout(() => setFilesOnDelete((prevState) => {
          const newState = prevState.filter((state) => state.id !== file.id)
          if (newState.length === 0) setIsModalOpen(false)
          return newState;
        }), 3500)
      }
    })
  }

  function handleConfirmDeleteSelectedFiles() {
    let checkeds = Array.from(
      document.querySelectorAll('.check-row-gallery-item:checked') as NodeListOf<HTMLInputElement>
    );

    if (checkeds.length === 0) {
      toast.warning('Selecione pelo menos 1 arquivo para poder excluir');
      return;
    }

    const checkedFilesOnDelete: FileOnDeleteType[] = checkeds.map((checked) => ({
      id: checked.value,
      name: checked.dataset.value!,
      status: 'pendente'
    }))

    setFilesOnDelete(checkedFilesOnDelete);

    setIsModalOpen(true)
  };

  const handleInformationFile = (item: GalleryItemType) => {
    setSelectedFileInfo(item);
    setInfoModalOpen(true);
  };

  return (
    <Wrapper
      breadcrumbs={[
        { name: 'Meus Docs.', href: hubRoutes.gallery.home() },
        ...(galleryInfo ? [{
          name: galleryInfo.name,
          href: '#'
        }] : [])
      ]}
      asideActive="Meus Docs."
      module_name="Configurações"
      omit={['button-help']}
    >
      <div className="flex items-center justify-end">
        <div>
          <button
            type="button"
            className={`
              text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
              hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
              rounded-lg text-sm 
              px-3 pt-1.5 pb-1.5 text-center w-full mb-2 mt-auto
            `}
            onClick={() => setIsModalAddOpen(true)}
          ><PlusIcon w={20} /> Novo Arquivo</button>
        </div>
      </div>
      <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 px-4 text-primary-700 h-16">
        {hasItemSelected && (
          <div className="flex items-center gap-3">
            <Tooltip
              trigger="hover"
              content="Baixar Selecionados"
            >
              <button
                type="button"
                className="flex bg-gradient-light bg-gray-100 hover:bg-gray-50 p-2 border-transparent rounded-lg outline-none focus:ring-gray-100/50 focus:ring-4 focus:outline-none focus:ring-400"
              // onClick={handleDownloadSelectedFiles}
              ><DownloadAltIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
            </Tooltip>
            <Tooltip
              trigger="hover"
              content="Excluir Selecionados"
            >
              <button
                type="button"
                className="flex bg-gradient-light bg-gray-100 hover:bg-gray-50 p-2 border-transparent rounded-lg outline-none focus:ring-gray-100/50 focus:ring-4 focus:outline-none focus:ring-400"
                onClick={() => handleConfirmDeleteSelectedFiles()}
              ><TrashIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
            </Tooltip>
          </div>
        )}
        <div className="group relative flex items-center ml-auto" ref={divSearchRef}>
          <input
            type="text"
            id="simple-search"
            className="
              hidden group-[.show]:block
              bg-gray-100/10 backdrop-blur-[25px]
              
              border-primary-500/20
              text-gray-600 text-sm rounded-lg 
              placeholder-primary-700

              focus:ring-gray-100/20 focus:border-gray-100/20 w-44 pr-10 p-2.5
            "
            value={filter.search ?? ''}
            onChange={(e) => setFilter((prevState) => ({
              ...prevState,
              search: e.target.value
            }))}
            placeholder="Pesquisar..."
          />
          <button
            type="button"
            className="
              absolute inset-y-0 right-2
              flex justify-center items-center
              h-6 w-6 my-auto 
              rounded-full outline-none focus:ring-gray-100/50 focus:ring-1
            "
            onClick={() => {
              if (!divSearchRef.current) return;
              divSearchRef.current.classList.toggle('show');

              let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
              if (divSearchRef.current.classList.contains('show')) input.focus();
            }}
          ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
        </div>
      </div>

      <div className="my-6">
        <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-primary-800 uppercase bg-primary-500/5">
              <tr>
                <th className="px-3 py-2 w-10">
                  <input
                    type="checkbox"
                    id={`check-row-gallery-item`}
                    className={`${shortclass.checkbox.primary} bg-transparent border-2`}
                    onClick={(e) => {
                      let el = e.target as HTMLInputElement;
                      let isChecked = el.checked;

                      let chs = document.querySelectorAll(`.check-row-gallery-item${isChecked ? ':not(:checked)' : ':checked'
                        }`) as NodeListOf<HTMLInputElement>;

                      chs.forEach(ch => ch.checked = isChecked);

                      setHasItemSelected(isChecked)
                    }}
                  />
                </th>
                <th className="px-3 py-2 font-bold max-w-[14rem]">Nome</th>
                <th className="px-3 py-2 font-bold">Tamanho</th>
                <th className="px-3 py-2 font-bold">Criado em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {galleryItems.length === 0 || showingGalleryItems.length === 0 ? (
                <tr>
                  <td
                    colSpan={4}
                    className={`text-center text-sm text-gray-400 bg-gray-200/80 h-72 ${galleryItems.length === 0 ? 'cursor-pointer' : ''}`}
                    onClick={() => {
                      if (galleryItems.length > 0) return;
                      setIsModalAddOpen(true)
                    }}
                  >
                    {galleryItems.length === 0 ? "Adicione seus arquivos aqui" : "Nenhum arquivo encontrado com este filtro"}
                  </td>
                </tr>
              ) : galleryItems.filter((item) => showingGalleryItems.includes(item.id)).slice(
                pageIndex * perPage,
                (pageIndex + 1) * perPage).map((item, i) => (
                  <tr className="hover:bg-gray-200/60 border-b" key={`item-${item.id}`}>
                    <td className="px-3 py-4 w-10">
                      <input
                        type="checkbox"
                        id={`check-row-gallery-item-${item.id}`}
                        className={`check-row-gallery-item ${shortclass.checkbox.primary} bg-transparent border-2`}
                        value={item.id}
                        onClick={(e) => {
                          let hasAtLeast1Checked = document.querySelectorAll(`.check-row-gallery-item:checked`).length > 0;
                          let checkAll = document.getElementById(`check-row-gallery-item`) as HTMLInputElement;

                          if (hasAtLeast1Checked) {
                            setHasItemSelected(true);

                            checkAll.checked = document.querySelectorAll(
                              `.check-row-gallery-item:not(:checked)`
                            ).length === 0;
                          }
                          else {
                            setHasItemSelected(false);
                            checkAll.checked = false;
                          }
                        }}
                        data-value={item.name}
                      />
                    </td>
                    <td className="px-3 py-4 max-w-[12rem] truncate">{item.name}</td>
                    <td className="px-3 py-4">{filesize(item.size) as string}</td>
                    <td className="px-3 py-4">{moment(item.created_at).format('DD/MM/YYYY')}</td>
                    <td className="px-3 py-4">
                      <div className="flex gap-3 justify-end">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${item.src}`}
                          className={`
                            text-gray-600 outline-none
                            p-1.5 border border-gray-300 rounded-lg inline-block
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-gray-400 hover:bg-primary-300/40 hover:shadow
                          `}
                        ><DownloadAltIcon w={20} h={20} /></a>
                        <button
                          type="button"
                          className="
                            text-gray-600 outline-none
                            p-1.5 border border-gray-300 rounded-lg inline-block
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-gray-400 hover:bg-primary-300/40 hover:shadow
                          "
                          onClick={() => handleDeleteConfirmation(item.id, item.name)}
                        ><TrashIcon w={20} h={20} /></button>
                        <button
                          type="button"
                          className="
                            text-gray-600 outline-none
                            p-1.5 border border-gray-300 rounded-lg inline-block
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-gray-400 hover:bg-primary-300/40 hover:shadow
                          "
                          onClick={() => handleInformationFile(item)}
                        ><InfoIcon w={20} h={20} /></button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <OrderTableFooter
          perPage={perPage}
          pageIndex={pageIndex}
          totalPages={!!filter.search ? Math.ceil(showingGalleryItems.length / perPage) : totalPages}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          goToPage={goToPage}
          previousPage={previousPage}
          nextPage={nextPage}
          total={!!filter.search ? showingGalleryItems.length : galleryItems.length}
          isFiltering={!!filter.search}
        />
      </div>
      <SlideOver
        isOpen={infoModalOpen}
        onClose={() => {
          setInfoModalOpen(false);
          setSelectedFileInfo(null);
        }}
        title="Informações"
      >
        {selectedFileInfo ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-1">
            <tbody>
              {[
                { name: 'Nome', value: selectedFileInfo.name },
                {
                  name: 'Tipo', value: (() => {
                    const splitedName = selectedFileInfo.name.split('.');
                    if (splitedName.length > 1) return splitedName[splitedName.length - 1].toUpperCase();
                    return '-'
                  })()
                },
                { name: 'Tamanho', value: filesize(selectedFileInfo.size) as string },
                { name: 'Descrição', value: selectedFileInfo.description ?? '-' },
              ].map((info, i) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50" key={i}>
                  <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {info.name}:
                  </th>
                  <td className="px-2 py-4 text-right">
                    {info.value}
                  </td>
                </tr>
              ))}
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                <td colSpan={2} className="px-2 py-4">
                  <p className="pb-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Locais utilizado:
                  </p>
                  {selectedFileInfo.refs && selectedFileInfo.refs.length > 0 ? (
                    <div className="flex flex-col gap-2">
                      {selectedFileInfo.refs.map((ref) => (
                        <a href={ref.url} target="_blank" className="
                          bg-gray-200/50 rounded-lg py-1.5 px-2 flex items-center gap-2 justify-between
                          focus:outline-none focus:ring-2 focus:ring-offset-2
                          focus:ring-primary-300 hover:bg-primary-400/5 hover:border-2
                        " rel="noreferrer">
                          <span>Local: {ref.type}</span>
                          <ChevronDownIcon className="-rotate-90" w={20} h={20} />
                        </a>
                      ))}
                    </div>
                  ) : (
                    <span className="text-xs text-gray-400 pb-4">
                      Este arquivo ainda não foi utilizado em nenhum lugar
                    </span>
                  )}

                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-400 text-center py-6">Selecione um arquivo para ver suas informações</p>
        )}
      </SlideOver>
      {galleryInfo && (
        <SlideOverAddItem
          isOpen={isModalAddOpen}
          onClose={() => setIsModalAddOpen(false)}
          gallery={galleryInfo}
          setGalleryItems={setGalleryItems}
        />
      )}

      {/* Componente de Modal para confirmação de exclusão de mais de um arquivo */}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} options={{
        title: '',
        actionButton: {
          onClick: handleDeleteMultiple,
          theme: "primary",
          autoClose: false,
          text: "Confirmar",
        }
      }}>
        <div className="">
          <h1 className="text-xl font-bold pb-3 -mt-1">Confirmação de exclusão</h1>
          <p className="mb-4">Deseja realmente excluir os arquivos selecionados?</p>
          <ul className="pb-2 max-h-[calc(100vh-16rem)] overflow-y-auto">
            {filesOnDelete.map((file, index) => (
              <div className="border-b flex justify-between items-center" key={index}>
                <div className="flex flex-1 justify-between items-center gap-2">
                  <div className="py-2.5 flex flex-col">
                    <span className={file.status === 'em andamento' ? "text-sm text-primary-400 font-bold" : "text-sm text-gray-600"}>{file.name}</span>
                  </div>

                  <div className="py-2.5 flex pl-16">
                    {file.message && (
                      <span className={`text-xs ${file.status === 'finalizado' ? 'text-green-500' : file.status === 'erro' ? 'text-red-500' : 'text-gray-600'}`}>{file.message}</span>
                    )}
                  </div>
                </div>

                {file.status === "em andamento" && (
                  <div className="flex items-center mt-1 pr-1" style={{ width: '35px', height: '35px' }}>

                    <Spinner
                      aria-label="Carregando"
                      size="md"
                    />
                  </div>
                )}
              </div>
            ))}
          </ul>
        </div>
      </Modal>
    </Wrapper>
  )
}

interface OrderTableFooterType {
  perPage: number,

  pageIndex: number,
  totalPages: number,
  total: number,

  canPreviousPage: boolean,
  canNextPage: boolean,

  goToPage: (param: number) => void,
  previousPage: () => void,
  nextPage: () => void,

  isFiltering: boolean
}

const OrderTableFooter = ({ perPage, pageIndex, totalPages, canPreviousPage, canNextPage, goToPage, previousPage, nextPage, total }: OrderTableFooterType) => {
  return (
    <div className="w-full flex justify-between items-center mt-6">
      <div className="flex items-center gap-4 -mt-6">
        {total > 0 && (
          <p className="text-sm text-gray-600 pl-6">
            Mostrando <span className="font-medium">{pageIndex * perPage + 1}</span> à <span className="font-medium">{
              (() => {
                const calculatedTotal = (pageIndex + 1) * perPage
                return (calculatedTotal > total) ? total : calculatedTotal
              })()
            }</span> de <span className="font-medium">{total}</span> resultados
          </p>
        )}
      </div>
      <div className="flex items-center -mt-4 mr-6">
        <button
          className={`${canPreviousPage ? 'opacity-100' : 'opacity-40 pointer-events-none bg-transparent'
            } relative inline-flex items-center rounded-l-md
            border border-gray-300 px-2 py-2 text-sm font-medium text-gray-600 
            hover:bg-gray-300/40 focus:z-20 `}
          onClick={previousPage}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        {Array.from({ length: totalPages }).map((_, i) => {
          let hidden = false;
          if (totalPages > 4) {
            hidden = (
              i <= (pageIndex - 2) && i < (totalPages - 3)
            ) || (
                (pageIndex === 0 && i > (4 + pageIndex - 2)) ||
                (pageIndex > 0 && i > (3 + pageIndex - 2))
              );
          }

          return (
            <button
              key={`page-${i}`}
              type="button"
              aria-current={pageIndex === i ? "page" : undefined}
              className={`
                relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20
                ${hidden ? 'hidden' : 'inline-flex'}
                ${pageIndex === i ?
                  'z-10 border-primary-500/50 bg-primary-300/30 text-primary-600' :
                  'border-gray-300 hover:bg-gray-300/40 text-gray-500 hover:bg-gray-50'
                } 
              `}
              onClick={() => goToPage(i)}
            >{i + 1}</button>
          )
        })}
        <button
          className={`${canNextPage ? 'opacity-100' : 'opacity-40 pointer-events-none bg-transparent'
            } relative inline-flex items-center rounded-r-md
            border border-gray-300 px-2 py-2 text-sm font-medium text-gray-600 
            hover:bg-gray-300/40 focus:z-20`}
          onClick={nextPage}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { forgotPassword } from "../../services/authenticate";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";

import logoIcon from "../../shared-components/assets/logo-hub.png";
import { shortclass } from "../../styles/styles";
import { Modal } from "../../shared-components/utils/Modal";
import { EyeIcon, LockIcon, MailIcon } from "../../components/SvgIcons";
import { hubRoutes } from "../../shared-types/utils/routes";

interface formType {
  email: string
}

export const Login = () => {
  const { toast } = useNotify();
  const { signIn } = useAuth();

  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);
  const [UseEmail, setUserEmail] = useState<string>('');
  const [recoveryEmail, setRecoveryEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit, reset } = useForm<formType>({
    defaultValues: {
      email: ''
    }
  });

  const handleForgotPassword = async (formValues: formType) => {
    console.log('Enviando email de recuperação:', formValues.email);
    const res = await forgotPassword({ email: formValues.email });
    
    const finishAndRedirect = (resubmit_on?: number) => {
      reset();

      navigate(hubRoutes.auth.showCodeToken(
        encodeURIComponent(recoveryEmail)
      ) + (
        resubmit_on && resubmit_on > 0 ?
        `?resubmit_on=${resubmit_on}` : ''
      ));
    }

    if (!res.result) {
      if(res.data && res.data.type === 'recovery-email-already-sent'){
        toast.warning(res.response)
        finishAndRedirect(res.data.resubmit_on)
        return;
      }

      toast.error(res.response);
      return;
    }

    toast.success(res.response);
    finishAndRedirect(res.data?.resubmit_on)
    return;
  };

  async function handleUserAuthentication(e: FormEvent) {
    e.preventDefault()
    if (!UseEmail) {
      toast.warning('Preencha o seu email');
      document.getElementById('input-login-email')?.focus();
      return;
    }
    if (!password) {
      toast.warning('Preencha sua senha');
      document.getElementById('input-login-password')?.focus();
      return;
    }

    const data = {
      email: UseEmail,
      password: password,
      remember_me: !!document.querySelector('#check-login-remember-me:checked')
    }

    await signIn(data).then(() => {
      navigate('/')
    }).catch((error) => {
      toast.error(error.message);
    })
  }

  return (
    <div className="
      h-full flex
      bg-background
      bg-cover bg-no-repeat bg-center
      min-h-[100vh]
    ">
      <div className="
        min-h-full min-w-full sm:min-w-max max-w-[1500px]
        container grid items-center md:justify-center
        mx-auto
        pt-12 pb-8 p-0 xs:px-2 sm:px-8 md:px-10 lg:px-12
      " style={{ zIndex: 1 }}>
        <div className="
          bg-white xs:rounded-lg
          m-auto md:m-0
          px-2 xxs:px-4 xs:px-6 pt-9 pb-9
          w-full xs:w-[24rem]

        " style={{ maxWidth: "100vw" }}>
          <div>
            <img
              className="mx-auto h-10 xs:h-12 w-auto mt-4"
              src={logoIcon}
              alt="Your Company"
            />
          </div>
          <form
            className="mt-10 flex flex-col gap-6"
            method="POST"
            onSubmit={handleUserAuthentication}
          >
            <div className="rounded-md shadow-sm">
              <div>
                <label htmlFor="input-login-email" className="sr-only">Email</label>
                <div className="relative">
                  <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                    <MailIcon width="18" />
                  </span>
                  <input
                    id="input-login-email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={event => setUserEmail(event.target.value)}
                    value={UseEmail}
                    required
                    className={`${shortclass.input.roundedTop} pl-8`}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Senha</label>
                <div className="relative">
                  <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                    <LockIcon width="18" />
                  </span>
                  <input
                    id="input-login-password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    required
                    className={`${shortclass.input.roundedBottom} pl-8`}
                    placeholder="Senha"
                  />
                  <button
                    type="button"
                    className="absolute right-0 top-0 bottom-0 p-2 z-50 text-gray-700/80"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <EyeIcon eyeOpen={!showPassword} width="18" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex gap-1 xs:items-center justify-between flex-col xs:flex-row -mt-2 mb-2">
              <div className="flex items-center">
                <input
                  name="remember-me"
                  id="check-login-remember-me"
                  type="checkbox"
                  className={shortclass.checkbox.primary + ' !bg-primary-500'}
                  value="true"
                  defaultChecked={true}
                />
                <label
                  htmlFor="check-login-remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >Manter conectado</label>
              </div>
              <div className="hidden xs:block text-sm">
                <button
                  type="button"
                  onClick={() => { setModal(true); }}
                  className="font-medium text-primary-600 hover:text-primary-500"
                >Esqueci minha senha</button>
              </div>
            </div>
            <div>
              <button type="submit" className={`
                group relative w-full
                ${shortclass.button.primary} !bg-gradient-aside !font-semibold !mb-0
              `}>Entrar</button>
              <div className="xs:hidden text-sm text-center mt-2 -mb-4">
                <button
                  type="button"
                  onClick={() => { setModal(true); }}
                  className="font-medium text-primary-600 hover:text-primary-500"
                >Esqueci minha senha</button>
              </div>
            </div>
          </form>
        </div>

        <footer className="mt-auto d-flex items-center justify-center text-center">
          <p className="text-gray-600 text-sm">Ivrim {new Date().getUTCFullYear()} © Todos os direitos reservados</p>
        </footer>
      </div>

      <Modal
        isOpen={modal}
        setIsOpen={setModal}
        options={{
          title: 'Esqueci minha senha',
          actionButton: {
            theme: 'primary',
            text: 'Enviar Solicitação',
            onClick: () => {
              handleSubmit(handleForgotPassword)();
            },
            autoClose: false,
          },
        }}
      >
        <form
          id="form-recorver-password"
          onSubmit={handleSubmit(handleForgotPassword)}
          className="mt-5"
        >
          <div className="text-center mb-4">
            <p className="text-gray-600">
              Insira o e-mail que você usou no cadastro. Enviaremos um e-mail com instruções para redefinição da senha.
            </p>
          </div>
          <div className="w-full mx-auto">
            <div className="mb-2">
              <label htmlFor="input-recovery-pass-email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                {...register('email')}
                required={true}
                placeholder="Email"
                onChange={(event) => setRecoveryEmail(event.target.value)}
                className="w-full rounded-md shadow-sm px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
          </div>
          <button type="submit" className="d-none" id="btn-submit-form-recorver-password"></button>
        </form>
      </Modal>
    </div >
  );
}
import { useEffect, useRef, useState } from "react";
import { DownloadAltIcon, PlusIcon, SearchIcon } from "../../shared-components/utils/icons";
import { useParams } from "react-router-dom";
import { Wrapper } from "../../shared-components/Wrapper";
import { hubRoutes } from "../../shared-types/utils/routes";
import { HeaderBreadcrumbs } from "../../shared-components/Wrapper/v3/Wrapper";
import { IS_HASH_ROUTER } from "../../routes";
import { ClosingFolderDir, ClosingFolderFile, ClosingFolderType } from "../../shared-types";
import { appendClosingFolder, getClosingFolders } from "../../services/closingFolder";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { ModalCreateFolder } from "../../components/ClosingFolder/CreateFolderModal";
import { ModalCreateFile } from "../../components/ClosingFolder/CreateFileModal";
import { RecursiveClosingFolder } from "../../components/ClosingFolder/RecursiveClosingFolder";
import moment from "moment";
import JSZip from "jszip";

export const ClosingFolder = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const divSearchRef = useRef<HTMLDivElement>(null);

  const [folders, setFolders] = useState<ClosingFolderType[]>([]);
  const [paths, setPaths] = useState<string[]>([]);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const paramPaths = useParams();
  const currentDate = moment();
  const formattedDate = currentDate.format('YYYY-MM');
  const [currentData, setCurrentData] = useState<ClosingFolderType | ClosingFolderDir>();
  const [currentMonthFolder, setCurrentMonthFolder] = useState(true);
  useEffect(() => { loadClosingFolders() }, [])
  useEffect(() => {
    setCurrentMonthFolder(folders.some(folder => folder.title === formattedDate))
  },[folders])
  useEffect(() => {
    setPaths(paramPaths && paramPaths['*'] ? paramPaths['*'].split('/') : [])
  }, [paramPaths])
  useEffect(() => {
    if (window.history && window.history.pushState) window.history.pushState(
      null, '', (IS_HASH_ROUTER ? '#' : '') + hubRoutes.closing_folder.home(paths)
    );
  }, [paths]);
  useEffect(() => {
    setCurrentData(paths.length === 0 ? undefined : paths.length === 1 ? (folders.find(
      (folder) => folder.slug === paths[0] && folder.type === 'dir') as (ClosingFolderType | ClosingFolderDir | undefined)
    ) : (
      paths.reduce((acc, curr) => {
        if(!acc || acc.type !== 'dir' || !acc.items || acc.items.length === 0) return undefined;

        const finded = acc.items.find((folder) => folder.slug === curr)

        if(!finded || finded.type !== 'dir') return undefined;
        
        return finded;
      }, folders.find((folder) => folder.slug === paths[0]) as (ClosingFolderType | ClosingFolderDir | undefined))
    ))
  }, [paths, folders])

  async function loadClosingFolders() {
    if (!user) return;

    const res = await getClosingFolders(user.token);
    if (!res.result) {
      toast.error(res.response);
      return;
    }

    const compararItems = (a: ClosingFolderType | ClosingFolderDir | ClosingFolderFile, b: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => {
      if(a.type === 'dir' && b.type === 'file') return -1;
      if(a.type === 'file' && b.type === 'dir') return 1;
      return b.title.localeCompare(a.title);
    };
    const recursiveOrdenation = (folder: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) : ClosingFolderType | ClosingFolderDir | ClosingFolderFile => {
      if(folder.type !== 'dir') return folder;
      return {
        ...folder,
        items: (folder.items ?? []).map((subFolder) => recursiveOrdenation(subFolder)).sort(compararItems)
      }
    }
    if (res.data) setFolders(
      res.data.map(
        (folder) => recursiveOrdenation(folder)
      ).sort(compararItems) as ClosingFolderType[]
    );
  }
  async function createClosingFoldersOfThisMonth(){
    if (!user) return;

    const appendFolderResult = await appendClosingFolder([formattedDate], [], user.token);

    if (appendFolderResult.result) {
      loadClosingFolders();
      toast.success(`Pasta ${formattedDate} criada com sucesso.`);
    } else {
      toast.error(`Erro ao criar a pasta ${formattedDate}.`);
    }
  }
  async function handleDownload(data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile){
    if(data.type !== 'dir' || !data.items || data.items.length === 0) return;

    const handleZipRecursive = async (item: ClosingFolderDir | ClosingFolderFile, zip: JSZip) => {
      if (item.type === 'file') {
        if (!item.src.includes(".")) return
        const response = await fetch(item.src);
        const fileContent = await response.blob();
        const fileExtension = item.src.split('.').pop();

        zip.file(`${item.title}.${fileExtension}`, fileContent);
        return
      }
      if (item.type === 'dir' && item.items) {

        const subfolderZip = zip.folder(item.title);
        if (!subfolderZip) {
          toast.error("Não foi possível adicionar essa pasta ao zip")
          return
        }
        if (item.items && item.items.length > 0) {
          item.items.map(async (item) => await handleZipRecursive(item, subfolderZip))
        }
      }
    }

    const zip = new JSZip();

    await Promise.all(
      data.items.map(async (item) => await handleZipRecursive(item, zip))
    );

    const content = await zip.generateAsync({ type: 'blob' });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(content);
    downloadLink.download = `${data.title}.zip`;
    downloadLink.click();
  }  

  return (
    <>
      <Wrapper
        breadcrumbs={[
          { name: "Fechamentos Financeiros", href: hubRoutes.closing_folder.home() }, ...paths.reduce((acc, curr) => {
            if (acc.items) {
              const finded = acc.items.find((item: any) => item.slug === curr)
              if (finded) {
                acc.path.push(curr);

                const currPath = [...acc.path]
                acc.breadcrumbs.push({
                  name: finded.title,
                  subtitle: 'Fechamentos Financeiros',
                  onClick: () => setPaths(currPath)
                })
                acc.items = finded.items ?? []
              }
            }

            return acc
          }, { breadcrumbs: [] as HeaderBreadcrumbs[], items: folders as any, path: [] as string[] }).breadcrumbs
        ]}
        asideActive="Fechamentos Finan."
        module_name="Configurações"
      >
        {/* BEGIN: HEADER */}
        <div className="flex items-center justify-end mb-2">
          <div className="flex gap-2">
            {currentData && (
              <button
                type="button"
                className={`
                  flex items-center text-primary-700 font-semibold bg-gradient-light
                  hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                  rounded-lg text-sm px-3 pt-1.5 pb-1.5 text-center
                `}
                onClick={() => handleDownload(currentData)}
              >
                <DownloadAltIcon w={20} className="mr-2" /> Baixar Pasta
              </button>
            )}
            {!currentMonthFolder && (
              <button
                type="button"
                className={`
                  flex items-center text-primary-700 font-semibold bg-gradient-light
                  hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                  rounded-lg text-sm px-3 pt-1.5 pb-1.5 text-center
                `}
                onClick={createClosingFoldersOfThisMonth}
              >
                <PlusIcon w={20} className="mr-2" /> Criar Fechamento deste Mês
              </button>
            )}
            {paths.length > 0 && (
              <>
                <button
                  type="button"
                  className={`
                    text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
                    hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                    rounded-lg text-sm px-3 pt-1.5 pb-1.5 text-center
                  `}
                  onClick={() => setShowFolderModal(true)}
                >
                  <PlusIcon w={20} /> Criar Pasta
                </button>
                <button
                  type="button"
                  className={`
                    text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
                    hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                    rounded-lg text-sm px-3 pt-1.5 pb-1.5 text-center
                  `}
                  onClick={() => setShowFileModal(true)}
                >
                  <PlusIcon w={20} /> Criar Arquivo
                </button>
              </>
            )}
          </div>
        </div>
        <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 px-4 text-primary-700 h-16">
          <div className="group relative flex items-center ml-auto" ref={divSearchRef}>
            <input
              type="text"
              id="simple-search"
              className="
                hidden group-[.show]:block
                bg-gray-100/10 backdrop-blur-[25px]
                
                border-primary-500/20
                text-gray-600 text-sm rounded-lg 
                placeholder-primary-700

                focus:ring-gray-100/20 focus:border-gray-100/20 w-44 pr-10 p-2.5
              "
              value={filter}
              onChange={(e) => setFilter(
                e.target.value
              )}
              placeholder="Pesquisar..."
            />
            <button
              type="button"
              className="
                absolute inset-y-0 right-2
                flex justify-center items-center
                h-6 w-6 my-auto 
                rounded-full outline-none focus:ring-gray-100/50 focus:ring-1
              "
              onClick={() => {
                if (!divSearchRef.current) return;
                divSearchRef.current.classList.toggle('show');

                let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
                if (divSearchRef.current.classList.contains('show')) input.focus();
              }}
            ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
          </div>
        </div>
        {/* END: HEADER */}
        <div className="mt-6 min-h-[calc(100%-17rem)]">
          <div className="min-h-[calc(70vh-17rem)]">
            {folders.length === 0 ? (
              <div className="
                text-sm text-center rounded-lg
                text-gray-700/80 w-full min-h-[calc(70vh-17rem)] bg-primary-400/5 hover:bg-primary-400/10
                flex flex-1 self-center items-center justify-center
                mx-auto
              ">
                Você ainda não possui nenhum fechamento!
              </div>
            ):(
              <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {filter.length > 0 && paths.length === 0 ? (
                    <>
                      {folders.filter(subItem => subItem.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).length > 0 ? (
                        <>
                          {
                            folders.filter(subItem => subItem.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).map((item, index) => (
                              <RecursiveClosingFolder
                                key={item.slug}
                                paths={paths}
                                updateFolders={loadClosingFolders}
                                navigateTo={(to: string[]) => setPaths(to)}
                                breadcrumbs={[]}
                                data={item}
                                filter={filter}
                                onDownload={(data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => handleDownload(data)}
                              />
                            ))
                          }
                        </>
                      ) : (
                        <div className="
                          text-sm text-center text-gray-700/80 w-full min-h-[calc(70vh-17rem)] bg-primary-400/5 hover:bg-primary-400/10 flex self-center items-center justify-center mx-auto
                          md:col-span-2 lg:col-span-3 xl:col-span-4
                        ">
                          Não existe nenhuma pasta com esse filtro!
                        </div>

                      )}
                    </>
                  ) : folders.map((item, index) => (
                    <RecursiveClosingFolder
                      key={index}
                      paths={paths}
                      updateFolders={loadClosingFolders}
                      navigateTo={(to: string[]) => setPaths(to)}
                      breadcrumbs={[]}
                      data={item}
                      onDownload={(data: ClosingFolderType | ClosingFolderDir | ClosingFolderFile) => handleDownload(data)}
                      filter={filter}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>


        <ModalCreateFolder
          isOpen={showFolderModal}
          setIsOpen={setShowFolderModal}
          paths={paths}
          updateFolders={loadClosingFolders}
        />
        <ModalCreateFile
          isOpen={showFileModal}
          setIsOpen={setShowFileModal}
          paths={paths}
          updateFolders={loadClosingFolders}
        />
      </Wrapper>
    </>
  );
};

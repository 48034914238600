import React, { useState, useRef, useEffect } from 'react';
import styles from './OtpInput.module.scss';

interface OtpInputProps {
    pinCount: number;
    onCodeChanged: (code: string) => void;
    codeInputHighlightStyle?: React.CSSProperties;
    defaultValue: string;
}

export const OtpInput = (props: OtpInputProps) => {
    const { pinCount, onCodeChanged } = props;
    const [values, setValues] = useState<string[]>(Array(pinCount).fill(''));
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => { inputRefs.current[0]?.focus(); }, []);

    useEffect(() => {
        if(props.defaultValue){
            setValues(
                String(props.defaultValue).padEnd(4, ' ').split("").slice(0, pinCount).map(
                    (i) => i.trim()
                )
            )
        }
    }, [props.defaultValue])

    const handleInputChange = (index: number, newValue: string) => {
        let newValues = [...values];
        if(index === 0 && newValue.length === pinCount) newValues = newValue.split('')
        else newValues[index] = newValue.slice(0,1);
        setValues(newValues);

        const code = newValues.join('');
        onCodeChanged(code);

        if (newValue !== '' && index < pinCount - 1) {
            const nextInput = inputRefs.current[index + 1];
            nextInput?.focus();
            nextInput?.select();
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        if (e.key === 'Backspace' && index > 0) {
            e.preventDefault();
            const newValues = [...values];
            newValues[index] = '';
            setValues(newValues);
            const code = newValues.join('');
            onCodeChanged(code);

            const prevInput = inputRefs.current[index - 1];
            prevInput?.focus();
            prevInput?.select();
        }
    };

    return (
        <div className={styles.container_otp}>
            {Array.from({ length: pinCount }, (_, index) => (
                <div key={`key-${index}`} className={styles.container_otp__slot}>
                    <input
                        style={props.codeInputHighlightStyle}
                        ref={(el) => (inputRefs.current[index] = el!)}
                        className={styles.container_otp__slot__input}
                        value={values[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        type="number"
                    />
                </div>
            ))}
        </div>
    );
};

export const IframePPT = ({ url, className } : { url?: string, className?: string }) => (
  <div className={`
    ${(url && url !== '#') ? "before:content-[''] before:pt-[56.238%] before:block":''}
    relative overflow-hidden mt-10
    w-full ${className ? className : ''}
  `}>
    {(url && url !== '#') ? (
      <iframe 
        src={url}
        frameBorder={0}
        style={{
          border: 'none !important',
          outline: 'none !important',
          background: 'transparent !important',
          width: 'calc(100% + 3px)',
          height: 'calc(100% + 38px)',
          position: 'absolute',
          top: -2, left: -1
        }}
      />
    ):(
      <div className="bg-gray-100/50 rounded-xl h-[calc(100vh-14.5rem)] w-full flex items-center justify-center text-center text-gray-500">
        Esta dashboard não está disponível
      </div>
    )}
  </div>
)
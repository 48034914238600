import { wf } from "../shared-components/services/conn/api";
import { ResultAndResponse } from "../shared-types";
import {
  handleErrorResultAndResponse, headerBearer
} from "./api"

export interface NotifyOwnerData {
  body: string;
  subject: string;
  wf_ids?: string[];
}

interface ListSessionsResponse extends ResultAndResponse {
  data?: {
    key: string,
    number: string,
    connected: boolean,
    client?: {
      nome_fantasia: string,
      picture: string,
      id: string,
    },
    workflows?: {
      _id: string,
      theme: string,
      title: string,
      description?: string,
      user_id: string,
      status: string,
    }[]
  }[]
}

export const getListSessions = async (token: string): Promise<ListSessionsResponse> => {
  try {
    const { data } = await wf.get<ListSessionsResponse>(`/whatsapp/list-sessions`, headerBearer(token));

    return {
      ...data,
      data: (data.data ? data.data.map(session => ({
        ...session,
        number: session.number.length > 11 ? session.number.slice(2) : session.number
      })): undefined)
    };
  } catch (e) {
    console.error(e);

    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao listar as sessões'
    })
  }
};

export const disconnectSession = async (token: string, sessionKey: string): Promise<ResultAndResponse> => {
  try {
    const { data } = await wf.delete(`/whatsapp/disconnect/${sessionKey}`, headerBearer(token));

    return data;
  } catch (e) {
    console.error(e);

    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao desconectar o dispositivo'
    })
  }
};

export const sendTest = async (token: string, sessionKey: string): Promise<ResultAndResponse> => {
  try {
    const { data } = await wf.get(`/whatsapp/send-test/${sessionKey}`, headerBearer(token));

    return data;
  } catch (e) {
    console.error(e);

    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao desconectar o dispositivo'
    })
  }
};

export const notifyOwner = async (token: string, sessionKey: string, field: NotifyOwnerData): Promise<ResultAndResponse> => {
  try {
    const { data } = await wf.post(`/whatsapp/notify-owner/${sessionKey}`, field, headerBearer(token));

    return data;
  } catch (e) {
    console.error(e);

    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao enviar a notificação'
    })
  }
};
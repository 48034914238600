import { Label, TextInput } from "flowbite-react";
import { cnpjMask, phoneMask } from "../../../services/mask";

interface MACStepTwoProps{
  ddd: string,
  setDdd: React.Dispatch<React.SetStateAction<string>>,
  telefone: string,
  setTelefone: React.Dispatch<React.SetStateAction<string>>,
  email: string,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  contaCorrente: string,
  setContaCorrente: React.Dispatch<React.SetStateAction<string>>,
  agencia: string,
  setAgencia: React.Dispatch<React.SetStateAction<string>>,
  banco: string,
  setBanco: React.Dispatch<React.SetStateAction<string>>,
}
export const MACStepTwo = ({
  ddd,
  setDdd,
  telefone,
  setTelefone,
  email,
  setEmail,

  // TODO
  contaCorrente,
  setContaCorrente,
  agencia,
  setAgencia,
  banco,
  setBanco,
}: MACStepTwoProps) => (
  <div className="mt-5">
    <strong className="font-semibold uppercase bg-gray-100 p-0.5 text-center flex items-center justify-center rounded-sm pb-0 text-gray-700 mb-1">
      Informações de Contato
    </strong>
    <span className="text-xs text-gray-500 text-end block ml-auto mb-1">*opcional</span>
    <div className="mb-4 flex gap-4">
      <div className="max-w-[8rem]">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-ddd`}
            value={'DDD'}
          />
        </div>
        <TextInput
          id={`manage-company-ddd`}
          type="number"
          max={999}
          className="flex-1"
          value={ddd}
          onChange={(e) => setDdd(e.target.value)}
          placeholder={"DDD"}
          autoFocus
        />
      </div>
      <div className="flex-1">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-telefone`}
            value={'Telefone'}
          />
        </div>
        <TextInput
          id={`manage-company-telefone`}
          type={'text'}
          className="flex-1"
          value={phoneMask(telefone)}
          onChange={(e) => setTelefone(e.target.value)}
          placeholder={"Digite o telefone para contato"}
        />
      </div>
    </div>
    <div className="mb-4">
      <div className="mb-2 block">
        <Label
          className="block text-sm font-medium !text-gray-700"
          htmlFor={`manage-company-email`}
          value={'Email'}
        />
      </div>
      <TextInput
        id={`manage-company-email`}
        type={'email'}
        className="flex-1"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={"Digite o email da empresa"}
      />
    </div>
  </div>
)
import { Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client, Project } from "../../types";
import { getClient } from "../../services/client";
import { shortclass } from "../../styles/styles";
import { MoreVerticalIcon } from "../../components/SvgIcons";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { createProject, getProjects, toggleActiveProject } from "../../services/project";
import { useNotify } from "../../contexts/NotifyContext";

export const AdminProjects = () => {
  const { toast } = useNotify();
  const { user } = useAuth();

  const [client, setClient] = useState<Client>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    if(!user) return;
    loadClient();
    loadProjects();
  },[user]);
  
  async function loadClient(){
    if(!user || !user.current_client) return;

    const client = await getClient(user.token);
    if(client) setClient(client);
  }
  async function loadProjects(){
    if(!user) return;

    const response = await getProjects(user.token, true);
    if(response.result && response.data){
      setProjects(response.data);
    }else toast.error(response.response as String);
  }
  async function handleToggleActiveProject(project_id: string, is_activate: boolean){
    if(!user) return;

    const res = await toggleActiveProject(project_id, is_activate, user.token);
    if(res.result && res.data){
      setProjects((prevState) => prevState.map((project) => project.id === project_id ? res.data! : project));
      toast.success(res.response);
      return;
    }
    
    toast.error(res.response)
  }
  async function handleCreateProject(){
    if(!user) return;

    if(!projectName){
      toast.error('Insira o nome do projeto');
      return;
    }

    const res = await createProject(projectName, user.token);
    if(res.result && res.data){
      setProjects((prevState) => ([
        ...prevState,
        res.data!
      ]));
      setProjectName('');
      toast.success(res.response);
      return;
    }
    toast.error(res.response);
  }

  return (
    <Wrapper
      asideActive={['Admin Dados', 'Projetos']}
      breadcrumbs={[{ name: 'Admin Dados', href: '#', subtitle: 'Projetos' }]}
      module_name="Admin Panel"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl"/> : (
        <div className="w-full">
          <div className="flex gap-4 items-start flex-col lg:flex-row">
            <div className={`
              flex rounded-lg !bg-primary-300/10 backdrop-blur-[25px]
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 w-full lg:w-[24rem] xl:w-[28rem] min-h-[15rem] py-6 text-primary-800
            `}>
              <div className="flex items-center justify-between gap-2 px-6">
                <h5 className="text-lg font-semibold dark:text-white">Projetos</h5>
              </div>
              <div className="overflow-y-auto max-h-96">
                {projects && projects.length > 0 ? projects.map((project) => (
                  <div className="relative border-b last:border-b-0 border-gray-200/50 hover:bg-gray-100/20 hover:rounded-lg cursor-pointer px-2 mx-4" key={project.id}>
                    <div className="flex items-center py-2">
                      <div className="flex-1 flex justify-between items-center">
                        <div className="flex flex-col items-start">
                          <span className="whitespace-nowrap font-semibold text-primary-700/80">{project.name}</span>
                          {!project.is_active && (
                            <span className="rounded bg-gray-200/25 px-2 py-0.5 text-xs font-medium text-primary-400">
                              Inátivo
                            </span>
                          )}
                        </div>
                        <Dropdown
                          trigger={<MoreVerticalIcon color="rgb(31 71 127 / 0.8)"/>}
                          classNames={{ wrapper: "block mt-2 text-left" }}
                          orientation={null}
                          styles={{ list: { transform: 'translateX(-12rem)' } }}
                        >
                          <button
                            type="button"
                            className={shortclass.dropdownItem}
                            onClick={() => handleToggleActiveProject(project.id, !project.is_active)}
                          >{project.is_active ? 'Desativar':'Ativar'}</button>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )):(
                  <p className="
                    text-sm text-center text-gray-200/75
                    flex items-center justify-center
                    mx-6 p-4 m-auto h-40 rounded-lg
                    bg-gray-100/10
                  ">Não há projetos criados</p>
                )}
              </div>
            </div>
            <div className={`
              flex flex-1 rounded-lg !bg-primary-300/10 backdrop-blur-[25px]
              shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col gap-2
              mb-6 w-full py-6 text-primary-800
            `}>
              <div className="flex items-center justify-between gap-2 px-6">
                <h5 className="text-lg font-semibold dark:text-white">Criar novo projeto</h5>
              </div>
              <div className="px-6 pb-4">
                <div className="flex flex-col">
                  <div className="relative w-full">
                    <div className="mb-1">
                      <Label className="text-xs font-medium text-primary-400" htmlFor="new-project-name">Nome do projeto</Label>
                    </div>
                    <div className="flex">
                      <input
                        className={`
                          block w-full placeholder-primary-700
                          border  bg-gray-100/10 backdrop-blur-[25px] border-primary-500/20 text-gray-600
                          focus:ring-gray-100/20 focus:border-gray-100/20
                          dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                          dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
                          rounded-l-lg p-2.5 text-sm
                          read-only:bg-gray-200
                        `}
                        id="new-project-name"
                        type="text"
                        placeholder="Digite o nome do projeto"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                      <button
                        type="button"
                        className={`
                          flex justify-center 
                          rounded-r-lg border border-transparent 
                          py-2 px-4
                          text-sm font-medium 
                          focus:outline-none focus:ring-2 focus:ring-offset-2
                        text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
                        `}
                        onClick={handleCreateProject}
                      >Cadastrar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
import { Label, TextInput } from "flowbite-react";
import { cnpjMask } from "../../../services/mask";

interface MACStepOneProps{
  cnpj: string,
  setCnpj: React.Dispatch<React.SetStateAction<string>>,
  razaoSocial: string,
  setRazaoSocial: React.Dispatch<React.SetStateAction<string>>,
  nomeFantasia: string,
  setNomeFantasia: React.Dispatch<React.SetStateAction<string>>,
}
export const MACStepOne = ({
  cnpj,
  setCnpj,
  razaoSocial,
  setRazaoSocial,
  nomeFantasia,
  setNomeFantasia,
}: MACStepOneProps) => (
  <div className="mt-5">
    <strong className="font-semibold uppercase bg-gray-100 p-0.5 text-center flex items-center justify-center rounded-sm pb-0 text-gray-700 mb-4">
      Identificação da Empresa
    </strong>
    <div className="mb-4">
      <div className="mb-2 block">
        <Label
          className="block text-sm font-medium !text-gray-700"
          htmlFor={`manage-company-cnpj`}
          value={'CNPJ'}
        />
      </div>
      <TextInput
        id={`manage-company-cnpj`}
        type={'text'}
        className="flex-1"
        value={cnpjMask(cnpj)}
        onChange={(e) => setCnpj(e.target.value)}
        placeholder={"Digite o CNPJ da empresa"}
        autoFocus
        required
      />
    </div>
    <div className="mb-4">
      <div className="mb-2 block">
        <Label
          className="block text-sm font-medium !text-gray-700"
          htmlFor={`manage-company-razao-social`}
          value={'Razão Social'}
        />
      </div>
      <TextInput
        id={`manage-company-razao-social`}
        type={'text'}
        className="flex-1"
        value={razaoSocial}
        onChange={(e) => setRazaoSocial(e.target.value)}
        placeholder={"Digite a razão social da empresa"}
        required
      />
    </div>
    <div className="mb-4">
      <div className="mb-2 block">
        <Label
          className="block text-sm font-medium !text-gray-700"
          htmlFor={`manage-company-nome-fantasia`}
          value={'Nome Fantasia'}
        />
      </div>
      <TextInput
        id={`manage-company-nome-fantasia`}
        type={'text'}
        className="flex-1"
        value={nomeFantasia}
        onChange={(e) => setNomeFantasia(e.target.value)}
        placeholder={"Digite o nome fantasia da empresa"}
        required
      />
    </div>
  </div>
)
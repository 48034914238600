import { ResultAndResponse } from "../shared-types";
import { GalleryItemType, GalleryType } from "../types/gallery.type";
import { api, handleErrorResultAndResponse, headerBearer } from "./api"
import { __parseGalleryItem } from "./galleryItems";

interface GetGalleryResponse extends ResultAndResponse {
  data?: GalleryType
}
interface GetGalleriesResponse extends ResultAndResponse {
  data?: GalleryType[]
}
interface GetRecentsResponse extends ResultAndResponse {
  data?: GalleryItemType[]
}

export const createGallery = async (token: string, fields: { name: string }) : Promise<GetGalleryResponse> => {
  try{
    const { data } = await api.post('/gallery/new', fields, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return { ...data, data: data.data ? __parseGallery(data.data) : data.data };
  }catch(e){
    return handleErrorResultAndResponse(e,{
      result: false,
      response: 'Houve um erro inesperado ao tentar criar a galeria'
    })
  }
} 
export const getGalleries = async (token: string): Promise<GetGalleriesResponse> => {
  try {
    const { data } = await api.get<GetGalleriesResponse>('/gallery', headerBearer(token));

    if (!data.result) throw new Error(data.response);
    return {
      ...data,
      data: (data.data ?? []).map((gallery) => __parseGallery(gallery))
    };
  } catch (e) {
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível carregar suas galerias de arquivos'
    })
  }
}
export const getRecentsFromAllGalleries = async (token: string): Promise<GetRecentsResponse> => {
  try {
    const { data } = await api.get<GetRecentsResponse>('/gallery/recents', headerBearer(token));

    if (!data.result) throw new Error(
      data.response
    )

    return {
      ...data,
      data: data.data?.map(item => __parseGalleryItem(item))
    };
  } catch (e) {
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível obter os arquivos modificados recentemente'
    })
  }
}
export const deleteGallery = async (token: string, gallery_id: string) : Promise<ResultAndResponse> => {
  try{
    const { data } = await api.delete(`/gallery/${gallery_id}`, headerBearer(token));
    
    if(!data.result) throw new Error(
      data.response
    )

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar excluir a galeria'
    })
  }
}
export function __parseGallery(gallery: GalleryType) : GalleryType{
  return {
    ...gallery,
    num_items: gallery.num_items ?? 0,
    total_size: gallery.total_size ?? 0,
    items: gallery.items?.map(item => __parseGalleryItem(item))
  }
}
import { useEffect, useRef, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client } from "../../types";

import { useNotify } from "../../contexts/NotifyContext";
import { PlusIcon, SearchIcon, ArrowRightIcon } from "../../shared-components/utils/icons";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { ModalCompany } from "../../components/Company/ModalCompany";
import { getClients } from "../../services/client";
import { useSearchParams } from "react-router-dom";
import { FormCreateCompany } from "../../components/Company/ModalCompany/FormCreateCompany";
import { ivrimID } from "../../shared-components/services/conn/api";

export const AdminCompanies = () => {
  const divSearchRef = useRef<HTMLDivElement>(null);

  const { user } = useAuth();
  const { toast } = useNotify();
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<{ search: string, type: 'Nome' | 'CNPJ' }>({ search: '', type: 'Nome' });
  const [showingClients, setShowingClients] = useState<string[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [currentClient, setCurrentClient] = useState<Client | undefined>();
  const [modalAddClientIsOpen, setModalAddClientIsOpen] = useState(false);
  const [isOpenFormCreateClient, setIsOpenFormCreateClient] = useState(false);

  useEffect(() => {
    const clientId = searchParams.get('client')
    if(clientId){
      const findedClient = clients.find(c => c.id === clientId)
      if(findedClient){
        setCurrentClient(findedClient);
        setModalAddClientIsOpen(true);
      }
    }
  }, [user, clients])

  useEffect(() => { loadClients() }, [user])

  useEffect(() => {
    setShowingClients(filter.search ? (
      clients.filter((c) => (
        (
          filter.type === 'Nome' ? c.nome_fantasia : c.cnpj
        ).toLocaleLowerCase().includes(filter.search!.toLocaleLowerCase())
      )).map(c => c.id)
    ) : clients.map(c => c.id))
  }, [clients, filter])

  async function loadClients() {
    if (!user || isLoading || user.current_client !== ivrimID) return;
    
    setIsLoading(true)

    const res = await getClients(user.token)

    if (!res.result) toast.error(res.response)
    else {
      if (res.data) setClients(res.data.sort((a, b) => {
        const A = a.nome_fantasia.toUpperCase();
        const B = b.nome_fantasia.toUpperCase();

        return A < B ? -1 : (A > B ? 1 : 0)
      }))
    }

    setIsLoading(false)
  }

  function handleOpenNewCompany() {
    setIsOpenFormCreateClient(true);

    let el = document.getElementById('new-user-name') as HTMLInputElement;
    if (el) el.focus();
  }
  function handleOpenCompany(client: Client){
    if(!client) return;

    searchParams.set('client', client.id)
    setSearchParams(searchParams)

    setCurrentClient(client);
    setModalAddClientIsOpen(true);
  }
  return (
    <Wrapper
      asideActive={['Admin Hub', 'Empresas']}
      breadcrumbs={[
        { name: 'Admin Hub', href: '#', subtitle: 'Empresas' }
      ]}
      module_name="Admin Panel"
      isAdmin
    >
      <div>
      {isLoading && <Loading className="h-[calc(100vh-2rem)] rounded-xl" />}
        <div className="flex items-center justify-between">
          <div className="flex gap-2 mb-2 mt-auto ml-auto">
            <button
              type="button"
              className={`
              text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
              hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
              rounded-lg text-sm whitespace-nowrap
              px-3 pt-1.5 pb-1.5 text-center w-full
              `}
              onClick={handleOpenNewCompany}
            ><PlusIcon w={20} /> Nova Empresa</button>
          </div>
        </div>
        <div className="bg-gradient-light min-h-[4rem] backdrop-blur-[25px] rounded-lg flex justify-between items-center gap-2 mt-4 px-4 text-primary-700">
          <div className="max-sm:hidden">
            <Dropdown
              classNames={{
                list: `
                  absolute left-0 z-10
                  -mt-1 w-56 origin-top-right rounded-md
                  bg-gray-100/70 backdrop-blur-[25px] shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none pb-2
                `
              }}
              trigger={
                <div className="
                  my-3 w-40 h-10 border border-gray-50/10
                  flex items-center justify-between p-4 rounded-md bg-gray-100/5 
                  text-sm backdrop-blur-[25px] font-semibold
                ">
                  {filter.type}
                  <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90" />
                </div>
              }
            >
              <button
                onClick={() => setFilter({ search: '', type: 'Nome' })}
                className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
              >Nome</button>
              <button
                onClick={() => setFilter({ search: '', type: 'CNPJ' })}
                className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
              >CNPJ</button>
            </Dropdown>
          </div>

          <div className="group relative" ref={divSearchRef}>
            <input
              type="text"
              id="simple-search"
              className="
                hidden group-[.show]:block
              bg-gray-100/10 backdrop-blur-[25px]
                
                border-primary-500/20
                text-gray-600 text-sm rounded-lg 
                placeholder-primary-700

                focus:ring-gray-100/20 focus:border-gray-100/20 w-full pr-10 p-2.5
              "
              value={filter.search}
              onChange={(e) => setFilter((prevState) => ({
                ...prevState,
                search: e.target.value
              }))}
              placeholder="Pesquisar..."
            />
            <button
              type="button"
              className="absolute inset-y-0 right-2 flex items-center"
              onClick={() => {
                if (!divSearchRef.current) return;
                divSearchRef.current.classList.toggle('show');

                let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
                if (divSearchRef.current.classList.contains('show')) input.focus();
                else {
                  setFilter(prevState => ({ ...prevState, search: '' }))
                  input.value = '';
                }
              }}
            ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
          </div>
        </div>
        <div className="mt-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
            {clients.filter((c) => showingClients.includes(c.id)).map(client => (
              <div
                className="
                  bg-gradient-light rounded-lg px-4 py-3
                  text-gray-700
                  flex flex-col
                "
                onClick={() => handleOpenCompany(client)}
              >
                <div
                  className="pt-0.5 pb-1 flex-1 flex flex-col gap-0.5 cursor-pointer text-primary-400"
                >
                  <strong className="truncate hover:text-clip block hover:whitespace-normal text-primary-800">{client.nome_fantasia}</strong>
                  <p className="text-sm mb-5 -mt-1">
                    {client.cnpj}
                  </p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      <ModalCompany
        isOpen={modalAddClientIsOpen}
        setIsOpen={() => {
          setModalAddClientIsOpen(false);
          setCurrentClient(undefined);
          searchParams.delete('client')
          setSearchParams(searchParams)
        }}
        selectedCompany={currentClient!}
      />
      <FormCreateCompany
        isOpen={isOpenFormCreateClient}
        onClose={() => setIsOpenFormCreateClient(false)}
        onCreate={(client: Client) => {
          setClients((prevState) => [...prevState, client])
          handleOpenCompany(client)
        }}
      />
    </Wrapper>
  );
}


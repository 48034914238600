import { Conciliacao } from "../pages/ReceivementModule/Reconciliation";
import { api, headerBearer } from "./api";

let cacheReconciliations : { company: string, token: string, data: Conciliacao[] }[] = [];
const clearCacheReconciliation = (company: string, token: string) => cacheReconciliations = cacheReconciliations.filter(
  (cache) => cache.company !== company && cache.token !== token
) 
export const findReconciliations = async(company: string, token: string) : Promise<Conciliacao[]> => {
  try{
    if(cacheReconciliations.length > 0){
      const findedCache = cacheReconciliations.find((cache) => cache.company === company && cache.token === token)
      if(findedCache){
        console.log('[cached-reconciliations]')
        return findedCache.data
      }
    }
  }catch(e){}
  try {
    const { data } = await api.get(`/reconciliation/${company}`,headerBearer(token));
    console.log('[requested-reconciliation]');
    if(data.data){
      cacheReconciliations.push({
        company,
        token,
        data: data.data
      })

      return data.data;
    }
  }catch (error){ console.error(error) }

  return [];
}

export const deleteConciliation = async(id: string, company: string, token: string) => {
  try {
    const { data } = await api.delete(`/conciliation/${id}`,headerBearer(token));

    clearCacheReconciliation(company, token);
    return data
  } catch (error: any) {
    throw new Error(error.message?? "Erro inesperado");
  }
}

export const updateConciliation = async(body: Partial<Conciliacao>, company: string, token: string) => {
  try {
    const { data } = await api.put('/conciliation/',body,headerBearer(token));

    clearCacheReconciliation(company, token);
    return data?.data;
  } catch (error) {
    throw new Error("");   
  }
}
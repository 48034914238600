import { Timeline } from "flowbite-react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { getOrderHistory } from "../../services/orderHistory";
import { OrderFormattedType, OrderHistoryType } from "../../types";
import { Loading } from "../Loading";

export const OrderTimeline = ({ order } : {
  order: OrderFormattedType
}) => {
  const { user } = useAuth();
  const { toast } = useNotify();
  
  const [history, setHistory] = useState<OrderHistoryType[]>();

  useEffect(() => {
    if(!user) return;

    (async () => {
      const res = await getOrderHistory(user.token, order.id);
      if(!res.result){
        toast.error(res.response);
        return;
      }

      if(res.result && res.data) setHistory(res.data);
    })()
  },[user, order, order.id]);

  return (
    <div className="max-h-[calc(100vh-14rem)] overflow-y-auto pl-2 -ml-1">
      {history ? (
        <Timeline>
          {history.map((point, i) => (
            <Timeline.Item
              key={`${point.id}`}
            >
              <Timeline.Point/>
              <Timeline.Content>
                <Timeline.Time>{ point.date }</Timeline.Time>
                <Timeline.Title>
                  <span className="text-gray-800">{ point.user.email }</span>  
                </Timeline.Title>
                <Timeline.Body>
                  { point.description }
                  <div className="grid grid-cols-2 gap-2 mt-3">
                    <div className="flex flex-col">
                      <strong className="font-semibold uppercase text-xs leading-none">Etapa:</strong><span>{point.stage.name}</span>
                    </div>
                    <div className="flex flex-col">
                      <strong className="font-semibold uppercase text-xs leading-none">Status:</strong><span>{point.status.name}</span>
                    </div>
                  </div>
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : <Loading/>}
    </div>
  );
};
import { Link } from "react-router-dom"
import { ChevronLeftIcon } from "../../components/SvgIcons";
import  "./style.css";
import { shortclass } from "../../styles/styles";

interface PageErrorProps{
  code?: string,
  title?: string,
  message?: string
}
export const PageError = ({
  code = '404',
  title = 'Página não encontrada',
  message = 'A página que você está procurando não existe ou foi removida.',
}: PageErrorProps) => {
  return (
    <div className="page_error bg-gradient-primary">
      <div className="page_error__content">
        <h1 className="text-8xl font-semibold text-gray-50 mb-1">{code}</h1>
        <h2 className="text-lg uppercase font-semibold text-gray-400 mb-3">{title}</h2>
        <p className="text-gray-400/80 max-w-md">{message}</p>
        <div>
          <Link to="/" className={`
            ${shortclass.button.normal}
            text-gray-700 font-semibold focus:ring-gray-300 bg-gray-100 hover:bg-gray-200
            mt-4 w-fit mx-auto uppercase text-xs items-center
          `}>
            <ChevronLeftIcon />
            Voltar para o início</Link>
        </div>
      </div>
    </div>
  )
}

import moment from 'moment';

import { Wrapper } from "../../shared-components/Wrapper"
import { shortclass } from "../../styles/styles";
import { useEffect, useState } from "react";
import { NotificationType, NotificationTypeType, availableNotificationTypeFormatted } from "../../shared-types/notification.type";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { TableFooter } from "../../shared-components/TableFooter";
import { NotificationIconOrDefaultByType } from "../../shared-components/Wrapper/v3/Notification/NotificationIconOrDefaultByType";
import { getNotifications, markAsArchived, markAsViewed } from "../../shared-components/services/notification";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { ArchiveIcon, ArrowRightIcon, DropboxIcon, EnvelopeIcon, EnvelopeOpenIcon } from '../../shared-components/utils/icons';
import { Loading } from '../../components/Loading';
import { ResultAndResponse } from '../../shared-types';
import { handleRegexUrl } from '../../shared-types/utils/routes';
import { useNavigate } from 'react-router-dom';

type NotificationStatusType = 'unviewed' | 'viewed' | 'archived'
const notificationStatus : Record<NotificationStatusType, string> = {
  unviewed: 'Não Visualizadas',
  viewed: 'Visualizadas',
  archived: 'Arquivadas',
}
export const Notification = () => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [viewedNotifications, setViewedNotifications] = useState<NotificationType[]>([]);
  const [archivedNotifications, setArchivedNotifications] = useState<NotificationType[]>([]);

  const [hasSelected, setHasSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [filter, setFilter] = useState<{ type?: NotificationTypeType, status: NotificationStatusType }>({ status: 'unviewed' })
  const [isFiltering, setIsFiltering] = useState(false);
  //#region HANDLE PAGINATION
  const [total, setTotal] = useState<Record<NotificationStatusType, number | undefined>>({
    unviewed: undefined,
    archived: undefined,
    viewed: undefined
  });
  const [totalPartial, setTotalPartial] = useState<Record<string, number>>({})
  const [showingNotifications, setShowingNotifications] = useState<NotificationType[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 10;

  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);

  const calcTotalPages = (totalDatas: number) => {
    let numPages = Math.ceil(totalDatas / perPage);
    if(numPages <= 0) numPages = 1;

    setTotalPages(numPages);
  };
  const previousPage = () => setPageIndex((prevState) => {
    let newIndex = prevState - 1;
    if(newIndex >= 0) return newIndex;
    return prevState;
  });
  const nextPage = () => setPageIndex((prevState) => {
    let totalPagesInDB = Math.ceil((
      isFiltering ? showingNotifications.length : total[filter.status] ?? 0
    ) / perPage);
    let newIndex = prevState + 1;

    if(newIndex >= totalPagesInDB || newIndex < 0) return prevState;
    if(newIndex >= totalPages) onLoad();

    return newIndex;
  });
  const goToPage = (index: number) => {
    let totalPagesInDB = Math.ceil((total[filter.status] ?? 0) / perPage);

    if(index >= totalPagesInDB || index < 0) return;
    if(index >= totalPages) onLoad();
  
    setPageIndex(() => index);
  }
  //#endregion HANDLE PAGINATION

  useEffect(() => { onLoad() },[user])
  useEffect(() => {
    calcTotalPages(
      filter.status === 'unviewed' ? notifications.length :
      filter.status === 'viewed' ? viewedNotifications.length :
      filter.status === 'archived' ? archivedNotifications.length : 0
    )
  }, [
    notifications,
    viewedNotifications,
    archivedNotifications,
    filter.status,
    total,
  ])
  useEffect(() => {
    if(pageIndex !== 0) setPageIndex(0)
  },[filter])
  useEffect(() => {
    setShowingNotifications(handleFilter())
  },[notifications, viewedNotifications, archivedNotifications, filter]);
  useEffect(() => {
    setCanPreviousPage(pageIndex > 0)
    setCanNextPage(pageIndex < (Math.ceil((
      isFiltering ? showingNotifications.length : total[filter.status] ?? 0
    ) / perPage) - 1))
  },[pageIndex, total, perPage]);

  async function onLoad(){
    if(!user || isLoading) return;

    let targetNotifications : NotificationType[] = filter.status === 'unviewed' ? notifications :
      filter.status === 'viewed' ? viewedNotifications :
      filter.status === 'archived' ? archivedNotifications : []
    const dispatcher = filter.status === 'unviewed' ? setNotifications :
      filter.status === 'viewed' ? setViewedNotifications :
      filter.status === 'archived' ? setArchivedNotifications : undefined

    if(filter.type) targetNotifications = targetNotifications.filter(
      (notif) => notif.type === filter.type
    )

    const skip = targetNotifications.length;

    setIsLoading(true);
    await (async () => {
      const res = await getNotifications({
        token: user.token,
        ...(skip > 0 ? { skip }:{}),
        ...(filter.type ? { type: filter.type }:{}),
        ...(filter.status !== 'unviewed' ? (
          filter.status === 'archived' ? { is_archived: true } :
          filter.status === 'viewed' ? { viewed: true } : {}
        ) : {})      
      })
      
      if(!res.result){
        toast.error(res.response)
        return;
      }

      if(!res.data) return;
      
      if(dispatcher){
        const ids = res.data!.datas.map(d => d.id)
        dispatcher((prevState) => [
          ...prevState.filter(state => !ids.includes(state.id)),
          ...res.data!.datas
        ])
      }
      if(res.data.total > -1){
        if(!filter.type) setTotal((prevState) => ({
          ...prevState,
          [filter.status]: res.data!.total
        }))
        else setTotalPartial((prevState) => ({
          ...prevState,
          [`${filter.status}:${filter.type}`]: res.data!.total
        }))
      }
    })()
    setIsLoading(false)
  }
  function handleFilter() : NotificationType[] {
    let targetNotifications : NotificationType[] = filter.status === 'unviewed' ? notifications :
      filter.status === 'viewed' ? viewedNotifications :
      filter.status === 'archived' ? archivedNotifications : []

    if(filter.type) targetNotifications = targetNotifications.filter(
      (notif) => notif.type === filter.type
    )

    if(targetNotifications.length < perPage){
      if(filter.type){
        if(
          totalPartial[`${filter.status}:${filter.type}`] === undefined ||
          totalPartial[`${filter.status}:${filter.type}`] >= perPage
        ){
          console.log('[handle-filter-type:on-load-called]')
          onLoad()
        }
      }else
      if(total[filter.status] === undefined || total[filter.status]! >= perPage){
        console.log('[handle-filter:on-load-called]')
        onLoad()
      }
    }

    return targetNotifications;
  }
  function handleChangeFilter(type?: NotificationTypeType){
    setFilter((prevState) => ({
      ...prevState, type
    }))

    setIsFiltering(!!type)
  }
  //#region UPDATE STATUS
  let inViewing = false
  async function handleMultipleMarkAsViewed(unviewed = false){
    handleMultiple({
      controlVariable: inViewing,
      messages: {
        onload: `Já existe uma fila de ${unviewed ? 'remoção de visualização':'visualizações'} em andamento. Aguarde a finalização para iniciar uma nova.`,
        confirm_title: unviewed ? 'Remover Visualização':'Confirmar Visualização',
        confirm_message: (total: number) => `Deseja ${unviewed ? 'desmarcar':'marcar'} as ${total} notificações como visualizadas?` 
      },
      callback: (id: string | string[]) => handleMarkAsViewed(id, unviewed)
    })
  }
  let inArchiving = false
  async function handleMultipleMarkAsArchived(unarchive = false){
    handleMultiple({
      controlVariable: inArchiving,
      messages: {
        onload: `Já existe uma fila de ${unarchive ? 'desarquivamento':'arquivamento'} em andamento. Aguarde a finalização para iniciar uma nova.`,
        confirm_title: unarchive ? 'Confirmar Desarquivameto':'Confirmar Arquivamento',
        confirm_message: (total: number) => `Deseja arquivar as ${total} notificações?`
      },
      callback: (id: string | string[]) => handleMarkAsArchived(id, unarchive)
    })
  }
  const handleMultiple = ({ messages, controlVariable, callback }:{
    controlVariable: boolean,
    messages: {
      onload: string,
      confirm_title: string,
      confirm_message: (total: number) => string
    },
    callback: (ids: string | string[]) => Promise<void>,
  }) => {
    if(!user) return;

    if(controlVariable){
      toast.warning(messages.onload);
      return;
    }
    let checkeds = Array.from(
      document.querySelectorAll('.check-row-home-table:checked') as NodeListOf<HTMLInputElement>
    );
    let total = checkeds.length;
    if(total === 0){
      toast.warning('Selecione pelo menos 1 notificação na tabela');
      return;
    }
  
    const makeUpdate = async () => {
      try{
        let chs = document.querySelectorAll('.check-row-home-table:checked') as NodeListOf<HTMLInputElement>;
        const ids : string[] = []

        chs.forEach(c => ids.push(c.value))
        
        await callback(ids)

        chs.forEach(ch => ch.checked = false);

        controlVariable = false;
      }catch(e){
        console.error(e);
        controlVariable = false;
      }
      setHasSelected(false);
    }

    if(total > 1) showMessage((
      <p>{messages.confirm_message(total)}</p>
    ),{
      title: messages.confirm_title,
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeUpdate,
        text: 'Sim',
        theme: 'primary'
      }
    });
    else makeUpdate()
  }
  async function handleMarkAsViewed(id: string | string[], unviewed = false, ignoreToast = false){
    const effect = (findedNotifications: NotificationType[]) => {
      const archived : NotificationType[] = []
      const outhers : NotificationType[] = []

      findedNotifications.forEach((notif) => {
        if(notif.is_archived) archived.push(notif)
        else outhers.push(notif)
      })

      if(unviewed){
        if(outhers.length > 0){
          setViewedNotifications((prevState) => prevState.filter(state => !outhers.find((o) => o.id  === state.id)))
          setNotifications((prevState) => [
            ...outhers.map((findedNotification) => ({
              ...findedNotification,
              viewed: false
            })),
            ...prevState
          ])
        }
        if(archived.length > 0) setArchivedNotifications((prevState) => prevState.map(state => archived.find((a) => a.id  === state.id) ? ({
          ...state,
          viewed: false
        }): state))
      }else{
        if(outhers.length > 0){
          setNotifications((prevState) => prevState.filter(state => !outhers.find((o) => o.id  === state.id)))
          setViewedNotifications((prevState) => [
            ...outhers.map((findedNotification) => ({
              ...findedNotification,
              viewed: true
            })),
            ...prevState
          ])
        }
        if(archived.length > 0) setArchivedNotifications((prevState) => prevState.map(state => archived.find((a) => a.id  === state.id) ? ({
          ...state,
          viewed: true
        }): state))
      }
    }

    updateNotificationsStatus({
      id,
      messages: {
        empty: `Selecione pelo menos 1 notificação para ser ${unviewed ? 'desmarcada como visualizada' : 'visualizada'}`,
        success: unviewed ? 'Notificação desmarcada como visualizada':'Notificação marcada como visualizada'
      },
      effect,
      callback: (ids: string[], token: string) => markAsViewed(ids, token, unviewed),
      ignoreToast
    })
  }
  async function handleMarkAsArchived(id: string | string[], unarchived = false){
    const effect = (findedNotifications: NotificationType[]) => {
      const viewed : NotificationType[] = []
      const unviewed : NotificationType[] = []

      findedNotifications.forEach(notif => {
        if(notif.viewed) viewed.push(notif)
        else unviewed.push(notif)
      })
      if(unarchived){
        setArchivedNotifications((prevState) => prevState.filter(state => !findedNotifications.find((o) => o.id  === state.id)))
        if(viewed.length > 0) setViewedNotifications((prevState) => [
          ...viewed.map((findedNotification) => ({
            ...findedNotification,
            is_archived: false
          })),
          ...prevState
        ])
        if(unviewed.length > 0) setNotifications((prevState) => [
          ...unviewed.map((findedNotification) => ({
            ...findedNotification,
            is_archived: false
          })),
          ...prevState
        ])
      }else{
        if(viewed.length > 0) setViewedNotifications((prevState) => prevState.filter(state => !viewed.find((o) => o.id  === state.id)))
        if(unviewed.length > 0) setNotifications((prevState) => prevState.filter(state => !unviewed.find((o) => o.id  === state.id)))
        setArchivedNotifications((prevState) => [
          ...findedNotifications.map((findedNotification) => ({
            ...findedNotification,
            is_archived: true
          })),
          ...prevState
        ])
      }
    }
    updateNotificationsStatus({
      id,
      messages: {
        empty: `Selecione pelo menos 1 notificação para ser ${unarchived ? 'desarquivar':'arquivada'}`,
        success: unarchived ? 'Notificação desarquivada':'Notificação arquivada'
      },
      callback: (ids: string[], token: string) => markAsArchived(ids, token, unarchived),
      effect
    })
  }
  const updateNotificationsStatus = async ({ id, messages, callback, effect, ignoreToast = false }:{
    id: string | string[],
    messages: {
      empty: string,
      success: string
    },
    callback: (ids: string[], token: string) => Promise<ResultAndResponse>,
    effect: (findedNotification: NotificationType[]) => void,
    ignoreToast?: boolean
  }) => {
    if(!user) return;

    const ids = typeof id === 'string' ? [id] : id;
    if(ids.length === 0){
      toast.warning(messages.empty)
      return
    }

    const findedNotifications = [...notifications,...viewedNotifications,...archivedNotifications].filter(
      (notification) => ids.includes(notification.id)
    )

    if(findedNotifications.length === 0){
      toast.warning(ids.length > 1 ? 'Notificações não encontradas' : 'Notificação não encontrada')
      return
    }
    else if(findedNotifications.length !== ids.length) toast.warning('Algumas notificações não foram encontradas')

    const res = await callback(ids, user.token)
    if(!res.result){
      if(ignoreToast) toast.error(res.response);
      return;
    }

    if(ignoreToast) toast.success(messages.success)

    effect(findedNotifications)
  }
  //#endregion UPDATE STATUS
  function onDetails(notification: NotificationType){
    if(!notification.viewed) handleMarkAsViewed(notification.id, false, true)
    showMessage((
      <div>
        <p className="text-gray-500">{notification.description}</p>
      </div>
    ),{
      title: notification.title,
      cancelButtonText: 'Voltar',
      ...(notification.redirect_to ? {
        actionButton: {
          onClick: () => {
            const url = handleRegexUrl(notification.redirect_to! as any, user?.token)
            if(url.slice(0,4) === 'http') window.location.href = url
            else navigate(url)
          },
          theme: 'primary',
          text: 'Acessar'
        }
      }:{})
    })
  }

  return (
    <Wrapper
      module_name="Configurações"
      asideActive={['Notificações', 'Todas']}
      breadcrumbs={[{ name: 'Notificações', href: '#', subtitle: 'Todas' }]}
    >
      <div className="mb-6">
        <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 mb-4 px-4 text-primary-700 relative z-10">
          <div className="flex items-center my-auto gap-4">
            <div className="max-sm:hidden">
              <Dropdown
                classNames={{ list: `
                  absolute left-0 z-10
                  -mt-1 w-56 origin-top-right rounded-md
                  bg-gray-100/70 backdrop-blur-[25px] shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none pb-2
                ` }}
                trigger={
                  <div className="
                    my-3 w-40 h-10 border border-gray-50/10
                    flex items-center justify-between p-4 rounded-md bg-gray-100/5 
                    text-sm backdrop-blur-[25px] font-semibold
                  ">
                    {!filter.type ? 'Todos' : availableNotificationTypeFormatted[filter.type as NotificationTypeType]}
                    <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90"/>
                  </div>
                }
              >
                {[[undefined, 'Todos'], ...Object.entries(availableNotificationTypeFormatted)].map(([type, name]) => (
                  <button
                    className={`
                      text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] 
                      w-full text-start p-2 hover:bg-primary-300/10
                      ${type === filter.type ?'bg-primary-300/20 shadow text-gray-900':''}
                    `}
                    onClick={() => handleChangeFilter(type as NotificationTypeType | undefined)}
                    key={type ?? 'all'}
                  >{name}</button>
                ))}
              </Dropdown>
            </div>
            {hasSelected && (
              <div className="flex items-center gap-3 mr-4">
                <button
                  type="button"
                  className="
                    rounded-lg hover:border focus:border leading-none
                    shadow-sm hover:bg-gray-200
                    text-primary-900/80 font-semibold
                    focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                  "
                  onClick={() => handleMultipleMarkAsViewed(filter.status === 'viewed')}
                >
                  {filter.status === 'viewed' ? (
                    <EnvelopeIcon w={20} h={20}/>
                  ):(
                    <EnvelopeOpenIcon w={20} h={20}/>
                  )}
                </button>
                {filter.status === 'archived' && (
                  <button
                    type="button"
                    className="
                      rounded-lg hover:border focus:border leading-none
                      shadow-sm hover:bg-gray-200
                      text-primary-900/80 font-semibold
                      focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                    "
                    onClick={() => handleMultipleMarkAsViewed(true)}
                  ><EnvelopeIcon w={20} h={20}/></button>
                )}
                <button
                  type="button"
                  className="
                    rounded-lg hover:border focus:border leading-none
                    shadow-sm hover:bg-gray-200
                    text-primary-900/80 font-semibold
                    focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                  "
                  onClick={() => handleMultipleMarkAsArchived(filter.status === 'archived')}
                >
                  {filter.status === 'archived' ? (
                    <DropboxIcon w={20} h={20}/>
                  ):(
                    <ArchiveIcon w={20} h={20}/>
                  )}
                </button>
            </div>
          )}
          </div>
          <div className="md:hidden max-sm:w-full">
            <Dropdown
              classNames={{ list: `
                absolute right-0 z-10
                -mt-1 w-56 origin-top-right rounded-md
                bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                ring-1 ring-black ring-opacity-5
                focus:outline-none pb-2
              `, button: 'max-sm:w-full', wrapper: 'relative inline-block text-left group max-sm:w-full' }}
              trigger={
                <div
                  className="my-3 w-40 max-sm:w-full h-10 border border-gray-50/10 flex items-center justify-between p-4 rounded-md bg-gray-100/5 text-sm backdrop-blur-[25px] font-semibold"
                >
                  
                  {notificationStatus[filter.status] ?? 'Status de Notificação'}
                  <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90"/>
                </div>
              }
            >
              {Object.entries(notificationStatus).map(([status, name]) => (
                <button
                  className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
                  onClick={() => setFilter((prevState) => ({
                    ...prevState,
                    status: status as NotificationStatusType
                  }))}
                  key={status}
                >{name}</button>
              ))}
            </Dropdown>
          </div>
          <ul className="hidden md:flex gap-4 text-sm h-16 text-primary-700">
            {Object.entries(notificationStatus).map(([status, name]) => (
              <li className={`group ${filter.status === status ? 'active':''}`} key={status}>
                <button
                  type="button"
                  className={`
                    relative h-full px-2 text-center font-semibold
                    
                    after:w-full after:h-1
                    after:-bottom-0.5 after:left-0
                    after:rounded-lg

                    group-[.active]:after:content['']
                    group-[.active]:after:absolute 
                    group-[.active]:after:block 
                    group-[.active]:after:bg-[#6DBFFF] group-[.active]:after:shadow-[0_1px_20px_#6DBFFF]

                    hover:after:content['']
                    hover:after:absolute 
                    hover:after:block 
                    hover:after:bg-[#6DBFFF30]
                    hover:text-primary-500/80
                  `}
                  onClick={() => setFilter((prevState) => ({
                    ...prevState,
                    status: status as NotificationStatusType
                  }))}
                >{name}</button>
              </li>
            ))}
          </ul>
        </div>
        <div className={`
          h-full min-h-[calc(20rem-3rem)]
          flex flex-col justify-between
        `}>
          <div>
            <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs  text-primary-800 uppercase bg-primary-500/5">
                  <tr>
                    <th className="px-3 py-2 w-5">
                      <input
                        type="checkbox"
                        id={`check-row-home-table`}
                        className={`${shortclass.checkbox.primary} bg-transparent border-2`}
                        onClick={(e) => {
                          let el = e.target as HTMLInputElement;
                          let isChecked = el.checked;
                          
                          let chs = document.querySelectorAll(`.check-row-home-table${
                            isChecked ? ':not(:checked)' : ':checked'
                          }`) as NodeListOf<HTMLInputElement>;

                          chs.forEach(ch => ch.checked = isChecked);

                          setHasSelected(isChecked)
                        }}
                      />
                    </th>
                    <th className="px-3 py-2 font-bold">Notificações {notificationStatus[filter.status]}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {showingNotifications.slice(
                    pageIndex * perPage,
                    (pageIndex + 1) * perPage
                  ).map(notification => (
                    <tr className="hover:bg-gray-200/80 border-b last:border-none" key={notification.id}>
                      <td className="px-3 py-4 w-5">
                        <input
                          type="checkbox"
                          id={`check-row-home-table-${notification.id}`}
                          className={`check-row-home-table ${shortclass.checkbox.primary} bg-transparent border-2`}
                          data-name={notification.title}
                          value={notification.id}
                          onClick={(e) => {
                            let hasAtLeast1Checked = document.querySelectorAll(`.check-row-home-table:checked`).length > 0;
                            let checkAll = document.getElementById(`check-row-home-table`) as HTMLInputElement;

                            if(hasAtLeast1Checked){
                              setHasSelected(true);

                              checkAll.checked = document.querySelectorAll(
                                `.check-row-home-table:not(:checked)`
                              ).length === 0;
                            }
                            else{
                              setHasSelected(false);
                              checkAll.checked = false;
                            }
                          }}
                        />
                      </td>                            

                      <td
                        className="px-3 py-4 cursor-pointer"
                        onClick={() => onDetails(notification)}
                      >

                        <div className="flex items-center gap-2 max-w-full">
                          <NotificationIconOrDefaultByType notification={notification} props={{ w: 18, h: 18 }}/>
                          <strong className="text-sm max-w-[calc(100%-2rem)] truncate">{notification.title}</strong>
                        </div>
                        <div className="flex flex-col gap-2 mt-0.5">
                          <span className="text-gray-500 text-xs font-normal">
                            {notification.description.slice(0, 80) + (notification.description.length > 80 ? '...':'')}
                          </span>
                          <span className="text-gray-400 text-xs">{moment(notification.schedule).format('DD/MM/YYYY H:mm')}</span>
                        </div>
                      </td>
                      <td className="px-3 py-4 cursor-pointer text-end">
                        <div className="flex items-center gap-2 justify-end">
                          <button
                            type="button"
                            className="
                              rounded-lg border leading-none
                              shadow-sm hover:bg-gray-200
                              text-gray-500 font-semibold
                              focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                            "
                            onClick={() => handleMarkAsViewed(notification.id, notification.viewed)}
                          >
                            {notification.viewed ? (
                              <EnvelopeIcon w={20} h={20}/>
                            ):(
                              <EnvelopeOpenIcon w={20} h={20}/>
                            )}
                          </button>
                          <button
                            type="button"
                            className="
                              rounded-lg border leading-none
                              shadow-sm hover:bg-gray-200
                              text-gray-500 font-semibold
                              focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                            "
                            onClick={() => handleMarkAsArchived(notification.id, notification.is_archived)}
                          >
                            {notification.is_archived ? (
                              <DropboxIcon w={20} h={20}/>
                            ):(
                              <ArchiveIcon w={20} h={20}/>
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {showingNotifications.length === 0 && (
                    <tr>
                      <td
                        colSpan={3}
                        className="px-3 py-10 text-center"
                      >
                        {isFiltering ? (
                          'Não há notificações com esse filtro'
                        ):(
                          filter.status === 'unviewed' ? 'Todas as notificações já foram visualizadas' :
                          filter.status === 'viewed' ? 'Não há notificações visualizadas' :
                          filter.status === 'archived' ? 'Não há notificações arquivadas':'...'
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <TableFooter
            perPage={perPage}
            pageIndex={pageIndex}
            totalPages={isFiltering ? Math.ceil(showingNotifications.length / perPage) : totalPages}
            total={isFiltering ? showingNotifications.length : total[filter.status] ?? 0}

            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}

            goToPage={goToPage}
            previousPage={previousPage}
            nextPage={nextPage}

            isFiltering={isFiltering}
          />
          {isLoading && <Loading className="absolute inset-0 z-50 bg-gray-50/75"/>}
        </div>
      </div>
    </Wrapper>
  )
}
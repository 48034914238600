import { Label, TextInput } from "flowbite-react";
import { Modal } from "../../shared-components/utils/Modal"
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { phoneMask } from "../../services/mask";
import { useNotify } from "../../contexts/NotifyContext";
import { updateUser } from "../../services/user";
import { CircularProgressbar } from "react-circular-progressbar";
import { headerBearer, handleErrorResultAndResponse, api } from "../../services/api";
import { changePassword } from "../../services/authenticate";

interface UploadPictureType{
  file?: File,
  name?: string,
  preview?: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
}
interface ModalProfileProps{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalChangePassword = ({ isOpen, setIsOpen } : ModalProfileProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return;
    
    if(!currentPassword || !password || !confPassword){
      toast.error('Todos os campos são obrigatórios')
      return;
    }

    if(password.length < 6){
      toast.warning('A nova senha deve conter no mínimo 6 caracteres')
      return;
    }

    if(password !== confPassword){
      toast.error('A nova senha e a confirmação de senha estão divergentes')
      return;
    }

    const res = await changePassword({ currentPassword, newPassword: password }, user.token)

    if(!res.result){
      toast.error(res.response);
      return;
    }

    toast.success(res.response)
    
    setIsOpen(false);
  }
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{ 
        title: '',
        actionButton: {
          onClick: () => {
            if(buttonSubmitRef.current) buttonSubmitRef.current.click()
          },
          theme: 'primary',
          autoClose: false,
          text: 'Salvar'
        }
      }}
    >
      <form onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={buttonSubmitRef}/>

        <div className="flex items-center pb-4 gap-4">
          <div className="relative">
            {user ? (
              <img
                className="h-[56px] w-[56px] rounded-full shadow object-cover"
                src={user.picture}
                alt={user.name}
              />
            ):(<div className="h-[56px] w-[56px] rounded-full shadow"/>)}
          </div>
          <div>
            <h5 className="block mb-1 text-xl leading-none font-semibold">
              Alterar Senha
            </h5>
            <span className="block text-xs leading-snug text-gray-400 w-72">
              Adicione uma senha com no mínimo 6 caracteres.
            </span>
          </div>
        </div>

        <div className="mt-1 mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-current-password"
              value="Senha Atual"
              className="text-sm font-medium dark:text-gray-300 block !text-gray-700"
            />
          </div>
          <TextInput
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            id="user-current-password"
            type="password"
            placeholder="Digite a senha atual"
            required
          />
        </div>
        <div className="mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-password"
              value="Nova Senha"
              className="text-sm font-medium dark:text-gray-300 block !text-gray-700"
            />
          </div>
          <TextInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="user-password"
            type="password"
            placeholder="Digite a nova senha"
            required
          />
        </div>
        <div className="mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-conf-password"
              value="Confirmar Senha"
              className="text-sm font-medium dark:text-gray-300 block !text-gray-700"
            />
          </div>
          <div className="relative w-full">
            <input
              type="password"
              id="user-conf-password"
              placeholder="Repita sua nova senha"
              className={`
                block w-full border disabled:cursor-not-allowed disabled:opacity-50 
                bg-gray-50 text-gray-900
                dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400
                dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm
                ${password !== confPassword ? `border-red-500 focus:border-red-500 focus:ring-red-500`:`border-gray-300 focus:border-emerald-500 focus:ring-emerald-500`}
              `}
              onChange={(e) => setConfPassword(e.target.value)}
              value={confPassword}
              required
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}
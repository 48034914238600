import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { NotifyOwnerData, disconnectSession, sendTest, notifyOwner } from "../../services/whatsapp";
import { SessionType } from "../../pages/Admin/AdminIntegracoes";
import { Label, TextInput } from "flowbite-react";
import { ChevronDownIcon } from "../../shared-components/utils/icons";
import { shortclass } from "../../styles/styles";

interface NotifyOwnersProps{
  goBack: () => void,
  selectedSession: SessionType;
}
export const  NotifyOwners = ({ goBack,selectedSession }:NotifyOwnersProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [showWorkflowList, setShowWorkflowList] = useState(false);
  const [selectedWorkflows, setSelectedWorkflows] = useState<string[]>([]);
  const [editSubject, setEditSubject] = useState('');
  const [editBody, setEditBody] = useState('')

  const handleWorkflowSelection = (workflowId: string) => {
    setSelectedWorkflows((prevSelectedWorkflows) => {
      if (prevSelectedWorkflows.includes(workflowId)) {
        return prevSelectedWorkflows.filter((id) => id !== workflowId);
      } else {
        return [...prevSelectedWorkflows, workflowId];
      }
    });
  };

  async function notifyOwnerHandler(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if (!user || !selectedSession) return;

    const data: NotifyOwnerData = {
      subject: editSubject,
      body: editBody,
    };

    const res = await notifyOwner(user.token, selectedSession.key, data);
    if(!res.result){
      toast.error(res.response)
      return;
    }

    toast.success(res.response);
  };
  
  return (
    <form onSubmit={notifyOwnerHandler} className="flex-1 flex flex-col justify-between">
      <div>
        <div className="mb-4">
          <button
            type="button"
            className={`
              w-full block bg-gray-200 hover:bg-gray-300/80 py-0.5 px-5 ${showWorkflowList ? 'rounded-t':'rounded'}
              text-xs uppercase text-gray-700 text-center relative
              focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
            `}
            onClick={() => setShowWorkflowList(!showWorkflowList)}
          >
            <strong>{showWorkflowList ? 'Ocultar Fluxos' : 'Selecionar Fluxos'}</strong>
            <ChevronDownIcon
              className={`absolute right-0 top-0 bottom-0 ${showWorkflowList ?  'rotate-180':''}`}
              w={20}
              h={20}
            />
          </button>
          {showWorkflowList && selectedSession && (
            <div className="flex flex-col rounded-b-lg border border-gray-100 p-4">
              {selectedSession.workflows?.map((workflow) => (
                <label className="relative inline-flex items-center cursor-pointer" key={workflow._id}>
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={selectedWorkflows.includes(workflow._id)}
                    onChange={() => handleWorkflowSelection(workflow._id)}
                  />
                  <div className={`
                    w-9 h-5 bg-gray-200
                    peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                    after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                    dark:border-gray-600 peer-checked:bg-blue-600
                  `}></div>
                  <span className="ml-3 text-xs  font-semibold uppercase text-gray-700 dark:text-gray-300">
                    {workflow.title}
                  </span>
                </label>
              ))}
            </div>
          )}

        </div>
        <div className="mb-4">
          <div className="mb-2 block">
            <Label
              className="block text-sm font-medium !text-gray-700"
              htmlFor={`notify-subject`}
              value="Assunto"
            />
          </div>
          <TextInput
            value={editSubject}
            id={`notify-subject`}
            placeholder="Digite o assunto da notificação"
            onChange={(e) => setEditSubject(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <div className="mb-2 block">
            <Label
              className="block text-sm font-medium !text-gray-700"
              htmlFor={`notify-body`}
              value="Mensagem"
            />
          </div>
          <textarea
            className="
              block w-full border 
              disabled:cursor-not-allowed disabled:opacity-50 
              bg-gray-50 border-gray-300 text-gray-900 
              focus:border-blue-500 focus:ring-blue-500 
              dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
              dark:focus:border-blue-500 dark:focus:ring-blue-500
              rounded-lg p-2.5 text-sm
            "
            value={editBody}
            id={`notify-body`}
            onChange={(e) => setEditBody(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="flex flex-col mt-4 gap-3">
        <button
          type="button"
          className={shortclass.button.secondary}
          onClick={goBack}
        >Voltar</button>
        <button
          type="submit"
          className={shortclass.button.primary}
        >Enviar</button>
      </div>
    </form>
  );
}
import { Label, TextInput } from "flowbite-react"
import { BusinessUnitType, CompanyType } from "../../../types/conciliation.type"
import { useEffect, useState } from "react"

interface MACStepFourProps{
  company: CompanyType,
  unit?: BusinessUnitType,
  name: string,
  setName: React.Dispatch<React.SetStateAction<string>>,
  erpId: string,
  setErpId: React.Dispatch<React.SetStateAction<string>>  
}
export const MACStepFour = ({ company, unit, name, setName, erpId, setErpId }: MACStepFourProps) => {
  useEffect(() => {
    setName(unit?.name ?? '')
    setErpId(unit?.erp_id ?? '')
  },[unit])
  
  return (
    <div className="mt-4">
      <strong className="font-semibold uppercase bg-gray-100 p-0.5 text-center flex items-center justify-center rounded-sm pb-0 text-gray-700 mb-4">
        {company.nome_fantasia}: {unit ? 'Edição de Unidade': 'Criação de Unidade'}
      </strong>
      <div className="mb-4">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-unit-name`}
            value={'Nome'}
          />
        </div>
        <TextInput
          id={`manage-unit-name`}
          type={'text'}
          className="flex-1"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={"Digite o nome da unidade"}
          required
        />
      </div>
      <div className="mb-4">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-unit-erp-id`}
            value={'Código no ERP'}
          />
        </div>
        <TextInput
          id={`manage-unit-erp-id`}
          type={'text'}
          className="flex-1"
          value={erpId}
          onChange={(e) => setErpId(e.target.value)}
          placeholder={"Digite o código do ERP da unidade"}
          required
        />
      </div>
    </div>
  )
}
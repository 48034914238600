import React, { useEffect, useRef, useState } from "react";
import { ArrowRightIcon, SearchIcon, TrashIcon } from "../../shared-components/utils/icons";
import { StatusType } from "../../types";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
import { StateFilter } from "./StateFilter";
import Datepicker from "react-tailwindcss-datepicker";

type AvailableSearchTypes = 'Solicitante' | 'Nº PO' | 'Data';
export interface FilterProps{
  stageList?: StatusType[],
  filterStatus: string[] | undefined;
  setFilterStatus: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  isFiltering: boolean;
  filterDate: DateValueType;
  setFilterDate: React.Dispatch<React.SetStateAction<DateValueType>>;
  filterRequester: string;
  setFilterRequester: React.Dispatch<React.SetStateAction<string>>;
  filterNumberPO: string;
  setFilterNumberPO: React.Dispatch<React.SetStateAction<string>>;
  handleDeleteMultiple: () => void;
  hasSelectedForDelete: boolean;
}
export const Filter = ({
  stageList, 
  filterStatus, filterDate, filterRequester, filterNumberPO,
  setFilterStatus, setFilterDate, setFilterRequester, setFilterNumberPO,
  isFiltering, handleDeleteMultiple, hasSelectedForDelete
} : FilterProps) => {
  const divStatusRef = useRef<HTMLDivElement>(null);
  const divSearchRef = useRef<HTMLDivElement>(null);
  
  const [isApplied, setIsApplied] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(filterStatus ?? []);
  const [searchType, setSearchType] = useState<AvailableSearchTypes>('Solicitante');
  const [requester, setRequester] = useState(filterRequester);
  const [poId, setPoId] = useState(filterNumberPO);
  const [date, setDate] = useState(filterDate);

  useEffect(() => {
    setIsApplied(
      JSON.stringify([
        (selectedStatus.length === 0 ? undefined : selectedStatus), requester, poId, date
      ]) === JSON.stringify([
        filterStatus, filterRequester, filterNumberPO, filterDate
      ])
    )
  },[
    selectedStatus, requester, poId, date,
    filterStatus, filterRequester, filterNumberPO, filterDate
  ]);

  function handleApplyFilter(){
    if(JSON.stringify(selectedStatus) !== JSON.stringify(filterStatus)) setFilterStatus(
      selectedStatus.length === (stageList ?? []).length ? undefined : selectedStatus
    );
    if(JSON.stringify(date) !== JSON.stringify(filterDate)) setFilterDate(date);
    if(requester !== filterRequester) setFilterRequester(requester);
    if(poId !== filterNumberPO) setFilterNumberPO(poId);

    setIsApplied(true);
  }

  return (
    <div className="
      relative bg-gradient-light backdrop-blur-[25px] rounded-lg shadow-md
      flex flex-col gap-2 mt-4 px-4 py-3 text-primary-700 mb-4 z-10
    ">
      <div className="flex justify-between gap-2">
        <div className="relative max-sm:hidden group" ref={divStatusRef}>
          <div className="flex items-center gap-3">
            <button
              type="button"
              className="
                text-sm text-start
                w-40 h-10 p-4
                border border-gray-50/10
                outline-none focus:ring-gray-100/50 focus:ring-1
                flex items-center justify-between gap-2
                rounded-md bg-gray-100/5 backdrop-blur-[25px]
              "
              onClick={() => {
                if(!divStatusRef.current) return;

                divStatusRef.current.classList.toggle('expanded');
              }}
            >
              <span className="truncate">
                {selectedStatus.length === 0 || selectedStatus.length === (stageList ?? []).length ? 'Todos' : (
                  selectedStatus.length === 1 ? stageList?.find(s => s.slug === selectedStatus[0])?.name : 
                  `${selectedStatus!.length} status selecionados`
                )}
              </span>
              <ArrowRightIcon className="rotate-90 group-[.expanded]:-rotate-90"/>
            </button>
            {hasSelectedForDelete && (
              <button
                type="button"
                className="
                  flex justify-center items-center
                  my-auto p-2 border-transparent
                  rounded-lg outline-none focus:ring-gray-100/50 focus:ring-1
                  hover:bg-gray-100/20 hover:border-gray-300
                "
                onClick={handleDeleteMultiple}
              ><TrashIcon className="w-5 h-5 text-primary-700 dark:text-gray-400"/></button>
            )}
          </div>
          <div className={`
            hidden group-[.expanded]:flex flex-col
            absolute left-0 z-20
            focus:outline-none p-2
            ring-1 ring-black ring-opacity-5
            mt-0.5 w-72 origin-top-right rounded-md
            bg-gray-100/40 backdrop-blur-[25px] shadow-lg
          `}>
            <div className="grid grid-cols-2 gap-2">
              {[{ name: 'Todos', slug: 'all' }, ...(stageList ?? [])].map((status) => (
                <button
                  className={`
                    font-semibold text-gray-700 text-sm text-start truncate
                    backdrop-blur-[25px] hover:bg-gray-50/30 
                    rounded-lg w-full p-2 ${
                      (status.name === 'Todos' && (
                        selectedStatus.length === 0 || selectedStatus.length === (stageList ?? []).length
                      )) || selectedStatus.includes(status.slug) ? '!bg-gray-50/75':''
                    }
                  `}
                  onClick={() => setSelectedStatus(prevState => {
                    if(status.name  === 'Todos') return prevState.length === 0 ? stageList?.map(s => s.slug) ?? [] : [];
                    
                    if(prevState.includes(status.slug)) return prevState.filter(s => s !== status.slug);

                    return [
                    ...prevState, status.slug
                    ].filter((slug, index, self) => index === self.findIndex((t) => t === slug));
                  })}
                  key={status.slug}
                >{status.name.replace('Aguardando','Ag.')}</button>
              ))}
            </div>
          </div>
        </div>

        <div className="group relative flex items-center" ref={divSearchRef}>
          <div className="hidden group-[.show]:block mr-2">
            <Dropdown
              classNames={{ list: `
                absolute left-0 z-10
                mt-0.5 w-56 origin-top-right rounded-md
                bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                ring-1 ring-black ring-opacity-5
                focus:outline-none pb-2
              ` }}
              trigger={
                <div className="
                  p-4 w-40 h-10 rounded-md
                  border border-gray-50/10 bg-gray-100/5
                  flex items-center justify-between 
                  text-sm backdrop-blur-[25px]
                ">
                  {searchType}
                  <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90"/>
                </div>
              }
            >
              {(['Solicitante', 'Nº PO', 'Data'] as AvailableSearchTypes[]).map(type => (
                <button
                  className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
                  onClick={() => setSearchType(type)}
                  key={type}
                >{type}</button>
              ))}
            </Dropdown>
          </div>
          {searchType === 'Data' ? (
            <div className="hidden group-[.show]:block mr-10">
              <Datepicker
                inputClassName={`
                  border border-primary-500/20 bg-gray-100/5
                  bg-gray-100/10 backdrop-blur-[25px]
                  
                  text-primary-700 text-sm rounded-lg 
                  placeholder-primary-700
    
                  focus:ring-gray-100/20 focus:border-primary-500/30 w-44 p-2.5
                `}
                value={date}
                onChange={setDate}
                placeholder={"Data"}
                displayFormat={"DD/MM/YYYY"}
              />
            </div>
          ):(
            <input
              type="text"
              id="simple-search"
              className="
                hidden group-[.show]:block
                bg-gray-100/10 backdrop-blur-[25px]
                
                border-primary-500/20
                text-gray-600 text-sm rounded-lg 
                placeholder-primary-700
    
                focus:ring-gray-100/20 focus:border-gray-100/20 w-44 pr-10 p-2.5
              "
              value={
                searchType === 'Solicitante' ? requester : 
                searchType === 'Nº PO' ? poId :
                // searchType === 'Data' ? date?.startDate : 
                undefined
              }
              onChange={(e) => {
                if(searchType === 'Solicitante') setRequester(e.target.value);
                else if(searchType === 'Nº PO') setPoId(e.target.value);
                // else if(searchType === 'Data') setDate(e.target.value);
              }}
              placeholder="Pesquisar..."
            />
          )}
          <button
            type="button"
            className="
              absolute inset-y-0 right-2
              flex justify-center items-center
              h-6 w-6 my-auto 
              rounded-full outline-none focus:ring-gray-100/50 focus:ring-1
            "
            onClick={() => {
              if(!divSearchRef.current) return;
              divSearchRef.current.classList.toggle('show');

              let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
              if(divSearchRef.current.classList.contains('show')) input.focus();
            }}
          ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400"/></button>
        </div>
      </div>

      {(isFiltering || !isApplied) && (
        <StateFilter {...{
          stageList,
          isApplied,
          selectedStatus, requester, poId, date,
          setSelectedStatus, setRequester, setPoId, setDate,
          handleApplyFilter
        }}/>
      )}
    </div>
  );
}
import { toast, ToastContainer } from "react-toastify";
import {  Modal, ModalOptionsType } from "../shared-components/utils/Modal";
import { createContext, ReactNode, useContext, useState } from "react";;

interface NotifyContextType {
  toast: any,
  showMessage: (content: ReactNode, options: ModalOptionsType) => void,
  onCloseModal: () => void
}

export const NotifyContext = createContext({} as NotifyContextType);

export function NotifyProvider({ children }: { children: ReactNode }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptionsType>({
    title: 'Mensagem'
  });
  const [modalContent, setModalContent] = useState<ReactNode>();

  function showMessage(content: ReactNode, options: ModalOptionsType){
    setModalOptions(options);
    setModalContent(content);
    setModalIsOpen(true);
  }
  
  return (
    <NotifyContext.Provider value={{
      toast, showMessage, onCloseModal: () => setModalIsOpen(false)
    }}>
      <>
        <ToastContainer position="top-center"/>
        {children}
        <Modal
         isOpen={modalIsOpen}
         setIsOpen={setModalIsOpen}
         options={modalOptions}
        >{ modalContent }</Modal>
      </>
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
import { Permition, ResultAndResponse } from "../shared-types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api"

let cachePermition : Permition[] = []
interface PermitionResponse extends ResultAndResponse{
  data?: Permition[]
}
export const getPermitions = async (token: string) : Promise<PermitionResponse> => {
  try{
    if(cachePermition.length > 0){
      console.log('[cached-permitions]')
      return {
        result: true,
        response: 'Permissões carregadas com sucesso',
        data: cachePermition
      }
    }
  }catch(e){}
  
  try{
    const { data } = await api.get('/permition', headerBearer(token))
    console.log('[requested-permitions]')

    if(data.result && data.data) cachePermition = data.data;

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível buscar as permissões do hub'
    })
  }
}
import { useEffect, useState } from "react";
import { Wrapper } from "../../../shared-components/Wrapper"
import { NotificationPreferenceType, NotificationTypeType, availableNotificationTypeFormatted } from "../../../shared-types/notification.type";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { Loading } from "../../../components/Loading";
import { InfoIcon } from "../../../shared-components/utils/icons";
import { Tooltip } from "flowbite-react";
import { shortclass } from "../../../styles/styles";
import { getNotificationPreference, PreferenceRequest, createOrUpdateNotificationPreference } from "../../../services/notification";

type AvailableNotifyBy =  'plataform' | 'email' | 'whatsapp'
const availableNotifyBy = [
  'plataform',
  'email',
  'whatsapp',
  /*'sms'*/
]
const requireNotifyByPlataform = ['license', 'update']
const descriptionByType : Record<NotificationTypeType,string>= {
  alert:    'Alertas emitidos pela plataforma ou empresa',
  license:  'Notificações sobre sua licensa do software',
  mention:  'Notificações quando você é mencionado',
  reminder: 'Lembretes de eventos',
  update:   'Notificações sobre atualização da plataforma ou de ferramentas da empresa'
}
export const PreferenceNotification = () => {
  const { user } = useAuth()
  const { toast } = useNotify();

  const [preference, setPreference] = useState<NotificationPreferenceType>();
  const [isReplicate, setIsReplicate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => { loadPreference() },[user])
  useEffect(() => {
    if(preference){
      Object.keys(descriptionByType).forEach(type => {
        const notifyBy = preference.notify_by.find(
          (nb) => nb.type === type
        )
        availableNotifyBy.forEach(by => {
          let el = document.getElementById(`${type}-${by}`) as HTMLInputElement
          el.checked = !!(notifyBy && notifyBy[by as AvailableNotifyBy])
        })
        let el = document.getElementById(`${type}-archive`) as HTMLInputElement
        el.checked = preference.auto_archive_type.includes(type)
      })
    }
  },[preference])
  
  async function loadPreference(){
    if(!user) return;

    const res = await getNotificationPreference(user.token)

    if(!res.result){
      toast.error(res.response)
      return;
    }

    if(!res.data) return

    const defaultPreference : NotificationPreferenceType = {
      id: '',
      auto_archive_type: [],
      user_id: user.id,
      notify_by: [
        { type: 'alert',    plataform: true },
        { type: 'license',  plataform: true },
        { type: 'mention',  plataform: true },
        { type: 'reminder', plataform: true },
        { type: 'update',   plataform: true }
      ]
    }

    const currentPreference : NotificationPreferenceType = res.data.length === 0 ? defaultPreference : res.data.length === 1 ? res.data[0] : res.data.find(
      (d) => d.client_id === user.current_client
    ) ?? res.data.find(
      (d) => !d.client_id
    ) ?? defaultPreference

    if(res.data.length === 2){
      setIsReplicate(
        JSON.stringify({
          auto_archive_type: res.data[0].auto_archive_type,
          notify_by: res.data[0].notify_by
        }) === JSON.stringify({
          auto_archive_type: res.data[1].auto_archive_type,
          notify_by: res.data[1].notify_by
        })
      )
    }

    setPreference(currentPreference)
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return
    
    setIsLoading(true)

    const formData = new FormData(e.currentTarget);

    const types = Object.keys(descriptionByType)

    const data : PreferenceRequest = {
      auto_archive_type: [],
      notify_by: []
    }

    types.forEach((type) => {
      let selected = formData.getAll(type)
      if(selected.includes('auto-archive')) data.auto_archive_type.push(type)
      let availableSelected = selected.filter((s) => s !== 'auto-archive')

      if(requireNotifyByPlataform.includes(type) || availableSelected.length > 0 || data.auto_archive_type.includes(type)){
        let notify_by : Record<string, any> = { type };

        (availableSelected as string[]).forEach((available: string) => { notify_by[available] = true })

        if(requireNotifyByPlataform.includes(type) || data.auto_archive_type.includes(type)) notify_by.plataform = true
        
        data.notify_by.push(notify_by as any)
      }
    })

    const res = await createOrUpdateNotificationPreference(
      data,
      isReplicate,
      user.token
    )

    setIsLoading(false)

    if(!res.result){
      toast.error(res.response)
      return;
    }

    toast.success(res.response);
    if(res.data) setPreference(res.data);
  }
  return (
    <Wrapper
      module_name="Configurações"
      asideActive={['Notificações', 'Preferências']}
      breadcrumbs={[
        { name: 'Notificações', href: '#', subtitle: `Preferências${user ? ` - ${user.client_name}`:''}`}
      ]}
    >
      <form onSubmit={handleSubmit}>
        {preference && (
          <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs  text-primary-800 uppercase bg-primary-500/5">
                <tr>
                  <th className="px-3 py-2">
                    Tipo de notificação
                  </th>
                  {availableNotifyBy.map((name) => (
                    <th className="px-3 py-2 font-bold text-center" key={name}>
                      {name === 'plataform' ? (
                        <div className="flex items-center justify-center gap-2 mx-auto">
                          <span className="pt-0.5">Plataforma</span>
                          <Tooltip
                            trigger="hover"
                            content={<span style={{ textTransform: 'initial' }}>Notificações no sininho</span>}
                          >
                            <InfoIcon w={18} h={18}/>
                          </Tooltip>
                        </div>
                      ):name === 'whatsapp' ? (
                        <div className="flex items-center justify-center gap-2 mx-auto">
                          <span className="pt-0.5">Whatsapp</span>
                          <Tooltip
                            trigger="hover"
                            content={
                            <span style={{ textTransform: 'initial' }}>
                              Só funcionará se você tiver adicionado seu<br/>
                              celular em suas informações pessoais
                            </span>}
                          >
                            <InfoIcon w={18} h={18}/>
                          </Tooltip>
                        </div>
                      ):name}
                    </th>
                  ))}
                  <th className="px-3 py-2 font-bold text-center">
                    <div className="flex items-center justify-center gap-2 mx-auto">
                      <span className="pt-0.5">Auto-Arquivar</span>
                      <Tooltip
                        trigger="hover"
                        content={
                          <span style={{ textTransform: 'initial' }}>
                            Arquivar uma tipo de notificação automaticamente.<br/>
                            <small>(aplicável apenas com plataforma ativa)</small>
                          </span>
                        }
                      >
                        <InfoIcon w={18} h={18}/>
                      </Tooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(availableNotificationTypeFormatted).map(([type, name]) => {
                  const findedPref = preference.notify_by.find((nb) => nb.type === type)
                  const isAutoArchive = preference.auto_archive_type.includes(type)
                  return (
                    <tr className="hover:bg-gray-200/80 border-b last:border-none" key={type}>
                      <td className="px-3 py-4 max-w-[10rem]">
                        <span className="block">{name}</span>
                        <span className="text-gray-400 text-xs">
                          {descriptionByType[type as  NotificationTypeType]}
                        </span>
                      </td>                            
                      {availableNotifyBy.map((notifyBy) => (
                        <td className="px-3 py-4 cursor-pointer text-center" key={notifyBy}>
                          {notifyBy === 'plataform' && requireNotifyByPlataform.includes(type) ? (
                            <div className="flex items-center justify-center">
                              <Tooltip
                                trigger="hover"
                                content={
                                  <div className="max-w-[8rem]">
                                    Não é possível desabilitar esse tipo de notificação
                                  </div>
                                }
                              >
                                <label className="relative inline-flex items-center">
                                  <input
                                    type="checkbox"
                                    name={type}
                                    value={notifyBy}
                                    id={`${type}-${notifyBy}`}
                                    className="sr-only peer"
                                    checked
                                    disabled
                                  />
                                  <div className={`
                                    w-9 h-5 bg-gray-200 border border-gray-300
                                    peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                                    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                                    after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                                    after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                                    dark:border-gray-600 peer-checked:bg-blue-600
                                    opacity-50
                                  `}></div>
                                </label>
                              </Tooltip>
                            </div>
                          ):(
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                name={type}
                                id={`${type}-${notifyBy}`}
                                value={notifyBy}
                                className="sr-only peer"
                                defaultChecked={findedPref ? findedPref[notifyBy as AvailableNotifyBy] ?? false : false}
                              />
                              <div className={`
                                w-9 h-5 bg-gray-200 border border-gray-300
                                peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                                rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                                after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                                dark:border-gray-600 peer-checked:bg-blue-600
                              `}></div>
                            </label>
                          )}
                        </td>
                      ))}
                      <td className="px-3 py-4 cursor-pointer text-center">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name={type}
                            id={`${type}-archive`}
                            value={'auto-archive'}
                            className="sr-only peer"
                            defaultChecked={isAutoArchive}
                            onChange={(e) => {
                              if(e.target.checked){
                                const el = document.getElementById(`${type}-plataform`) as HTMLInputElement
                                if(el) el.checked = true
                              }
                            }}
                          />
                          <div className={`
                            w-9 h-5 bg-gray-200 border border-gray-300
                            peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                            rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                            after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                            dark:border-gray-600 peer-checked:bg-blue-600
                          `}></div>
                        </label>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="flex items-start justify-between gap-4 mt-4 mb-14">
          <div className="pt-2">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isReplicate}
                onChange={() => setIsReplicate(!isReplicate)}
              />
              <div className={`
                w-9 h-5 bg-gray-200 border border-gray-300
                peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                dark:border-gray-600 peer-checked:bg-blue-600
              `}></div>
              <span className="ml-2 text-sm text-gray-500">Tornar configuração padrão</span>
            </label>

            <div className="text-xs text-gray-400 w-72">
              Quando habilitado, essa será sua configuração
              padrão para empresa que não definir uma preferência especifica.
            </div>
          </div>
          <button type="submit" className={`${shortclass.button.primary} min-w-[6rem] !mb-0`}>Salvar</button>
        </div>
        {(!preference || isLoading) && <Loading className="absolute inset-0 z-50 bg-gray-50/75"/>}
      </form>
    </Wrapper>
  )
}
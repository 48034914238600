import { ResultAndResponse } from "../shared-types";
import { BusinessUnitType } from "../types/conciliation.type";
import { api, handleErrorResultAndResponse, headerBearer } from "./api";

interface BusinessUnitReponse extends ResultAndResponse{
  data?: BusinessUnitType
}
export const createUnit = async (fields: Omit<BusinessUnitType, 'business_unit_slug' | 'client_id'>, token: string) : Promise<BusinessUnitReponse> => {
  try{
    const { data } = await api.post('/conciliacao/business-unit', fields, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return data
  }catch(e){
    console.error('[error-on-create-unit]', e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível cadastrar a nova unidade'
    })
  }
}
export const updateUnit = async (fields: Partial<BusinessUnitType>, token: string) : Promise<BusinessUnitReponse> => {
  try{
    const { data } = await api.put('/conciliacao/business-unit', fields, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return data
  }catch(e){
    console.error('[error-on-update-unit]', e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível atualizar a unidade'
    })
  }
}
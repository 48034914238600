import { useEffect, useState } from "react";
import { Wrapper } from "../../../shared-components/Wrapper";
import { useAuth } from "../../../contexts/AuthContext";
import { getCompanies } from "../../../services/company";

import { sendFiles } from "../../../services/pos";
import { toast } from "react-toastify";
import { Label } from "flowbite-react";
import Datepicker from "react-tailwindcss-datepicker";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
import { CompanyType } from "../../../types/conciliation.type";
import { CardManagePOS } from "../../../components/ReceivementModule/CardManagePOS";
import Dropzone from "react-dropzone";
import { TrashIcon } from "../../../shared-components/utils/icons";
import { uniqueId } from "lodash";
import { filesize } from "filesize";
import { Loading } from "../../../components/Loading";
import { CardManageCompany } from "../../../components/ReceivementModule/CardManageCompany";

type AvailableCategory = "Extrato" | "Fechamento" | "PIX" | "Recebimentos" | "Vendas"
interface ConciliationFile {
  id: string;
  file: File;
  name: string,
  readableSize: string,
  category: string;
}
export default function POS() {
  const { user } = useAuth();
  
  const [filterDate, setFilterDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [company, setCompany] = useState<CompanyType>();
  const [tableCompany, setTableCompany] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<ConciliationFile[]>([]);

  const handleFiles = (loadedFile: File[], category: AvailableCategory) => {
    const error = [[loadedFile.length === 0, 'Selecione um arquivo'],
     [loadedFile.length > 1, 'Selecione apenas 1 arquivo']
    ].find(([isFail,_]) => isFail)

    if(error){
      toast.error(error[1])
      return
    }

    setFiles((prevState) => [
      ...prevState.filter(state => state.category !== category), ...(loadedFile ?? []).map((file: File) => ({
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size) as string,
        category,
        file,
      })),
    ]);
  }
  const handleRemoveFile = (id: string) => setFiles((prevState) => prevState.filter(
    state => state.id !== id
  ));

  async function handleSendFiles(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    //#region VALIDATE
    if(!user) return;

    const errorMessage = [
      [files.length === 0, 'Selecione pelo menos um arquivo para ser carregado'],
      [!filterDate || !filterDate.startDate || !filterDate.endDate, 'Selecione o Período de conciliação'],
      [!tableCompany, 'Selecione a empresa']
    ].find(([isFail]) => isFail)

    if(errorMessage){
      toast.error(errorMessage[1])
      return;
    }
    //#endregion VALIDATE
    setIsLoading(true);
    const data = new FormData();

    files.forEach((item: ConciliationFile) => data.append(
      item.category.toLocaleLowerCase(),
      item.file
    ));
    
    data.append("files", JSON.stringify(
      files.map((item: ConciliationFile) => ({
        company: tableCompany,
        name: item.file.name,
        category: item.category,
      }))
    ));

    if(!filterDate) return;

    data.append("dt_start", filterDate.startDate as string)
    data.append("dt_end", filterDate.endDate as string)

    const responseSendDocs = await sendFiles(data, user.token);
    setIsLoading(false);
    
    if(!responseSendDocs.result){
      toast.error(responseSendDocs.response)
      return;
    }
    
    toast.success(responseSendDocs.response)
  };
  
  useEffect(() => {
    (async () => {
      if (!user) return;
      const response = await getCompanies(user?.token);
      setCompanies(response);
    })();
  }, [user]);

  return (
    <Wrapper
      asideActive={"Gerenciamento"}
      breadcrumbs={[{
        name: "Gerenciamento",
        href: "#",
      }]}
      module_name="Ivrim Conciliation"
    >
      <div className="gap-4 grid items-start max-xl:!grid-cols-1" style={{ 
        display: 'grid',
        gridTemplateColumns: '1fr 300px'
      }}>
        <form
          onSubmit={handleSendFiles}
          className="
            flex flex-col gap-2 rounded-lg backdrop-blur-[25px] bg-white
            mb-6 w-full min-h-[20rem] py-6 text-primary-800 relative z-10
            shadow-md dark:border-gray-700 dark:bg-gray-800
          "
        >
          <h5 className="text-lg font-semibold dark:text-white px-5 mb-2">
            Upload de Relatórios
          </h5>
          <div className="flex flex-col sm:flex-row max-w-[1200px] flex-wrap gap-4 mx-5 mb-2">
            <div className="flex-1">
              <div className="mb-2">
                <Label
                  htmlFor="datepicker-period"
                  value="Período de Conciliação"
                  className="!text-gray-700 text-sm font-semibold"
                />
              </div>
              <Datepicker
                inputId="datepicker-period"
                popoverDirection="down"
                inputClassName={`
                  border border-primary-500/20 bg-gray-100/5
                  bg-gray-100/10 backdrop-blur-[25px]

                  text-primary-700 text-sm rounded-lg
                  placeholder-primary-700 w-full
      
                  focus:ring-gray-100/20 focus:border-primary-500/30 p-2.5
                `}
                value={filterDate}
                onChange={setFilterDate}
                placeholder={"Data"}
                displayFormat={"DD/MM/YYYY"}
                
              />
            </div>
            <div className="flex-1">
              <div className="mb-2">
                <Label
                  htmlFor="tableCompanies"
                  value="Empresa"
                  className="!text-gray-700 text-sm font-semibold"
                />
              </div>
              <select
                id="tableCompanies"
                onChange={(event) => setTableCompany(event.target.value)}
                className="
                  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full
                  focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700
                  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                "
                required
              >
                <option value="">Selecione a empresa</option>
                {companies.map((company, index) => (
                  <option key={index} value={company.nome_fantasia}>
                    {company.nome_fantasia}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="grid xs:grid-cols-2 lg:grid-cols-3 gap-4 mx-5 mb-4">
            {(["Fechamento", "Extrato", "PIX", "Recebimentos", "Vendas"] as AvailableCategory[]).map((categoryName) => (
              <div className="mb-2" key={categoryName}>
                <div className="mb-2">
                  <Label
                    htmlFor={`upload-form-${categoryName}`}
                    value={categoryName === 'Fechamento' ? `${categoryName} ERP`:categoryName}
                    className="!text-gray-700 text-sm font-semibold"
                  />
                </div> 
                <Dropzone onDropAccepted={(e) => handleFiles(e, categoryName)}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div
                      {...getRootProps()}
                      className={`
                        dropzone
                        border border-dashed 
                        ${isDragActive ? 'border-emerald-600': isDragReject ? 'border-red-400' : 'border-gray-400'}
                        rounded cursor-pointer
                      `}
                      style={{ transition: "height 0.2s ease" }}
                    >
                      <input {...getInputProps()}/>
                      {isDragActive ? (
                        <div className="flex justify-center items-center text-sm py-8 text-emerald-600/80">Solte o arquivo aqui</div>
                      ):isDragReject ? (
                        <div className="flex justify-center items-center text-sm py-8 text-red-500/80">Arquivo não suportado</div>
                      ):(
                        <div className="flex justify-center items-center text-sm py-8 text-gray-500/80">
                          {files.find(file => file.category === categoryName) ?
                           "Arraste o arquivo para substituir..." :
                           "Arraste o arquivo aqui..."
                          }
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
                {files.length > 0 && <FileList files={files.filter(file => file.category === categoryName)} onDelete={handleRemoveFile}/>}
              </div>
            ))}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className={`
                flex justify-center 
                rounded-lg border border-transparent 
                py-2 px-4
                text-sm font-medium 
                focus:outline-none focus:ring-2 focus:ring-offset-2
              text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
              `}
            >Processar Relatórios</button>
          </div>
        </form>
        <div className="flex flex-col sm:flex-row sm:gap-4 -mt-4 xl:mt-0 xl:gap-0 xl:flex-col">
          <CardManageCompany {...{
            companies,
            setCompanies
          }}/>
          <CardManagePOS {...{ 
            companies, setCompany, company,
          }}/>
        </div>
        {isLoading && <Loading className={`
          fixed inset-0 z-50 bg-gray-50/75
        `}/>}
      </div>
    </Wrapper>
  );
}

interface FileListProps{
  files: ConciliationFile[],
  onDelete: (id: string) => void
}
export const FileList = ({ files, onDelete }: FileListProps) => (
  <ul className="mt-1 px-2">
    {files.map(uploadedFile => (
      <li className="flex justify-between items-center text-gray-500 mt-4 max-w-full overflow-hidden" key={uploadedFile.id}>
        <div className="flex items-center">
          <div className="w-9 h-9 rounded-md bg-no-repeat border bg-gray-200 shadow-lg bg-cover mr-2.5"/>
          <div className="flex flex-col">
            <strong className="truncate block text-ellipsis max-w-[14rem]">{uploadedFile.name}</strong>
            <span className="text-xs text-gray-500 mt-1.5 flex items-center">
              {uploadedFile.readableSize}{" "}
              <button
                type="button"
                className="
                  text-red-500/80 ml-1.5 cursor-pointer
                  w-4 h-4 flex items-center justify-center
                  rounded-lg border leading-none
                  shadow-sm hover:bg-gray-200
                  text-gray-500 font-semibold
                  focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                "
                onClick={() => onDelete(uploadedFile.id)}
              ><TrashIcon w="16" h="16"/></button>
            </span>
          </div>
        </div>
      </li>
    ))}
  </ul>
)
import { getUrls } from "../shared-components/services/conn/api";
import { ResultAndResponse } from "../shared-types";
import { GalleryItemType, GalleryType } from "../types/gallery.type";
import { api, headerBearer, handleErrorResultAndResponse } from "./api";
import { __parseGallery } from "./gallery";

interface GetGalleryItemsResponse extends ResultAndResponse {
  data?: GalleryType
}

export const deleteGalleryItem = async (token: string, gallery_id: string, id: string) : Promise<ResultAndResponse>=> {
  try{
    const { data } = await api.delete(`/gallery/item/${gallery_id}/${id}`, headerBearer(token));
    if(!data.result) throw new Error(
      data.response
    )

    return data;
  }catch(e){
    console.error(e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar excluir este item'
    })
  }
}
export const getGalleryItems = async (token: string, gallery_id: string): Promise<GetGalleryItemsResponse> => {
  try {
    const { data } = await api.get<GetGalleryItemsResponse>(`/gallery/${gallery_id}`, headerBearer(token));

    if (!data.result) throw new Error(
      data.response
    )

    return {
      ...data,
      data: (data.data ? __parseGallery(data.data) : data.data)
    };
  } catch (e) {
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível carregar a galeria de arquivos!'
    })
  }
}
export function __parseGalleryItem(item: GalleryItemType): GalleryItemType {
  return { ...item, src: `${getUrls('back')!.portal}${item.src}` }
}
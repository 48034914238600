import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { ClientWithResume, EconomicGroup, exportUserAnalysisByClient, exportUserAnalysisByEconomicGroup, getUserAnalysisByClientOrEconomicGroup, UserInClient } from "../../services/client";
import { useNotify } from "../../contexts/NotifyContext";
import { DownloadAltIcon, PlusIcon } from "../../shared-components/utils/icons";

export const AdminUsersByClient = () => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<ClientWithResume[]>([]);
  const [economicGroups, setEconomicGroups] = useState<EconomicGroup[]>([]);
  const [usersByClient, setUsersByClient] = useState<UserInClient[]>([]);

  useEffect(() => { load(); },[user])

  async function load(){
    if(!user) return;
  
    setIsLoading(true);

    const res = await getUserAnalysisByClientOrEconomicGroup(user.token);

    setIsLoading(false);

    if(!res.result){
      toast.error(res.response);
      return;
    }

    if(!res.data) return;

    
    setClients(res.data.clients);
    setEconomicGroups(res.data.economic_groups);
    setUsersByClient(res.data.users_by_client);
  }

  function showClientsOfEconomicGroup(economic_group: string){
    showMessage((
      <ClientsList
        onExportClient={handleExportClient}
        clients={clients.filter(client => client.economic_group === economic_group)}
      />
    ), {
      title: economic_group,
      size: 'sm:w-full sm:max-w-4xl'
    })
  }

  async function handleExportEconomicGroup(economic_group: string){
    if (!user || isLoading) return;

    try {
      setIsLoading(true);
      const response = await exportUserAnalysisByEconomicGroup(economic_group, user.token);
      setIsLoading(false);

      if(!response.result){
        toast.error(response.response);
        return;
      }

      if(!response.data?.data){
        toast.error('Sem dados suficientes para gerar arquivo')
        return;
      }
      
      handleDownload({
        buffer: response.data.data,
        filename: `${economic_group.replace(/[^\w\d-_.]/gi, '_')}.xlsx`
      })
    } catch (error) {
      toast.error("Erro ao gerar a exportação");
      setIsLoading(false);
    }  
  }
  async function handleExportClient(client_id: string){
    if (!user || isLoading) return;

    const client = clients.find(client => client.client_id === client_id);
    if(!client){
      toast.error('Empresa não encontrada');
      return;
    }

    try {
      setIsLoading(true);
      const response = await exportUserAnalysisByClient(client_id, user.token);
      setIsLoading(false);

      if(!response.result){
        toast.error(response.response);
        return;
      }

      if(!response.data?.data){
        toast.error('Sem dados suficientes para gerar arquivo')
        return;
      }
      
      handleDownload({
        buffer: response.data.data,
        filename: `${client.nome_fantasia.replace(/[^\w\d-_.]/gi, '_')}.xlsx`
      })
    } catch (error) {
      toast.error("Erro ao gerar a exportação");
      setIsLoading(false);
    }
  };

  function handleDownload({ buffer, filename }:{ buffer: number[], filename: string }){
    //#region HANDLE DOWNLOAD
    try{
      const blob = new Blob([new Uint8Array(buffer).buffer], { type: `text/xlsx` });
      const downloadLink = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }catch(e){
      toast.error('Não foi possível gerar o arquivo para download')
      console.error('[error-download-file]', e)
    }
    //#endregion HANDLE DOWNLOAD
  }
  
  return (
    <Wrapper
      asideActive={['Admin Hub', 'Usuários por Empresas']}
      breadcrumbs={[
        { name: 'Admin Hub', href: '#', subtitle: 'Usuários por Empresas' }
      ]}
      module_name="Admin Panel"
      isAdmin
    >
      <div>
        {isLoading && <Loading className="h-[calc(100vh-2rem)] rounded-xl" />}

        <p className="text-gray-500 p-4 bg-gray-300/50 text-sm rounded-lg mb-8 -mt-4">
          <b>Obs.</b> Os usuários com @ivrim.com.br e @ivrim.tech são excluídos dessa verificação.
        </p>

        <div className="mb-8">
          <h2 className="font-semibold text-lg">Grupos Economicos</h2>
          <div>
            {economicGroups.length === 0 ? (
              <p className="text-gray-500 text-sm p-7 bg-gray-200 block text-center w-full">
                Não foi encontrado nenhum grupo economico.
              </p>
            ):(
              <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
                {economicGroups.map((group) => (
                  <div className="bg-gray-200 border shadow-lg rounded p-2" key={group.name}>
                    <button
                      type="button"
                      className="hover:bg-gray-300 rounded-lg px-2 py-0.5 flex justify-between w-full text-gray-800"
                      onClick={() => handleExportEconomicGroup(group.name)}
                    >
                      <strong>{group.name}</strong>
                      <DownloadAltIcon w={20} h={20}/>
                    </button>
                    <div className="text-sm flex flex-col text-gray-700 px-2 pt-1 pb-2">
                      <span>Empresas: {group.client_ids.length}</span>
                      <span>Usuários: {group.user_ids.length}</span>
                      <span>Workflows: {group.flows}</span>
                      <ModulesAndTemplates
                        modules={group.modules}
                        templates={group.templates}
                      />
                    </div>
                    <button
                      type="button"
                      className="hover:bg-gray-300 rounded-lg px-2 py-1 flex items-center justify-center w-full text-gray-800 text-sm my-1"
                      onClick={() => showClientsOfEconomicGroup(group.name)}
                    >
                      <PlusIcon w={20} h={20}/> Detalhes das Empresas
                      
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <h2 className="font-semibold text-lg">Empresas</h2>
          <div>
            <ClientsList
              clients={clients.filter(client => !client.economic_group)}
              onExportClient={handleExportClient}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export const ClientsList = ({ clients, onExportClient }:{ clients: ClientWithResume[], onExportClient: (client_id: string) => void }) => (
  <>
    {clients.length === 0 ? (
      <p className="text-gray-500 text-sm p-7 bg-gray-200 block text-center w-full">
        Não foi encontrada nenhuma empresa.
      </p>
    ):(
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        {clients.map((client) => (
          <div className="bg-gray-200 border shadow-lg rounded p-2" key={client.client_id}>
            <button
              type="button"
              className="hover:bg-gray-300 rounded-lg px-2 py-0.5 flex items-center justify-between w-full text-gray-800  text-start"
              onClick={() => onExportClient(client.client_id)}
            >
              <strong>{client.nome_fantasia}</strong>
              <DownloadAltIcon w={20} h={20}/>
            </button>
            <div className="text-sm flex flex-col text-gray-700 px-2 pb-2 pt-1">
              <span>Usuários: {client.resume.users}</span>
              <span>Workflows: {client.resume.flows}</span>
              <ModulesAndTemplates
                modules={client.resume.modules}
                templates={client.resume.templates}
              />
            </div>
          </div>
        ))}
      </div>
    )}
  </>
)
export const ModulesAndTemplates = ({ modules, templates }:{ modules: string[], templates: string[] }) => (
  <>
    <div className="w-full mt-1">
      <strong className="text-xs uppercase text-center bg-gray-600 text-gray-200 block rounded">Módulos</strong>
      {modules.length === 0 ? (
        <p className="text-xs text-gray-400 mt-1 text-center">Não possui nenhum módulo</p>
      ) : (
        <div className="flex flex-wrap gap-1 mt-1">
          {modules.map((module) => (
            <span className="bg-gray-300 rounded-lg px-1.5 font-semibold text-xs" key={module}>{module}</span>
          ))}
        </div>
      )}
    </div>
    <div className="w-full mt-1">
      <strong className="text-xs uppercase text-center bg-gray-600 text-gray-200 block rounded">Templates</strong>
      {templates.length === 0 ? (
        <p className="text-xs text-gray-400 mt-1 text-center">Não utiliza nenhum template</p>
      ) : (
        <div className="flex flex-wrap gap-1 mt-1">
          {templates.map((module) => (
            <span className="bg-gray-300 rounded-lg px-1.5 font-semibold text-xs" key={module}>{module}</span>
          ))}
        </div>
      )}
    </div>
  </>
)

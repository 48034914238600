import { Radio } from "flowbite-react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { UserCategory, availablePossiblePermissions } from "../../../shared-types";
import { getUserCategories } from "../../../services/userCategory";
import { NotificationToType } from "../../../shared-types/notification.type";
import { useNotify } from "../../../contexts/NotifyContext";
import { getPublishedFlows } from "../../../shared-components/services/workflow";

interface FSSelectPermissionProps{
  isHub: boolean,
  flowId?: string,
  toType?: NotificationToType,
  permissions: string[],
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>,
  setIgnoreClientId: React.Dispatch<React.SetStateAction<boolean>>
}
export const FSSelectPermission = ({ isHub, flowId, toType, setIgnoreClientId, permissions, setPermissions }:FSSelectPermissionProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [mode, setMode] = useState<'client' | 'hub' | 'flow'>();
  const [availablePermissions, setAvailablePermissions] = useState<UserCategory[]>([]);

  useEffect(() => {
    if(toType === 'flow_perms') setMode('flow')
    else if(!isHub) setMode('client')
  },[isHub, toType])

  useEffect(() => {
    if(mode) setIgnoreClientId(mode === 'hub')
    setPermissions([])
  },[mode])

  useEffect(() => { if(user) loadPermissions() },[user, mode])

  async function loadPermissions(){
    if(!user) return;

    if(mode === 'flow'){
      if(!flowId){
        toast.error('Selecione um workflow primeiro para depois selecionar as permissões')
        return;
      }

      const res = await getPublishedFlows(user.token);

      if(!res.result){
        toast.error(res.response)
        return;
      }

      if(res.data){
        const findedFlow = res.data.find((data) => data._id === flowId)
        if(findedFlow){
          const flowPermissions = findedFlow.resume?.permissions?.groups ?? []

          setAvailablePermissions(
            flowPermissions.map((perm) => ({
              clientId: '',
              description: `Permissão dentro do fluxo ${findedFlow.title}`,
              id: perm.name,
              name: perm.name,
              permitions: perm.actions as any,
              slug: perm.name
            }))
          )
        }
        return;
      }

      toast.error('Fluxo não encontrado')
      return;

      // [x] OBTER WF SELECIONADO 
      // [x] OBTER PERMISSÕES VÁLIDAS
      // [ ] CONVERTER ESSAS PERMISSÕES PARA O PADRÃO USERCATEGORY[]
    }else
    if(mode === 'client'){
      const response = await getUserCategories(user.token);
      setAvailablePermissions(response)
    }else
    if(mode === 'hub'){
      setAvailablePermissions(
        availablePossiblePermissions
      )
    }
  }

  if(!mode) return (
    <div className="mb-4">
      <h3 className="mb-4 text-lg font-semibold">Quais permissões deseja selecionar?</h3>
      <div className="flex flex-col gap-3">
        <label className="text-sm text-gray-600 flex items-center gap-2">
          <Radio
            onChange={() => setMode('client')}
            checked={mode === 'client'}
          />
          <span className="pb-0.5">Apenas da minha empresa</span>
        </label>
        <label className="text-sm text-gray-600 flex items-center gap-2">
          <Radio
            onChange={() => setMode('hub')}
            checked={mode === 'hub'}
          />
          <span className="pb-0.5">Qualquer permissão do hub</span>
        </label>
      </div>
    </div>
  )

  return (
    <div className="mb-4">
      <div className="mb-4 flex flex-col sm:flex-row gap-0.5 justify-between sm:items-center">
        <h3 className="text-lg font-semibold">Selecione as permissões{mode === 'flow' ? ' do fluxo':''}:</h3>
        {(isHub && mode !== 'flow') && (
          <div className="flex gap-3">
            <label className="text-sm text-gray-600 flex items-center gap-2">
              <Radio
                onChange={() => setMode('client')}
                checked={mode === 'client'}
              />
              <span className="pb-0.5">da empresa</span>
            </label>
            <label className="text-sm text-gray-600 flex items-center gap-2">
              <Radio
                onChange={() => setMode('hub')}
                checked={mode === 'hub'}
              />
              <span className="pb-0.5">do hub</span>
            </label>
          </div>
        )}
      </div>
      {availablePermissions.length === 0 ? (
        <p className="text-center text-sm text-gray-500 w-full flex-1 bg-gray-300/30 rounded-lg py-14">
          {mode === 'flow' ? 
            'Não existe permissões nesse fluxo':
            mode === 'client' ?
            'Não foi possível obter as permissões dessa empresa':
            mode === 'hub' ?
            'Não foi possível obter as permissões da plataforma':
            '...'
          }
        </p>
      ) : (
        <div className="py-4 text-gray-500 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {availablePermissions.map(perm => (
            <label
              className="
                cursor-pointer
                flex items-center justify-between rounded-lg gap-4
                border border-gray-300 shadow bg-primary-300/10 hover:bg-primary-300/20 p-4
              "
              key={perm.id}
            >
              <div className="flex items-center">
                <div className="flex flex-col items-start">
                  <span className="whitespace-nowrap text-sm text-primary-700/80 leading-none">{perm.name}</span>
                  <span className="text-xs text-primary-400 block mb-1">{perm.description}</span>
                </div>
              </div>
              <Radio
                onClick={() => setPermissions((prevState) => {
                  if(prevState.includes(perm.slug)) return [...prevState.filter(
                    (state) => state !== perm.slug
                  )]
                  return [...prevState, perm.slug]
                })}
                checked={permissions.includes(perm.slug)}
              />
            </label>
          ))}
        </div>
      )}
    </div>
  )
}
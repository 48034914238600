import { Label, TextInput } from "flowbite-react";
import { cnpjMask, phoneMask } from "../../../services/mask";

interface MACStepThreeProps{
  cep: string,
  setCep: React.Dispatch<React.SetStateAction<string>>,
  logradouro: string,
  setLogradouro: React.Dispatch<React.SetStateAction<string>>,
  numero: string,
  setNumero: React.Dispatch<React.SetStateAction<string>>,
  bairro: string,
  setBairro: React.Dispatch<React.SetStateAction<string>>,
  cidade: string,
  setCidade: React.Dispatch<React.SetStateAction<string>>,
  estado: string,
  setEstado: React.Dispatch<React.SetStateAction<string>>
}
export const MACStepThree = ({
  cep,
  setCep,
  logradouro,
  setLogradouro,
  numero,
  setNumero,
  bairro,
  setBairro,
  cidade,
  setCidade,
  estado,
  setEstado
}: MACStepThreeProps) => (
  <div className="mt-5">
    <strong className="font-semibold uppercase bg-gray-100 p-0.5 text-center flex items-center justify-center rounded-sm pb-0 text-gray-700 mb-1">
      Informações de Endereço
    </strong>
    <span className="text-xs text-gray-500 text-end block ml-auto mb-1">*opcional</span>
    <div className="mb-4 flex gap-4">
      <div className="max-w-[10rem]">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-cep`}
            value={'CEP'}
          />
        </div>
        <TextInput
          id={`manage-company-cep`}
          type="text"
          className="flex-1"
          value={cep}
          onChange={(e) => setCep(e.target.value)}
          placeholder={"Digite o CEP"}
          autoFocus
        />
      </div>
      <div className="flex-1">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-logradouro`}
            value={'Logradouro'}
          />
        </div>
        <TextInput
          id={`manage-company-logradouro`}
          type="text"
          className="flex-1"
          value={logradouro}
          onChange={(e) => setLogradouro(e.target.value)}
          placeholder={"Digite o nome da Rua ou Av."}
        />
      </div>
    </div>
    <div className="mb-4 flex gap-4">
      <div className="max-w-[10rem]">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-numero`}
            value={'Número'}
          />
        </div>
        <TextInput
          id={`manage-company-numero`}
          type="text"
          className="flex-1"
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
          placeholder={"Digite o número"}
        />
      </div>
      <div className="flex-1">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-bairro`}
            value={'Bairro'}
          />
        </div>
        <TextInput
          id={`manage-company-bairro`}
          type="text"
          className="flex-1"
          value={bairro}
          onChange={(e) => setBairro(e.target.value)}
          placeholder={"Digite o bairro"}
        />
      </div>
    </div>
    <div className="mb-4 flex gap-4">
      <div className="flex-1">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-cidade`}
            value={'Cidade'}
          />
        </div>
        <TextInput
          id={`manage-company-cidade`}
          type="text"
          className="flex-1"
          value={cidade}
          onChange={(e) => setCidade(e.target.value)}
          placeholder={"Digite a cidade"}
        />
      </div>
      <div className="max-w-[8rem]">
        <div className="mb-2 block">
          <Label
            className="block text-sm font-medium !text-gray-700"
            htmlFor={`manage-company-estado`}
            value={'Estado'}
          />
        </div>
        <TextInput
          id={`manage-company-estado`}
          type="text"
          maxLength={2}
          className="flex-1"
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          placeholder={"Com 2 letras"}
        />
      </div>
    </div>
  </div>
)
import Dropzone from "react-dropzone";
import { SlideOver } from "../../shared-components/utils/SlideOver";
import { CircularProgressbar } from "react-circular-progressbar";
import { DownloadAltIcon, CheckedCircleIcon, ErrorCircleIcon } from "../../shared-components/utils/icons";
import { uniqueId } from "lodash";
import { useNotify } from "../../contexts/NotifyContext";
import { filesize } from "filesize";
import { useAuth } from "../../contexts/AuthContext";
import { api, headerBearer, handleErrorResultAndResponse } from "../../services/api";
import { __parseGalleryItem } from "../../services/galleryItems";
import { getUrls } from "../../shared-components/services/conn/api";
import { useState } from "react";
import { GalleryItemType, GalleryType } from "../../types/gallery.type";

interface FileListType{
  file: File | null,
  id: string,
  name: string,
  readableSize: string,
  preview: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
  url?: string
}
interface SlideOverAddItemProps{
  isOpen: boolean,
  onClose: () => void,
  gallery: GalleryType,
  setGalleryItems: React.Dispatch<React.SetStateAction<GalleryItemType[]>>
}
export const SlideOverAddItem = ({ isOpen, onClose, gallery, setGalleryItems } : SlideOverAddItemProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [files, setFiles] = useState<FileListType[]>([]);
  
  function handleUpload(selectedFiles: File[]){      
    const uploadedFiles : FileListType[] = selectedFiles.map<FileListType>(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size) as string,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
    }));
  
    setFiles(prevState => [
      ...prevState,
      ...uploadedFiles
    ]);
  
    uploadedFiles.forEach(processUpload);
  }
  async function processUpload(uploadedFile: FileListType) {
    if (!uploadedFile.file) return;
    if(!user || !gallery) return;

    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);
    if(gallery.external_id) data.append('external_id', gallery.external_id);
    data.append('name', gallery.name);
    data.append('type', gallery.type);
    data.append('description', `Adicionado na galeria de arquivos`);
    try{
      const { data: response } = await api.post("/gallery", data, {
        ...headerBearer(user.token),
        onUploadProgress: e => {
          const progress = e.total ? Math.round(
            (e.loaded * 100) / e.total
          ) : 0;

          setFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
            ...file,
            progress
          } : file));
        }
      });

      if(!response.result) throw new Error(
        response.response
      );

      if(!response.data) return;

      setFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
        ...file,
        uploaded: true,
        id: response.data.id,
        url: `${getUrls('back')!.portal}${response.data.src}`,
      } : file));

      setGalleryItems(prevState => [
        __parseGalleryItem({
          ...response.data,
          gallery_name: gallery?.name
        }),
        ...prevState.filter(state => state.id !== response.data.id)
      ])
    }catch(err){
      const error = handleErrorResultAndResponse(err, {
        result: false,
        response: `Não foi possível fazer o upload do arquivo ${uploadedFile.name}`
      });

      toast.error(error.response);

      setFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
        ...file,
        error: true
      } : file));
    }
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title="Adicionar arquivo"
    >
      <Dropzone onDropAccepted={handleUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={`
              dropzone
              border border-dashed 
              ${isDragActive ? 'border-emerald-600': isDragReject ? 'border-red-400' : 'border-gray-400'}
              rounded cursor-pointer
            `}
            style={{ transition: "height 0.2s ease" }}
          >
            <input {...getInputProps()}/>
            {isDragActive ? (
              <div className="flex justify-center items-center text-sm py-8 text-emerald-600/80">Solte os arquivos aqui</div>
            ):isDragReject ? (
              <div className="flex justify-center items-center text-sm py-8 text-red-500/80">Arquivo não suportado</div>
            ):(
              <div className="flex justify-center items-center text-sm py-8 text-gray-500/80">Arraste arquivos aqui...</div>
            )}
          </div>
        )}
      </Dropzone>
      {files.length > 0 && (
        <div className="mt-2">
          <button
            type="button"
            className="
              block -mb-1 ml-auto text-xs text-gray-400
              border border-transparent hover:bg-gray-100/50 hover:border-gray-300 px-1 rounded
            "
            onClick={() => {
              setFiles((prevState) => prevState.filter(
                (state) => !state.error && !state.uploaded)
              )
            }}>limpar</button>
          <FileList files={files}/>
        </div>
      )}
    </SlideOver>
  );
}
interface FileListProps{ files: FileListType[] }
const FileList = ({ files }: FileListProps) => (
  <ul className="px-2">
    {files.map(uploadedFile => (
      <li className="flex justify-between items-center text-gray-500 mt-4" key={uploadedFile.id}>
        <div className="flex items-center">
          <div className="w-9 h-9 rounded-md bg-no-repeat border bg-gray-200 shadow-lg bg-cover mr-2.5" style={{ 
            backgroundImage: `url(${uploadedFile.preview})`,
            backgroundPosition: '50% 50%'
          }}/>
          <div className="flex flex-col">
            <strong className="truncate block text-ellipsis max-w-[14rem]">{uploadedFile.name}</strong>
            <span className="text-xs text-gray-500 mt-1.5 flex items-center">
              {uploadedFile.readableSize}{" "}
            </span>
          </div>
        </div>

        <div className="flex gap-1">
          {!uploadedFile.uploaded &&
            !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: "#225397" },
                  trail:{ stroke: "#ccc" }
                }}
                strokeWidth={10}
                value={uploadedFile.progress}
              />
            )}

          {uploadedFile.url && (
            <a
              href={uploadedFile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadAltIcon className="mr-2" w={24} h={24}/>
            </a>
          )}

          {uploadedFile.uploaded && <CheckedCircleIcon w={24} h={24} color="rgba(5,150,105,.8)"/>}
          {uploadedFile.error && <ErrorCircleIcon w={24} h={24} color="rgb(249,128,128)" />}
        </div>
      </li>
    ))}
  </ul>
)
import { ResultAndResponse } from "../shared-types"
import { CompanyType, POSType } from "../types/conciliation.type"
import { api, handleErrorResultAndResponse, headerBearer } from "./api"

interface ResponseCompanies extends ResultAndResponse{
  data?: POSType[]
}
//#region HANDLE CACHE POS BY COMPANY
let cachePOSByCompany : Record<string, { company: string, data: ResponseCompanies }[]> = {}
const clearCachePOSByCompany = (token: string, company?: string) => {
  if(!company){
    cachePOSByCompany[token] = []
    return;
  }

  cachePOSByCompany[token] = (cachePOSByCompany[token] ?? []).filter(cache => cache.company !== company)
}
const pushCachePOSByCompany = (token: string, company: string, data: ResponseCompanies) => {
  clearCachePOSByCompany(token, company)
  cachePOSByCompany[token].push({ company, data })
}
//#endregion HANDLE CACHE POS BY COMPANY
export const getCompanyPOS = async(company: string, token: string) : Promise<ResponseCompanies> => {
  try{
    if(cachePOSByCompany[token] && cachePOSByCompany[token].length > 0){
      const findedCache = cachePOSByCompany[token].find(cache => cache.company === company)
      if(findedCache){
        console.log('[cached-pos]')
        return findedCache.data;
      }
    }
  }catch(e){}

  try {
    console.log('[request-pos]');
    const { data } = await api.get('/pos/'+company,headerBearer(token))
    
    if(!data.result) throw new Error(data.response);

    pushCachePOSByCompany(token, company, data)

    return data 
  }catch(e){
    console.error(e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível carregar os POS desta unidade'
    })
  }
}

export const disablePOS = async(id: string, token: string) => {
  try {
    await api.delete('/pos/'+id,headerBearer(token));

    return;
  } catch (error) {
    throw new Error("Não foi possível desativar");
    
  }
}

export interface createPOSDTO{
  id: string,
  date: string,
  company: string,
  unit: string
}
export const createPOS = async(data:createPOSDTO, token: string) : Promise<POSType> => {
  try {
    const newPOS = await api.post('/create-pos',data, headerBearer(token))

    return newPOS.data.data
  } catch (error) {
    throw new Error("Não foi possível criar"); 
  }
}


export const sendFiles = async(formData: FormData, token: string) : Promise<ResultAndResponse> => {
  try{
    const { data } = await api.post('/files',formData, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return data;
  }catch(e){
    console.error(e);
    
    return handleErrorResultAndResponse(e, {
      result: false,
      response: "Não foi possível enviar arquivos"
    })
  }
}
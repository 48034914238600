import logo from '../../../shared-components/assets/default-enterprise.svg';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Loading } from '../../Loading';
import { Client, UserCategory } from '../../../types';
import { CloseIcon, SettingIcon } from '../../../shared-components/utils/icons';

import { shortclass } from '../../../styles/styles';
import { CreateCategoryForm } from './CreateCategoryForm';
import { useAuth } from '../../../contexts/AuthContext';
import { hubRoutes } from '../../../shared-types/utils/routes';
import { useNotify } from '../../../contexts/NotifyContext';
import { useNavigate } from 'react-router-dom';

interface ModalAddTemplateProps {
  isOpen: boolean,
  setIsOpen: (param: boolean) => void,
  selectedCompany: Client,
}

export const ModalCompany = ({ isOpen, setIsOpen, selectedCompany }: ModalAddTemplateProps) => {
  const { user, changeClient } = useAuth();
  const { toast } = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateCategoryForm, setShowCreateCategoryForm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<UserCategory>();
  const navigate = useNavigate();

  function handleClose() {
    setIsOpen(false);
  }
  function handleCreateCategoryClick() {
    setCurrentCategory(undefined)
    setShowCreateCategoryForm(true);
  }
  function handleCancelCreateCategory() {
    setCurrentCategory(undefined)
    setShowCreateCategoryForm(false);
  }
  function handleGearIconClick(category: UserCategory){
    setCurrentCategory(category);

    setShowCreateCategoryForm(true);
  }
  async function handleRedirect(to: 'manage-users' | 'manage-company'){
    if(!user || isLoading) return;
    setIsLoading(true)
    await (async () => {
      if(user.current_client !== selectedCompany.id){
        const res = await changeClient(selectedCompany.id, selectedCompany.nome_fantasia, user.token)
        if(!res.result){
          toast.error(res.response)
          return;
        }
      }
  
      navigate(
        to === 'manage-users' ?
        hubRoutes.admin_panel.users() : 
        hubRoutes.admin_panel.client()
      )
    })()
    setIsLoading(false)
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={undefined} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end sm:items-center justify-center text-center  pt-4 sm:pt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`
                relative transform overflow-hidden
                rounded-t-3xl bg-white text-left shadow-xl
                transition-all sm:mt-12 
                w-full h-[calc(100vh-3rem)]
              `}>
                <div className="h-[calc(100vh-3rem)] p-6">
                  <div className="flex flex-col lg:flex-row w-full gap-6">
                    <div className="h-[calc(100vh-8.4rem)] flex flex-col w-full">
                      <header className="mb-5">
                        <div className="flex justify-between items-center">
                          <div className="flex-shrink-0">
                            <img
                              alt={selectedCompany?.nome_fantasia}
                              className="rounded-full object-cover w-12 h-12"
                              src={selectedCompany?.picture}
                              onError={(e) => {
                                let img = e.target as HTMLImageElement;
                                if(img.src !== logo) img.src = logo;
                              }}
                            />
                          </div>
                          <div className="ml-4 flex flex-col">
                            <h4 className="text-gray-800 font-bold">
                              {selectedCompany?.nome_fantasia}
                            </h4>
                            <h6 className="text-gray-500 text-sm">
                              {selectedCompany?.cnpj}
                            </h6>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleClose()}
                            className="ml-auto"
                          ><CloseIcon /></button>
                        </div>
                      </header>
                      {showCreateCategoryForm ? (
                        <CreateCategoryForm
                          onCancel={handleCancelCreateCategory}
                          selectedCompany={selectedCompany}
                          category={currentCategory}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5 items-start overflow-auto mb-4 max-h-full pr-2 -mr-1">
                          {(selectedCompany?.userCategories ?? []).map((category) => (
                            <div key={category.id} className="bg-gray-100 rounded p-2">
                              <div className="flex justify-between gap-1">
                                <div className="flex-1 min-w-0 flex items-center gap-2">
                                  <span className="bg-gray-800 text-gray-300 text-[10px] px-1 py-0.5 rounded-lg leading-none">{category.depth}</span>
                                  <strong className="leading-none">{category.name}</strong>
                                </div>
                                <button
                                  type="button"
                                  className="text-gray-500 hover:text-gray-700"
                                  onClick={() => handleGearIconClick(category)}
                                ><SettingIcon className="w-5 h-5" /></button>
                              </div>
                              <ul className="max-h-40 overflow-y-auto pr-2">
                                {(category.permitions ?? []).map((permission) => (
                                  <li
                                    className="flex items-center text-[13px] font-semibold text-gray-700 truncate dark:text-white p-0.6 ml-1 pt-2 hover:bg-gray-200 cursor-pointer border-b last:border-b-0"
                                    key={permission.id}
                                  >
                                    <span className="mb-1 ml-1">{permission.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>

                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {!showCreateCategoryForm && (
                    <div className="mt-auto pt-0">
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2.5">
                        <button
                          type="button"
                          className={`${shortclass.button.secondary}`}
                          onClick={() => handleRedirect('manage-company')}
                        >Editar Empresa</button>
                        <button
                          type="button"
                          className={`${shortclass.button.secondary}`}
                          onClick={() => handleRedirect('manage-users')}
                        >Gerenciar Usuários</button>
                        <button
                          type="button"
                          className={`${shortclass.button.primary} sm:col-span-2 md:col-span-1`}
                          onClick={handleCreateCategoryClick}
                        >
                          Criar Categoria
                        </button>
                      </div>
                    </div>
                  )}
                  {isLoading && <Loading className="absolute inset-0 z-50 bg-gray-50/75" />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  )
}
import { useRef, useState } from "react";

import { Client } from "../../../shared-types";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { createClient } from "../../../services/client";
import { SlideOver } from "../../../shared-components/utils/SlideOver";
import { Label, TextInput } from "flowbite-react";
import { ChevronDownIcon } from "../../../shared-components/utils/icons";
import { shortclass } from "../../../styles/styles";
import { Loading } from "../../Loading";
import { cnpjMask } from "../../../services/mask";
import { CircularProgressbar } from "react-circular-progressbar";
import { api, handleErrorResultAndResponse, headerBearer } from "../../../services/api";
import logo from '../../../shared-components/assets/default-client.jpg';

interface FormCreateCompanyType {
  isOpen: boolean,
  onClose: () => void,
  onCreate: (client: Client) => void
}
interface UploadPictureType{
  file?: File,
  name?: string,
  preview?: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
}

const INITIAL_UPLOAD_STATE = { progress: 0, uploaded: false, error: false }
export const FormCreateCompany = ({ isOpen, onClose, onCreate }: FormCreateCompanyType) => {
    const { user } = useAuth();
    const { toast } = useNotify();
    
    const [loading, setLoading] = useState(false);
    const [fantasia, setFantasia] = useState('');
    const [razao, setRazao] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [economicGroup, setEconomicGroup] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [ddd, setDDD] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');

    const [picture, setPicture] = useState(logo);
    const [upload, setUpload] = useState<UploadPictureType>(INITIAL_UPLOAD_STATE)
  
    const groupCollapseRef = useRef<HTMLDivElement>(null);
    const groupCollapseCont = useRef<HTMLDivElement>(null);
  
    function handleClose() {
      setFantasia('');
      setRazao('');
      setCnpj('');
      setEconomicGroup('');
      setCep('');
      setLogradouro('');
      setNumero('');
      setBairro('');
      setCidade('');
      setEstado('');
      setDDD('');
      setTelefone('');
      setEmail('');
      setPicture(logo);
      setUpload(INITIAL_UPLOAD_STATE);
  
      onClose();
    }
  
    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
  
      if(!user) return;
  
      const error = ([
        [!cnpj, 'É obrigatório informar o CNPJ'],
        [!fantasia, 'É obrigatório informar o nome fantasia'],
        [!razao, 'É obrigatório informar a razão social']
      ] as Array<[boolean, string]>).find(([condition]) => condition)
  
      if(error){
        toast.error(error[1])
        return;
      }
  
      setLoading(true)
      
      const res = await createClient({
        bairro,
        cep,
        cidade,
        cnpj,
        economic_group: economicGroup,
        ddd,
        email,
        estado,
        logradouro,
        nome_fantasia: fantasia,
        numero,
        picture,
        razao_social: razao,
        telefone
      }, user.token)

      setLoading(false)

      if(!res.result){
        toast.error(res.response)
        return;
      }
  
      if(res.data) onCreate(res.data);
  
      handleClose();
    }
  
    function handleClientPicture(target: HTMLInputElement){
      if(!target.files || target.files.length === 0) return;
  
      const uploadPicture : UploadPictureType = {
        file: target.files[0],
        name: target.files[0].name,
        preview: URL.createObjectURL(target.files[0]),
        progress: 0,
        uploaded: false,
        error: false,
      };
      setUpload(uploadPicture)
  
      processUpload(uploadPicture)
    }
    async function processUpload(uploadedFile: UploadPictureType){
      if(!uploadedFile.file) return;
      if(!user) return;
      
      const data = new FormData();
  
      data.append('file', uploadedFile.file, uploadedFile.name);
      data.append('external_id', user.id);
      data.append('name', 'Outros');
      data.append('type', 'personal');
      data.append('description', `Adicionando foto da empresa`);
      
      try{
        const { data: response } = await api.post("/gallery", data, {
          ...headerBearer(user.token),
          onUploadProgress: e => {
            const progress = e.total ? Math.round(
              (e.loaded * 100) / e.total
            ) : 0;
    
            setUpload((prevState) => ({
              ...prevState,
              progress
            }));
          }
        });
      
        if(!response.result) throw new Error(
          response.response
        );
    
        const url = `${process.env.REACT_APP_BASE_URL}${response.data.src}`;
        setUpload((prevState) => ({
          ...prevState,
          uploaded: true,
          id: response.data.id,
          url,
        }));
  
        setPicture(url);
      }catch(err){
        const error = handleErrorResultAndResponse(err, {
          result: false,
          response: `Não foi possível fazer o upload do arquivo ${uploadedFile.name}`
        });
  
        toast.error(error.response);
  
        setUpload((prevState) => ({
          ...prevState,
          error: true
        }));
      }
    }

    return (
      <SlideOver
        isOpen={isOpen}
        onClose={handleClose}
      >
        <div className="relative h-full">
          
          <div className="flex items-center pb-4 gap-4 mt-3">
            <label className="relative">
              <CircularProgressbar
                styles={{
                  root: { width: 58 },
                  path: { stroke: upload.uploaded ? "rgba(5,150,105,.8)" : upload.error ? "rgb(249,108,108)" : "#225397" },
                  trail:{ stroke: "#ccc" }
                }}
                strokeWidth={10}
                value={upload.progress}
              />
              {(upload.preview || picture) ? (
                <img
                  className="h-[50px] w-[50px] rounded-full shadow object-cover absolute top-[4px] left-[4px]"
                  src={upload.preview && !upload.error && !upload.uploaded ? upload.preview : picture}
                  alt={"Foto de perfil"}
                />
              ):(<div className="h-[50px] w-[50px] rounded-full shadow absolute top-[4px] left-[4px]"/>)}
              <input
                type="file"
                accept="*/image"
                className="hidden"
                onChange={(e) => handleClientPicture(e.target)}
              />
            </label>
            <div> 
              <h5 className="block mb-1 text-lg leading-none font-semibold">
                Adicionar Empresa
              </h5>             
              <span className="block text-xs leading-snug text-gray-400 w-80">
                Clique na imagem para incluir a foto da empresa
              </span>
            </div>
          </div>
          <form
            className="h-full flex flex-col justify-between"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="mb-5">
              <div className="mb-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="new-empresa-fantasia"
                    value="Nome Fantasia"
                  />
                </div>
                <TextInput
                  value={fantasia}
                  onChange={(e) => setFantasia(e.target.value)}
                  id="new-empresa-fantasia"
                  type="fantasia"
                  placeholder="Digite o nome fantasia"
                  required
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="new-empresa-razao"
                    value="Razão Social"
                  />
                </div>
                <TextInput
                  value={razao}
                  onChange={(e) => setRazao(e.target.value)}
                  id="new-empresa-razao"
                  type="razao"
                  placeholder="Digite o nome da razão social"
                  required
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="new-empresa-cnpj"
                    value="CNPJ"
                  />
                </div>
                <TextInput
                  value={cnpjMask(cnpj ?? '')}
                  onChange={(e) => setCnpj(cnpjMask(e.target.value ?? ''))}
                  id="new-empresa-cnpj"
                  type="cnpj"
                  placeholder="Digite o CNPJ"
                  required
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 block">
                  <Label
                    htmlFor="new-economic-group"
                    value="Grupo Econômico (opcional)"
                  />
                </div>
                <TextInput
                  value={economicGroup}
                  onChange={(e) => setEconomicGroup(e.target.value)}
                  id="new-economic-group"
                  type="text"
                  placeholder="Digite o grupo econômico"              
                />
              </div>
              <div>
                <hr className="mt-5"/>
                <div className="mt-4 mb-5 group" ref={groupCollapseRef}>
                  <button
                    type="button"
                    className="
                      text-lg text-start font-medium text-gray-900 -mb-1 pb-1 px-1 cursor-pointer
                      flex justify-between items-center rounded
                      w-full border-gray-50/10 focus:ring-gray-200 focus:border focus:border-gray-200 outline-none hover:outline-gray-200
                    "
                    onClick={() => {
                      if (!groupCollapseRef.current) return;
                      groupCollapseRef.current.classList.toggle('show');
                    }}
                  >
                    <div>
                      <span className="font-light text-gray-600 text-base block mt-1">Endereço (opcional)</span>
                    </div>
    
                    <ChevronDownIcon className="group-[.show]:rotate-180" />
                  </button>
                  <div className={`
                    transform opacity-0 scale-95 hidden
        
                    group-[.show]:block
                    group-[.show]:opacity-100
                    group-[.show]:scale-100
        
                    mt-4
                  `}>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-cep"
                          value="CEP"
                        />
                      </div>
                      <TextInput
                        value={cep}
                        onChange={(e) => setCep(e.target.value)}
                        id="new-empresa-cep"
                        type="CEP"
                        placeholder="Digite o cep"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-logradouro"
                          value="Logradouro"
                        />
                      </div>
                      <TextInput
                        value={logradouro}
                        onChange={(e) => setLogradouro(e.target.value)}
                        id="new-empresa-logradouro"
                        type="logradouro"
                        placeholder="Digite o logradouro"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-numero"
                          value="Número"
                        />
                      </div>
                      <TextInput
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                        id="new-empresa-numero"
                        type="numero"
                        placeholder="Digite o número"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-bairro"
                          value="Bairro"
                        />
                      </div>
                      <TextInput
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                        id="new-empresa-bairro"
                        type="bairro"
                        placeholder="Digite o bairro"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-cidade"
                          value="Cidade"
                        />
                      </div>
                      <TextInput
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                        id="new-empresa-cidade"
                        type="cidade"
                        placeholder="Digite a cidade"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-estado"
                          value="Estado"
                        />
                      </div>
                      <TextInput
                        value={estado}
                        onChange={(e) => setEstado(e.target.value)}
                        id="new-empresa-estado"
                        type="estado"
                        placeholder="Digite o estado"
                      />
                    </div>
                  </div>
                </div>
                <hr className="mb-4"/>
              </div>
              <div>
                <hr className="mt-5"/>
                <div className="mt-4 mb-5 group" ref={groupCollapseCont}>
                  <button
                    type="button"
                    className="
                      text-lg text-start font-medium text-gray-900 -mb-1 pb-1 px-1 cursor-pointer
                      flex justify-between items-center rounded
                      w-full border-gray-50/10 focus:ring-gray-200 focus:border focus:border-gray-200 outline-none hover:outline-gray-200
                    "
                    onClick={() => {
                      if (!groupCollapseCont.current) return;
                      groupCollapseCont.current.classList.toggle('show');
                    }}
                  >
                    <div>
                      <span className="font-light text-gray-600 text-base block mt-1">Contato (opcional)</span>
                    </div>
    
                    <ChevronDownIcon className="group-[.show]:rotate-180" />
                  </button>
                  <div className={`
                    transform opacity-0 scale-95 hidden
    
                    group-[.show]:block
                    group-[.show]:opacity-100
                    group-[.show]:scale-100
    
                    mt-4
                  `}>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-ddd"
                          value="DDD"
                        />
                      </div>
                      <TextInput
                        value={ddd}
                        onChange={(e) => setDDD(e.target.value)}
                        id="new-empresa-ddd"
                        type="ddd"
                        placeholder="Digite o ddd"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-telefone"
                          value="Telefone"
                        />
                      </div>
                      <TextInput
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        id="new-empresa-telefone"
                        type="telefone"
                        placeholder="Digite o telefone"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="new-empresa-email"
                          value="Email"
                        />
                      </div>
                      <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="new-empresa-email"
                        type="email"
                        placeholder="Digite o email"
                      />
                    </div>
                  </div>
                </div>
                <hr className="mb-4"/>
              </div>
            </div>
  
            <button
              type="submit"
              className={`${shortclass.button.primary} w-full mt-6`}
            >Adicionar</button>
          </form>
          {loading && <Loading className={`
            absolute -top-16 -left-2 -right-2 h-[calc(100%+5rem)] w-[calc(100%+1rem)]
            bg-semi-transparent
          `} />}
        </div>
      </SlideOver>
    )
  }
import React, { useEffect, useState } from 'react';
import { OtpInput } from '../../components/OtpInput';
import logoIcon from "../../shared-components/assets/logo-hub.png";
import { EyeIcon, LockIcon } from '../../components/SvgIcons';
import { shortclass } from "../../styles/styles";
import { checkToken, forgotPassword, redefinePassword } from '../../services/authenticate';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useNotify } from '../../contexts/NotifyContext';
import { Spinner } from 'flowbite-react';

const ShowCheckToken = () => {
    const { toast } = useNotify();
    const { email } = useParams();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resubmitOn, setResubmitOn] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const codeParam = searchParams.get('code');
        const resubmitOnParam = searchParams.get('resubmit_on');

        if (codeParam) {
            setCode(codeParam || '');
            searchParams.delete('code');
        }
        if (resubmitOnParam) {
            setResubmitOn(resubmitOnParam && !isNaN(Number(resubmitOnParam)) ?
                Number(resubmitOnParam) :
                1 * 60 * 1000
            )

            searchParams.delete('resubmit_on');
        }

        setSearchParams(searchParams)
    }, [searchParams]);


    const handleCodeChanged = (newCode: string) => {
        setCode(newCode);
    };

    const handleSubmitCode = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!email) {
            navigate('/');
            return;
        }
        if (isLoading)
            return
        setIsLoading(true)
        const res = await checkToken({ email, code });
        setIsLoading(false)
        if (!res.result) {
            toast.error(res.response)
            return;
        }

        toast.success(res.response)
        setCodeSent(true);
    }

    const handleSubmitNewPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!email) {
            navigate('/');
            return;
        }
        if (isLoading) return
        if (!code) {
            toast.warning('Insira o token')
            setCodeSent(false)
            return;
        }

        const error = ([
            [!newPassword, 'É obrigatório inserir a senha'],
            [newPassword !== repeatPassword, 'As senhas estão divergentes']
        ] as [boolean, string][]).find(([condition]) => !!condition)

        if (error) {
            toast.error(error[1])
            return;
        }
        setIsLoading(true)
        const res = await redefinePassword({ email, code, password: newPassword })
        setIsLoading(false)
        if (!res.result) {
            toast.error(res.response)
            return;
        }

        toast.success(res.response)
        navigate('/');
    };

    async function handleResubmit() {
        if (!email || isLoading) return;

        setIsLoading(true)
        const res = await forgotPassword({
            email,
            is_resend: true
        })
        setIsLoading(false)
        if (res.result) toast.success(res.response)
        else toast.error(res.response)

        if (res.data?.resubmit_on && res.data.resubmit_on > 0) setResubmitOn(
            res.data.resubmit_on
        )
    }

    if (codeSent) {
        return (
            <div className="h-full flex bg-background bg-cover bg-no-repeat bg-center min-h-[100vh]">
                <div className="min-h-full min-w-full sm:min-w-max max-w-[1500px] container grid items-center md:justify-center mx-auto pt-12 pb-8 p-0 xs:px-2 sm:px-8 md:px-10 lg:px-12" style={{ zIndex: 1 }}>
                    <div className="bg-white xs:rounded-lg m-auto md:m-0 px-2 xxs:px-4 xs:px-6 pt-9 pb-9 w-full xs:w-[24rem]" style={{ maxWidth: "100vw" }}>
                        <div>
                            <img
                                className="mx-auto h-10 xs:h-12 w-auto mt-3"
                                src={logoIcon}
                                alt="Your Company"
                            />
                        </div>
                        <form
                            className="mt-10 flex flex-col gap-6"
                            method="POST"
                            onSubmit={handleSubmitNewPassword}
                        >
                            <div className="rounded-md shadow-sm">
                                <div>
                                    <label htmlFor="newPassword" className="sr-only">Nova Senha</label>
                                    <div className="relative">
                                        <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                                            <LockIcon width="18" />
                                        </span>
                                        <input
                                            id="newPassword"
                                            name="newPassword"
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            value={newPassword}
                                            required
                                            className={`${shortclass.input.roundedTop} pl-8`}
                                            placeholder="Nova Senha"
                                        />
                                        <button
                                            type="button"
                                            className="absolute right-0 top-0 bottom-0 p-2 z-50 text-gray-700/80"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            <EyeIcon eyeOpen={!showPassword} width="18" />
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="repeatPassword" className="sr-only">Repetir Senha</label>
                                    <div className="relative">
                                        <span className="absolute left-0 top-0 bottom-0 p-2 z-50 text-gray-700/80">
                                            <LockIcon width="18" />
                                        </span>
                                        <input
                                            id="repeatPassword"
                                            name="repeatPassword"
                                            type={showRepeatPassword ? 'text' : 'password'}
                                            onChange={(e) => setRepeatPassword(e.target.value)}
                                            value={repeatPassword}
                                            required
                                            className={`${shortclass.input.roundedBottom} pl-8`}
                                            placeholder="Repetir Senha"
                                        />
                                        <button
                                            type="button"
                                            className="absolute right-0 top-0 bottom-0 p-2 z-50 text-gray-700/80"
                                            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                        >
                                            <EyeIcon eyeOpen={!showRepeatPassword} width="18" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className={`
                  group relative w-full
                  ${shortclass.button.primary} !bg-gradient-aside !font-semibold !mb-0 !pt-1.5
                `}>{isLoading ? (
                                        <Spinner size={"sm"} />
                                    ) : "Enviar"}</button>
                            </div>
                        </form>
                    </div>
                    <footer className="mt-auto d-flex items-center justify-center text-center">
                        <p className="text-gray-600 text-sm">Ivrim {new Date().getUTCFullYear()} © Todos os direitos reservados</p>
                    </footer>
                </div>
            </div>
        );
    } else {
        return (
            <div className="h-full flex bg-background bg-cover bg-no-repeat bg-center min-h-[100vh]">
                <div className="min-h-full min-w-full sm:min-w-max max-w-[1500px] container grid items-center md:justify-center mx-auto pt-12 pb-8 p-0 xs:px-2 sm:px-8 md:px-10 lg:px-12" style={{ zIndex: 1 }}>
                    <div className="bg-white xs:rounded-lg m-auto md:m-0 px-2 xxs:px-4 xs:px-6 pt-9 pb-9 w-full xs:w-[24rem]" style={{ maxWidth: "100vw" }}>
                        <div>
                            <img
                                className="mx-auto h-10 xs:h-12 w-auto mt-3"
                                src={logoIcon}
                                alt="Your Company"
                            />
                        </div>
                        <form className="mt-8 flex flex-col gap-6" onSubmit={handleSubmitCode} method="POST">
                            <div className="rounded-md shadow-sm mb-4">
                                <div className="text-center">
                                    <p className="text-sm text-gray-600">Favor inserir o código enviado em seu email</p>
                                </div>
                                <OtpInput
                                    pinCount={4}
                                    defaultValue={code}
                                    onCodeChanged={handleCodeChanged}
                                />
                            </div>
                            <div>
                                <button type="submit" className="group relative w-full mt-6 bg-primary-500 text-white pb-2 pt-1.5 px-4 rounded-md transition duration-300 hover:bg-primary-600 focus:outline-none focus:ring focus:border-blue-300">
                                    {isLoading ? (
                                        <Spinner size={"sm"} />
                                    ) : "Enviar"}
                                </button>
                                {!isLoading && (
                                    <BtnCounter
                                        defaultMilliseconds={resubmitOn}
                                        onClick={handleResubmit}
                                    />)}
                            </div>
                        </form>
                    </div>
                    <footer className="mt-auto d-flex items-center justify-center text-center">
                        <p className="text-gray-600 text-sm">Ivrim {new Date().getUTCFullYear()} © Todos os direitos reservados</p>
                    </footer>
                </div>

            </div>

        );
    }

};

let ctrlCounter: NodeJS.Timer | undefined = undefined
const BtnCounter = ({ defaultMilliseconds, onClick }: { defaultMilliseconds: number, onClick: () => void }) => {
    const [milliseconds, setMilliseconds] = useState(defaultMilliseconds)

    useEffect(() => {
        setMilliseconds(defaultMilliseconds)

        if (ctrlCounter) clearInterval(ctrlCounter)
        ctrlCounter = setInterval(() => {
            setMilliseconds(prevState => {
                if (prevState <= 1000) {
                    clearInterval(ctrlCounter)
                    ctrlCounter = undefined
                    return 0;
                }

                return prevState - 1000
            })
        }, 1000)

        return () => { if (ctrlCounter) { clearInterval(ctrlCounter) } }
    }, [defaultMilliseconds])
    return (
        <button
            type="button"
            className="text-gray-500 text-sm text-center block p-2 w-full -mb-4"
            onClick={milliseconds === 0 ? onClick : undefined}
        >
            {milliseconds === 0 ? (
                'Reenviar Código'
            ) : (
                <>
                    Reenviar em {((milliseconds: number) => {
                        if (milliseconds === 0) return `00:00`

                        const seconds = Math.floor(milliseconds / 1000);
                        const minutes = Math.floor(seconds / 60);
                        const secondsRest = seconds % 60;

                        return `${minutes.toString().padStart(2, '0')}:${secondsRest.toString().padStart(2, '0')}`;
                    })(milliseconds)}
                </>
            )}
        </button>
    )

}
export default ShowCheckToken;

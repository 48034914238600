import { ResultAndResponse } from "../shared-types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api"

export interface ConciliationJustification{
  type: string,
  id: string,
  client_id: string
}
interface ConciliationJustificationsResponse extends ResultAndResponse{
  data?: ConciliationJustification[]
}
interface ConciliationJustificationResponse extends ResultAndResponse{
  data?: ConciliationJustification
}

let cacheConciliationJustifications : Record<string, ConciliationJustificationsResponse> = {}
const clearCacheConciliationJustification = (token: string) => {
  if(cacheConciliationJustifications[token]) delete cacheConciliationJustifications[token]
}
export const getConciliationJustifications = async (token: string) : Promise<ConciliationJustificationsResponse> => {
  try{
    if(cacheConciliationJustifications[token]){
      console.log('[cached-get-conciliation-justifications]')
      return cacheConciliationJustifications[token]
    }
  }catch(e){}
  
  try{
    const { data } = await api.get('/conciliation-justification', headerBearer(token))
    console.log('[requested-get-conciliation-justifications]')

    if(!data.result) throw new Error(
      data.response
    )

    cacheConciliationJustifications[token] = data

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível carregar os motivos de justificativa'
    })
  }
}
export const createConciliationJustification = async (type: string, token: string) : Promise<ConciliationJustificationResponse> => {
  try{
    const { data } = await api.post('/conciliation-justification', { type }, headerBearer(token))
    
    clearCacheConciliationJustification(token);

    return data;
  }catch(e){
    console.error(e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível salvar esse motivo de justificativa'
    })
  }
}
import { Wrapper } from "../../../shared-components/Wrapper";
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { MoreVerticalIcon } from "../../../shared-components/utils/icons";
import { getCompanies } from "../../../services/company";
import {
  deleteConciliation,
  findReconciliations,
  updateConciliation,
} from "../../../services/reconciliation";
import { shortclass } from "../../../styles/styles";
import { Dropdown } from "../../../shared-components/utils/Dropdown";
import { useNotify } from "../../../contexts/NotifyContext";
import { CompanyType } from "../../../types/conciliation.type";
import { TableFooter } from "../../../shared-components/TableFooter";
import { ModalJustifyConciliation } from "../../../components/ReceivementModule/ModalJustifyConciliation";

export interface Conciliacao {
  id: string;
  dt_conciliacao?: string;
  vl_debito_venda?: number;
  vl_debito_erp?: number;
  vl_credito_venda?: number;
  vl_credito_erp?: number;
  vl_pix_erp?: number;
  vl_pix_relatorio?: number;
  vl_total_erp: number;
  vl_dinheiro?: number;
  status_cartao?: boolean;
  status_pix?: boolean;
  status_dinheiro?: number;
  observacao?: string;
  dt_debito_venda?: Date;
  dt_credito_venda?: Date;
  nome_fantasia?: string;
  unit_slug?: string;
  business_unit?: BusinessUnit;
  type_of_justification?: string;
  vl_reajuste?: number;
}

interface BusinessUnit {
  name: string;
  nome_fantasia: string;
  business_unit_slug: string;
}

const THs = [
  'Data Conciliação',
  'Valor ERP(R$)',
  'Valor pix(R$)',
  'Valor débito(R$)',
  'Valor crédito(R$)',
  'Valor dinheiro(R$)',
  'Status Conciliação'
];
const moneyFormatter = (value?: number) => (typeof value === 'number' || (
  typeof value === 'string' && !isNaN(Number(value))
) ? Number(value):  0).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
}).replace('R$','').trim();
export default function Reconciliation() {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const [reconciliations, setReconciliations] = useState<Conciliacao[]>([]);
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [company, setCompany] = useState<CompanyType>();
  const [showingReconciliations, setShowingReconciliations] = useState<Conciliacao[]>([]);
  const [filterByUnit, setFilterByUnit] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [perPage, _] = useState(10);
  const [hasSelected, setHasSelected] = useState(false);

  const [currentConciliation, setCurrentConciliation] = useState<Conciliacao>();
  
  useEffect(() => {
    (async () => {
      if (!user) return;
      const response = await getCompanies(user?.token);
      setCompanies(response);
    })();
  }, [user]);
  useEffect(() => {
    (async () => {
      if (!user || !company) return [];
      setFilterByUnit(undefined)

      const response = await findReconciliations(company.nome_fantasia, user?.token);
      setReconciliations(
        response.filter((item: Conciliacao) => ![1,3].includes(item.status_dinheiro!))
      );
    })();
  }, [company]);
  useEffect(() => {
    setHasSelected(false)

    setShowingReconciliations(() => {
      let newState : Conciliacao[] = [];
      if(filterByUnit) newState = reconciliations.filter(
        (rec) => rec.business_unit?.business_unit_slug === filterByUnit
      )
      else reconciliations.forEach((conciliation) => {
        const findedIndex = newState.findIndex((rec) => String(rec.dt_conciliacao).slice(0,10) === String(conciliation.dt_conciliacao).slice(0,10))
        if(findedIndex === -1) newState.push(conciliation)
        else newState = newState.map((state, i) => i === findedIndex ? {
          ...state,
          vl_debito_venda: (state.vl_debito_venda ?? 0) + (conciliation.vl_debito_venda ?? 0),
          vl_debito_erp: (state.vl_debito_erp ?? 0) + (conciliation.vl_debito_erp ?? 0),
          vl_credito_venda: (state.vl_credito_venda ?? 0) + (conciliation.vl_credito_venda ?? 0),
          vl_credito_erp: (state.vl_credito_erp ?? 0) + (conciliation.vl_credito_erp ?? 0),
          vl_pix_erp: (state.vl_pix_erp ?? 0) + (conciliation.vl_pix_erp ?? 0),
          vl_pix_relatorio: (state.vl_pix_relatorio ?? 0) + (conciliation.vl_pix_relatorio ?? 0),
          vl_total_erp: (state.vl_total_erp ?? 0) + (conciliation.vl_total_erp ?? 0),
          vl_dinheiro: (state.vl_dinheiro ?? 0) + (conciliation.vl_dinheiro ?? 0),
        }:state)
      })
      return newState;
    })
    setPage(0)
  },[reconciliations, filterByUnit])

  async function handleDivergente(id: string){
    if (!user || !company) return;
    try {
      await updateConciliation({ id, status_dinheiro: 2 }, company.nome_fantasia, user.token);
      setReconciliations((prev: Conciliacao[]) => prev.map((state) => (state.id === id ? {
        ...state,
        status_dinheiro: 2
      }:state)));
      toast.success("Status alterado")
    } catch (error) {
      toast.error("Não foi possível alterar status da conciliação");
    }
  }
  //#region HANDLE CONCILIATE
  async function handleConciliate(id: string, config?: {
    date?: string,
    force: boolean,
    showSuccess?: boolean, 
  }) {
    if (!user || !company) return;

    const { date, showSuccess, force } = {
      showSuccess: true,
      force: false,
      ...(config ?? {})
    }

    const makeConciliation = async () => {
      try {
        await updateConciliation({ id, status_dinheiro: 1 }, company.nome_fantasia, user.token);
  
        setReconciliations((prev) => prev.filter((data) => data.id !== id));
        setShowingReconciliations((prev) => prev.filter((data) => data.id !== id))
        if(showSuccess) toast.success("Status alterado");
      } catch (error) {
        console.error(error)
        toast.error(date ? 
          `Não foi possível alterar o status da conciliação do dia ${date}`:
          `Não foi possível alterar o status dessa conciliação`
        );
      }
    }

    if(force) makeConciliation() 
    else showMessage((
      <p>Tem certeza que deseja conciliar essa data?</p>
    ),{
      title: 'Confirmar Conciliação',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeConciliation,
        text: 'Sim',
        theme: 'primary'
      }
    });
  }
  let inConciliateMultiple = false;
  function handleConciliateMultiple(){
    if(!user) return;

    if(inConciliateMultiple){
      toast.warning('Já existe uma fila de conciliação em andamento. Aguarde a finalização para iniciar uma nova.');
      return;
    }
    let checkeds = Array.from(
      document.querySelectorAll('.check-row-conciliation-table:checked') as NodeListOf<HTMLInputElement>
    );
    let total = checkeds.length;
    if(total === 0){
      toast.warning('Selecione pelo menos 1 item na tabela');
      return;
    }

    const makeConciliation = async () => await toast.promise(new Promise(async (resolve) => {
      if(!user) return;

      inConciliateMultiple = true;
      try{
        let el = document.getElementById('toast-control-queue-conciliate-multiple');
        do{
          let check = checkeds.shift();
          if(check){
            let id = check.value;
            let value = check.dataset.value ?? '';
            await handleConciliate(id, {
              date: value,
              showSuccess: false,
              force: true
            });
          }
          if(el){
            el.innerHTML = String(total - checkeds.length);
          }
        }while(checkeds.length > 0);
        let chs = document.querySelectorAll('.check-row-conciliation-table:checked') as NodeListOf<HTMLInputElement>;
        chs.forEach(ch => ch.checked = false);
        resolve('success');

        inConciliateMultiple = false;
      }catch(e){
        console.error(e);
        inConciliateMultiple = false;
      }
      setHasSelected(false);
    }),{
      pending: {
        render(){
          return <>
            <span>Conciliando...</span><br/>
            <span id="toast-control-queue-conciliate-multiple">0</span> de {total}
          </>;
        }
      }
    });

    showMessage((
      <p>Tem certeza que deseja conciliar {
        total === 1 ? 'essa data?' : `os ${total} dias?`
      }</p>
    ),{
      title: 'Confirmar Conciliação',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeConciliation,
        text: 'Sim',
        theme: 'primary'
      }
    });
  }
  //#endregion HANDLE CONCILIATE
  //#region HANDLE DELETE
  async function handleDeleteConciliation(id: string, config?: {
    date?: string,
    showSuccess?: boolean,
    force?: boolean
  }) {
    if (!user) return;

    const { date, showSuccess, force } = {
      showSuccess: true,
      force: false,
      ...(config ?? {})
    }

    const makeDeletion = async () => {
      if(!company) return;
      try {
        await deleteConciliation(id, company.nome_fantasia, user.token);
        setReconciliations((prev) => prev.filter((item) => item.id !== id));
        if(showSuccess) toast.success("Removida com sucesso");
      } catch (error) {
        console.error(error)
        toast.error(date ? 
          `Não foi possível remover a conciliação do dia ${date}`:
          `Não foi possível remover conciliação`
        );
      }
    }

    if(force) makeDeletion()
    else showMessage((
      <p>Tem certeza que deseja excluir essa conciliação?</p>
    ),{
      title: 'Confirmar Exclusão',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeDeletion,
        text: 'Sim',
        theme: 'red'
      }
    });
  }
  let inDeleteMultiple = false;
  function handleDeleteMultipleConciliation(){
    if(!user) return;

    if(inDeleteMultiple){
      toast.warning('Já existe uma fila de exclusão em andamento. Aguarde a finalização para iniciar uma nova.');
      return;
    }
    let checkeds = Array.from(
      document.querySelectorAll('.check-row-conciliation-table:checked') as NodeListOf<HTMLInputElement>
    );
    let total = checkeds.length;
    if(total === 0){
      toast.warning('Selecione pelo menos 1 item na tabela');
      return;
    }

    const makeDeletion = async () => await toast.promise(new Promise(async (resolve) => {
      if(!user) return;

      inDeleteMultiple = true;
      try{
        let el = document.getElementById('toast-control-queue-delete-multiple');
        do{
          let check = checkeds.shift();
          if(check){
            let id = check.value;
            let value = check.dataset.value ?? '';
            await handleDeleteConciliation(id, {
              date: value,
              showSuccess: false,
              force: true
            });
          }
          if(el){
            el.innerHTML = String(total - checkeds.length);
          }
        }while(checkeds.length > 0);
        let chs = document.querySelectorAll('.check-row-conciliation-table:checked') as NodeListOf<HTMLInputElement>;
        chs.forEach(ch => ch.checked = false);
        resolve('success');

        inDeleteMultiple = false;
      }catch(e){
        console.error(e);
        inDeleteMultiple = false;
      }
      setHasSelected(false);
    }),{
      pending: {
        render(){
          return <>
            <span>Excluindo...</span><br/>
            <span id="toast-control-queue-delete-multiple">0</span> de {total}
          </>;
        }
      }
    });

    showMessage((
      <p>Tem certeza que deseja excluir {
        total === 1 ? 'essa conciliação?' : `${total} conciliações?`
      }</p>
    ),{
      title: 'Confirmar Exclusão',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeDeletion,
        text: 'Sim',
        theme: 'red'
      }
    });
  }
  //#endregion HANDLE DELETE

  return (
    <Wrapper
      asideActive={"Em Conciliação"}
      breadcrumbs={[{ name: "Em Conciliação", href: "#" }]}
      module_name="Ivrim Conciliation"
    >
      <div className="flex gap-4 mb-5">
        <select
          id="companies"
          onChange={(event) => {
            const findedCompany = event.target.value ? companies.find((company) => company.nome_fantasia === event.target.value) : undefined
            setCompany(findedCompany);
          }}
          className="bg-gray-50 border max-w-xs border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value={""}>Selecione a empresa</option>
          {companies.map((company, index) => (
            <option key={index} value={company.nome_fantasia}>
              {company.nome_fantasia}
            </option>
          ))}
        </select>
        {company && company.businessUnits && (
          <select
            value={filterByUnit}
            onChange={(event) => {
              const findedUnit = event.target.value ? company!.businessUnits!.map(
                (unit) => unit.business_unit_slug
              ).find((unit_slug) => unit_slug === event.target.value) : undefined
              setFilterByUnit(findedUnit);
            }}
            className="bg-gray-50 border max-w-xs border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={""}>Todas unidades</option>
            {company.businessUnits.map((unit) => (
              <option key={unit.business_unit_slug} value={unit.business_unit_slug}>
                {unit.name}
              </option>
            ))}
          </select>
        )}
        {hasSelected && (
          <>
            <button
              type="button"
              className={`
                flex justify-center items-center
                rounded-lg border border-transparent 
                py-2 px-4
                text-sm font-medium 
                focus:outline-none focus:ring-2 focus:ring-offset-2
                text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
              `}
              onClick={() => handleConciliateMultiple()}
            >Conciliar Selecionados</button>
            <button
              type="button"
              className={`
                flex justify-center items-center
                rounded-lg border border-transparent 
                py-2 px-4
                text-sm font-medium 
                focus:outline-none focus:ring-2 focus:ring-offset-2
                text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
              `}
              onClick={() => handleDeleteMultipleConciliation()}
            >Excluir Selecionados</button>
          </>
        )}

      </div>
      <div className="rounded-lg overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {filterByUnit === undefined ? <th/> : (
                <th className="px-3 py-2 w-10">
                  <input
                    type="checkbox"
                    id={`check-row-conciliation-table`}
                    className={`${shortclass.checkbox.primary} bg-transparent border-2`}
                    onClick={(e) => {
                      let el = e.target as HTMLInputElement;
                      let isChecked = el.checked;
                      
                      let chs = document.querySelectorAll(`.check-row-conciliation-table${
                        isChecked ? ':not(:checked)' : ':checked'
                      }`) as NodeListOf<HTMLInputElement>;

                      chs.forEach(ch => ch.checked = isChecked);

                      setHasSelected(isChecked)
                    }}
                  />
                </th>
              )}
              {THs.map(th => (
                <th scope="col" className="px-6 py-3" key={th}>{th}</th>
              ))}
              <th className="w-3"></th>
            </tr>
          </thead>
          <tbody>
            {showingReconciliations.length === 0 && (
              <tr>
                <td colSpan={THs.length + 2}>
                  <div className="text-center text-sm px-6 py-12 flex items-center justify-center bg-gray-100/80 text-gray-500">
                    {company ? (
                      `Não há nenhuma conciliação na ${filterByUnit ? 'unidade':'empresa'} selecionada`
                    ):'Selecione uma empresa para ver suas conciliações'}
                  </div>
                </td>
              </tr>
            )}
            {showingReconciliations.slice(page * perPage, (page + 1) * perPage).map((item) => (
              <tr className="bg-white/40 border-b dark:bg-gray-900 dark:border-gray-700" key={item.id}>
                {filterByUnit === undefined ? <td/> : (
                  <td className="px-3 py-4 w-10">
                    <input
                      type="checkbox"
                      id={`check-row-conciliation-table-${item.id.replaceAll('.','_')}`}
                      className={`check-row-conciliation-table ${shortclass.checkbox.primary} bg-transparent border-2`}
                      value={item.id}
                      onClick={(e) => {
                        let hasAtLeast1Checked = document.querySelectorAll(`.check-row-conciliation-table:checked`).length > 0;
                        let checkAll = document.getElementById(`check-row-conciliation-table`) as HTMLInputElement;

                        if(hasAtLeast1Checked){
                          setHasSelected(true);

                          checkAll.checked = document.querySelectorAll(
                            `.check-row-conciliation-table:not(:checked)`
                          ).length === 0;
                        }
                        else{
                          setHasSelected(false);
                          checkAll.checked = false;
                        }
                      }}
                      data-value={item.dt_conciliacao?.slice(0, 10).split("-").reverse().join("/") || ""}
                    />
                  </td>
                )}
                <td
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-800 whitespace-nowrap dark:text-white"
                >
                  <div className="flex flex-col">
                    <span>{item.dt_conciliacao?.slice(0, 10).split("-").reverse().join("/") || ""}</span>
                    <span className="text-sm text-gray-500">
                      {filterByUnit === undefined ? 'Todas Unidades' : ( item.business_unit ? item.business_unit.name : '-' )}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  {moneyFormatter(item.vl_total_erp)}
                </td>
                <td className="px-6 py-4">{moneyFormatter(item.vl_pix_relatorio)}</td>
                <td className="px-6 py-4">{moneyFormatter(item.vl_debito_venda)}</td>
                <td className="px-6 py-4">{moneyFormatter(item.vl_credito_venda)}</td>
                <td className="px-6 py-4">{moneyFormatter(item.vl_dinheiro)}</td>
                <td className="px-6 py-4">
                  {
                   item.status_dinheiro === 0 ? "Pendente" : 
                   item.status_dinheiro === 1 ? "Conciliado" : 
                   item.status_dinheiro === 3 ? "Conciliado com Justif.":"Divergente"
                  }
                </td>
                <td className="px-2 py-4 w-3 relative">
                  {filterByUnit !== undefined && (
                    <Dropdown
                      trigger={<MoreVerticalIcon color="rgb(31 71 127 / 0.8)" />}
                      classNames={{ wrapper: "block mt-2 text-left" }}
                      orientation={null}
                      styles={{ list: { transform: "translateX(-14rem)" } }}
                      autoPosition
                    >
                      {item.status_dinheiro === 0 && (
                        <>
                          <button
                            type="button"
                            className={shortclass.dropdownItem}
                            onClick={() => handleConciliate(item.id)}
                          >Apontar Conciliado</button>
                          <button
                            type="button"
                            className={shortclass.dropdownItem}
                            onClick={() => setCurrentConciliation(item)}
                          >Justificar</button>
                          <button
                            type="button"
                            className={shortclass.dropdownItem}
                            onClick={() => handleDivergente(item.id)}
                          >Apontar Divergência</button>
                        </>
                      )}
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => handleDeleteConciliation(item.id)}
                      >Excluir</button>
                    </Dropdown>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!filterByUnit && showingReconciliations.length > 0 && (
        <p className="w-full pt-2 px-4 text-xs text-gray-500">
          Os valores acumulados contabilizam apenas conciliações de unidades em aberto
        </p>
      )}
      <TableFooter
        pageIndex={page}
        perPage={perPage}
        total={showingReconciliations.length}
        totalPages={Math.ceil(showingReconciliations.length / perPage)}
        canNextPage={page < (Math.ceil(showingReconciliations.length / perPage) - 1)}
        canPreviousPage={page > 0}
        goToPage={(pageIndex) => setPage(pageIndex)}
        nextPage={() => setPage((current) => current + 1)}
        previousPage={() => setPage((current) => current - 1)}
        isFiltering={false}
      />
      <ModalJustifyConciliation
        isOpen={!!currentConciliation}
        setIsOpen={() => setCurrentConciliation(undefined)}
        conciliation={currentConciliation}
        onSubmit={() => {
          if(!currentConciliation) return;
          
          setReconciliations((prev) => prev.filter((data) => data.id !== currentConciliation.id))
          setShowingReconciliations((prev) => prev.filter((data) => data.id !== currentConciliation.id))
          setCurrentConciliation(undefined)
        }}
      />
    </Wrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { Label, TextInput } from 'flowbite-react';
import { shortclass } from '../../../styles/styles';
import { Client, Permition, UserCategory } from '../../../shared-types';
import { getPermitions } from '../../../services/permition';
import { useNotify } from '../../../contexts/NotifyContext';
import { useAuth } from '../../../contexts/AuthContext';
import { ResponseUserCategory, createUserCategory, deleteUserCategory, updateUserCategory } from '../../../services/userCategory';

interface CreateCategoryFormProps {
  onCancel: () => void;
  selectedCompany: Client,
  category?: UserCategory;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCategoryForm = ({ onCancel, selectedCompany, category, isLoading, setIsLoading }:CreateCategoryFormProps) => {
  const { toast, showMessage } = useNotify();
  const { user } = useAuth();

  const [newCategoryName, setNewCategoryName] = useState(category?.name ?? '');
  const [newCategoryDescription, setNewCategoryDescription] = useState(category?.description ?? '');
  const [permissions, setPermissions] = useState<Permition[]>([]);
  const [depth, setDepth] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      if (!user) return
      try {
        const response = await getPermitions(user.token);
        if (response.result && response.data) {
          setPermissions(response.data);
        } else {
          toast.error(response.response || 'Erro ao obter permissões');
        }
      } catch (error) {
        toast.error('Erro ao obter permissões');
      }
    };

    fetchData();
  }, [user]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if(!user || isLoading) return;

    setIsLoading(true);
    try{
      await (async () => {
        const formData = new FormData(e.currentTarget)
        if (!newCategoryName) {
          toast.error('O nome da categoria é obrigatório!')
          return
        }
    
        const permitionSlugs = formData.getAll('category_checkbox_permission') as string[]
        const slug = category ? category.slug : newCategoryName.replace(/[^\w\d-_.]/gi, '_');
        const id = category?.id;
        const name = newCategoryName;
        const description = newCategoryDescription;
    
        if (selectedCompany?.userCategories) {
          if (selectedCompany.userCategories.some(
            (cat) => !!(cat.name === name && cat.id !== id)
          )) {
            toast.error("Já existe uma categoria com este nome.")
            return
          }
        }
        
        let res: ResponseUserCategory | undefined = undefined
        if(id) res = await updateUserCategory(id, {
          name,
          description,
          permitionSlugs,
          slug,
          clientId: selectedCompany.id,
          depth
        }, user.token)    
        else res = await createUserCategory({
          name,
          description,
          permitionSlugs,
          slug,
          clientId: selectedCompany.id,
          depth
        }, user.token)
        
        if(res && !res.result){
          toast.error(res.response) 
          return
        }
        
        toast.success(`${res.response}. Está página irá recarregar em alguns segundos.`)
        setTimeout(() => window.location.reload(), 3*1000);
      })()
    }catch(e){ console.error(e) }
    setIsLoading(false)
  }
  function handleDelete(){
    if(!user || !category) return;

    const onDelete = async () => {
      if(isLoading)  return;
      setIsLoading(true)
      try{
        await (async () => {
          const res = await deleteUserCategory(category.id, category.clientId, user.token)
          if(!res.result){
            toast.error(res.response)
            return;
          }
    
          toast.success(`${res.response}. Está página irá recarregar em alguns segundos.`)
          setTimeout(() => window.location.reload(), 3*1000);
        })()
      }catch(e){ console.error(e) }
      setIsLoading(false)
    }

    showMessage((
      <p className="text-gray-500 text-sm">
        Tem certeza que deseja excluir está categoria de permissão?
      </p>
    ), {
      title: 'Confirmar Exclusão',
      actionButton: {
        theme: 'danger',
        text: 'Excluir',
        onClick: onDelete
      }
    })
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className="relative h-full flex flex-col">
      <div className="mb-5">
        <div className="mb-2">
          <div className="mb-2 block">
            <Label htmlFor="new-category-name" value="Nome da categoria" />
          </div>
          <TextInput
            id="new-category-name"
            type="text"
            placeholder="Digite o nome da categoria"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <div className="mb-2 block">
            <Label htmlFor="new-category-description" value="Descrição" />
          </div>
          <TextInput
            id="new-category-description"
            type="text"
            placeholder="Digite a descrição da categoria"
            value={newCategoryDescription}
            onChange={(e) => setNewCategoryDescription(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <div className="mb-2 block">
            <Label htmlFor="new-category-depth" value="Nível de Hierarquia" />
          </div>
          <TextInput
            id="new-category-depth"
            type="number"
            min={0}
            placeholder="Defina o nível desta permissão na hierarquia"
            value={depth}
            onChange={(e) => setDepth(Number(e.target.value) ?? 0)}
            required
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5 items-start overflow-y-auto max-h-[20rem] mb-9">
        {permissions.map((permission, i) => (
          <div className="flex flex-col gap-2 pr-1" key={permission.id}>
            <label className="relative inline-flex items-center cursor-pointer m-1">
              <input
                type="checkbox"
                name={`category_checkbox_permission`}
                id={`category-checkbox-${permission.id}-${i}`}
                value={permission.slug}
                className="sr-only peer"
                defaultChecked={
                  (category?.permitions ?? []).some((perm) => perm.slug === permission.slug)
                }
              />
              <div className={`
                w-9 h-5 bg-gray-200
                peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                dark:border-gray-600 peer-checked:bg-blue-600
              `}></div>
              <span className="ml-3 text-xs font-semibold uppercase text-gray-700 dark:text-gray-300">
                {permission.name}
              </span>


            </label>
            <span className="ml-12 -mt-4 text-xs text-gray-500 dark:text-gray-400 pl-1">
              {permission.description}
            </span>
          </div>
        ))}
      </div>
      <div className="mt-auto pt-0 pb-4">
        <div className={`grid grid-cols-1 ${category ? 'sm:grid-cols-3':'sm:grid-cols-2 md:grid-cols-2'} gap-2.5`}>
          <button
            type="button"
            className={`${shortclass.button.secondary}`}
            onClick={onCancel}
          >Cancelar</button>
          <button
            type="submit"
            className={`${shortclass.button.primary}`}
          >{category ? 'Atualizar' : 'Criar'}</button>
          {category && (
            <button
              type="button"
              className={`${shortclass.button.danger}`}
              onClick={handleDelete}
            >Excluir</button>
          )}
        </div>
      </div>
    </form>
  );
};
import { OrderType, StatusType } from "../types"
import { api, handleErrorResultAndResponse, headerBearer } from "./api"
import { clearCacheOrderHistoryOrderId } from "./orderHistory";

export async function putStep(order_id: string, step: number, token: string): Promise<{
  result: boolean,
  response: string,
  data?: OrderType
}> {
  try{
    const { data } = await api.put(`/order`, {
      order_id: order_id,
      step: step
    }, headerBearer(token));

    clearCacheOrderHistoryOrderId(order_id);

    return {
      result: true,
      response: "Etapa alterada com sucesso",
      data
    };
  }catch(err){
    console.error(err)
    return handleErrorResultAndResponse(err, {
      result: false,
      response: "Houve um erro inesparado ao alterar a etapa"
    });
  }
}
export async function getStages(token: string) : Promise<StatusType[]>{
  try{
    const response = await api.get(`/stages`, headerBearer(token));
    return response.data;
  }
  catch(err){
    console.error(err)
    const errorMessage = handleErrorResultAndResponse(err, {
      result: false,
      response: "Houve um erro inesparado ao carregar a etapa"
    });

    throw new Error(errorMessage.response);
  }
}
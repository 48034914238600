import moment from 'moment';

import React, { useEffect, useState } from "react";
import { TableFooter } from "../../shared-components/TableFooter";
import { useAuth } from "../../contexts/AuthContext";
import { Loading } from '../../components/Loading';

export type TableType = {
  data: Record<string, any>,
  onLoad: () => Promise<void>,
  loading: boolean,
  perPage?: number,
  icons?: Record<string, React.ReactElement>,
  isFiltering?: boolean,
}

export type InformationData = {
  title: string,
  description: string,
  date?: Date,
  icon?: Record<string, JSX.Element>
}

export const Table = ({ 
  data,
  icons,
  onLoad,
  loading,
  perPage = 5,
  isFiltering = false
}: TableType): React.ReactElement => {
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);

  const calcTotalPages = (totalDatas: number) => {
    let numPages = Math.ceil(totalDatas / perPage);
    if(numPages <= 0) numPages = 1;

    setTotalPages(numPages);
  };
  const previousPage = () => setPageIndex((prevState) => {
    let newIndex = prevState - 1;
    if(newIndex >= 0) return newIndex;
    return prevState;
  });
  const nextPage = () => setPageIndex((prevState) => {
    let totalPagesInDB = Math.ceil((
      data.count ?? 0
    ) / perPage);
    let newIndex = prevState + 1;

    if(newIndex >= totalPagesInDB || newIndex < 0) return prevState;
    if(newIndex >= totalPages) onLoad();

    return newIndex;
  });
  const goToPage = (index: number) => {
    let totalPagesInDB = Math.ceil((data.count ?? 0) / perPage);

    if(index >= totalPagesInDB || index < 0) return;
    if(index >= totalPages) onLoad();
  
    setPageIndex(() => index);
  }

  const handleWord = (text: string, position: number = 0) => {
    const words = text.split(' ');
    return words[position]
  }

  useEffect(() => {
    calcTotalPages(data.count)
  }, [data])
  
  useEffect(() => {
    setCanPreviousPage(pageIndex > 0)
    setCanNextPage(pageIndex < (Math.ceil((data.count) / perPage) - 1))
  },[pageIndex, perPage]);

  return (
    <div className="mb-6">
      <div className={`
        h-full min-h-[calc(20rem-3rem)]
        flex flex-col justify-between
      `}>
        <div>
          <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs  text-primary-800 uppercase bg-primary-500/5">
                <tr>
                  <th className="px-5 py-2 font-bold">Últimas sessões</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.data && data.data.slice(
                  pageIndex * perPage,
                  (pageIndex + 1) * perPage
                ).map((item: InformationData, index: number) => (
                  <tr className="hover:bg-gray-200/80 border-b last:border-none" key={index}>
                    <td
                      className="flex flex-row gap-1 px-5 py-4 h-full"
                    >
                      <div className="flex justify-center items-center px-4 py-4 h-full max-w-full">
                        {(icons && Object.keys(icons).includes(handleWord(item.title, 1))) && (icons[handleWord(item.title, 1)])}
                      </div>
                      <div className='w-full'>
                        <div className="flex items-center gap-2 max-w-full">
                          {item.title.split(' ').length > 0 ? (
                            <>
                              <span className="text-[11px] max-w-[calc(100%-2rem)] truncate bg-gray-400 text-white rounded-lg px-1.5">
                                {handleWord(item.title, 0)}
                              </span>
                              <strong className="text-sm max-w-[calc(100%-2rem)] truncate">{handleWord(item.title, 1)}</strong>
                            </>
                          ):(
                            <strong className="text-sm max-w-[calc(100%-2rem)] truncate">{item.title}</strong>
                          )}
                        </div>
                        <div className="flex flex-col gap-2 mt-0.5">
                          <span className="text-gray-500 text-xs font-normal">
                            {item.description}
                          </span>
                          <span className="text-gray-400 text-xs">{moment(item.date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm')}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {(data && data.count === 0) && (
                  <tr>
                    <td
                      colSpan={3}
                      className="px-3 py-10 text-center"
                    >
                      {'Não há histórico de sessões'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <TableFooter
          perPage={perPage}
          pageIndex={pageIndex}
          totalPages={totalPages}
          total={data && data.count as number}

          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}

          goToPage={goToPage}
          previousPage={previousPage}
          nextPage={nextPage}

          isFiltering={isFiltering}
        />
        {loading && <Loading className="absolute inset-0 z-50 bg-gray-50/75"/>}
      </div>
    </div>
  )
}
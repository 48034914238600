import { FileInput, Label, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import { OrderHeader } from "../../../components/Order/OrderHeader";
import { DeleteIcon, DownloadAltIcon } from "../../../components/SvgIcons";
import { Wrapper } from "../../../shared-components/Wrapper";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { availableBanks, cpfOrCnpjMask } from "../../../services/mask";
import { formatRows, getOrder } from "../../../services/order";
import { createDeposit, deleteDeposit, sendDocs } from "../../../services/payment";
import { shortclass } from "../../../styles/styles";
import { OrderFormattedType, PaymentDocType, User } from "../../../types";
import { hubRoutes } from "../../../shared-types/utils/routes";

type EnumFileTypes = 'nf' | 'boleto' | 'contrato' | 'pagamento' | 'suporte';
type EnumFormGroup = 'paymentData' | 'billet'; 
interface PaymentDocTempType extends PaymentDocType{
  file: File | null
}
export const PaymentDocs = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { toast } = useNotify();
  const navigate = useNavigate();
  
  const [order, setOrder] = useState<OrderFormattedType>();  
  const [selectedFormGroup, setSelectedFormGroup]= useState<EnumFormGroup>('paymentData'); 
  const [loading,setLoading] = useState(false);
  // #region PAYMENT DATA STATES
  const [hasDeposit, setHasDeposit] = useState<boolean>(false);
  const [cnpjOrCpf, setCnpjOrCpf] = useState<string>(); 
  const [bankName, setBankName] = useState<string>(); 
  const [agency, setAgency] = useState<string>(); 
  const [account, setAccount] = useState<string>();
  const clearDeposit = () => {
    setCnpjOrCpf('');
    setBankName('');
    setAgency('');
    setAccount('');
    setHasDeposit(false);
  }
  // #endregion PAYMENT DATA STATES

  const [nfs, setNfs] = useState<PaymentDocTempType[]>([]);
  const [billets, setBillets] = useState<PaymentDocTempType[]>([]);
  const [contracts, setContracts] = useState<PaymentDocTempType[]>([]);
  const [payments, setPayments] = useState<PaymentDocTempType[]>([]);
  const [supports, setSupports] = useState<PaymentDocTempType[]>([]);
  const [requiredFiles,setRequiredFiles] = useState<EnumFileTypes[]>();

  // #region HANDLE EFFECTS
  useEffect(() => {
    if(!user) return;
    if(!id){
      navigate('/compras-e-contas-a-pagar');
      return;
    }

    (async  () => {
      const response = await getOrder(id, user.token);
      const parsedOrderArray = formatRows([response]);      
      setOrder(parsedOrderArray[0]);
    })()
  },[id, user]);
  useEffect(() => {
    if(!order) return;

    switch(order.typeSlug){
      case 'servico': setRequiredFiles([
        'contrato','nf','boleto'
      ]); break;
      case 'produto': setRequiredFiles([
        'nf','boleto'
      ]); break;
      case 'geral_e_adm': setRequiredFiles([
        'suporte', 'pagamento'
      ]); break;
    }

    if(!order.documents){
      setBillets([]);
      setNfs([]);
      setContracts([]);
      setPayments([]);
      setSupports([]);

      return;
    }

    const handlePrepareDocs = (type: EnumFileTypes) => order.documents!.filter(doc => doc.type === type).map(
      (doc) => ({
        ...doc,
        file: null  
      })
    );

    const filteredBillets = handlePrepareDocs('boleto'); 
    setBillets(filteredBillets);
    if(filteredBillets.length > 0) setSelectedFormGroup('billet');

    setNfs(handlePrepareDocs('nf'));
    setContracts(handlePrepareDocs('contrato'));
    setPayments(handlePrepareDocs('pagamento'));
    setSupports(handlePrepareDocs('suporte'))

    if(order.deposito){
      setCnpjOrCpf(cpfOrCnpjMask(order.deposito.cnpj));
      setBankName(order.deposito.banco);
      setAgency(order.deposito.agencia);
      setAccount(order.deposito.contacorrente);
      setHasDeposit(true);
    }
    else clearDeposit();
  }, [order]);
  // #endregion HANDLE EFFECTS

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();
    setLoading(true); 

    if(!user || !order) return;

    let isSendDocument = true; 
    if(
      nfs.length === 0 &&
      contracts.length === 0 &&
      payments.length === 0 &&
      supports.length === 0 &&
      billets.length === 0
    ){
      isSendDocument = false;

      if(selectedFormGroup === "billet"){
        toast.warning('Enviei pelo menos um arquivo');
        return;
      }
    }
    if(!isSendDocument && selectedFormGroup === "paymentData"){
      if(
        !cnpjOrCpf ||
        !bankName ||
        !agency ||
        !account
      ){
        toast.warning('Enviei pelo menos um arquivo ou os dados para pagamento');
        return;
      }
    }

    let redirectToDetails = true;

    if(isSendDocument){
      const data = new FormData();
      
      const handleAppendFileToFormData = (docs: PaymentDocTempType[], type: EnumFileTypes) => {
        docs.filter((doc) => !!doc.file).forEach((doc, i) => data.append(type, doc.file!))
      }
  
      if(nfs.length > 0) handleAppendFileToFormData(nfs, 'nf');
      if(contracts.length > 0) handleAppendFileToFormData(contracts, 'contrato');
      if(payments.length > 0) handleAppendFileToFormData(payments, 'pagamento');
      if(supports.length > 0) handleAppendFileToFormData(supports, 'suporte');     
      
      if(selectedFormGroup === "billet"){
        if(billets.length > 0) handleAppendFileToFormData(billets, 'boleto');
        else{
          toast.error('É obrigatório inserir o boleto quando este método está selecionado');
          return;
        }
      }
  
      const oldFiles : {}[] = [
        ...nfs.filter((doc) => !doc.file).map((doc) => doc.src),
        ...contracts.filter((doc) => !doc.file).map((doc) => doc.src),
        ...payments.filter((doc) => !doc.file).map((doc) => doc.src),
        ...supports.filter((doc) => !doc.file).map((doc) => doc.src),
        ...billets.filter((doc) => !doc.file).map((doc) => doc.src),
      ];
  
      if(oldFiles.length > 0) data.append('oldFiles', JSON.stringify(oldFiles));

      try{
        const responseSendDocs = await sendDocs(data, order.id, order.poId, user.token);
        const parsedOrderArray = formatRows([
          responseSendDocs
        ]);
        setOrder(parsedOrderArray[0]);
        toast.success('Documentos enviados');
        setLoading(false);
      }catch(e){
        console.error(e);
        toast.error('Houve um erro inesperado ao enviar documentos');
        redirectToDetails = false;
        setLoading(false);
      }
    }
    
    if(selectedFormGroup === 'paymentData') redirectToDetails = await handlePaymentData();

    if(redirectToDetails) navigate(`/compras-e-contas-a-pagar?poId=${order.poId}`);
  }
  async function handlePaymentData(){
    if(!user || !order) return false;

    if(!cnpjOrCpf && !bankName && !agency && !account) return false;
    if(
      !cnpjOrCpf ||
      !bankName ||
      !agency ||
      !account
    ){
      toast.error('Todos os campos de dados  para pagamento são obrigatórios');
      return false;
    }

    const data = {
      cnpj: cnpjOrCpf.replace(/[^0-9]/g,''),
      banco: bankName,
      agencia: agency.replace(/[^0-9]/g,''),
      contacorrente: account.replace(/[^0-9]/g,''),
      orderId: order.id
    };

    try{
      const response = await createDeposit(data, user.token);
      if(!response.result || !response.data){
        toast.error(response.response);
        return false;
      }

      toast.success(response.response);
      if(response.data){
        const parsedOrderArray = formatRows([
          response.data
        ]);
        setOrder(parsedOrderArray[0]);
      }
      setLoading(false);
      return true;
      
    }catch(e){
      console.error(e);
      toast.error('Houve um erro ao lidar com a resposta dos dados de depósito');
      setLoading(false);
      return false;
    }
  }
  function handleSelectFormGroup(formGroup: EnumFormGroup){
    if(formGroup === 'paymentData' && billets.length > 0){
      toast.warning('Remove primeiro os boletos para depois alterar a forma de pagamento');
      return;
    }
    if(formGroup === 'billet'  && hasDeposit){
      toast.warning('Exclua primeiro os dados para pagamento no botão de lixeira para depois alterar a forma de pagamento');
      return;
    }
    setSelectedFormGroup(formGroup);
  }
  function handleChangeFile(
    target: any, 
    type: EnumFileTypes
  ){
    if(!target.files) return;

    const handleAppendFile = (prevState: PaymentDocTempType[]) : PaymentDocTempType[] => {
      let file = target.files[0];
      if(!file) return prevState;
      return [...prevState, {
        name: file.name,
        src: '',
        type,
        file
      }];
    }

    switch(type){
      case 'nf':        setNfs(handleAppendFile);        break;
      case 'boleto':    setBillets(handleAppendFile);    break;
      case 'contrato':  setContracts(handleAppendFile);  break;
      case 'pagamento': setPayments(handleAppendFile);   break;
      case 'suporte':   setSupports(handleAppendFile);   break;
    }
  }
  function handleRemoveFile(index: number, type: EnumFileTypes){
    const handleRemove = (prevState: PaymentDocTempType[]) : PaymentDocTempType[] => {
      if(prevState.length <= index){
        toast.error('Documento não encontrado');
        return prevState;
      }

      return prevState.filter((_, i) => i !== index);
    }
    switch(type){
      case 'nf':        setNfs(handleRemove);        break;
      case 'boleto':    setBillets(handleRemove);  break;
      case 'contrato':  setContracts(handleRemove);  break;
      case 'pagamento': setPayments(handleRemove);   break;
      case 'suporte':   setSupports(handleRemove);   break;
    }
  }
  async function handleDeleteDeposit(){
    if(!user || !order) return;

    if(!order.deposito){
      toast.warning('O deposito já foi removido');
      return;
    }
    try{
      await deleteDeposit(order.id, user.token);
  
      clearDeposit();

      toast.success('Deposito removido com sucesso');
    }catch(e){
      console.error(e);
      toast.error('Houve um erro inesperado');
    }
  }
  return (
    <Wrapper
      asideActive="Contas a Pagar"
      breadcrumbs={[{
        'name': 'Contas a Pagar',
        'href': hubRoutes.old_cap.home()
      },{
        'name': 'Documentos p/ Pagamento',
        'href': '#'
      }]}
      module_name="Ivrim Flows"
    >
      <div className="border bg-primary-400/10 bg-gradient-glass backdrop-blur-[25px] rounded-lg py-6 px-4 text-gray-500">
        {!order ? '' : (
          <div className="h-full flex flex-col">
            <div>
              <OrderHeader order={order}/>
              <div className="text-gray-600">
                <span className="block uppercase text-xs mt-2">
                  <b className="text-gray-700">Tipo de Ordem:</b> {order.type}
                </span>
                <span className="uppercase text-xs">
                  <b className="text-gray-700">Status:</b> {order.status}
                </span>
              </div>
            </div>
            <form className="flex-1 flex flex-col justify-between" onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-4 md:gap-6 mt-6">
                <div className="h-full flex flex-col">
                  <div className="grid grid-cols-2 mb-0 border border-gray-300 border-b-0 rounded-t-lg">
                    <button
                      type="button"
                      className={`h-10 text-sm text-gray-500 font-semibold px-4 rounded-tl-lg hover:bg-gray-300/50 hover:text-gray-600 ${
                        selectedFormGroup === 'paymentData' ? 'bg-primary-400/20 text-gray-500':''
                      } text-ellipsis overflow-hidden whitespace-nowrap max-w-full`}
                      onClick={() => handleSelectFormGroup('paymentData')}
                    >Dados para Pagamento</button>
                    <button
                      type="button"
                      className={`h-10 text-sm text-gray-500 font-semibold px-4 rounded-tr-lg hover:bg-gray-300/50 hover:text-gray-600 ${
                        selectedFormGroup === 'billet' ? 'bg-primary-400/20 text-gray-500':''
                      }`}
                      onClick={() => handleSelectFormGroup('billet')}
                    >Boleto</button>
                  </div>
                  <div className={`px-4 flex-1 rounded-b-lg bg-gray-200/25 border border-gray-300`}>
                    {selectedFormGroup === 'paymentData' && (
                      <FormGroupPaymentData
                        hasDeposit={hasDeposit}

                        cnpjOrCpf={cnpjOrCpf} setCnpjOrCpf={setCnpjOrCpf}
                        bankName={bankName}   setBankName={setBankName}
                        agency={agency}       setAgency={setAgency}
                        account={account}     setAccount={setAccount}
                        
                        onDelete={handleDeleteDeposit}
                      />
                    )}
                    {selectedFormGroup === 'billet' && (
                      <div className="mt-4">
                        <FormGroupDoc
                          title="Upload do Boleto"
                          type="boleto"
                          docs={billets}
                          onChangeFile={handleChangeFile}
                          onRemoveFile={handleRemoveFile}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="h-full flex flex-col">
                  <strong className={`flex items-center justify-center h-10 text-center text-sm font-semibold px-4 rounded-t-lg border border-b-0 border-gray-300 hover:bg-gray-300/50 bg-primary-400/20 text-gray-500`}>Outros arquivos</strong>
                  <div className="bg-gray-200/25 px-4 rounded-b-lg pt-2 flex-1 border border-gray-300">
                    {requiredFiles?.filter(required => required !== 'boleto').map(required => (
                      <div className="mb-4" key={required}>
                        {
                          required === 'nf' ? (
                            <FormGroupDoc
                              title="Upload da Nota Fiscal"
                              type="nf"
                              docs={nfs}
                              onChangeFile={handleChangeFile}
                              onRemoveFile={handleRemoveFile}
                            />
                          ) : (
                            required === 'contrato' ? (
                              <FormGroupDoc
                                title="Upload do Contrato"
                                type="contrato"
                                docs={contracts}
                                onChangeFile={handleChangeFile}
                                onRemoveFile={handleRemoveFile}
                              />
                            ) : (
                              required === 'pagamento' ? (
                                <FormGroupDoc
                                  title="Upload do Doc. para Pagamento"
                                  type="pagamento"
                                  docs={payments}
                                  onChangeFile={handleChangeFile}
                                  onRemoveFile={handleRemoveFile}
                                />
                              ) : (
                                required === 'suporte' ? (
                                  <FormGroupDoc
                                    title="Upload do Doc. de Suporte"
                                    type="suporte"
                                    docs={supports}
                                    onChangeFile={handleChangeFile}
                                    onRemoveFile={handleRemoveFile}
                                  />
                                ) : null
                              )
                            )
                          )
                        }
                      </div>
                    ))}
                  </div>
                </div>      
              </div>
              <button
                type="submit"
                className={shortclass.button.primary + ` w-52 font-semibold mt-8 mx-auto !rounded-lg`}
              >Salvar Arquivos</button> 
            </form>
          </div>
        )}
      </div>
      {(loading || !order )&& <Loading className="fixed top-0 left-0  h-full w-full bg-semi-transparent"/> }
     
    </Wrapper>
  );
}

interface FormGroupPaymentDataProps {
  cnpjOrCpf: string | undefined,
  setCnpjOrCpf: (param: string | undefined) => void,
  bankName: string | undefined,
  setBankName: (param: string | undefined) => void,
  agency: string | undefined,
  setAgency: (param: string | undefined) => void,
  account: string | undefined,
  setAccount: (param: string | undefined) => void,
  hasDeposit: boolean,
  onDelete: () => Promise<void>
}
const FormGroupPaymentData = ({
  cnpjOrCpf, setCnpjOrCpf,
  bankName, setBankName,
  agency, setAgency,
  account, setAccount,
  hasDeposit,
  onDelete
}: FormGroupPaymentDataProps) => (
  <div className="grid lg:grid-cols-2 grid-flow-row gap-4 max-w-xl my-2">
    <div>
      <div className="mb-2 block">
        <Label
          className="text-gray-600 text-sm"
          htmlFor="input_cnpj_or_cpf"
          value="CNPJ ou CPF"
        />
      </div>
      <TextInput
        id="input_cnpj_or_cpf"
        type="text"
        value={cpfOrCnpjMask(cnpjOrCpf ?? '')}
        onChange={(e) => setCnpjOrCpf(e.target.value)}
        placeholder="Digite o CNPJ ou CPF"
      />
    </div>
    <div>
      <div className="mb-2 block">
        <Label
          className="text-gray-600 text-sm"
          htmlFor="input_bank_name"
          value="Banco"
        />
      </div>
      <TextInput
        id="input_bank_name"
        list="bank-name-datalist"
        type="text"
        value={bankName}
        onChange={(e) => setBankName(e.target.value)}
        placeholder="Digite o nome do banco"
      />
      <datalist id="bank-name-datalist">
        {availableBanks.map((bank) => (
          <option value={bank.name} key={`${bank.name}-${bank.code}`}/>
        ))}
      </datalist>
    </div>
    <div>
      <div className="mb-2 block">
        <Label
          className="text-gray-600 text-sm"
          htmlFor="input_agency"
          value="Agência"
        />
      </div>
      <TextInput
        id="input_agency"
        type="text"
        value={agency}
        onChange={(e) => setAgency(e.target.value)}
        placeholder="Digite o número da Agência"
      />
    </div>
    <div>
      <div className="mb-2 block">
        <Label
          className="text-gray-600 text-sm"
          htmlFor="input_account"
          value="Conta Corrente"
        />
      </div>
      <TextInput
        id="input_account"
        type="text"
        value={account}
        onChange={(e) => setAccount(e.target.value)}
        placeholder="Digite o número da Conta Corrente"
      />
    </div>
    {hasDeposit && (
      <button
        type="button"
        className={`
          text-gray-600 outline-none
          px-4 py-2 border rounded-lg 
          flex items-center justify-center text-center col-span-2 bg-gray-200
          focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-gray-200 hover:bg-gray-100 hover:border-2
        `}
        onClick={onDelete}
      ><DeleteIcon width="20" height="20"/></button>
    )}
  </div>
);

interface FormGroupDocProps{
  title: string,
  type: EnumFileTypes,
  docs: PaymentDocTempType[],
  onChangeFile: (target: any, type: EnumFileTypes) => void,
  onRemoveFile: (i: number, type: EnumFileTypes) => void
}
export const FormGroupDoc = ({
  title,
  type,
  docs,
  onChangeFile,
  onRemoveFile
}: FormGroupDocProps) => (
  <div className="mb-2">  
    <div className="mb-2">
      <Label
        htmlFor={`upload-form-${type}`}
        value={title}
        className="text-gray-600 text-sm"
      />
    </div>
    <FileInput
      id={`upload-form-${type}`}
      defaultValue={""}
      className="rounded-lg"
      onChange={(e) => onChangeFile(e.target, type)}
    />
    <ul className="mt-4 max-w-[calc(100vw-8.50rem)]">
      {docs.map((doc, i) => (
        <li className="p-2 flex flex-col sm:flex-row justify-between items-center gap-2 border-b last:border-b-0 hover:bg-gray-200/10 rounded-md" key={doc.src + i}>
          <span className="text-sm text-gray-600 text-ellipsis overflow-hidden whitespace-nowrap max-w-full">{doc.name}</span>
          <div className="flex gap-2">
            {!doc.file && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BASE_URL}${doc.src}`}
                className={`
                  text-gray-600 outline-none
                  px-4 py-2 border rounded-lg inline-block
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-gray-200 hover:bg-gray-100 hover:border-2
                `}
              ><DownloadAltIcon width="20" height="20"/></a>
            )}
            <button
              type="button"
              className={`
                text-gray-600 outline-none
                px-4 py-2 border rounded-lg inline-block
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-gray-200 hover:bg-gray-100/50 hover:border-2
              `}
              onClick={() => onRemoveFile(i, type)}
            ><DeleteIcon width="20" height="20"/></button>
          </div>
        </li>
      ))}
    </ul>
  </div>
);
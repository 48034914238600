import { useRef, useState } from "react";
import { Modal } from "../../shared-components/utils/Modal"
import { Label } from "flowbite-react";
import { useNotify } from "../../contexts/NotifyContext";
import { useAuth } from "../../contexts/AuthContext";
import { appendClosingFolder } from "../../services/closingFolder";

interface ModalCreateFolderProps {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  updateFolders: () => void;
  paths: string[]
}
export const ModalCreateFolder = ({ isOpen, setIsOpen, updateFolders, paths }: ModalCreateFolderProps) => {
  const submitFormRef = useRef<HTMLButtonElement>(null);

  const { user } = useAuth();
  const { toast } = useNotify();

  const [name, setName] = useState('');

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!user) return;

    const res = await appendClosingFolder(paths, [{
      type: "dir", title: name
    }], user.token)

    if (res.result) {
      toast.success(res.response)
      updateFolders()
      setIsOpen(false);
    }
    else toast.error(res.response)

  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{
        title: "Criar Pasta",
        size: 'sm:w-full sm:max-w-md',
        cancelButton: true,
        cancelButtonText: "Cancelar",
        actionButton: {
          theme: "primary",
          text: "Finalizar",
          onClick: () => { if (submitFormRef.current) submitFormRef.current.click() },
          autoClose: false,
        },
      }}
    >
      <form className="mb-4" onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={submitFormRef} />
        <div className="mb-2">
          <Label
            className="text-xs pt-3 pb-1 text-gray-700 focus:outline-none"
            htmlFor="gallery-name"
            value="Digite o nome que deseja para sua pasta:"
          />
        </div>
        <input
          className="
            block w-full text-gray-700
            border bg-gray-50 border-gray-300
            focus:border-blue-500 focus:ring-blue-500 
            dark:border-gray-600 dark:bg-gray-700 dark:text-white 
            dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
            rounded-lg p-2 text-sm
            read-only:bg-gray-200 outline-none
          "
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="gallery-name"
          required
        />
      </form>
    </Modal>
  )
}
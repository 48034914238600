import { OrderFormattedType } from "../../types"

export const OrderHeader = ({order, light = false}:{
  order: OrderFormattedType,
  light?: boolean
}) => (
  <h5 className={`
    text-2xl font-bold tracking-tight 
    ${light ? 'text-gray-50':'text-gray-900'} dark:text-white
    flex gap-2
  `}>
    <span className={light ? "text-gray-50":"text-gray-400"}>#</span>
    <span className="flex flex-col">
      <span>{order.poId}</span>
      <span className={`${light ? 'text-gray-200':'text-gray-500'} text-xs font-normal`}>
        {order.date}
        {order.date !== order.update && (
          <>&nbsp;&nbsp; - &nbsp; {order.update}</>
        )}
      </span>
    </span>
  </h5>
)
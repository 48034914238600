export const Maintenance = () => (
  <div className="bg-gradient-primary h-screen w-screen">
    <div className="flex items-center justify-center m-auto h-full">
      <div className="text-center">
        <h1 className="text-4xl font-medium text-gray-50">Em Manutenção</h1>
        <div className="grid grid-cols-2 mt-4 p-2 border border-gray-400 rounded-lg text-gray-300 text-xs uppercase">
          <span className="text-start">Inicando em:</span>
          <span className="text-end">10/02</span>
          <span className="text-start">Previsão de término:</span>
          <span className="text-end">12/02</span>
        </div>
      </div>
    </div>
  </div>
)
import { ResultAndResponse } from "../shared-types";
import { UserCategory } from "../types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api"

let cacheCategories : Record<string,UserCategory[]>  = {}
export const getUserCategories = async (token: string) : Promise<UserCategory[]> => {
  try{
    if(cacheCategories[token]){
      console.log('[cached-user-categories]')
      return cacheCategories[token]
    }
  }catch(e){}

  try{
    const { data } = await api.get('/user-categories', headerBearer(token));
    console.log('[requested-user-categories]')
    
    if(data) cacheCategories[token] = data;
    
    return data;
  }catch(e){
    const error = handleErrorResultAndResponse(e, { result: false, response: 'Não foi possível carregar as categorias desta empresa' })
    console.error(error);
    return []
  }
}
export const changeUserCategory = async (user_id: string, category_id: string, token: string) : Promise<{
  result: boolean,
  response: string,
  data?: UserCategory
}> => {
  try{
    const { data } = await api.post(`/user-category-user/${user_id}/${category_id}`,{},  headerBearer(token));
    return {
      result: true,
      response: 'Permissão alterada com sucesso',
      data
    };
  }catch(e: any){
    console.error(e);
    let errorMessage : string | undefined = undefined;
    if(e?.response?.data){
      switch(e.response.data){
        case "Cannot read properties of undefined (reading 'permitionSlugs')":
          errorMessage = 'Sem permissão para executar essa função';
          break;
        default: errorMessage = e.response.data; break;
      }
    }

    if(errorMessage) return {
      result: false,
      response: errorMessage,
    };

    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao alterar a permissão'
    });
  }
}

interface UserCategoryProps extends Omit<UserCategory, 'permitions' | 'id'>{
  permitionSlugs: string[]
}

export interface ResponseUserCategory extends ResultAndResponse {
  data?: UserCategory,
}

export const createUserCategory = async (fields: UserCategoryProps, token: string): Promise<ResponseUserCategory> => {
  try {

    const { data } = await api.post('/user-category', fields, headerBearer(token));

    if (!data.result) throw new Error(data.response);

    return data;
  } catch (e) {
    console.error('[error-on-create-category]', e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível cadastrar nova categoria',
    });
  }
};

export const updateUserCategory = async (id: string, fields: UserCategoryProps, token: string): Promise<ResponseUserCategory> => {
  try {

    const { data } = await api.put(`/user-category/${id}`, fields, headerBearer(token));

    if (!data.result) throw new Error(data.response);

    return data;
  } catch (e) {
    console.error('[error-on-update-category]', e);
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível atualizar a categoria',
    });
  }
};
export const deleteUserCategory = async (id: string, clientId: string, token: string): Promise<ResultAndResponse> => {
  try{
    const { data } = await api.delete(`/user-category/${id}/${clientId}`, headerBearer(token))
    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro inesperado ao lidar com a exclusão da categoria de permissão'
    })
  }
}
import { BusinessUnitType, CompanyType } from "../../../types/conciliation.type"

interface MACStepZeroProps{
  company: CompanyType,
  onEdit: (unit: BusinessUnitType) => void
}
export const MACStepZero = ({ company, onEdit } : MACStepZeroProps) => {
  return (
    <div className="mt-4">
      <strong className="font-semibold uppercase bg-gray-100 p-0.5 text-center flex items-center justify-center rounded-sm pb-0 text-gray-700 mb-4">
      {company.nome_fantasia}: Gerenciar Unidades
      </strong>

      {(!company.businessUnits || company.businessUnits.length === 0) ? (
        <div className="text-gray-600 p-6 mt-2 text-[13px] text-center flex items-center justify-center bg-gray-50 h-full flex-1">
          <span className="max-w-[12rem] block">Você não possui nenhuma unidade nesta empresas</span>
        </div>
      ):(
        <>
          <div className="overflow-y-auto flex-1">
            {company.businessUnits.map((unit) => (
              <div
                className="relative border-b last:border-b-0 border-gray-200/50 hover:bg-gray-100/20 hover:rounded-lg cursor-pointer px-2"
                key={unit.nome_fantasia}
              >
                <div className="flex items-center py-1.5">
                  <div className="flex-1 flex justify-between items-center">
                    <button type="button" className="flex flex-1 flex-col items-start hover:bg-gray-50/70 drop-shadow" onClick={() => {
                      onEdit(unit)
                    }}>
                      <span className="whitespace-nowrap text-gray-600">
                        {unit.name}{unit.erp_id ? ` - ${unit.erp_id}`:''}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
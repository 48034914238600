import { ResultAndResponse } from "../shared-types";
import { AvailableIcons } from "../shared-types/icon.type";
import { NotificationPreferenceType, NotificationToType, NotificationTypeType } from "../shared-types/notification.type";
import { headerBearer, handleErrorResultAndResponse, api } from "./api";

export interface CreateNotificationResquest{
  title: string;
  description: string;
  type: NotificationTypeType;
  icon?: AvailableIcons;
  redirect_to?: string;
  to: NotificationToType;
  flow_id?: string;
  
  // [ ] DEVE SER CAPAZ DE RECEBER UM ARRAY DE TEMPLATES
  template_id?: string;
  template_data?: string;

  priority?: number;
  is_hub?: boolean;
  ignore_client_id?: boolean;
  data?: string[],
  schedule?: string
}
export const createNotification = async (fields: CreateNotificationResquest, token: string) => {
  try{
    const { data } = await api.post('/notification', fields, headerBearer(token))
    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível criar as notificações'
    })
  }
}

export interface PreferenceRequest{
  auto_archive_type: string[],
  notify_by: NotificationPreferenceType['notify_by']
}
interface PreferenceResponse extends ResultAndResponse{ data?: NotificationPreferenceType }
export const createOrUpdateNotificationPreference = async (fields: PreferenceRequest, replicate: boolean, token: string) : Promise<PreferenceResponse> => {
  try{
    const { data } = await api.post<PreferenceResponse>(`/notification/preference?${replicate ? 'replicate=true':''}`, { data: fields }, headerBearer(token))
    return {
      ...data,
      data: data.data ? _parseNotificationPreference(data.data) : data.data
    };
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível salvar sua preferência de notificação'
    })
  }
}
interface PreferencesResponse extends ResultAndResponse{
  data?: NotificationPreferenceType[]
}
export const getNotificationPreference = async (token: string) : Promise<PreferencesResponse> => {
  try{
    const { data } = await api.get<PreferencesResponse>('/notification/preference', headerBearer(token))
    return {
      ...data,
      data: data.data ? data.data.map((preference) => _parseNotificationPreference(preference)) : data.data
    };
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível obter sua preferência de notificação'
    })
  }
}
const _parseNotificationPreference = (preference: NotificationPreferenceType) : NotificationPreferenceType => ({
  ...preference,
  auto_archive_type: typeof preference.auto_archive_type === 'string' ? (preference.auto_archive_type as string).split(',') : preference.auto_archive_type,
  notify_by: typeof preference.notify_by === 'string' ? (() => {
    try{ return JSON.parse(preference.notify_by as string) }catch(e){ return []}
  })() : preference.notify_by
})
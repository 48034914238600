import { Label, TextInput } from "flowbite-react";
import { Modal } from "../../shared-components/utils/Modal"
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { phoneMask } from "../../services/mask";
import { useNotify } from "../../contexts/NotifyContext";
import { updateUser } from "../../services/user";
import { CircularProgressbar } from "react-circular-progressbar";
import { headerBearer, handleErrorResultAndResponse, api } from "../../services/api";

interface UploadPictureType{
  file?: File,
  name?: string,
  preview?: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
}
interface ModalProfileProps{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const INITIAL_UPLOAD_STATE = { progress: 0, uploaded: false, error: false }
export const ModalProfile = ({ isOpen, setIsOpen } : ModalProfileProps) => {
  const { user, updateUser: localUpdateUser } = useAuth();
  const { toast } = useNotify();

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const [email, setEmail] = useState(user?.email ?? '');
  const [whatsapp, setWhatsapp] = useState(user?.whatsapp ?? '');
  const [picture, setPicture] = useState(user?.picture ?? '');
  const [name, setName] = useState(user?.name ?? '');
  const [upload, setUpload] = useState<UploadPictureType>(INITIAL_UPLOAD_STATE)
  useEffect(() => {
    if(!user) return;
    
    setEmail(user.email ?? '')
    setWhatsapp(user.whatsapp ?? '')
    setPicture(user.picture ?? '')
    setUpload(INITIAL_UPLOAD_STATE)
    setName(user.name ?? '')
  },[user])

  function handleProfilePicture(target: HTMLInputElement){
    if(!target.files || target.files.length === 0) return;

    const uploadPicture : UploadPictureType = {
      file: target.files[0],
      name: target.files[0].name,
      preview: URL.createObjectURL(target.files[0]),
      progress: 0,
      uploaded: false,
      error: false,
    };
    setUpload(uploadPicture)

    processUpload(uploadPicture)
  }
  async function processUpload(uploadedFile: UploadPictureType){
    if(!uploadedFile.file) return;
    if(!user) return;
    
    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('external_id', user.id);
    data.append('name', 'Outros');
    data.append('type', 'personal');
    data.append('description', `Atualização da foto de perfil`);
    
    try{
      const { data: response } = await api.post("/gallery", data, {
        ...headerBearer(user.token),
        onUploadProgress: e => {
          const progress = e.total ? Math.round(
            (e.loaded * 100) / e.total
          ) : 0;
  
          setUpload((prevState) => ({
            ...prevState,
            progress
          }));
        }
      });
    
      if(!response.result) throw new Error(
        response.response
      );
  
      const url = `${process.env.REACT_APP_BASE_URL}${response.data.src}`;
      setUpload((prevState) => ({
        ...prevState,
        uploaded: true,
        id: response.data.id,
        url,
      }));

      setPicture(url);
    }catch(err){
      const error = handleErrorResultAndResponse(err, {
        result: false,
        response: `Não foi possível fazer o upload do arquivo ${uploadedFile.name}`
      });

      toast.error(error.response);

      setUpload((prevState) => ({
        ...prevState,
        error: true
      }));
    }
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return;
    
    if(!name || name.length < 3){
      toast.error('É obrigatório preencher o nome com pelo menos 3 caracteres')
      return;
    }

    const defaultPicture = "https://ivrim.tech/api/uploads/galleries/default-user.png"

    let data : { name: string, picture: string, whatsapp?: string } = { name, picture: picture ?? defaultPicture };

    if(whatsapp){
      let parsedWhatsapp = whatsapp.match(/\d+/g)?.join('') || '';
      if(parsedWhatsapp.length !== 11){
        toast.error('Número de whatsapp inválido. Ele deve conter 11 digitos(ignorando pontuação)');
        return;
      }

      parsedWhatsapp = phoneMask(parsedWhatsapp);
      data.whatsapp = parsedWhatsapp;
    }

    const res = await updateUser(data, user.token);

    if(!res.result){
      toast.error(res.response)
      return
    }

    toast.success("Dados atualizados com sucesso");

    localUpdateUser(data);

    setIsOpen(false);
  }
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{ 
        title: '',
        actionButton: {
          onClick: () => {
            if(buttonSubmitRef.current) buttonSubmitRef.current.click()
          },
          theme: 'primary',
          autoClose: false,
          text: 'Salvar'
        }
      }}
    >
      <form onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={buttonSubmitRef}/>

        <div className="flex items-center pb-4 gap-4">
          <label className="relative">
            <CircularProgressbar
              styles={{
                root: { width: 58 },
                path: { stroke: upload.uploaded ? "rgba(5,150,105,.8)" : upload.error ? "rgb(249,108,108)" : "#225397" },
                trail:{ stroke: "#ccc" }
              }}
              strokeWidth={10}
              value={upload.progress}
            />
            {(upload.preview || picture) ? (
              <img
                className="h-[50px] w-[50px] rounded-full shadow object-cover absolute top-[4px] left-[4px]"
                src={upload.preview && !upload.error && !upload.uploaded ? upload.preview : picture}
                alt={"Foto de perfil"}
              />
            ):(<div className="h-[50px] w-[50px] rounded-full shadow absolute top-[4px] left-[4px]"/>)}
            <input
              type="file"
              accept="*/image"
              className="hidden"
              onChange={(e) => handleProfilePicture(e.target)}
            />
          </label>
          <div>
            <h5 className="block mb-1 text-xl leading-none font-semibold">
              Editar Informações
            </h5>
            <span className="block text-xs leading-snug text-gray-400 w-72">
              Clique na imagem para poder atualizar a foto de perfil, depois clique em salvar
            </span>
          </div>
        </div>

        <div className="mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-name"
              value="Nome"
              className="text-gray-700"
            />
          </div>
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="user-name"
            type="text"
            placeholder="Digite o seu nome"
            required
          />
        </div>
        <div className="mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-email"
              value="Email"
              className="text-gray-700"
            />
          </div>
          <TextInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="user-email"
            type="email"
            placeholder="Digite o email"
            required
          />
        </div>
        <div className="mb-3">
          <div className="mb-1 block">
            <Label
              htmlFor="user-whatsapp"
              value="Whatsapp"
              className="text-gray-700"
            />
          </div>
          <TextInput
            value={phoneMask(whatsapp)}
            onChange={(e) => setWhatsapp(e.target.value)}
            id="user-whatsapp"
            type="text"
            placeholder="Digite o número de whatsapp"
          />
        </div>
      </form>
    </Modal>
  )
}
import { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { createPOS } from "../../services/pos";
import { Modal } from "../../shared-components/utils/Modal";
import { CompanyType, POSType } from "../../types/conciliation.type";

interface ModalAddPOSProps{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  companies: CompanyType[],
  company: CompanyType,
  setPOS: React.Dispatch<React.SetStateAction<POSType[]>>
}
export const ModalAddPOS = ({ isOpen, setIsOpen, companies, company, setPOS }: ModalAddPOSProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const btnSubmitForm = useRef<HTMLButtonElement>(null);

  const [unit, setUnit] = useState("");
  const [date, setDate] = useState("");
  const [id, setId] = useState("");

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return;

    try {
      const newPOS = await createPOS({
        company: company.nome_fantasia,
        date,
        id,
        unit,
      }, user.token);

      setPOS((prevState) => [...prevState, newPOS]);

      toast.success("Adicionado com sucesso");
    } catch (error) {
      toast.error("Não foi possível adicionar");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{
        title: "Adicionar POS",
        size: "sm:w-full sm:max-w-[500px]",
        cancelButton: true,
        cancelButtonText: "Cancelar",
        actionButton: {
          theme: "primary",
          text: "Finalizar",
          onClick: () => {
            if(btnSubmitForm.current) btnSubmitForm.current.click()
          },
          autoClose: false,
        },
      }}
    >
      <form className="my-4" onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={btnSubmitForm}/>
        <div className="flex">
          <select
            id="units"
            onChange={(event) => setUnit(event.target.value)}
            className="
              bg-gray-50 border max-w-xs  w-[65%] border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mr-5
            "
          >
            <option value={""}>Selecione a unidade</option>
            {companies.find((item) => item.nome_fantasia === company.nome_fantasia)?.businessUnits?.map(
              (unit, index) => (
                <option key={index} value={unit.name}>
                  {unit.name}
                </option>
              )
            )}
          </select>
          <div className="relative max-w-[100px] sm:max-w-[150px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </div>
          </div>
          <input
            datepicker-autohide
            type="date"
            className="
              bg-gray-50 border w-[40%] border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
            "
            placeholder="Select date"
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />
        </div>

        <div className="flex mt-5 ">
          <div className="flex w-full">
            <input
              className="
                block w-full text-gray-700
                border bg-gray-50 border-gray-300
                focus:border-blue-500 focus:ring-blue-500 
                dark:border-gray-600 dark:bg-gray-700 dark:text-white 
                dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
                rounded-lg p-2 text-sm
                read-only:bg-gray-200 outline-none
              "
              placeholder="Digite o número do POS"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}
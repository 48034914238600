import { useEffect, useRef, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client, DashboardType, ManagementLinkType } from "../../types";

import { useNotify } from "../../contexts/NotifyContext";
import { PlusIcon, SearchIcon, MoreVerticalIcon, ArrowRightIcon, getIconByName, ChevronDownIcon } from "../../shared-components/utils/icons";
import moment from "moment";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { ModalAddDashboard } from "../../components/Dashboard/ModalAddDashboard";
import { deleteDashboard, getDashboards } from "../../services/dashboard";
import { useNavigate } from "react-router-dom";
import { AvailableIcons } from "../../shared-types/icon.type";
import { hubRoutes } from "../../shared-types/utils/routes";

type AvailableFilterStatus = 'Ativo' | 'Inativo';
export const AdminManagement = () => {
  const divSearchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const [client, setClient] = useState<Client>();

  const [filter, setFilter] = useState<{ search?: string, status?: AvailableFilterStatus }>({ });
  const [showingDashboards, setShowingDashboards] = useState<string[]>([]);
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<DashboardType>();
  const [modelAddDashboardIsOpen, setModelAddDashboardIsOpen] = useState(false);

  useEffect(() => {
    if (!user) return;
    loadClient();
    loadUsers();
    loadDashboards();
  }, [user]);
  useEffect(() => {
    setShowingDashboards(filter.search || filter.status ? (
      dashboards.filter((d) => (
        (filter.search ? d.title.toLocaleLowerCase().includes(filter.search!.toLocaleLowerCase()) : true) &&
        (filter.status ? ((filter.status === 'Ativo' && d.active) || (filter.status === 'Inativo' && !d.active)) : true)
      )).map(d => d.id)
    ) : dashboards.map(d => d.id))
  }, [dashboards, filter])

  async function loadClient() {
    if (!user || !user.current_client) return;

    let findedClient = user.clients?.find(c => c.id === user.current_client);
    if (findedClient) setClient(findedClient);
    else toast.error('Empresa não encontrada');
  }
  async function loadUsers() {
    if (!user) return;

    // const res = await getManageLinks(user.token);

    // if(!res.result){
    //   toast.error(res.response);
    //   return;
    // }
    // if(res.data?.users) setUsers(res.data.users as any);
  }
  async function loadDashboards(){
    if(!user) return;
    
    const res = await getDashboards(user.token);
    if(!res.result){
      toast.error(res.response)
      return;
    }
    
    if(!res.data) return;

    setDashboards(res.data.filter((dash) => dash.slug !== 'flow-dashboard'))
    setShowingDashboards(res.data.map(d => d.id))
  }
  function handleEdit(dashboard: DashboardType){
    setCurrentDashboard(dashboard)
    setModelAddDashboardIsOpen(true)
  }
  function handleDelete(id: string){
    const onClick = async () => {
      if(!user) return;

      const res = await deleteDashboard(id, user.token)
      if(!res.result){
        toast.error(res.response);
        return;
      }

      toast.success(res.response);
      setDashboards(prevState => prevState.filter(s => s.id !== id));
    }

    showMessage('Tem certeza que deseja excluir esta dashboard?', {
      title: 'Confirmar Exclusão',
      actionButton: {
        theme: 'danger',
        text: 'Sim',
        onClick
      },
      cancelButtonText: 'Não'
    })
  }
  return (
    <Wrapper
      asideActive={['Admin Dados', 'Dashboards']}
      breadcrumbs={[
        { name: 'Admin Dados', href: '#', subtitle: 'Dashboards' }
      ]}
      module_name="Admin Panel"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl" /> : (
        <div>
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-500">{showingDashboards.length} de {dashboards.length}</div>
            <div className="flex gap-2 mb-2 mt-auto">
              <button
                type="button"
                className={`
                text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
                hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                rounded-lg text-sm  
                px-3 pt-1.5 pb-1.5 text-center w-full
                `}
                onClick={() => navigate(hubRoutes.dashboard.home())}
              >
                <ChevronDownIcon w={20} h={20} className="rotate-90"/>
                Ver Dashboards
              </button>
              <button
                type="button"
                className={`
                text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
                hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
                rounded-lg text-sm whitespace-nowrap
                px-3 pt-1.5 pb-1.5 text-center w-full
                `}
                onClick={() => setModelAddDashboardIsOpen(true)}
              ><PlusIcon w={20}/> Nova Dashboard</button>
            </div>
          </div>
          <div className="bg-gradient-light min-h-[4rem] backdrop-blur-[25px] rounded-lg flex justify-between items-center gap-2 mt-4 px-4 text-primary-700">
            <div className="max-sm:hidden">
              <Dropdown
                classNames={{ list: `
                  absolute left-0 z-10
                  -mt-1 w-56 origin-top-right rounded-md
                  bg-gray-100/70 backdrop-blur-[25px] shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none pb-2
                ` }}
                trigger={
                  <div className="
                    my-3 w-40 h-10 border border-gray-50/10
                    flex items-center justify-between p-4 rounded-md bg-gray-100/5 
                    text-sm backdrop-blur-[25px] font-semibold
                  ">
                    {!filter.status ? 'Todos' : filter.status}
                    <ArrowRightIcon className="rotate-90 group-data-[headlessui-state=open]:-rotate-90"/>
                  </div>
                }
              >
                {['Todos', 'Ativo', 'Inativo'].map((status) => (
                  <button
                    className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/30"
                    onClick={() => setFilter((prevState) => ({
                      ...prevState,
                      status: status === 'Todos' ? undefined : status as AvailableFilterStatus
                    }))}
                    key={status}
                  >{status}</button>
                ))}
              </Dropdown>
            </div>

            <div className="group relative" ref={divSearchRef}>
              <input
                type="text"
                id="simple-search"
                className="
                  hidden group-[.show]:block
                bg-gray-100/10 backdrop-blur-[25px]
                  
                  border-primary-500/20
                  text-gray-600 text-sm rounded-lg 
                  placeholder-primary-700

                  focus:ring-gray-100/20 focus:border-gray-100/20 w-full pr-10 p-2.5
                "
                value={filter.search}
                onChange={(e) => setFilter((prevState) => ({
                  ...prevState,
                  search: e.target.value
                }))}
                placeholder="Pesquisar..."
              />
              <button
                type="button"
                className="absolute inset-y-0 right-2 flex items-center"
                onClick={() => {
                  if(!divSearchRef.current) return;
                  divSearchRef.current.classList.toggle('show');

                  let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
                  if(divSearchRef.current.classList.contains('show')) input.focus();
                  else{
                    setFilter(prevState => ({ ...prevState, search: undefined }))
                    input.value = '';
                  }
                }}
              ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400"/></button>
            </div>
          </div>
          <div className="mt-6">
            {showingDashboards.length === 0 ? (
              <p className="text-sm text-center text-gray-700/80 w-full py-6">
                {!filter.search ? (
                  "Nenhuma dashboard criada"
                ):(
                  "Nenhuma dashboard encontrada com este filtro"
                )}
              </p>
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {dashboards.filter(d => showingDashboards.includes(d.id)).map((dashboard) => (
                  <div key={dashboard.id} className="
                    bg-gradient-light rounded-lg px-4 py-3
                    text-gray-700
                    flex flex-col
                  ">
                    <header className="flex gap-2 items-center justify-between text-xs">
                      <span className="flex items-center gap-1 text-primary-700 font-semibold">
                        {dashboard.active ? (
                          <>
                            <span className={`block rounded-full w-2 h-2 shadow-xl bg-green-500 shadow-green-600`}/>
                            ativo
                          </>
                        ):(
                          <>
                            <span className={`block rounded-full w-2 h-2 shadow-xl bg-red-500 shadow-red-600`}/>
                            inativo
                          </>
                        )}
                      </span>
                      <div>
                        <Dropdown
                          classNames={{ list: `
                            absolute right-0 z-10
                            -mt-1 w-56 origin-top-right rounded-md
                            bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                            ring-1 ring-black ring-opacity-5
                            focus:outline-none pb-2
                          ` }}
                          trigger={
                            <div
                              className="flex items-center justify-between rounded-md  text-sm backdrop-blur-[25px]"
                            >
                              <MoreVerticalIcon className="-rotate-90"/>
                            </div>
                          }
                        >
                          <button
                            type="button"
                            className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                            onClick={() => handleEdit(dashboard)}
                          >Editar</button>
                          <button
                            className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                            onClick={() => handleDelete(dashboard.id)}
                          >Excluir</button>
                        </Dropdown>
                      </div>
                    </header>
                    <div
                      className="pt-0.5 pb-1 flex-1 flex flex-col gap-0.5 cursor-pointer text-primary-400"
                      onClick={() => handleEdit(dashboard)}
                    >
                      <strong className="truncate hover:text-clip block hover:whitespace-normal text-primary-800">{dashboard.title}</strong>
                      <p className="text-sm mb-2">
                        {dashboard.description}
                      </p>
                      <div className="flex gap-4 justify-between items-center">
                        <span className="text-xs block mt-auto">{dashboard.updated_at ? moment(dashboard.updated_at).format('DD/MM/YYYY'):''}</span>
                        <span className="scale-50">{dashboard.icon && getIconByName(dashboard.icon as AvailableIcons)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      <ModalAddDashboard
        isOpen={modelAddDashboardIsOpen}
        setIsOpen={() => {
          setModelAddDashboardIsOpen(false)
          setCurrentDashboard(undefined)
        }}
        setDashboards={setDashboards}
        dashboard={currentDashboard} 
      />
    </Wrapper>
  );
}
import { useParams } from "react-router-dom"
import { IframePPT } from "../../components/CoPilotDashboard/IframePPT"
import { Wrapper, WrapperProps } from "../../shared-components/Wrapper"
import { useEffect, useState } from "react";
import { DashboardType } from "../../types";
import { getDashboardBySlug, getDashboards } from "../../services/dashboard";
import { useNotify } from "../../contexts/NotifyContext";
import { useAuth } from "../../contexts/AuthContext";
import { getIconByName } from "../../shared-components/utils/icons";
import { shortclass } from "../../styles/styles";
import { AvailableIcons } from "../../shared-types/icon.type";
import { handleRegexUrl, hubRoutes } from "../../shared-types/utils/routes";

let toastNotifiedError = false
export const ShowDashboard = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(true);  
  const [dashboard, setDashboard] = useState<DashboardType>();

  useEffect(() => {
    let body = document.body;

    if(body && window.innerWidth > 640 && !body.classList.contains('collapsed-desktop-aside')){
      body.classList.add('collapsed-desktop-aside');
      body.classList.remove('expanded-aside');

      return () => { body.classList.remove('collapsed-desktop-aside') }
    }
  },[])
  useEffect(() => { loadDashboard() }, [user, slug]);

  async function loadDashboard(){
    if(!user || !slug) return;
    
    setIsLoading(true)
    const res = await getDashboardBySlug(user.token, slug);
    setIsLoading(false);
    if(!res.result){
      if(!toastNotifiedError){
        toast.error(res.response);

        toastNotifiedError = true;
        setTimeout(() => toastNotifiedError = false, 1000)
      }
      
      setDashboard(undefined);
      return;
    }
    if(!res.data){
      setDashboard(undefined);
      return;
    }

    setDashboard(res.data);
  }
  
  const wrapperProps : WrapperProps = dashboard && dashboard.menu && dashboard.menu.items.length > 0 ? {
    module_name: dashboard.title,
    asideActive: dashboard.menu.active ?? dashboard.title,
    asideItems: dashboard.menu.items.map((item) => ({
      id: item.id,
      name: item.name,
      href: item.href ? handleRegexUrl(item.href, user?.token) : undefined,
      icon: item.icon ? getIconByName(item.icon) : undefined,
      // [ ]  disabled -- ADICIONAR REGRA PARA A PROPRIEDADE DISABLE
      // [ ] implementar recursividade para lidar com subitems
    }))
  } : {
    module_name: "Co-Pilot Dashboard",
    asideActive: ['Dashboard', dashboard?.title ?? '']
  }
  return (
    <Wrapper
      {...wrapperProps}
      breadcrumbs={[
        { name: 'Dashboards', href: hubRoutes.dashboard.home() },
        ...(dashboard ? [{
          name: dashboard.title,
          href: hubRoutes.dashboard.show(dashboard.slug),
        }]: [])
      ]}
    >
      {(!isLoading && !dashboard) ? (
        <p className="h-[calc(100%-8rem)] bg-gray-200 rounded-lg shadow w-full flex items-center justify-center text-center text-gray-500">
          {slug ? 'Dashboard não encontrada' : 'Você não possui acesso a essa Dashboard'}
        </p>
      ):(
        <>
          {dashboard && dashboard.actions && dashboard.actions.length > 0 && (
            <div className="flex items-center justify-end gap-4">
              {dashboard.actions.map(act => act.href ? (
                <a
                  href={act.href}
                  rel="noreferrer"
                  target="_blank"
                  className={`${shortclass.button.primary} py-1.5 flex items-center justify-center text-center gap-2 text-sm`}
                >{act.icon ? getIconByName(act.icon as AvailableIcons) : <></>}{act.name}</a>
              ):(
                <button
                  type="button"
                  className={shortclass.button.primary}
                  onClick={() => toast.warning('Em desenvolvimento')}
                >{act.name}</button>
              ))}
            </div>
          )}
          <IframePPT url={dashboard?.link}/>
        </>
      )}
    </Wrapper>
  )

}
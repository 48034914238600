import { Spinner, Table } from "flowbite-react";
import { OrderFormattedType } from "../../types";

export type WarningTypeOnDetailsType = 'warning' | 'expired' | 'now';
interface WarningTableType{
  orders: OrderFormattedType[] | undefined,
  textEmpty: string
  onDetails: (order: OrderFormattedType, type: WarningTypeOnDetailsType) => void,
  type: WarningTypeOnDetailsType
}
// #endregion DECLARE TYPES

export const WarningTable = ({ orders, textEmpty, onDetails, type } : WarningTableType) => (
  <Table>
    <Table.Head className="text-primary-800 bg-primary-500/5">
      <Table.HeadCell className="!text-xs">Nª da PO</Table.HeadCell>
      <Table.HeadCell className="!text-xs">Descrição</Table.HeadCell>
      <Table.HeadCell className="!text-xs">Status</Table.HeadCell>
      <Table.HeadCell className="!text-xs">Criação</Table.HeadCell>
      <Table.HeadCell className="!text-xs">Atualização</Table.HeadCell>
      {['expired','warning'].includes(type) && (
        <Table.HeadCell className="!text-xs">
          {type === 'warning' ? 'Vence Em' : 'Vencido à'}
        </Table.HeadCell>
      )}
    </Table.Head>
    <Table.Body className="divide-y">
      {orders ? ( orders.length === 0 ? (
        <Table.Row className="bg-gray-100/10">
          <Table.Cell colSpan={['expired','warning'].includes(type) ? 6 : 5} className="text-center py-8 text-gray-400/75">
            {textEmpty}
          </Table.Cell>
        </Table.Row>
      ):(
        orders.map((order) => (
          <Table.Row 
            className="bg-gray-100/10 hover:bg-gray-100/20 text-gray-500 cursor-pointer"
            onClick={() => onDetails(order, type)}
            key={order.id}
          >
            <Table.Cell>
              <span className="whitespace-nowrap font-medium">
                {order.poId}
              </span>
              <span className="block uppercase text-[10px] leading-none text-gray-400">
                {order.user}
              </span>
            </Table.Cell>
            <Table.Cell>{order.description ?? <span className="text-xs">-- sem descrição --</span>}</Table.Cell>
            <Table.Cell>{order.status}</Table.Cell>
            <Table.Cell>{order.date}</Table.Cell>
            <Table.Cell>{order.update}</Table.Cell>
            {['expired','warning'].includes(type) && order.remainingDays && (
              <Table.Cell>
                {Math.abs(order.remainingDays) == 1 ? '1 dia': `${Math.abs(order.remainingDays)} dias`}
              </Table.Cell>
            )}
          </Table.Row>
        ))
      )):(
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell colSpan={['expired','warning'].includes(type) ? 7 : 6} className="text-center py-8">
            <Spinner aria-label="Carregando dados"/>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);
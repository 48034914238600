import { Fragment, ReactNode, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckedIcon, ChevronUpDownIcon } from '../SvgIcons';

interface OptionType{
  id: string | number | null,
  html: ReactNode
}
interface SelectTypes{
  label?: string,
  options: OptionType[],
  selectedId?: string | number,
  selectedRaw?: ReactNode,
  emptyRaw?: ReactNode,
  classNames?: {
    wrapper?: string,
    button?: string,
    list?: string
  },
  styles?:{
    wrapper?: React.CSSProperties,
    button?: React.CSSProperties,
    list?: React.CSSProperties
  },
  onChange?: (param: OptionType | undefined) => void
}
export default function Select({
  label,
  options,
  selectedId,
  selectedRaw,
  emptyRaw,
  classNames,
  styles,
  onChange
}: SelectTypes) {
  const [selected, setSelected] = useState<OptionType | undefined>(() => {
    if(selectedId){
      let option = options.find(op => op.id === selectedId)
      if(option) return option;
    }

    if(selectedRaw) return {
      id: null,
      html: selectedRaw
    };
    
    return undefined;
  });

  useEffect(() => {
    if(onChange) onChange(selected);
  },[selected]);
  
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              { label }
            </Listbox.Label>
          )}
          <div
            className={classNames?.wrapper ?? "relative mt-1"}
            style={styles?.wrapper ?? {}}
          >
            <Listbox.Button className={
              classNames?.button ?? `
                py-2 pl-3 pr-10
                relative w-full cursor-default
                rounded-md border border-gray-300 bg-white 
                text-left shadow-sm sm:text-sm
                focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500
              `
            } style={ styles?.button ?? {}}>
              {selected ? selected.html : <span></span>}
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400"/>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={
                classNames?.list ?? `
                  absolute z-10
                  mt-1 max-h-56 w-full
                  overflow-auto rounded-md bg-white py-1
                  text-base shadow-lg ring-1 ring-black ring-opacity-5
                  focus:outline-none sm:text-sm
                `
              } style={styles?.list ?? {}}>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>`
                      relative cursor-default select-none py-2 pl-3 pr-9
                      ${active ? 'text-white bg-primary-600' : 'text-gray-900'}
                    `}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className={selected ? 'font-semibold' : 'font-normal'}>
                          {option.html}
                        </div>

                        {selected ? (
                          <span
                            className={`
                              absolute inset-y-0 right-0 flex items-center pr-4
                              ${active ? 'text-white' : 'text-primary-600'}
                            `}
                          ><CheckedIcon className="h-5 w-5"/></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {options.length === 0 && (
                  emptyRaw
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
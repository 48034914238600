import { cachedUser } from "../shared-components/services/authenticate";
import { Permition, PossiblePermissions, User } from "../shared-types";
import { ResultAndResponse } from "../types";
import { api, handleErrorResultAndResponse, headerBearer } from "./api";

export interface AuthenticateType{
  email: string,
  password: string,
  remember_me?: boolean
}
export const authenticate = async (fields: AuthenticateType) : Promise<User> => {
  try {
    const { data } = await api.post<User>('/auth', fields, { headers:{'Content-Type': 'application/json'}});

    let permitions = handleFormatPermitionsSlug(data.permitions) as PossiblePermissions[];
    data.permitions_slug = permitions;
    
    cachedUser.set(data);

    return data;
  } catch (e:any) {
    const error = handleErrorResultAndResponse(e,{
      result: false,
      response: 'Houve um erro inesperado'
    })
    
    throw new Error(error.response);
  }
}
export const changePassword = async (fields : { currentPassword: string, newPassword: string }, token: string) : Promise<ResultAndResponse> => {
  try{
    const { data } = await api.put('/auth/change-password', fields, headerBearer(token));

    if(!data.result) throw new Error(
      data.response
    )

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao tentar atualizar senha'
    })
  }
}
export interface ChangeCurrentClientResponseType extends ResultAndResponse{
  data?: {
    token: string,
    permitions: Permition[],
    permitions_slug: PossiblePermissions[]
  }
}
export async function changeCurrentClient(client_id: string, token: string) : Promise<ChangeCurrentClientResponseType>{
  try{
    const { data } = await api.get(`/auth/token/${client_id}`, headerBearer(token));

    let permitions_slug = handleFormatPermitionsSlug(data.permitions);

    return {
      result: true,
      response: 'Empresa alterada com sucesso',
      data: {
        ...data,
        permitions_slug
      }
    };
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Houve um erro ao alterar a empresa'
    });
  }
}

function handleFormatPermitionsSlug(permitions: undefined | any | any[]) : string[]{
  if(permitions &&
    Array.isArray(permitions) && 
    permitions.length > 0 && 
    typeof permitions[0] === 'string'
  ) return permitions;

  return [];
}
interface ForgotPasswordResponse extends ResultAndResponse{
  data?: {
    type: 'recovery-email-already-sent' | 'recovery-sended',
    resubmit_on?: number
  }
}
interface ForgotPassword {
  email: string;
  is_resend?: boolean; 
}
export const forgotPassword = async (fields: ForgotPassword) : Promise<ForgotPasswordResponse> => {
  try {
    const { data } = await api.post('/auth/forgot-password', fields);

    return data;
  } catch (error) {
    return handleErrorResultAndResponse(error, {
      result: false,
      response: 'Erro ao enviar email',
    });
  }
};
interface CheckToken {
  email: string;
  code: string; 
}
export const checkToken = async (fields: CheckToken) : Promise<ResultAndResponse> => {
  try {
    const { data } = await api.post('/auth/check-token', fields)
    return data;
  } catch (error) {
    return handleErrorResultAndResponse(error, {
      result: false,
      response: 'Erro ao enviar token',
    })
  }
}
export const redefinePassword = async (fields: { email: string, code: string, password: string }) : Promise<ResultAndResponse> => {
  try{
    const { data } = await api.post('/auth/redefine-password', fields)

    return data;
  }catch(e){
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível redefinir a senha'
    })
  }
}
import { useEffect, useState } from "react";
import { PaymentDocType, OrderFormattedType } from "../../types";
import { DownloadAltIcon, DownloadIcon } from "../SvgIcons";

interface DocumentsType{
  contrato: PaymentDocType[],
  nf: PaymentDocType[],
  boleto: PaymentDocType[],
  suporte: PaymentDocType[],
  pagamento: PaymentDocType[]
}
export const OrderSummary = ({ order } : {
  order: OrderFormattedType
}) => {
  const [documents, setDocuments] = useState<DocumentsType>();

  useEffect(() => {
    if(order.documents){
      setDocuments({
        contrato: order.documents.filter(doc => doc.type === 'contrato'),
        nf: order.documents.filter(doc => doc.type === 'nf'),
        boleto: order.documents.filter(doc => doc.type === 'boleto'),
        suporte: order.documents.filter(doc => doc.type === 'suporte'),
        pagamento: order.documents.filter(doc => doc.type === 'pagamento'),
      })
    }else setDocuments(undefined);
  },[order]);
  
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <tbody>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Solicitante:
          </th>
          <td className="px-2 py-4 text-right">
            { order.user }
          </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Centro de Custo:
          </th>
          <td className="px-2 py-4 text-right">
            { order.costCenter }
          </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Descrição:
          </th>
          <td className="px-2 py-4 text-right">
            { order.description ?? '-- sem descrição --' }
          </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Tipo de PO:
          </th>
          <td className="px-2 py-4 text-right">
            { order.type }
          </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Etapa:
          </th>
          <td className={`px-2 py-4 text-right ${order.stage === 'Encerrado' ? 'font-medium text-gray-800':''}`}>
            { order.stage }
          </td>
        </tr>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            Status:
          </th>
          <td className={`
            px-2 py-4 text-right
            ${order.status ===  'Reprovado' ? 'text-red-700 font-medium' : ''}
          `}>
            { order.status }
          </td>
        </tr>
        {order.src && (
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              Formulário:
            </th>
            <td className="p-2 text-right">
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BASE_URL}${order.src}`}
                className={`
                  text-gray-600 outline-none
                  px-4 py-2.5 border rounded-lg inline-block
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-gray-200 hover:bg-gray-100 hover:border-2
                `}
              ><DownloadAltIcon/></a>
            </td>
          </tr>
        )}
        {order.deposito && (
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td className="px-2 py-4" colSpan={2}>
              <p className="font-medium text-gray-900 whitespace-nowrap dark:text-white mb-2">Dados para Pagamento</p>
              <div className="grid xs:grid-cols-2 gap-2 grid-flow-row">
                <div className="flex flex-col">
                  <span className="font-semibold text-xs">CPF/CNPJ</span>
                  {order.deposito.cnpj}  
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xs">Banco</span>
                  {order.deposito.banco}
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xs">Agência</span>
                  {order.deposito.agencia}
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-xs">Conta Corrente</span>
                  {order.deposito.contacorrente}
                </div>
              </div>
            </td>
          </tr>
        )}
        {documents && (
          <>
            {documents.contrato && documents.contrato.length > 0 && (
              <BoxDocument
                docs={documents.contrato}
                title="Contratos"
              />
            )}
            {documents.nf && documents.nf.length > 0 && (
              <BoxDocument
                docs={documents.nf}
                title="Notas Fiscais"
              />
            )}
            {documents.boleto && documents.boleto.length > 0 && (
              <BoxDocument
                docs={documents.boleto}
                title="Boletos"
              />
            )}
            {documents.suporte && documents.suporte.length > 0 && (
              <BoxDocument
                docs={documents.suporte}
                title="Suporte"
              />
            )}
            {documents.pagamento && documents.pagamento.length > 0 && (
              <BoxDocument
                docs={documents.pagamento}
                title="Pagamento"
              />
            )}
          </>
        )}

      </tbody>
    </table>
  );
}

interface BoxDocumentProps{
  docs: PaymentDocType[],
  title: string
}
const BoxDocument = ({
  docs, title
}: BoxDocumentProps) => (
  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    <td className="px-2 py-4" colSpan={2}>
      <p className="font-medium text-gray-900 whitespace-nowrap dark:text-white mb-2">{title}</p>
      <div className="grid xs:grid-cols-2 gap-2 grid-flow-row">
        {docs.map((doc, i) => (
          <a
            key={`${doc.src}-${i}`}
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_BASE_URL}${doc.src}`}
            className={`
              ${docs.length === 1 ? 'col-span-2':''}
            text-gray-600 outline-none
              px-4 py-2.5 border rounded-lg 
              flex items-center justify-center text-center gap-1
              focus:outline-none focus:ring-2 focus:ring-offset-2
              focus:ring-gray-200 hover:bg-gray-100 hover:border-2
            `}
          >
            <DownloadAltIcon width="20" height="20"/>
            <span className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-24px)]">{ doc.name }</span> 
          </a>
        ))}
      </div>
    </td>
  </tr>
);
import { Label, Radio, TextInput } from "flowbite-react";
import { AvailableIcons } from "../../../shared-types/icon.type";
import { NotificationTypeType, availableNotificationTypeFormatted } from "../../../shared-types/notification.type";
import { useNotify } from "../../../contexts/NotifyContext";
import { CloseIcon, getIconByName, listAvailableIcons } from "../../../shared-components/utils/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { PossiblePermissions } from "../../../shared-types";
import { useEffect, useState } from "react";
import Datepicker, { DateType } from "react-tailwindcss-datepicker";
import Select from "../../utils/Select";

interface FSSettingProps{
  title: string,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  description: string,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  type: NotificationTypeType | undefined,
  setType: React.Dispatch<React.SetStateAction<NotificationTypeType | undefined>>,
  priority: number,
  setPriority: React.Dispatch<React.SetStateAction<number>>,
  isHub: boolean,
  setIsHub: React.Dispatch<React.SetStateAction<boolean>>,
  redirectTo: string | undefined,
  setRedirectTo: React.Dispatch<React.SetStateAction<string | undefined>>,
  icon: AvailableIcons | undefined,
  setIcon: React.Dispatch<React.SetStateAction<AvailableIcons | undefined>>,
  schedule: Date | undefined,
  setSchedule: React.Dispatch<React.SetStateAction<Date | undefined>>,
}
const INITIAL_SCHEDULE_TIME : [string, string] = ['hh','mm']
export const FSSetting = ({
  title,
  setTitle,
  description,
  setDescription,
  type,
  setType,
  priority,
  setPriority,
  isHub,
  setIsHub,
  redirectTo,
  setRedirectTo,
  icon,
  setIcon,
  schedule,
  setSchedule
}: FSSettingProps) => {
  const { toast } = useNotify();

  const [wantToSchedule, setWantToSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState<DateType | null>(null);
  const [scheduleTime, setScheduleTime] = useState<[string, string]>(INITIAL_SCHEDULE_TIME);

  useEffect(() => { setSchedule(undefined) },[])
  useEffect(() => {
    if(!scheduleDate || 
      !scheduleTime[0] ||
      scheduleTime[0] === 'hh' || 
      !scheduleTime[1] ||
      scheduleTime[1] === 'mm'
    ) return;

    const scheduleDateTime = new Date(`${scheduleDate}T${scheduleTime.join(':')}:00`)

    if(scheduleDateTime > new Date()) setSchedule(scheduleDateTime)
    else toast.warning(
    'Não é possível agendar uma notificação para o passado'
    )
  },[scheduleDate, scheduleTime])
  // [ ] RECEBER [schedule, setSchedule] POR PROPS
  // [ ] CRIAR UM USE EFFECT PARA LIDAR COM A DATA
  // [ ] SE POSSÍVEL SINALIZAR NO CAMPO QUE A DATA É VALIDA OU NÃO 
  const { showMessage, onCloseModal } = useNotify()
  const { user } = useAuth();

  function handleSelectIcon(){
    showMessage((
      <div className="p-4 grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-3 justify-center items-center gap-4 text-center">
        {listAvailableIcons.map(({component, title}, i) => (
          <button
            type="button"
            className="bg-gray-200 rounded-lg h-16 flex flex-col justify-center items-center gap-1"
            onClick={() => {
              setIcon(title)
              onCloseModal()
            }}
            key={i}
          >{component()}</button>
        ))}
      </div>
    ), {
      title: 'Selecione o Icone',
      size: 'sm:w-full sm:max-w-3xl',
      actionButton: {
        onClick: () => setIcon(undefined),
        theme: 'danger',
        text: 'Remover'
      }
    })
  }
  return (
    <div className="mb-4">
      <h3 className="mb-4 text-lg font-semibold">Preencha as informações da notificação:</h3>
      <div className="grid lg:grid-cols-2 gap-4">
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300" htmlFor={`notification-title`}>
            Título
          </label>
          <TextInput
            id={`notification-title`}
            type={'text'}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={"Digite o título da notificação"}
            required
          />
        </div>
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300" htmlFor={`notification-redirect-to`}>
            Agendar Notificação
          </label>
          {wantToSchedule ? (
            <div className="flex gap-2">
              <Datepicker
                inputId={`notification-schedule`}
                
                inputClassName={`
                  relative transition-all duration-300 p-2.5 pr-14
                  w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg text-sm
                `}
                value={{
                  startDate: scheduleDate,
                  endDate: scheduleDate
                }}
                asSingle
                useRange={false}
                minDate={new Date()}
                popoverDirection="down"
                onChange={(e) => setScheduleDate(e?.startDate ?? null)}
                displayFormat={"DD/MM/YYYY"}
              />
              <div className=" bg-white rounded-lg border border-gray-300 flex gap-3">
                <Select
                  selectedRaw={scheduleTime[0]}
                  classNames={{ 
                    wrapper: 'relative h-full',
                    button: `
                      py-2 pl-3 pr-10
                      relative w-full cursor-default
                      rounded-md bg-white h-full
                      text-left shadow-sm sm:text-sm
                      focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500
                    `
                  }}
                  options={Array.from({ length: 24 }).map((_,h) => ({
                    id: String(h).padStart(2,'0'), html: String(h).padStart(2,'0')
                  }))}
                  onChange={(op) => {
                    if(!op) setScheduleTime(prevState => ['hh', prevState[1]])
                    else setScheduleTime(prevState => [op!.id as string, prevState[1]])
                  }}
                />
                <span className="text-xl my-auto">:</span>
                <Select
                  selectedRaw={scheduleTime[1]}
                  classNames={{ 
                    wrapper: 'relative h-full',
                    button: `
                      py-2 pl-3 pr-10
                      relative w-full cursor-default
                      rounded-md bg-white h-full
                      text-left shadow-sm sm:text-sm
                      focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500
                    `
                  }}
                  options={['00','15','30','45'].map((m) => ({
                    id: m, html: m
                  }))}
                  onChange={(op) => {
                    if(!op) setScheduleTime(prevState => [prevState[0], 'mm'])
                    else setScheduleTime(prevState => [prevState[0], op!.id as string])
                  }}
                />
              </div>
              <button type="button" className="
                w-4 h-4 my-auto flex items-center justify-center
                rounded-lg border border-red-300 leading-none
                shadow-sm hover:bg-red-500 hover:text-white
                text-red-500 font-semibold
                focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
              " onClick={() => {
                setWantToSchedule(false)
                setScheduleDate(null)
                setScheduleTime(INITIAL_SCHEDULE_TIME)
              }}>
                <CloseIcon w={16} h={16}/>
              </button>
            </div>
          ):(
            <div>
              <button
                type="button"
                className="border border-gray-300 bg-gray-50 hover:brightness-95 text-xs text-gray-400 rounded-lg w-full p-1 flex justify-center items-center h-[2.5rem]"
                onClick={() => setWantToSchedule(true)}
              >Clique para agendar...</button>
            </div>
          )}

        </div>
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300" htmlFor={`notification-description`}>
            Descrição
          </label>
          <div className="flex">
            <div className="relative w-full">
              <textarea
                className="
                  block w-full border 
                  disabled:cursor-not-allowed disabled:opacity-50 
                  bg-gray-50 border-gray-300 text-gray-900 
                  focus:border-blue-500 focus:ring-blue-500 
                  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
                  dark:focus:border-blue-500 dark:focus:ring-blue-500
                  rounded-lg p-2.5 text-sm
                "
                id={`notification-description`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={"Digite a descrição da notificação"}
                required
              />
            </div>
          </div>
        </div>
        {/* MUDAR PARA SELECT */}
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300" htmlFor={`notification-redirect-to`}>
            Link de Redirecionamento (opcional)
          </label>
          <TextInput
            id={`notification-redirect-to`}
            type={'text'}
            value={redirectTo}
            onChange={(e) => setRedirectTo(e.target.value)}
            placeholder={"Digite o título da notificação"}
          />
        </div>
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300">
            Tipo de Notificação
          </label> 
          <div className="grid grid-cols-2 bg-gray-300/30 p-2 pb-1 rounded">
            {Object.entries(availableNotificationTypeFormatted).filter(([key]) => {
              return !(['license', 'update'].includes(key) && !(user?.permitions_slug ?? []).includes(
                PossiblePermissions.ADMIN_HUB
              ))
            }).map(([key, name]) => (
              <div className={`flex items-center gap-2 mb-1`} key={key}>
                <Radio
                  id={`radio-type-${key}`}
                  onChange={() => setType(key as NotificationTypeType)}
                  checked={key === type}
                />
                <Label htmlFor={`radio-type-${key}`} className="!text-sm !text-gray-700">
                  { name }
                </Label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className="block mb-1 mx-1 text-[10px] font-semibold uppercase text-gray-700 dark:text-gray-300" htmlFor="dashboard-link">
            Icone Personalizado (opcional)
          </label>
          <div>
            <button
              type="button"
              className="border border-gray-300 hover:brightness-95 bg-gray-50 text-gray-600 rounded-lg w-full p-1 flex justify-center items-center h-[2.5rem]"
              onClick={handleSelectIcon}
            >
              {icon ? (
                <div className="scale-75">
                  {getIconByName(icon as AvailableIcons)}
                </div>
              ) : (
                <span className="text-xs text-gray-400">Clique para selecionar...</span>
              )}
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-1">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              onChange={() => setIsHub(!isHub)}
              checked={isHub}
            />
            <div className={`
              w-9 h-5 bg-gray-300
              peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
              rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
              after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
              after:border after:rounded-full after:h-4 after:w-4 after:transition-all
              dark:border-gray-600 peer-checked:bg-blue-600
            `}></div>
            <span className="ml-3 text-xs  font-semibold uppercase text-gray-700 dark:text-gray-300">
              Notificação oficial
            </span>
          </label>
          <div className="text-xs text-gray-400 max-w-[20rem]">
            Quando a notificação é marcada como oficial ela irá com um selo para identificar que foi enviada pela plataforma.
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <label
            className="block text-[10px] pt-1 font-semibold uppercase text-gray-700 dark:text-gray-300"
            htmlFor={`notification-redirect-to`}
          >Prioridade:</label>
          <div className="relative w-full">
            <input
              className="w-full bg-gray-300 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 h-1.5 range-sm"
              type="range"
              min={1}
              max={5}
              value={priority}
              onChange={(e) => setPriority(Number(e.target.value))}
              step={1}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
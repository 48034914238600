import styles from './Loading.module.scss';
import { Spinner } from 'flowbite-react';

interface LoadingProps {
  className?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Loading = ({ className, size = "xl" } : LoadingProps) => (
  <div className={`${styles.loader__container} ${className ?? ''}`}>
    <Spinner
      aria-label="Carregando"
      size={size}
    />
  </div>
)
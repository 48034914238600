import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { Loading } from '../../Loading';
import { useNotify } from '../../../contexts/NotifyContext';
import { DashboardModes, DashboardType } from '../../../types';
import { ChevronDownIcon, CloseIcon, getIconByName, listAvailableIcons } from '../../../shared-components/utils/icons';
import { Label, TextInput } from 'flowbite-react';
import { IframePPT } from '../../CoPilotDashboard/IframePPT';
import { createOrUpdateDashboard } from '../../../services/dashboard';
import { useAuth } from '../../../contexts/AuthContext';
import { AvailableIcons } from '../../../shared-types/icon.type';
import { DashboardSelectUser } from './DashboardSelectUser';

interface ModalAddTemplateProps {
  isOpen: boolean,
  setIsOpen: (param: boolean) => void,
  dashboard?: DashboardType,
  setDashboards: React.Dispatch<React.SetStateAction<DashboardType[]>>
}
export const ModalAddDashboard = ({ isOpen, setIsOpen, setDashboards, dashboard }: ModalAddTemplateProps) => {
  const { toast, showMessage, onCloseModal } = useNotify();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [preview, setPreview] = useState('');
  const [active, setActive] = useState(true);
  const [mode, setMode] = useState<DashboardModes>('all');
  const [icon, setIcon] = useState('ProjectIcon');
  const [showPreviewOurUserSelection, setShowPreviewOurUserSelection] = useState<'select' | 'preview'>('preview');
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [userIds, setUserIds] = useState<string[]>([]);

  const [jsonActions, setJsonActions] = useState<string>();
  const [jsonMenu, setJsonMenu] = useState<string>();
  const parsedActions = (): DashboardType['actions'] | undefined => {
    if (!jsonActions) return;

    let actions: DashboardType['actions'] = undefined
    try { actions = JSON.parse(jsonActions) } catch (e) {
      console.error('[error-parse-actions]', { e })

      throw new Error('JSON de configuração das ações inválido')
    }

    if (!actions) return;

    if (!Array.isArray(actions)) throw new Error(
      'A configuração das ações deve ser um array'
    )

    actions.forEach((action) => {
      if (!action.name) throw new Error(
        'É obrigatório configurar o nome(name) de todas as ações'
      )

      if (!action.fn && !action.href) throw new Error(
        'Cada ação devem conter no mínimo uma função(fn) ou um link(href)'
      )
    })

    return actions;
  }
  const parsedMenu = (): DashboardType['menu'] | undefined => {
    if (!jsonMenu) return;

    let menu: DashboardType['menu'] = undefined
    try { menu = JSON.parse(jsonMenu) } catch (e) {
      console.error('[error-parse-menu]', { e })

      throw new Error('JSON de configuração do menu inválido')
    }

    if (!menu) return;

    if (
      typeof menu !== 'object' ||
      Array.isArray(menu) ||
      !menu.items ||
      menu.items.length === 0
    ) throw new Error(
      'A configuração das ações deve ser um objeto, contendo um array de itens(items) com pelo menos um item'
    );

    if (menu.active && (
      typeof menu.active !== 'string' && !(
        Array.isArray(menu.active) &&
        menu.active.every(ac => typeof ac === 'string')
      )
    )) throw new Error(
      'A propriedade menu.active, caso seja passada, deve ser uma string ou um array de string'
    )


    menu.items.forEach((item) => {
      if (item.items) throw new Error(
        'Ainda não há suporte para itens recursivos'
      )

      if (!item.id || !item.name) throw new Error(
        'É obrigatório que cada item tenha um id e nome'
      )

      // [ ] QUANDO HOUVER ITEMS RECURSIVOS A VALIDAÇÃO DEVE SER HREF OU SUB-ITEMS
      if (!item.href) throw new Error(
        'É obrigatório adicionar um link(href) aos itens do menu'
      )
    })

    return menu;
  }

  useEffect(() => {
    if (!dashboard) return

    setTitle(dashboard.title);
    setDescription(dashboard.description ?? '');
    setLink(dashboard.link);
    setPreview(dashboard.link);
    setActive(dashboard.active);
    setMode(dashboard.mode);
    setIcon(dashboard.icon ?? 'ProjectIcon');
    setJsonActions(dashboard.actions ? JSON.stringify(dashboard.actions, undefined, 2) : undefined);
    setJsonMenu(dashboard.menu ? JSON.stringify(dashboard.menu, undefined, 2) : undefined);
    setUserIds(dashboard.user_ids ?? []);
  }, [dashboard])

  useEffect(() => {
    if (!isOpen) {
      setShowPreviewOurUserSelection('preview');
    }
  }, [isOpen]);

  function handleReset() {
    setTitle('');
    setDescription('');
    setLink('');
    setPreview('');
    setActive(true);
    setMode('all');
    setIcon('ProjectIcon');
    setJsonActions(undefined);
    setJsonMenu(undefined);
    setUserIds([]);
  }
  function handleClose(force: boolean = false) {
    const closeModal = () => {
      handleReset();
      setIsOpen(false);
    }

    if (force) {
      closeModal();
      return;
    }

    showMessage(
      <p className="text-gray-600 text-sm">
        Suas alterações serão perdidas, tem certeza que deseja encerrar a criação de dashboard?
      </p>
      , {
        title: 'Confirmação',
        actionButton: {
          theme: 'danger',
          text: 'Encerrar',
          onClick: closeModal
        },
        cancelButtonText: 'Voltar ao Dashboard'
      })
  }
  function handleFinish(dashboard: DashboardType) {
    setDashboards((prevState) => [
      ...prevState.filter(state => state.id !== dashboard.id),
      dashboard
    ]);
    handleClose(true);
  }
  function handleSelectIcon() {
    showMessage((
      <div className="p-4 grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-3 justify-center items-center gap-4 text-center">
        {listAvailableIcons.map(({ component, title }, i) => (
          <button
            type="button"
            className="bg-gray-200 rounded-lg h-16 flex flex-col justify-center items-center gap-1"
            onClick={() => {
              setIcon(title)
              onCloseModal()
            }}
            key={i}
          >{component()}</button>
        ))}
      </div>
    ), {
      title: 'Selecione o Icone',
      size: 'sm:w-full sm:max-w-3xl'
    })
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!user) return;

    const makeSlug = (text: string) => text.toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");

    const error = [
      [!title, 'O campo título é obrigatório'],
      [!link,  'O link é obrigatório'],
      [makeSlug(title) === 'flow-dashboard', 'O seu dashboard não pode usar esse nome, pois é uma palavra reservada']
    ].find(([hasError]) => hasError)?.[1] as string | undefined;

    if (error) {
      toast.error(error);
      return;
    }

    setIsLoading(true);

    let actions = undefined;
    let menu = undefined;
    try {
      actions = parsedActions()
      menu = parsedMenu()
    } catch (e: any) {
      console.error('[error-parse-actions-or-menu]', e)
      toast.error(e.message)
      return;
    }

    if (mode === 'selected' && userIds.length === 0) {
      toast.error("É obrigatório selecionar pelo menos um usuário")
      return;
    }

    const request = {
      id: dashboard ? dashboard.id : undefined,
      title,
      description,
      link,
      active,
      mode,
      actions,
      menu,
      icon,
      ...(mode === 'selected' ? { user_ids: userIds } : {})
    };
    const res = await createOrUpdateDashboard(user.token, request);

    setIsLoading(false);

    if (!res.result) {
      toast.error(res.response);
      return;
    }

    toast.success(res.response);
    handleFinish(res.data!);
  }
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={undefined} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end sm:items-center justify-center text-center  pt-4 sm:pt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`
                relative transform overflow-hidden
                rounded-t-3xl bg-white text-left shadow-xl
                transition-all sm:mt-12 
                w-full h-[calc(100vh-3rem)]
              `}>
                <div className="h-[calc(100vh-3rem)] p-6 overflow-auto">
                  <div className="flex flex-col lg:flex-row w-full gap-6">
                    <div className="lg:h-[calc(100vh-6rem)] flex flex-col w-full lg:max-w-[34rem]">
                      <header className="mb-2">
                        <div className="flex justify-between items-center">
                          <div className="flex-1">
                            <h2 className="text-gray-800 text-2xl font-bold mb-2">
                              {dashboard ? 'Editar Dashboard' : 'Novo Dashboard'}
                            </h2>
                            <div className="w-full max-w-lg bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                              <div className="bg-blue-600 h-2.5 rounded-full" style={{
                                width: `${((Number(!!title) + Number(!!description) + Number(!!link)) * 100) / 4}%`
                              }}></div>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleClose()}
                          ><CloseIcon /></button>
                        </div>
                      </header>
                      <form
                        className="flex flex-col flex-1 gap-4 mt-8 w-full"
                        onSubmit={handleSubmit}
                      >
                        <div className="grid sm:grid-cols-1 sm:gap-4">
                          <div className="flex flex-col h-fit">
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label
                                  className="block text-sm font-medium !text-gray-700"
                                  htmlFor="dashboard-title"
                                  value={"Título"}
                                />
                              </div>
                              <TextInput
                                id="dashboard-title"
                                type={'text'}
                                className="flex-1"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Digite o título que aparecerá na página"
                                required={true}
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label
                                  className="block text-sm font-medium !text-gray-700"
                                  htmlFor="dashboard-description"
                                  value="Descrição"
                                />
                              </div>
                              <textarea
                                className="
                                  block w-full border 
                                  disabled:cursor-not-allowed disabled:opacity-50 
                                  bg-gray-50 border-gray-300 text-gray-900 
                                  focus:border-blue-500 focus:ring-blue-500 
                                  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
                                  dark:focus:border-blue-500 dark:focus:ring-blue-500
                                  rounded-lg p-2.5 text-sm
                                "
                                id="dashboard-description"
                                placeholder={"Dê uma descrição para poder identificar a Dashboard no futuro"}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required={true}
                              />
                            </div>
                            <div className="mb-4">
                              <div className="mb-2">
                                <Label
                                  className="block text-sm font-medium !text-gray-700"
                                  htmlFor="dashboard-link"
                                  value={"Link"}
                                />
                              </div>
                              <TextInput
                                id="dashboard-link"
                                type={link === '#' ? "text" : "url"}
                                className="flex-1"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                onBlur={(e) => setPreview(e.target.value)}
                                placeholder="Digite aqui o link do dashboard"
                                required={true}
                              />
                            </div>
                            <div className="mb-4 grid grid-cols-2 gap-4">
                              <div>
                                <div className="mb-2">
                                  <Label
                                    className="block text-sm font-medium !text-gray-700"
                                    htmlFor="dashboard-link"
                                    value={"Permissão de acesso"}
                                  />
                                </div>
                                <div className="grid grid-cols-2 rounded-md shadow-sm w-full">
                                  <button
                                    type="button"
                                    className={`
                                      ${mode === 'all' ?
                                        'border-primary-600 text-white bg-primary-600 font-semibold' :
                                        'border-gray-300 text-gray-900 bg-white font-medium focus:text-blue-700 hover:text-blue-700'
                                      }
                                      hover:brightness-90 focus:z-10 focus:ring-2 focus:ring-primary-600
                                      px-2 py-2 text-xs truncate border rounded-l-lg
                                    `}
                                    onClick={() => setMode('all')}
                                  >Todos da Empresa</button>
                                  <button
                                    type="button"
                                    className={`
                                      ${mode === 'selected' ?
                                        'border-primary-600 text-white bg-primary-600 font-semibold' :
                                        'border-gray-300 text-gray-900 bg-white font-medium focus:text-blue-700 hover:text-blue-700'
                                      }
                                      hover:brightness-90 focus:z-10 focus:ring-2 focus:ring-primary-600 
                                      px-1.5 py-2 text-xs truncate border rounded-r-md
                                    `}
                                    onClick={() => {
                                      setMode('selected');
                                      setShowPreviewOurUserSelection('select');
                                    }}
                                  >
                                    Apenas Selecionados
                                  </button>
                                </div>
                              </div>
                              <div>
                                <div className="mb-2">
                                  <Label
                                    className="block text-sm font-medium !text-gray-700"
                                    htmlFor="dashboard-link"
                                    value={"Icone do Dashboard"}
                                  />
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="border border-gray-300 text-gray-600 rounded-lg w-full p-1 flex justify-center items-center h-[2.1rem]"
                                    onClick={handleSelectIcon}
                                  >
                                    <div className="scale-75">{getIconByName(icon as AvailableIcons)}</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <button
                                type="button"
                                className="flex items-center gap-0.5 text-gray-600 text-xs font-semibold uppercase"
                                onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
                              >
                                <span className="pt-0.5">Avançado</span>
                                <ChevronDownIcon className={isAdvancedOpen ? "" : "-rotate-90"} />
                              </button>

                              {isAdvancedOpen && (
                                <div className="border-l-[3px] pl-3 mt-2 -ml-1">
                                  <div className="mb-4">
                                    <div className="mb-2">
                                      <Label
                                        className="block text-sm font-medium !text-gray-700"
                                        htmlFor="dashboard-actions"
                                        value="JSON de config. das Ações (opcional)"
                                      />
                                    </div>
                                    <textarea
                                      rows={8}
                                      className={`
                                        block w-full border 
                                        disabled:cursor-not-allowed disabled:opacity-50 
                                        bg-gray-50 text-gray-900 
                                        dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
                                        
                                        rounded-lg p-2.5 text-sm
                                        ${!((jsonActions?: string) => {
                                          if (!jsonActions) return true;

                                          try { return Array.isArray(JSON.parse(jsonActions)); }
                                          catch (e) { return false; }
                                        })(jsonActions) ? `
                                          border-red-500
                                          focus:border-red-500 focus:ring-red-500 
                                          dark:focus:border-red-500 dark:focus:ring-red-500
                                        `: `
                                          border-gray-300
                                          focus:border-blue-500 focus:ring-blue-500 
                                          dark:focus:border-blue-500 dark:focus:ring-blue-500
                                        `}
                                      `}
                                      id="dashboard-actions"
                                      placeholder={"Configure as ações disponíveis"}
                                      value={jsonActions}
                                      onChange={(e) => setJsonActions(e.target.value)}
                                    />
                                  </div>
                                  <div className="mb-4">
                                    <div className="mb-2">
                                      <Label
                                        className="block text-sm font-medium !text-gray-700"
                                        htmlFor="dashboard-menu"
                                        value="JSON de config. do Menu (opcional)"
                                      />
                                    </div>
                                    <textarea
                                      rows={8}
                                      className={`
                                        block w-full border 
                                        disabled:cursor-not-allowed disabled:opacity-50 
                                        bg-gray-50 border-gray-300 text-gray-900 
                                        focus:border-blue-500 focus:ring-blue-500 
                                        dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
                                        dark:focus:border-blue-500 dark:focus:ring-blue-500
                                        rounded-lg p-2.5 text-sm
                                        ${!((jsonMenu?: string) => {
                                          if (!jsonMenu) return true;

                                          try {
                                            const parsed = JSON.parse(jsonMenu);
                                            return typeof parsed === 'object' && !Array.isArray(parsed);
                                          }
                                          catch (e) { return false; }
                                        })(jsonMenu) ? `
                                          border-red-500
                                          focus:border-red-500 focus:ring-red-500 
                                          dark:focus:border-red-500 dark:focus:ring-red-500
                                        `: `
                                          border-gray-300
                                          focus:border-blue-500 focus:ring-blue-500 
                                          dark:focus:border-blue-500 dark:focus:ring-blue-500
                                        `}
                                      `}
                                      id="dashboard-menu"
                                      placeholder={"Configure as ações disponíveis"}
                                      value={jsonMenu}
                                      onChange={(e) => setJsonMenu(e.target.value)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mb-4">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                onClick={() => setActive(!active)}
                                checked={active}
                              />
                              <div className={`
                                w-9 h-5 bg-gray-200
                                peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800
                                rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                                after:border after:rounded-full after:h-4 after:w-4 after:transition-all
                                dark:border-gray-600 peer-checked:bg-blue-600
                              `}></div>
                              <span className="ml-3 text-xs  font-semibold uppercase text-gray-700 dark:text-gray-300">
                                Dashboard Ativa
                              </span>
                            </label>
                          </div>
                        </div>
                        {/* actions */}
                        <button
                          type={"submit"}
                          className={`
                            hover:brightness-90 mt-auto
                            focus:ring-4 focus:outline-none focus:ring-zinc-500
                            font-semibold rounded-lg text-sm bg-primary-600 hover:bg-primary-700
                            px-5 pt-2.5 pb-3 text-center mb-2 disabled:opacity-75 disabled:pointer-events-none
                            text-white mx-auto w-full first:mt-6
                          `}
                        >Salvar</button>
                      </form>
                    </div>
                    <div role="status" className="hidden space-y-8 md:space-y-0 md:space-x-8 md:flex md:items-center flex-1">
                      <div className="bg-gray-100 border min-h-[calc(100vh-6rem)] rounded-lg p-8 flex flex-col justify-center items-center w-full">
                        <div>
                          <button
                            className={`text-center uppercase text-xs text-gray-500 font-semibold ${showPreviewOurUserSelection === 'preview' ? 'opacity-100' : 'opacity-50'
                              }`}
                            onClick={() => setShowPreviewOurUserSelection('preview')}
                          >
                            Preview Dashboard
                          </button>

                          {mode === 'selected' && (
                            <button
                              className={`text-center uppercase text-xs text-gray-500 font-semibold ml-4 ${showPreviewOurUserSelection === 'select' ? 'opacity-100' : 'opacity-50'
                                }`}
                              onClick={() => setShowPreviewOurUserSelection('select')}
                            >
                              Selecionar Usuários
                            </button>
                          )}
                        </div>
                        {(showPreviewOurUserSelection === 'select' && mode === 'selected') ? (
                          <div className='flex-1 w-full'>
                            <DashboardSelectUser {...{
                              userIds,
                              isLoading,
                              setUserIds,
                              setIsLoading,
                            }} />
                          </div>
                        ) : (
                          <IframePPT url={preview} className="!my-auto" />
                        )}
                      </div>
                    </div>
                  </div>
                  {isLoading && <Loading className="absolute inset-0 z-50 bg-gray-50/75" />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  )
}
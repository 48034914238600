import { useEffect, useRef, useState } from "react";
import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { Client, DashboardType, ManagementLinkType, PossiblePermissions } from "../../types";
import { getListSessions } from "../../services/whatsapp";

import { useNotify } from "../../contexts/NotifyContext";
import { SearchIcon } from "../../shared-components/utils/icons";
import { ModalAddDashboard } from "../../components/Dashboard/ModalAddDashboard";
import { phoneMask } from "../../services/mask";

import { IntegrationSlideOver } from "../../components/IntegrationSlideOver";

type AvailableFilterStatus = 'Ativo' | 'Inativo';

export interface SessionType {
  key: string;
  number: string;
  connected: boolean;
  client?: {
    nome_fantasia: string;
    picture: string;
    id: string;
  };
  workflows?: {
    _id: string;
    theme: string;
    title: string;
    description?: string;
    user_id: string;
    status: string;
  }[];
}

export const AdminIntegracoes = () => {
  const divSearchRef = useRef<HTMLDivElement>(null);

  const { user } = useAuth();
  const { toast, } = useNotify();

  const [client, setClient] = useState<Client>();
  const [, setSelectedUser] = useState<ManagementLinkType | null>(null);

  const [filter, setFilter] = useState<{ search?: string, status?: AvailableFilterStatus }>({});
  const [, setShowingDashboards] = useState<string[]>([]);
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<DashboardType>();
  const [modelAddDashboardIsOpen, setModelAddDashboardIsOpen] = useState(false);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [, setSessions] = useState<SessionType[]>([]);
  const [selectedSession, setSelectedSession] = useState<SessionType | null>(null);
  const [searchResults, setSearchResults] = useState<SessionType[]>([]);

  const handleTableRowClick = (session: SessionType) => {
    setSelectedSession(session);
    setIsSlideOverOpen(true);
  };

  useEffect(() => {
    if (!user) return;
    loadClient();
    loadUsers();
    loadSessions();

    setSelectedUser({
      email: user.email,
      name: user.name,
      picture: user.picture,
      src: '#',
      user_id: user.id
    })
  }, [user]);

  useEffect(() => {
    setShowingDashboards(filter.search || filter.status ? (
      dashboards.filter((d) => (
        (filter.search ? d.title.toLocaleLowerCase().includes(filter.search!.toLocaleLowerCase()) : true) &&
        (filter.status ? ((filter.status === 'Ativo' && d.active) || (filter.status === 'Inativo' && !d.active)) : true)
      )).map(d => d.id)
    ) : dashboards.map(d => d.id))
  }, [dashboards, filter])

  async function loadClient() {
    if (!user || !user.current_client) return;

    let findedClient = user.clients?.find(c => c.id === user.current_client);
    if (findedClient) setClient(findedClient);
    else toast.error('Empresa não encontrada');
  }

  async function loadUsers() {
    if (!user) return;
  }

  const canAccessEmpresas = !!(
    user?.permitions_slug &&
    user.permitions_slug.includes(PossiblePermissions.ADMIN_HUB)
  );

  const loadSessions = async () => {
    try {
      if (!user?.token) {
        toast.error('Token de autenticação não encontrado.')
        return;
      }

      const token = user.token;
      const response = await getListSessions(token);
      if (response.result && response.data) {
        setSessions(response.data);
        setSearchResults(response.data); // Atualiza os resultados da pesquisa
      } else {
        toast.error('Houve um erro ao carregar as sessões:', response.response);
      }
    } catch (error) {
      toast.error('Houve um erro ao carregar as sessões:', error);
    }
  };

  return (
    <Wrapper
      asideActive={['Admin Integrações', 'Dashboards']}
      breadcrumbs={[
        { name: 'Admin Integrações', href: '#', subtitle: 'Whatsapp' }
      ]}
      module_name="Admin Panel"
      isAdmin
    >
      {(!user || !client) ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl" /> : (
        <div>
          <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 px-4 text-primary-700 h-16">
            <div className="group relative flex items-center ml-auto" ref={divSearchRef}>
              <input
                type="text"
                id="simple-search"
                className="
                  hidden group-[.show]:block
                  bg-gray-100/10 backdrop-blur-[25px]

                  border-primary-500/20
                  text-gray-600 text-sm rounded-lg 
                  placeholder-primary-700

                  focus:ring-gray-100/20 focus:border-gray-100/20 w-44 pr-10 p-2.5
                "
                value={filter.search ?? ''}
                onChange={(e) => setFilter((prevState) => ({
                  ...prevState,
                  search: e.target.value
                }))}
                placeholder="Pesquisar..."
              />
              <button
                type="button"
                className="
                  absolute inset-y-0 right-2
                  flex justify-center items-center
                  h-6 w-6 my-auto 
                  rounded-full outline-none focus:ring-gray-100/50 focus:ring-1
                "
                onClick={() => {
                  if (!divSearchRef.current) return;
                  divSearchRef.current.classList.toggle('show');

                  let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
                  if (divSearchRef.current.classList.contains('show')) input.focus();
                }}
              ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400" /></button>
            </div>
          </div>

          <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px] my-4 min-h-[10rem]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-primary-800 uppercase bg-primary-500/5">
                <tr>
                  <th className="px-3 py-2 font-bold max-w-[10rem]">Números Conectados</th>
                  {canAccessEmpresas && (
                    <th className="px-3 py-2 font-bold">Empresa</th>
                  )}
                  <th className="px-3 py-2 font-bold">Status</th>
                  <th className="px-3 py-2 font-bold">Quantidade de aplicações utilizando</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.length === 0 && (
                  <tr className="cursor-pointer h-full">
                    <td 
                      colSpan={4}
                      className="px-3 py-10 max-w-[12rem] truncate text-gray-400 text-sm text-center"
                    >
                      Não há nenhum dispositivo conectado
                    </td>
                  </tr>
                )}
                {searchResults.map((session) => (
                  <tr className="bg-gray-100/50 hover:bg-gray-200/60 border-b cursor-pointer" key={session.key} onClick={() => handleTableRowClick(session)}>
                    <td className="px-3 py-4 max-w-[12rem] truncate">{phoneMask(session.number)}</td>
                    {canAccessEmpresas && (
                      <td className="px-3 py-4">{session.client?.nome_fantasia}</td>
                    )}
                    <td className="px-3 py-4">{session.connected ? 'Ativo' : 'Inativo'}</td>
                    <td className="px-3 py-4">{session.workflows?.length || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <IntegrationSlideOver
              onClose={() => setIsSlideOverOpen(false)}
              isOpen={isSlideOverOpen}
              selectedSession={selectedSession}
            />
          </div>
        </div>
      )}
      <ModalAddDashboard
        isOpen={modelAddDashboardIsOpen}
        setIsOpen={() => {
          setModelAddDashboardIsOpen(false)
          setCurrentDashboard(undefined)
        }}
        setDashboards={setDashboards}
        dashboard={currentDashboard}
      />
    </Wrapper>
  );
}

import { Accordion, Badge, Spinner, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { OrderDetails } from "../../components/Order/OrderDetails";
import { Wrapper } from "../../shared-components/Wrapper";
import { useAuth } from "../../contexts/AuthContext";
import { formatRows, getOrderWithWarning, RulesType } from "../../services/order";
import { OrderFormattedType } from "../../types";
import { WarningTable, WarningTypeOnDetailsType } from "../../components/WarningTable";
import styles from './Warning.module.scss';

export const Warning = () => {
  const { user } = useAuth();
  const [warningOrders, setWarningOrders] = useState<OrderFormattedType[]>();
  const [expiredOrders, setExpiredOrders] = useState<OrderFormattedType[]>();
  const [nowOrders, setNowOrders] = useState<OrderFormattedType[]>();

  const [rules, setRules] = useState<RulesType>();
  
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderFormattedType>();
  const [warningTypeOnDetails, setWarningTypeOnDetails] = useState<WarningTypeOnDetailsType>();

  useEffect(() => {
    (async () => {
      if(!user) return;

      const data = await getOrderWithWarning(user.token)
      setExpiredOrders(formatRows(
        data.expired
      ));
      setWarningOrders(formatRows(
        data.warning
      ));
      setNowOrders(formatRows(
        data.now
      ));
      setRules(data.rules);
    })()
  },[user]);

  function handleShowDetails(order: OrderFormattedType, type: WarningTypeOnDetailsType){
    setWarningTypeOnDetails(type);
    setCurrentOrder(order);
    setDetailsIsOpen(true);
  }
  function handleCloseDetails(){
    setDetailsIsOpen(false);
    setCurrentOrder(undefined);
    setWarningTypeOnDetails(undefined);
  }
  function handleUpdateOrder(order: OrderFormattedType){
    setCurrentOrder(order);
    const updateOrder = (orders?: OrderFormattedType[]) => {
      if(!orders) return orders;

      if(warningTypeOnDetails && rules){
        let days = order.remainingDays ?? 0;
        let removeWarning = false;
        switch (rules[warningTypeOnDetails].operation) {
          case 'equal': removeWarning = !(days === rules[warningTypeOnDetails].value); break;
          case 'lt':    removeWarning = !(days < rules[warningTypeOnDetails].value); break;
          case 'gt': removeWarning = !(days > rules[warningTypeOnDetails].value); break;
          case 'lte': removeWarning = !(days <= rules[warningTypeOnDetails].value); break;
          case 'gte': removeWarning = !(days >= rules[warningTypeOnDetails].value); break;
        }
        if(removeWarning) return orders.filter(o => o.id !== order.id);
      }
      
      let index = orders?.findIndex(o => o.id === order.id);
      if(index > -1) orders[index] = order;

      return orders;
    };

    if(!warningTypeOnDetails) return;
    switch(warningTypeOnDetails){
      case 'warning': setWarningOrders(updateOrder);  break;
      case 'expired': setExpiredOrders(updateOrder);  break;
      case 'now':     setNowOrders(updateOrder);      break;
    }
  }

  return (
    <Wrapper
      asideActive={['Contas a Pagar', 'Alertas']}
      breadcrumbs={[
        { name: 'Alertas', href: '#'}
      ]}
      module_name="Ivrim Flows"
    >
      <>
        <div className="w-full">
          <Accordion alwaysOpen={true} className={`!border-none bg-gradient-glass backdrop-blur-[25px] !divide-gray-200/20 ${styles.accordion}`}>
            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2 text-gray-700">
                  Solicitações vencidas
                  <Badge className="!bg-red-700 ">
                    <span className="text-gray-50">
                      { expiredOrders?.length ?? 0 }
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <WarningTable
                  orders={expiredOrders}
                  textEmpty="Nenhuma solicitação vencida"
                  onDetails={handleShowDetails}
                  type="expired"
                />
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2 text-gray-700">
                  Solicitações a vencer hoje
                  <Badge className="!bg-gray-500">
                    <span className="text-gray-50">
                      { nowOrders?.length ?? 0}
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <WarningTable
                  orders={nowOrders}
                  textEmpty="Nenhuma solicitação a vencer hoje"
                  onDetails={handleShowDetails}
                  type="now"
                />
              </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
              <Accordion.Title>
                <div className="flex gap-2 text-gray-700">
                  Solicitações próximas do vencimento
                  <Badge className="!bg-yellow-400">
                    <span className="text-gray-50">
                      {warningOrders?.length ?? 0 }
                    </span>
                  </Badge>
                </div>
              </Accordion.Title>
              <Accordion.Content>
              <WarningTable
                orders={warningOrders}
                textEmpty="Nenhuma solicitação próxima ao vencimento"
                onDetails={handleShowDetails}
                type="warning"
              />
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
        <OrderDetails
          isOpen={detailsIsOpen}
          onClose={handleCloseDetails}
          onChange={handleUpdateOrder}
          order={currentOrder}
        />
      </>
    </Wrapper>
  );
}
import { Wrapper } from "../../shared-components/Wrapper"
import { useEffect, useState } from "react";
import { DashboardType } from "../../types";
import { getDashboards } from "../../services/dashboard";
import { useNotify } from "../../contexts/NotifyContext";
import { useAuth } from "../../contexts/AuthContext";
import { Loading } from "../../components/Loading";
import { getIconByName } from "../../shared-components/utils/icons";
import { AvailableIcons } from "../../shared-types/icon.type";
import { handleRegexUrl, hubRoutes } from "../../shared-types/utils/routes";
import { useNavigate } from "react-router-dom";

export const CoPilotDashboard = () => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);

  useEffect(() => { loadDashboards() }, [user]);  

  async function loadDashboards(){
    if(!user) return;
    
    setIsLoading(true)
    const res = await getDashboards(user.token, true);
    setIsLoading(false);
    if(!res.result){
      toast.error(res.response);
      return;
    }
    if(!res.data) return;

    setDashboards(res.data);
  }
  function handleRedirectDashboard(dashboard: DashboardType){
    let link = hubRoutes.dashboard.show(dashboard.slug);
    if(dashboard.link.includes('@isac:workflow.exec')){
      link = handleRegexUrl(dashboard.link as any, user?.token)
    }
    if(link.slice(0,4) === 'http') window.location.href = link;
    else navigate(link);
  }
  
  return (
    <Wrapper
      module_name="Co-Pilot Dashboard"
      asideActive={['Dashboard', 'Todas']}
      breadcrumbs={[
        { name: 'Dashboards', href: '#' },
      ]}
    >
      {isLoading ? (<Loading className="h-[calc(100vh-14.5rem)] rounded-xl" />):(
        <div>
          {dashboards.length === 0 ? (
            <div className="bg-gray-100/50 rounded-xl h-[calc(100vh-14.5rem)] w-full flex items-center justify-center text-center text-gray-500">
              Ainda não há dashboards disponíveis
            </div>
          ):(
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
              {dashboards.map((dashboard) => (
                <div key={dashboard.id} className="
                  bg-gradient-light rounded-lg px-4 py-3
                  text-gray-700 hover:opacity-90
                  flex flex-col
                ">
                  <div
                    className="pt-4 pb-1 flex-1 flex flex-col gap-0.5 cursor-pointer text-primary-400"
                    onClick={() => handleRedirectDashboard(dashboard)}
                  >
                    <strong className="truncate hover:text-clip block hover:whitespace-normal text-primary-800">{dashboard.title}</strong>
                    <p className="text-sm mb-1">
                      {dashboard.description ?? `Dashboard...`}
                    </p>
                    <div className="flex gap-4 justify-between items-center mt-auto">
                      <span className="text-xs block mt-auto"/>
                      <span className="scale-50">{dashboard.icon && getIconByName(dashboard.icon as AvailableIcons)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )} 

    </Wrapper>
  )

}
import { useState } from "react";
import { SlideOver } from "../../shared-components/utils/SlideOver";
import { SessionType } from "../../pages/Admin/AdminIntegracoes";
import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";
import { disconnectSession, sendTest } from "../../services/whatsapp";
import { phoneMask } from "../../services/mask";
import { shortclass } from "../../styles/styles";
import { NotifyOwners } from "./NotifyOwners";
import { handleRegexUrl } from "../../shared-types/utils/routes";

interface IntegrationSlideOverProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSession: SessionType | null;
}
export const IntegrationSlideOver = ({ isOpen, onClose, selectedSession }: IntegrationSlideOverProps) => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const [showNotifyForm, setShowNotifyForm] = useState(false);

  function disconnectSessionHandler(sessionKey: string){
    const disconnect = async () => {
      try {
        if (!user?.token) {
          toast.error('Token de autenticação não encontrado')
          return;
        }
  
        const token = user.token;
        await disconnectSession(token, sessionKey);
        toast.success('Sessão desconectada');
      } catch (error) {
        toast.error('Erro ao desconectar sessão');
      }
    }

    showMessage((
      <p className="text-gray-600 text-sm">
        Tem certeza que deseja desconectar este whatsapp?
        Todos os lugares que ele está sendo utilizado perderão a conexão com o whatsapp.
      </p>
    ), {
      title: 'Confirmação',
      actionButton: {
        theme: 'danger',
        text: 'Desconectar',
        onClick: disconnect
      },
      cancelButtonText: 'Voltar'
    })
  };
  async function sendTestMessageHandler(sessionKey: string){
    if (!user?.token) {
      toast.error('Token de autenticação não encontrado')
      return;
    }

    const token = user.token;
    const res = await sendTest(token, sessionKey);
    if(!res.result){
      toast.error(res.response)
      return;
    }
    toast.success(res.response);
  };

  return (
    <>
      {isOpen && (
        <SlideOver
          title={showNotifyForm ? "Notificar Responsável":"Detalhes da Integração"}
          isOpen={isOpen}
          onClose={onClose}
        >
          {selectedSession && (
            <div className="flex flex-col justify-between min-h-[100%] mt-2">
              {showNotifyForm ? (
                <NotifyOwners
                  goBack={() => setShowNotifyForm(false)}
                  selectedSession={selectedSession}
                />
              ) : (
                <>
                  <div className="pb-4 pt-3 flex flex-col">
                    <div className="flex flex-col mb-4">
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-1">
                        <tbody>
                          {selectedSession.client && (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                              <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Empresa:
                              </th>
                              <td className="px-2 py-4 text-right">
                                {selectedSession.client?.nome_fantasia}
                              </td>
                            </tr>
                          )}
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                            <th scope="row" className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              Empresa:
                            </th>
                            <td className="px-2 py-4 text-right">
                              {phoneMask(selectedSession.number)}
                            </td>
                          </tr>
                          <tr className="bg-white dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                            <td className="px-2 py-2 uppercase text-[10px] font-semibold text-center" colSpan={2}>
                              {selectedSession.connected ? (
                                <span className="text-emerald-500">Conectado</span>
                              ):(
                                <span className="text-red-500">Desconectado</span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {selectedSession.workflows && (
                      <div className="flex flex-col gap-2">
                        {selectedSession.workflows.map(workflow => (
                          <div className="bg-gray-100 p-2 text-gray-700" key={workflow._id}>
                            <div className="flex flex-col">
                              <span className="text-[10px] font-semibold uppercase">fluxo - {workflow.theme}</span>
                              <p className="text-sm text-gray-600">{workflow.title}</p>
                            </div>
                            {workflow.description && (
                              <div className="mt-2">
                                <span className="text-[10px] font-semibold uppercase">Descrição</span>
                                <p className="text-xs text-gray-600">{workflow.description}</p>
                              </div>
                            )}

                            <a
                              target="_blank"
                              href={handleRegexUrl(`@isac:workflow.create(${workflow._id})` as any)}
                              className="
                                block mt-4 px-4 py-1.5 text-center text-xs font-semibold 
                                text-gray-100 bg-primary-600 rounded-md shadow-md hover:bg-primary-700 transition-colors duration-200
                              "
                            >
                              Acesse o Workflow
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col mt-4 gap-3">
                    <button
                      type="button"
                      className={shortclass.button.primary}
                      onClick={() => setShowNotifyForm(true)}>Notificar Responsáveis</button>
                    <button
                      type="button"
                      className={shortclass.button.primary}
                      onClick={() => sendTestMessageHandler(selectedSession.key)}
                    >Testar Envio</button>
                    <button
                      type="button"
                      className={shortclass.button.danger}
                      onClick={() => disconnectSessionHandler(selectedSession.key)}
                    >Desconectar Dispositivo</button>
                  </div>
                </>
              )}
            </div>
          )}
        </SlideOver>
      )}
    </>
  )
}
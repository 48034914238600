import { useEffect, useState } from "react";
import { ShortUser } from "../../../shared-types";
import { getClient } from "../../../services/client";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { Radio, TextInput } from "flowbite-react";
import { CloseIcon, SearchIcon } from "../../../shared-components/utils/icons";

interface DashboardSelectUserProps {
    userIds: string[],
    setUserIds: React.Dispatch<React.SetStateAction<string[]>>,
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}
export const DashboardSelectUser = ({ userIds, setUserIds, isLoading, setIsLoading }: DashboardSelectUserProps) => {
    const { user } = useAuth();
    const { toast } = useNotify();

    const [availableUsers, setAvailableUsers] = useState<ShortUser[]>();
    const [filterNameOrEmail, setFilterNameOrEmail] = useState('');
    const [showAvailableUsers, setShowAvailableUsers] = useState<ShortUser[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (user) loadUsers()
    }, [user])

    useEffect(() => {
        setShowAvailableUsers(handleFilter(
            availableUsers ?? []
        ))
    }, [availableUsers, filterNameOrEmail])

    function handleFilter(users: ShortUser[]) {
        if (filterNameOrEmail) return users.filter((available) => available.name.toLowerCase().includes(
            filterNameOrEmail.toLowerCase()
        ) || (
                available.email.toLowerCase().includes(
                    filterNameOrEmail.toLowerCase()
                )
            ))

        return users
    }
    async function loadUsers() {
        if (!user) return;

        const client = await getClient(user.token)
        if (client && client.users) {
            setAvailableUsers(
                client.users.map((user) => ({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    picture: user.picture,
                    whatsapp: user.whatsapp,
                }))
            )
            setTotal(client.users.length)
        }
    }

    return (
        <div className="mb-4 mt-6 w-full">
            <div className="overflow-auto w-full">
                <div className="flex flex-col md:flex-row gap-2 p-1">
                    <div className="relative flex-1">
                        <TextInput
                            type="text"
                            value={filterNameOrEmail}
                            onChange={(e) => setFilterNameOrEmail(e.target.value)}
                            placeholder={'Filtrar por nome ou email'}
                        />
                        {filterNameOrEmail.length > 0 ? (
                            <button
                                type="button"
                                className="absolute inset-y-0 right-2 my-auto text-gray-700 hover:text-gray-500"
                                onClick={() => setFilterNameOrEmail('')}
                            ><CloseIcon w={18} h={18} /></button>
                        ) : (
                            <SearchIcon
                                className="absolute inset-y-0 right-2 my-auto text-gray-700"
                                w={18}
                                h={18}
                            />
                        )}
                    </div>
                </div>
                {showAvailableUsers.length === 0 ? (
                    <p className="text-sm text-center text-gray-500 py-14 mt-4 bg-gray-300/30 flex-1 w-full block">
                        {filterNameOrEmail ? 'Não foi encontrado nenhum usuário com esse filtro' : 'Não foi possível obter as usuários dessa empresa'}
                    </p>
                ) : (
                    <>
                        <div className="py-4 text-gray-500 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                            {showAvailableUsers.map(availableUser => (
                                <label
                                    className="
                  cursor-pointer
                  flex items-center justify-between rounded-lg gap-4
                  border border-gray-300 shadow bg-primary-300/10 hover:bg-primary-300/20 p-4
                "
                                    key={availableUser.id}
                                >
                                    <div className="flex items-center">
                                        <img
                                            alt={availableUser.name}
                                            className="rounded-full object-cover w-8 h-8"
                                            src={availableUser.picture}
                                        />
                                        <div className="ml-3 flex-1 flex justify-between items-center">
                                            <div className="flex flex-col items-start">
                                                <span className="whitespace-nowrap text-sm text-primary-700/80 leading-none">{availableUser.name}</span>
                                                <span className="text-xs text-primary-400 block">{availableUser.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Radio
                                        onClick={() => setUserIds((prevState) => {
                                            if (prevState.includes(availableUser.id)) return [...prevState.filter(
                                                (state) => state !== availableUser.id
                                            )]
                                            return [
                                                ...prevState,
                                                availableUser.id
                                            ]
                                        })}
                                        checked={userIds.includes(availableUser.id)}
                                    />
                                </label>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
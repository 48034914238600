import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { Modal } from "../../../shared-components/utils/Modal";
import { BusinessUnitType, CompanyType } from "../../../types/conciliation.type";
import { InfoIcon } from "../../../shared-components/utils/icons";
import { AddressIcon, ContactIcon } from "../../SvgIcons";
import { MACStepOne } from "./MACStepOne";
import { MACStepTwo } from "./MACStepTwo";
import { MACStepThree } from "./MACStepThree";
import { shortclass } from "../../../styles/styles";
import { MACStepZero } from "./MACStepZero";
import { MACStepFour } from "./MACStepFour";
import { createCompany, updateCompany } from "../../../services/company";
import { ResultAndResponse } from "../../../shared-types";
import { createUnit, updateUnit } from "../../../services/businessUnit";
import { Loading } from "../../Loading";

interface ModalAddCompanyProps{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  company?: CompanyType,
  setCompany: React.Dispatch<React.SetStateAction<CompanyType | undefined>>
  setCompanies: React.Dispatch<React.SetStateAction<CompanyType[]>>
}
export const ModalAddCompany = ({ isOpen, setIsOpen, company, setCompany, setCompanies }: ModalAddCompanyProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();
  const btnSubmitForm = useRef<HTMLButtonElement>(null);
  
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentUnit, setCurrentUnit] = useState<BusinessUnitType>();  
  //#region COMPANY DATAS
  const [cnpj, setCnpj] = useState('')
  const [razaoSocial, setRazaoSocial] = useState('')
  const [nomeFantasia, setNomeFantasia] = useState('')
  
  const [ddd, setDdd] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [contaCorrente, setContaCorrente] = useState('')
  const [agencia, setAgencia] = useState('')
  const [banco, setBanco] = useState('')

  // const [picture, setPicture] = useState('')

  const [cep, setCep] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [numero, setNumero] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [estado, setEstado] = useState('')
  
  useEffect(() => { setCurrentStep(1) },[isOpen])
  useEffect(() => {
    setCnpj(company?.cnpj ?? '')
    setRazaoSocial(company?.razao_social ?? '')
    setNomeFantasia(company?.nome_fantasia ?? '')
    // setPicture(company?.picture ?? '')
    setCep(company?.cep ?? '')
    setLogradouro(company?.logradouro ?? '')
    setNumero(company?.numero ?? '')
    setBairro(company?.bairro ?? '')
    setCidade(company?.cidade ?? '')
    setEstado(company?.estado ?? '')
    setDdd(company?.ddd ?? '')
    setTelefone(company?.telefone ?? '')
    setEmail(company?.email ?? '')
    setContaCorrente(company?.conta_corrente ?? '')
    setAgencia(company?.agencia ?? '')
    setBanco(company?.banco ?? '')
  },[company])
  //#endregion COMPANY DATAS
  //#region UNIT DATAS
  const [name, setName] = useState('')
  const [erpId, setErpId] = useState('')
  //#endregion UNIT DATAS

  //#region VALIDATE STEPS
  const validateStepOne = () => {
    if(!cnpj || !razaoSocial || !nomeFantasia){
      toast.error('O CNPJ, razão social e nome fantasia da empresa são obrigatórios')
      return false
    }
    return true
  }
  const validateStepTwo = () => {
    if((ddd || telefone) && (!ddd || !telefone)){
      toast.error('Para adicionar telefone é obrigatório preencher o campo DDD e telefone');
      return false;
    }

    return true
  }
  const validateStepThree = () => {
    if(cep || logradouro || numero || bairro || cidade || estado){
      if(
        !cep ||
        !logradouro ||
        !numero ||
        !bairro ||
        !cidade ||
        !estado
      ){
        toast.error(
          'Para adicionar o endereço é obrigatório preencher todos os campos (CEP, logradouro, número, bairro, cidade, estado)'
        )
        return false;
      }
    }
    return true
  }
  const validateStepFour = () => {
    if(!company){
      toast.error('Não foi possível identificar a empresa que deseja cadastrar a unidade')
      return false;
    }
    if(!name || !erpId){
      toast.error('É obrigatório informar o nome da unidade e o id no ERP')
      return false;
    }
    return true
  }
  //#endregion VALIDATE STEPS

  function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return;

    if(currentStep === 1){
      if(!validateStepOne()) return;

      setCurrentStep(2)
    }else
    if(currentStep === 2){
      if(!validateStepOne()){
        setCurrentStep(1)
        return;
      }

      if(!validateStepTwo()) return;

      setCurrentStep(3)
    }else
    if(currentStep === 3){
      if(!validateStepOne()){
        setCurrentStep(1)
        return;
      }
      if(!validateStepTwo()){
        setCurrentStep(2)
        return;
      }
      if(!validateStepThree()) return

      createOrUpdateCompany()
    }else
    if(currentStep === 0){
      setCurrentUnit(undefined)
      setCurrentStep(4)
    }else
    if(currentStep === 4){
      if(!validateStepFour()) return
      
      createOrUpdateUnit()
    }
  };
  async function createOrUpdateCompany(){
    if(!user) return;

    setIsLoading(true);

    const data = {
      cnpj: cnpj,
      razao_social: razaoSocial,
      nome_fantasia: nomeFantasia,
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      ddd: ddd,
      telefone: telefone,
      email: email,
      // TODO
      // picture: picture,
      // conta_corrente: contaCorrente,
      // agencia: agencia,
      // banco: banco
    }

    const res = await (async () => {
      if(company) return await updateCompany(data, company.cnpj, user.token)
      return await createCompany(data, user.token)
    })()
    
    setIsLoading(false);

    if(!res.result){
      toast.error(res.response)
      return
    }
  
    toast.success(res.response)
    if(res.data) setCompanies((prevState) => company ? prevState.map(
      (state) => state.cnpj === company.cnpj ? {
        ...state,
        ...res.data
      } : state
    ):[...prevState, res.data!])
    setIsOpen(false)
  }
  async function createOrUpdateUnit(){
    if(!user || !company) return

    setIsLoading(true);
    const data = {
      name,
      erp_id: erpId,
      nome_fantasia: company.nome_fantasia,
    }
    const res = await (async () => {
      if(currentUnit) return await updateUnit({
        ...data,
        business_unit_slug: currentUnit.business_unit_slug
      }, user.token)
      return await createUnit(data, user.token)
    })()

    setIsLoading(false);

    if(!res.result){
      toast.error(res.response)
      return
    }
  
    toast.success(res.response)
    if(res.data) setCompanies(prevState => prevState.map((state) => {
      if(state.cnpj !== company.cnpj) return state;
      
      const businessUnits : BusinessUnitType[] = [];
      if(state.businessUnits) businessUnits.push(...state.businessUnits.map(
        (unit) => currentUnit && currentUnit.business_unit_slug === unit.business_unit_slug ? {
          ...unit,
          ...res.data
        }:unit)
      )

      if(!currentUnit) businessUnits.push(res.data!)
      
      const newState = { ...state, businessUnits }
      if(company) setCompany(newState)

      return newState
    }))

    setCurrentUnit(undefined)
    setCurrentStep(0)
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{
        title: '',
        cancelButton: true,
        cancelButtonText: currentStep > 1 ? "Voltar": "Cancelar",
        cancelButtonFn: currentStep === 4 ? () => setCurrentStep(0) : currentStep > 1 ? () => setCurrentStep(
          (prevState) => prevState > 1 ? prevState - 1 : prevState
        ) : undefined,
        actionButton: {
          theme: "primary",
          text: currentStep === 0 ? 'Adicionar Unidade' : currentStep < 3 ? "Próximo": "Finalizar",
          onClick: () => {
            if(btnSubmitForm.current) btnSubmitForm.current.click()
          },
          autoClose: false,
        },
      }}
    >
      <div>
        {currentStep > 0 && currentStep < 4 && (
          <ol className="flex -mt-2">
            <li className={`flex w-full items-center after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700 ${currentStep > 1 ? 'after:border-b after:border-primary-500 after:border-4' : 'after:border-gray-200'}`}>
              <button type="button" onClick={() => setCurrentStep(1)} className={`
                flex items-center justify-center w-10 h-10 rounded-full shrink-0 
                ${currentStep >= 1 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'}
              `}>
                <InfoIcon w={20} h={20}/>
              </button>
            </li>
            <li className={`flex w-full items-center after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700 ${currentStep > 2 ? 'after:border-b after:border-primary-500 after:border-4' : 'after:border-gray-200'}`}>
              <button type="button" onClick={() => setCurrentStep(2)} className={`
                flex items-center justify-center w-10 h-10 rounded-full shrink-0 
                ${currentStep >= 2 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'}
              `}>
                <ContactIcon width={20} height={20}/>
              </button>
            </li>
            <li className="flex items-center">
              <button type="button" onClick={() => setCurrentStep(3)} className={`
                flex items-center justify-center w-10 h-10 rounded-full shrink-0
                ${currentStep === 3 ? 'bg-primary-500 text-white' : 'bg-gray-100 text-blue-600'}
              `}>
                <AddressIcon width={20} height={20}/>
              </button>
            </li>
          </ol>
        )}
        <form className="my-4 min-h-[10rem]" onSubmit={handleSubmit}>
          <button type="submit" className="hidden" ref={btnSubmitForm}/>

          {company && (currentStep < 2 || currentStep === 4) && (
            <button
              type="button"
              className={`${shortclass.button.primary} w-full font-semibold`}
              onClick={() => setCurrentStep(currentStep === 1 ? 0:1)}
            >
              {currentStep === 1 ? 'Gerenciar Unidades':'Voltar à Empresa'}
            </button>
          )}
          {/*[STEP] 1 - 3: Company | 0 e 4: Unit */}
          {currentStep === 0 ? (
            <MACStepZero {...{
              company: company!,
              onEdit: (unit: BusinessUnitType) => {
                setCurrentUnit(unit)
                setCurrentStep(4)
              }
            }}/>
          ):currentStep === 1 ? (
            <MACStepOne {...{
              cnpj,
              setCnpj,
              razaoSocial,
              setRazaoSocial,
              nomeFantasia,
              setNomeFantasia,
            }}/>
          ):currentStep === 2 ? (
            <MACStepTwo {...{
              ddd,
              setDdd,
              telefone,
              setTelefone,
              email,
              setEmail,
              contaCorrente,
              setContaCorrente,
              agencia,
              setAgencia,
              banco,
              setBanco,
            }}/>
          ):currentStep === 3 ? (
            <MACStepThree {...{
              cep,
              setCep,
              logradouro,
              setLogradouro,
              numero,
              setNumero,
              bairro,
              setBairro,
              cidade,
              setCidade,
              estado,
              setEstado
            }}/>
          ) : currentStep === 4 ? (
            <MACStepFour {...{
              company: company!,
              unit: currentUnit,
              name, setName,
              erpId, setErpId
            }}/>
          ):""}
        </form>
        {isLoading && <Loading className={`
          fixed inset-0 z-50 bg-gray-50/75 max-h-full
        `}/>}
      </div>
    </Modal>
  );
}
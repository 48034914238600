import { Wrapper } from "../../shared-components/Wrapper";
import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, CloudIcon, DownloadAltIcon, MoreVerticalIcon, SearchIcon } from "../../shared-components/utils/icons";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import Folder from "../../shared-components/assets/folder.svg";
import { PlusIcon } from "../../shared-components/utils/icons";
import { Link, useNavigate } from "react-router-dom";
import { filesize } from "filesize";
import { ModalAddGallery } from "../../components/Gallery/ModalAddGallery";
import { GalleryAvailableType, GalleryItemType, GalleryType, galleryAvailableTypesFormatted } from "../../types/gallery.type";
import { useAuth } from "../../contexts/AuthContext";
import { deleteGallery, getGalleries, getRecentsFromAllGalleries } from "../../services/gallery";
import { useNotify } from "../../contexts/NotifyContext";

export const Gallery = () => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();

  const navigate = useNavigate();
  const divSearchRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState(false);
  
  const [filter, setFilter] = useState<{ search?: string, type?: GalleryAvailableType }>({});
  const [folders, setFolders] = useState<GalleryType[]>([]);
  const [recentFiles, setRecentFiles] = useState<GalleryItemType[]>([]);
  const [showingFolders, setShowingFolders] = useState<string[]>([]);

  const loadShowingFilter = (folders: GalleryType[]) => {
    setShowingFolders(
      folders.filter((folder) => ((
        !filter.type || folder.type === filter.type
      ) && (
        !filter.search || folder.name.toLowerCase().includes(filter.search.toLowerCase())
      ))).map((folder) => folder.id)
    );
  };

  useEffect(() => {
    loadGalleries();
    loadRecents();
  },[user])
  useEffect(() => {
    if(folders.length > 0 || (
      folders.length === 0 && showingFolders.length !== 0
    )) loadShowingFilter(folders);
  },[folders, filter])
  
  async function loadGalleries(){
    if(!user) return;

    const res = await getGalleries(user.token);

    if(!res.result){
      toast.error(res.response);
      return;
    }
    
    if(!res.data) return;
    
    setFolders(res.data);
  }
  async function loadRecents(){
    if(!user) return;

    const res = await getRecentsFromAllGalleries(user.token);
    
    if(!res.result){
      toast.error(res.response);
      return;
    }

    if(!res.data) return;

    setRecentFiles(res.data);
  }
  function handleDelete(id: string, name: string){
    const onDelete =  async (id: string) => {
      if(!user) return;

      const res = await deleteGallery(user.token, id);
      if(!res.result){
        toast.error(res.response)
        return
      }

      toast.success(res.response);

      setFolders((prevState) => prevState.filter((state) => state.id !== id));
    }

    showMessage((
      <p className="mt-2 mb-4 text-gray-600">Deseja realmente excluir a galeria "{name}"?</p>
    ), {
      title: 'Confirmação de exclusão',
      actionButton: {
        onClick: () => onDelete(id),
        theme: 'primary',
        text: 'Confirmar'
      }
    })
  }

  const [size, maxSize, percent] = [35,100, 35];
  return (
    <Wrapper
      breadcrumbs={[
        { name: 'Meus Docs.', href: '#' }  
      ]}
      asideActive="Meus Docs."
      module_name="Configurações"
      // RETORNAR QUANDO ESTIVER FAZENDO O CALCULO CORRETO
      // footerItems={[{
      //   type: 'raw',
      //   content: (
      //     <div className="
      //       bg-gray-100/20 rounded-lg text-white text-xs p-2 flex items-center gap-2
      //       sm:justify-center md:justify-start
      //       group-[.collapsed]:justify-center
      //       group-[.collapsed-desktop-aside]:justify-center
      //     ">
      //       <div className="
      //         flex-1 pt-1
      //         sm:hidden md:block
      //         group-[.collapsed]:hidden
      //         group-[.collapsed-desktop-aside]:hidden
      //       ">
      //         <div className="w-full overflow-hidden rounded-full bg-gray-200/80 dark:bg-gray-700 h-1.5">
      //           <div
      //             className="flex items-center justify-center rounded-full text-center font-medium leading-none text-blue-100 bg-primary-500/90 h-1.5"
      //             style={{ width: `${percent}%` }}
      //           />
      //         </div>
      //         <p className="pt-1.5 text-gray-100 text-center">
      //           <span className="font-bold">{size} GB</span> de {maxSize} GB usados
      //         </p>
      //       </div>
      //       <div className="">
      //         <CloudIcon />
      //         <span className="text-[10px] leading-none">{percent}%</span>
      //       </div>
      //     </div>
      //   )
      // }]}
    >
      {/* BEGIN: HEADER */}
      <div className="flex items-center justify-between">
        <div className="text-sm text-gray-500">{showingFolders.length} de {folders.length}</div>
        <div>
          <button
            type="button"
            className={`
              text-primary-700 font-semibold bg-gradient-light flex gap-2 items-center
              hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-400
              rounded-lg text-sm 
              px-3 pt-1.5 pb-1.5 text-center w-full mb-2 mt-auto
            `}
            onClick={() => setShowModal(true)}
          ><PlusIcon w={20}/> Criar Pasta</button>
        </div>
      </div>
      <div className="bg-gradient-light backdrop-blur-[25px] rounded-lg flex justify-between gap-2 mt-4 px-4 text-primary-700 h-16">
        <div className="max-sm:hidden">
          <Dropdown
            classNames={{
              list: `
                absolute left-0 z-10
                -mt-1 w-56 origin-top-right rounded-md
                bg-gray-100/70 backdrop-blur-[25px] shadow-lg
                ring-1 ring-black ring-opacity-5
                focus:outline-none pb-2
              `
            }}
            trigger={
              <div className="
                my-3 w-40 h-10 border border-gray-50/10
                flex items-center justify-between p-4 rounded-md bg-gray-100/5 
                text-sm backdrop-blur-[25px] font-semibold
              ">
                <span className="pt-0.5 pl-2">{filter.type ? galleryAvailableTypesFormatted[filter.type] : 'Todos'}</span>
                <ChevronDownIcon className="
                  group-data-[headlessui-state=open]:rotate-180
                "/>
              </div>
            }
          >
            {[[undefined, 'Todos'], ...Object.entries(galleryAvailableTypesFormatted)].map(([key, name],i) => (
              <button
                type="button"
                key={`${i}-${key}`}
                className="
                  text-gray-700 font-semibold text-sm
                  rounded-lg backdrop-blur-[25px] w-full
                  text-start p-2 hover:bg-gray-50/30
                "
                onClick={() => setFilter((prevState) => ({
                  ...prevState,
                  type: key as GalleryAvailableType | undefined
                }))}
              >{name}</button>
            ))}
          </Dropdown>
        </div>
        <div className="group relative flex items-center ml-auto" ref={divSearchRef}>
          <input
            type="text"
            id="simple-search"
            className="
              hidden group-[.show]:block
              bg-gray-100/10 backdrop-blur-[25px]
              
              border-primary-500/20
              text-gray-600 text-sm rounded-lg 
              placeholder-primary-700

              focus:ring-gray-100/20 focus:border-gray-100/20 w-44 pr-10 p-2.5
            "
            value={filter.search ?? ''}
            onChange={(e) => setFilter((prevState) => ({
              ...prevState,
              search: e.target.value
            }))}
            placeholder="Pesquisar..."
          />
          <button
            type="button"
            className="
              absolute inset-y-0 right-2
              flex justify-center items-center
              h-6 w-6 my-auto 
              rounded-full outline-none focus:ring-gray-100/50 focus:ring-1
            "
            onClick={() => {
              if(!divSearchRef.current) return;
              divSearchRef.current.classList.toggle('show');

              let input = divSearchRef.current.childNodes[0] as HTMLInputElement;
              if(divSearchRef.current.classList.contains('show')) input.focus();
            }}
          ><SearchIcon className="w-5 h-5 text-primary-700 dark:text-gray-400"/></button>
        </div>
      </div>
      {/* END: HEADER */}
      <div className="mt-6 min-h-[calc(100%-17rem)]">
        <div className="min-h-[calc(70vh-17rem)]">
          {folders.length === 0 || showingFolders.length === 0 ? (
            <div className="
              text-sm text-center rounded-lg
              text-gray-700/80 w-full min-h-[calc(70vh-17rem)] bg-primary-400/5 hover:bg-primary-400/10
              flex flex-1 self-center items-center justify-center
            ">{folders.length === 0 ? 'Você ainda não possui nenhuma pasta':'Nenhuma pasta encontrada com esse filtro'}</div>
          ) : (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
              {folders.filter(f => showingFolders.includes(f.id)).map((folder) => (
                <div
                  className="bg-gradient-light rounded-lg px-4 pt-3 pb-4 text-gray-700 flex flex-col"
                  key={folder.id}
                >
                  <header className="flex gap-2 items-center justify-between text-xs">
                    <span className="flex items-center text-primary-700 font-semibold gap-1">
                      <img src={Folder} alt="icone de pasta" className="pb-1" />
                      <span className="uppercase text-[10px] leading-none">{galleryAvailableTypesFormatted[folder.type] ?? folder.type}</span>
                    </span>
                    <div>
                      <Dropdown
                        classNames={{ list: `
                          absolute right-0 z-10
                          -mt-1 w-56 origin-top-right rounded-md
                          bg-gray-100/40 backdrop-blur-[25px] shadow-lg
                          ring-1 ring-black ring-opacity-5
                          focus:outline-none pb-2
                        ` }}
                        trigger={
                          <div
                            className="flex items-center justify-between rounded-md  text-sm backdrop-blur-[25px]"
                          >
                            <MoreVerticalIcon className="-rotate-90 text-primary-800"/>
                          </div>
                        }
                      >
                        <button
                          type="button"
                          className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                          onClick={() => navigate(`/meus-docs/${folder.id}`)}
                        >Acessar</button>
                        {folder.type !== 'workflow' && !(folder.num_items && folder.num_items > 0) ? (
                          <button
                            type="button"
                            className="text-gray-700 font-semibold text-sm rounded-lg backdrop-blur-[25px] w-full text-start p-2 hover:bg-gray-50/80"
                            onClick={() => handleDelete(folder.id, folder.name)}
                          >Excluir</button>
                        ):(
                          <span className="text-xs text-gray-500">
                            {folder.type === 'workflow' ? 'Esse tipo de galeria não pode ser excluído':'Não é possível excluir galerias com arquivos dentro'}
                          </span>
                        )}
                      </Dropdown>
                    </div>
                  </header>
                  <div
                    className="text-sm pt-2 pb-2 flex-1 flex flex-col cursor-pointer gap-0.5"
                    onClick={() => navigate(`/meus-docs/${folder.id}`)}
                  >
                    <strong className="text-primary-800">{folder.name}</strong>
                    <span className="text-primary-400 text-xs">
                      {folder.num_items} arquivos ({filesize(folder.total_size) as string})
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {recentFiles.length > 0 && (
          <div className="mt-8">
            <div>
              <h3 className="font-semibold text-primary-800">Arquivos Recentes</h3>
              <div className="mt-6 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                {recentFiles.map((recent) => (
                  <div className="
                    bg-gradient-light hover:bg-primary-300/10
                    rounded-md text-sm flex items-center
                  " key={recent.id}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={recent.src}
                      className="
                        w-9 h-9 rounded-md bg-no-repeat border bg-gray-200 shadow-lg bg-cover mr-2.5 text-primary-800
                        flex items-center justify-center m-4
                        focus:outline-none focus:ring-2 focus:ring-offset-2
                        focus:ring-primary-300 hover:bg-gray-200/80 hover:border-2
                      "
                    >
                      <DownloadAltIcon w={20} h={20}/>
                    </a>
                    <Link to={`/meus-docs/${recent.gallery_id}`} className="
                      flex-1 flex items-center justify-center hover:bg-gray-50/30 p-4
                    ">
                      <div className="flex flex-col flex-1 gap-1">
                        <p className="
                          font-bold text-xs justify-start text-primary-800
                          text-ellipsis whitespace-nowrap overflow-hidden max-w-[10rem]
                        ">
                          {recent.name}
                        </p>
                        <span className="text-gray-500 text-xs">
                          {recent.gallery_name} ({filesize(recent.size) as string}) 
                        </span>
                      </div>
                      <div className="flex gap-1">
                        <button
                          type="button"
                          className={`
                            text-primary-800 outline-none
                            p-1.5rounded-lg inline-block
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-primary-300 hover:bg-primary-400/5 hover:border-2
                          `}
                        ><ChevronDownIcon className="-rotate-90" w={20} h={20}/></button>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalAddGallery
        isOpen={showModal}
        setIsOpen={setShowModal}
        setGalleries={setFolders}
      />
    </Wrapper>
  );
};

export default Gallery;

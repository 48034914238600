import { useState, useEffect } from "react";

import logoIcon from "../../shared-components/assets/logo-hub.png";
import { shortclass } from "../../styles/styles";
import { removeTokenJWT } from "../../shared-components/services/conn/api";
import { Loading } from "../../components/Loading";
import { useNotify } from "../../contexts/NotifyContext";
import { api, headerBearer } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const Session = () => {
  const { handleToken, loadUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useNotify();
  const navigate = useNavigate();
  async function handleSubmit() {
    try {
      setIsLoading(true);
      
      const token = handleToken();
      if (!token) {
        toast.error('Erro ao tentar forçar a sessão');
        setIsLoading(false);
        return;
      }
      const { data } = await api.post('/session/force', {}, headerBearer(token));

      if(!data.result) throw new Error(data.response);

      if(data && data.result) {
        toast.success('Sessão iniciada com sucesso!');
        loadUser();
        setIsLoading(false);
        window.history.go(-1);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error)
      toast.error('Não foi possível encerrar a sessão');      
    }
  }

  function decline() {
    setIsLoading(true);
    removeTokenJWT();
    setIsLoading(false);
  }

  useEffect(() => {
    if (!handleToken()) {
      navigate('/login')
    }
  }, []);

  return (
    <div className="
      h-full flex
      bg-background
      bg-cover bg-no-repeat bg-center
      min-h-[100vh]
    ">
      <div className="
        min-h-full min-w-full sm:min-w-max max-w-[1500px]
        container grid items-center md:justify-center
        mx-auto
        pt-12 pb-8 p-0 xs:px-2 sm:px-8 md:px-10 lg:px-12
      " style={{ zIndex: 1 }}>
        <div className="
          bg-white xs:rounded-lg
          m-auto md:m-0
          px-2 xxs:px-4 xs:px-6 pt-9 pb-9
          w-full xs:w-[24rem]

        " style={{ maxWidth: "100vw" }}>
          <div>
            <img
              className="mx-auto h-10 xs:h-12 w-auto mt-4"
              src={logoIcon}
              alt="IVRIM"
            />
          </div>
          <div
            className="mt-12 flex flex-col gap-6"
          >
            <div className="flex gap-1 xs:items-center justify-between flex-row xs:flex-row mt-1 mb-2">
              <div>
                <p className="text-center text-gray-500">Existe uma sessão ativa em outro dispositivo. Deseja prosseguir com esta sessão?</p>
              </div>

            </div>
            <div className="flex flex-row gap-1 -mb-2 mt-2">
              <button 
                type="button" 
                className={`
                  group relative w-full
                  ${shortclass.button.normal} text-gray-700 
                  focus:ring-primary-500 hover:bg-primary-500
                  hover:!bg-gradient-aside bg-gray-300 !font-semibold !mb-0
                `}
                onClick={decline}
              >Não</button>
              <button 
                type="submit" 
                className={`
                  group relative w-full
                  ${shortclass.button.primary} !bg-gradient-aside !font-semibold !mb-0
                `}
                onClick={handleSubmit}
              >Sim</button>
            </div>
          </div>
        </div>
        {isLoading && <Loading className="absolute inset-0 z-50 bg-gray-50/75"/>}

        <footer className="mt-auto d-flex items-center justify-center text-center">
          <p className="text-gray-600 text-sm">Ivrim {new Date().getUTCFullYear()} © Todos os direitos reservados</p>
        </footer>
      </div>
    </div >
  );
}
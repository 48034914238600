import { ResultAndResponse } from "../shared-types"
import { CompanyType } from "../types/conciliation.type"
import { api, handleErrorResultAndResponse, headerBearer } from "./api"

export const getCompanies = async(token: string) => {
  try {
    const response = await api.get('/company',headerBearer(token))

    return response.data.data 
  } catch (error) {
    return 
  }
}

interface CompanyResponse extends ResultAndResponse{
  data?: CompanyType
}
export const createCompany = async (fields: CompanyType, token: string) : Promise<CompanyResponse> => {
  try{
    const { data } = await api.post('/company', fields, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return data
  }catch(e){
    console.error('[error-on-create-company]', e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível cadastrar a nova empresa'
    })
  }
}
export const updateCompany = async (fields: Partial<CompanyType>, cnpj: string, token: string) : Promise<CompanyResponse> => {
  try{
    const { data } = await api.put('/company', {
      ...fields,
      current_cnpj: cnpj
    }, headerBearer(token))

    if(!data.result) throw new Error(data.response)

    return data
  }catch(e){
    console.error('[error-on-update-company]', e)
    return handleErrorResultAndResponse(e, {
      result: false,
      response: 'Não foi possível atualizar a empresa'
    })
  }
}
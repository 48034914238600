import { Wrapper } from "../../shared-components/Wrapper";
import { listAvailableIcons } from "../../shared-components/utils/icons";
import { hubRoutes } from "../../shared-types/utils/routes";

export const IconsPage = () => (
  <Wrapper
    breadcrumbs={[{ name: 'Icones', href: hubRoutes.icon() }]}
    module_name="Configurações"
  >
    <div className="bg-gray-50 rounded-lg p-4 grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-3 justify-center items-center gap-4 text-center">
      {listAvailableIcons.map(({component, title}, i) => (
        <div className="bg-gray-200 rounded-lg h-16 flex flex-col justify-center items-center gap-1" key={i}>
          {component()}
          <span className="text-xs text-gray-600">{title}</span>
        </div>
      ))}
    </div>
  </Wrapper>
);
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types"
import { shortclass } from "../../styles/styles";
import { OrderFormattedType, StatusType } from "../../types";
import { ChevronLeftIcon, ChevronRightIcon, MoreVerticalIcon } from "../SvgIcons";
import { Dropdown } from "../../shared-components/utils/Dropdown";

interface FiltersType{
  status: string[] | undefined,
  setStatus: (param: any) => void,
  statusList: StatusType[] | undefined,
  date: DateValueType,
  requester: string,
  numberPO: string,
}
type SetPageIndexCbType = (p: number) => number;
interface OrderTableProps {
  orders: OrderFormattedType[],
  filters: FiltersType,
  totalOrders: number,
  onDeleteItem: (id: string, po: string) => Promise<void>,
  onDetails: (order: OrderFormattedType) => void,
  onLoad: () => Promise<void>,
  pageIndex: number,
  setPageIndex: (param: number | SetPageIndexCbType) => void,
  totalPages: number,
  setTotalPages: (param: number) => void,
  goToPage: (index: number) => void,
  perPage: number,
  isFiltering: boolean,
  setHasSelectedForDelete: React.Dispatch<React.SetStateAction<boolean>>
}
export const OrderTable = ({
  orders,
  filters,
  totalOrders,
  onDeleteItem,
  onDetails,
  onLoad,
  
  pageIndex,
  setPageIndex,
  totalPages,
  setTotalPages,
  goToPage,
  perPage,

  isFiltering,
  setHasSelectedForDelete
}: OrderTableProps) => {
  const navigate = useNavigate();
  // #region MANAGE PAGES
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const calcTotalPages = (total: number) => {
    let numPages = Math.ceil(total / perPage);
    if(numPages <= 0) numPages = 1; 
    setTotalPages(numPages);
  };
  const previousPage = () => setPageIndex((prevState) => {
    let newIndex = prevState - 1;
    if(newIndex >= 0) return newIndex;
    return prevState;
  });
  const nextPage = () => setPageIndex((prevState) => {
    let totalPagesInDB = Math.ceil(totalOrders / perPage);
    let newIndex = prevState + 1;

    if(newIndex >= totalPagesInDB || newIndex < 0) return prevState;
    if(newIndex >= totalPages) onLoad();

    return newIndex;
  });
  // #endregion MANAGE PAGES
  const [showingOrders, setShowingOrders] = useState<OrderFormattedType[]>([]);
  
  useEffect(() => {
    setShowingOrders(orders.filter(handleFilter));
    calcTotalPages(orders.length);
  },[orders]);
  useEffect(() => calcTotalPages(showingOrders.length),[perPage, showingOrders]);
  useEffect(() => {
    setCanPreviousPage(pageIndex > 0);
    let totalPagesInDB = Math.ceil(totalOrders / perPage);
    setCanNextPage((pageIndex + 1) < totalPagesInDB);
  },[pageIndex, totalPages]);
  
  let timeFilter : NodeJS.Timeout | null  = null; 
  useEffect(() => {
    if(timeFilter) clearTimeout(timeFilter);
    timeFilter = setTimeout(() => {
      console.log('[filtering]');
      const filtered = orders.filter(handleFilter);
      if(filtered.length < perPage) onLoad();
      setShowingOrders(filtered);
    },500);
  },[filters]);

  function handleFilter(order: OrderFormattedType){
    if(filters){
      if(filters.status){
        let filterStatus = filters.status;
        if(
          filterStatus.length > 0 &&
          !filterStatus.includes(
            order.stageSlug
          ) && !filterStatus.includes('Todos')
        ) return false;
      }
      if(filters.date && filters.date.startDate && filters.date.endDate){
        const formatFilterDateToTimestamp = (date: string) => {
          let [y,m,d] = date.split('-');
          let filterDate = [y, m.padStart(2, '0'), d.padStart(2,'0')].join('-');
          return (new Date(`${filterDate}T00:00:00.000`)).getTime();
        }
        
        try{
          // @ts-ignore
          let orderDate = (new Date(`${
            order.date.split('/').reverse().join('-')
          }T00:00:00.000`)).getTime();
  
          let filterDates = {
            start: formatFilterDateToTimestamp(filters.date.startDate as string),
            end: formatFilterDateToTimestamp(filters.date.endDate as string)
          };
          
          if(!(orderDate >= filterDates.start && orderDate <= filterDates.end)) return false;
        }catch(e){ console.error(e); }
      }
      if(filters.requester){
        let requesterLow = filters.requester.toLowerCase(); 
        if(
          !order.user.toLowerCase().includes(requesterLow) && 
          !order.userEmail.toLowerCase().includes(requesterLow)
        ) return false;
      }
      if(filters.numberPO){
        if(!order.poId.includes(filters.numberPO)) return false;
      }
      return true;
    }
  }
  return(
    <div className={`
      h-full min-h-[calc(20rem-3rem)]
      flex flex-col justify-between
      overflow-x-auto
    `}>
      <div>
        <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gradient-glass backdrop-blur-[25px]">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs  text-primary-800 uppercase bg-primary-500/5">
              <tr>
                <th className="px-3 py-2">
                  <input
                    type="checkbox"
                    id={`check-row-home-table`}
                    className={`${shortclass.checkbox.primary} bg-transparent border-2`}
                    onClick={(e) => {
                      let el = e.target as HTMLInputElement;
                      let isChecked = el.checked;
                      
                      let chs = document.querySelectorAll(`.check-row-home-table${
                        isChecked ? ':not(:checked)' : ':checked'
                      }`) as NodeListOf<HTMLInputElement>;

                      chs.forEach(ch => ch.checked = isChecked);

                      setHasSelectedForDelete(isChecked)
                    }}
                  />
                </th>
                <th className="px-3 py-2 font-bold">Nª da PO</th>
                <th className="px-3 py-2 font-bold hidden md:table-cell">Solicitante</th>
                <th className="px-3 py-2 font-bold">Etapa</th>
                <th className="px-3 py-2 font-bold">Descrição</th>
                <th className="px-3 py-2 font-bold">Criação</th>
                <th className="px-3 py-2 font-bold">Atualização</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {showingOrders.slice(
                pageIndex * perPage,
                (pageIndex + 1) * perPage
              ).map(order => (
                <tr className=" hover:brightness-90" key={order.id}>
                  <td className="px-3 py-4">
                    <input
                      type="checkbox"
                      id={`check-row-home-table-${order.id}`}
                      className={`check-row-home-table ${shortclass.checkbox.primary} bg-transparent border-2`}
                      data-name={order.poId}
                      value={order.id}
                      onClick={(e) => {
                        let hasAtLeast1Checked = document.querySelectorAll(`.check-row-home-table:checked`).length > 0;
                        let checkAll = document.getElementById(`check-row-home-table`) as HTMLInputElement;

                        if(hasAtLeast1Checked){
                          setHasSelectedForDelete(true);

                          checkAll.checked = document.querySelectorAll(
                            `.check-row-home-table:not(:checked)`
                          ).length === 0;
                        }
                        else{
                          setHasSelectedForDelete(false);
                          checkAll.checked = false;
                        }
                      }}
                    />
                  </td>                            

                  <td
                    className="px-3 py-4 cursor-pointer"
                    onClick={() => onDetails(order)}
                  >
                    {order.poId}
                    <span className="block uppercase text-[10px] leading-none">{order.type}</span>
                  </td>
                  <td
                    className="px-3 py-4 hidden md:table-cell cursor-pointer"
                    onClick={() => onDetails(order)}
                  >
                    {order.user}
                    <span className="block text-[10px] leading-none font-light text-gray-200">{ order.userEmail }</span>
                  </td>
                  <td
                    className="px-3 py-4 cursor-pointer"
                    onClick={() => onDetails(order)}
                  >
                    {order.stage}
                    {['reprovado','doc_reprovado'].includes(order.statusSlug) && (
                      <span className={`
                        inline-block ml-1 pt-0.5 px-1
                        uppercase text-[9px] leading-none font-semibold
                        border border-red-700/50 rounded-md
                        bg-red-50 text-red-900/80
                      `}>{order.status}</span>
                    )}
                  </td>
                  <td
                    className="px-3 py-4 cursor-pointer"
                    onClick={() => onDetails(order)}
                  >{order.description ?? <span className="text-xs">-- sem descrição --</span>}</td>
                  <td
                    className="px-3 py-4 cursor-pointer"
                    onClick={() => onDetails(order)}
                  >{order.date}</td>
                  <td
                    className="px-3 py-4 cursor-pointer"
                    onClick={() => onDetails(order)}
                  >{order.update}</td>
                  <td className="px-1 py-4">
                    <Dropdown
                      trigger={
                        <span className="hover-opacity">
                          <MoreVerticalIcon color="#fff9"/>
                        </span>
                      }
                      classNames={{ wrapper: "inline-block text-left" }}
                      orientation={null}
                      styles={{
                        list: { transform: 'translateX(-12rem)' }
                      }}
                    >
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => onDetails(order)}
                      >Detalhes</button>
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => navigate(`/documentos-para-pagamento/${order.id}`)}
                        disabled={!['aguardando_doc_para_pagamento','aguardando_validacao_do_financeiro'].includes(order.stageSlug)}
                      >Alterar Documentos</button>
                      <button
                        type="button"
                        className={shortclass.dropdownItem}
                        onClick={() => onDeleteItem(order.id, order.poId)}
                      >Excluir</button>
                    </Dropdown>
                  </td>
                </tr>
              ))}
              {showingOrders.length === 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="px-3 py-5 text-center"
                  >Nenhuma ordem encontrada {isFiltering && (<>com esses filtros</>)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <OrderTableFooter
        perPage={perPage}
        
        pageIndex={pageIndex}
        totalPages={totalPages}
        totalOrders={totalOrders}

        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}

        goToPage={goToPage}
        previousPage={previousPage}
        nextPage={nextPage}

        isFiltering={isFiltering}
      />
    </div>
  )
}
export const OrderTableHeader = ({ filters, onPageStart }:{
  filters: {
    status: string[] | undefined,
    setStatus: (param: any) => void,
    statusList: StatusType[] | undefined
  },
  onPageStart: () => void,
}) => {
  const headerStatusButtons = useRef<HTMLDivElement>(null);
  function handleChangeStage(status: StatusType){
    filters.setStatus((prevState: string[] | undefined) => {
      if(!prevState) return [status.slug];
      if(prevState.includes(status.slug)) return prevState.filter((s) => s !== status.slug);
      return [status.slug];
    });

    onPageStart();
  }
  return (
    <div className="relative">
      <button
        type="button" className="absolute top-0 left-0 bg-gray-100/25 rounded-lg opacity-50 hover:opacity-100 text-gray-600 h-full"
        onClick={() => {
          if(!headerStatusButtons.current) return;
          let el = headerStatusButtons.current;
          el.scrollLeft-= 224; // w-56
        }}
      ><ChevronLeftIcon/></button>
      <button
        type="button" className="absolute top-0 right-0 bg-gray-100/25 rounded-lg opacity-50 hover:opacity-100 text-gray-600 h-full"
        onClick={() => {
          if(!headerStatusButtons.current) return;
          let el = headerStatusButtons.current;
          el.scrollLeft+= 224; // w-56
        }}
      ><ChevronRightIcon/></button>
      <div
        className="flex gap-2 overflow-x-auto w-full p-1 mb-2 invisible-scroll"
        ref={headerStatusButtons}
      >
        {filters.statusList && filters.statusList.map(status => (
          <button
            type="button"
            className={`
              rounded-md border border-transparent 
              py-1 px-4
              text-xs font-semibold 
              focus:outline-none focus:ring-2 focus:ring-offset-2
              ${ filters.status && filters.status.includes(status.slug) ? 
                `text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700`:
                `text-gray-50 focus:ring-gray-200 bg-gray-300/50 hover:bg-gray-400/70`
              }
              whitespace-nowrap text-ellipsis
            `}
            onClick={() => handleChangeStage(status)}
            key={status.id}
          ><span className="w-56 block">{status.name}</span></button>
        ))}
      </div>
    </div>
  );
}
interface OrderTableFooterType{
  perPage: number,
  
  pageIndex: number,
  totalPages: number,
  totalOrders: number,

  canPreviousPage: boolean,
  canNextPage: boolean,

  goToPage: (param: number) => void,
  previousPage: () => void,
  nextPage: () => void,

  isFiltering: boolean
}
const OrderTableFooter = ({
  perPage,
  pageIndex, totalPages, totalOrders,
  canPreviousPage, canNextPage,
  goToPage, previousPage, nextPage,
  isFiltering = false
}: OrderTableFooterType) => (
  <div className="flex items-center justify-between  px-4 pt-2 pb-1 sm:px-6 mt-4">
    <div className="flex flex-1 justify-between sm:hidden">
      <button
        type="button"
        className="relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-primary-500 hover:bg-gray-50/20"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >Anterior</button>
      <button
        type="button"
        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-primary-500 hover:bg-gray-50/20"
        onClick={nextPage}
        disabled={!canNextPage}
      >Próximo</button>
    </div>
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        {!isFiltering && (
          <p className="text-sm text-gray-600">
            Mostrando{' '}
            <span className="font-medium">{(pageIndex * perPage) + 1}</span> à{' '} 
            <span className="font-medium">{
              (pageIndex + 1) * perPage < totalOrders ? (pageIndex + 1) * perPage : totalOrders
            }</span> de{' '}
            <span className="font-medium">{totalOrders}</span> resultados
          </p>
        )}
      </div>
      <div>
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            type="button"
            onClick={previousPage}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center rounded-l-md border border-gray-300/75 px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200/20 focus:z-20"
          >
            <span className="sr-only">Anterior</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <Pagination
            perPage={perPage}
            pageIndex={pageIndex}
            totalPages={totalPages}
            totalOrders={totalOrders}
            unloadedPages={Math.ceil(totalOrders / perPage) - totalPages}
            goToPage={goToPage}
          />
          <button
            type="button"
            className="relative inline-flex items-center rounded-r-md border border-gray-300/75 border-l-0 px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200/20 focus:z-20"
            onClick={nextPage}
            disabled={!canNextPage}
          >
            <span className="sr-only">Próximo</span>
            <ChevronRightIcon className="h-5 w-5"/>
          </button>
        </nav>
      </div>
    </div>
  </div>
)
const Pagination = ({perPage, pageIndex, totalPages, unloadedPages, goToPage} : {
  perPage: number,
  pageIndex: number,
  totalPages: number,
  totalOrders: number,
  unloadedPages: number
  goToPage: (param: number) => void
}) => {
  return (
    <>
      {Array.from({length: totalPages}).map((_,i) => (
        <button
          key={`page-${i}`}
          type="button"
          aria-current={pageIndex === i ? "page" : undefined}
          className={`
            relative inline-flex items-center border border-l-0 px-4 py-2 text-sm font-medium focus:z-20
            ${
              pageIndex === i ? 
              'z-10 border-primary-500/50 bg-primary-300/30 text-primary-600':
                'border-gray-300 hover:bg-gray-300/40 text-gray-500 hover:bg-gray-50'
            } 
          `}
          onClick={() => goToPage(i)}
        >{i+1}</button>
      ))}
      {unloadedPages >= 0 && Array.from({ length: unloadedPages }).map((_,i) => (
        <button
          key={`page-${i + totalPages}`}
          type="button"
          aria-current={pageIndex === i + totalPages ? "page" : undefined}
          className={`
            relative inline-flex items-center border border-l-0 px-4 py-2 text-sm font-medium focus:z-20
            ${
              pageIndex === i + totalPages ? 
              'z-10 border-primary-500/50 bg-primary-300/30 text-primary-600':
                'border-gray-300 hover:bg-gray-300/40 text-gray-500 hover:bg-gray-50'
            } 
          `}
          onClick={() => goToPage(i + totalPages)}
        >{i + totalPages + 1}</button>
      ))}
      {/* [3] ... [3]
      <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
        ...
      </span> */}
    </>
  );
};
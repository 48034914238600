import { shortclass } from "../../styles/styles";
import { Dropdown } from "../../shared-components/utils/Dropdown";
import { MoreVerticalIcon } from "../../shared-components/utils/icons";
import { CompanyType } from "../../types/conciliation.type";
import { useState } from "react";
import { ModalAddCompany } from "./ModalAddCompany";

interface CardManageCompanyProps{
  companies: CompanyType[],
  setCompanies: React.Dispatch<React.SetStateAction<CompanyType[]>>
}
export const CardManageCompany = ({ companies, setCompanies } : CardManageCompanyProps) => {
  const [isOpenModalAddCompany, setIsOpenModalAddCompany] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<CompanyType>();
  
  return (
    <div className="
      flex flex-col rounded-lg backdrop-blur-[25px] bg-white
      shadow-md dark:border-gray-700 dark:bg-gray-800 justify-between gap-2
      mb-6 w-full min-h-[20rem] pt-5 text-primary-800 overflow-hidden
    ">
      <div className="flex justify-between mr-3">
        <div className="flex items-center justify-between gap-2 px-6">
          <h5 className="text-lg font-semibold dark:text-white">Empresas</h5>
        </div>
      </div>
      {companies.length === 0 ? (
        <div className="text-gray-600 p-6 mt-2 text-[13px] text-center flex items-center justify-center bg-gray-50 h-full flex-1">
          <span className="max-w-[12rem] block">Você não possui nenhuma empresa</span>
        </div>
      ):(
        <div className="overflow-y-auto max-h-96 flex-1 ">
          {companies.map((company) => (
            <div
              className="relative border-b last:border-b-0 border-gray-200/50 hover:bg-gray-100/20 hover:rounded-lg cursor-pointer px-2 mx-4"
              key={company.nome_fantasia}
            >
              <div className="flex items-center py-1.5">
                <div className="flex-1 flex justify-between items-center">
                  <button type="button" className="flex flex-1 flex-col items-start hover:bg-gray-50/70 drop-shadow" onClick={() => {
                    setIsOpenModalAddCompany(true)
                    setCurrentCompany(company)
                  }}>
                    <span className="whitespace-nowrap text-gray-600">
                      {company.nome_fantasia}
                    </span>
                    {company.businessUnits && company.businessUnits.length > 0 && (
                      <span className="text-xs text-gray-500">
                        {company.businessUnits.length === 1 ? '1 unidade' : `${company.businessUnits.length} unidades`}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="w-full flex justify-center mb-4">
        <button
          onClick={() => {
            setCurrentCompany(undefined)
            setIsOpenModalAddCompany(true)
          }}
          className={`
            flex justify-center 
            rounded-lg border border-transparent 
            py-2 px-4
            text-sm font-medium 
            max-w-[180px]
            focus:outline-none focus:ring-2 focus:ring-offset-2
            text-white focus:ring-primary-500 bg-primary-600 hover:bg-primary-700
          `}
        >Adicionar Empresa</button>
      </div>
      <ModalAddCompany
        isOpen={isOpenModalAddCompany}
        setIsOpen={setIsOpenModalAddCompany}
        company={currentCompany}
        setCompany={setCurrentCompany}
        setCompanies={setCompanies}
      />
    </div>
  )
}
import { useEffect, useRef, useState } from "react";
import { Modal } from "../../shared-components/utils/Modal";
import { Label } from "flowbite-react";
import { useNotify } from "../../contexts/NotifyContext";
import { useAuth } from "../../contexts/AuthContext";
import Dropzone from "react-dropzone";
import { TrashIcon } from "../../shared-components/utils/icons";
import { appendClosingFolder } from "../../services/closingFolder";
import { size, uniqueId } from "lodash";
import { filesize } from "filesize";
import { api, handleErrorResultAndResponse, headerBearer } from "../../services/api";
import { FileList, FileListType } from "./FileList";

interface ModalCreateFileProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paths: string[];
  updateFolders: () => void
}

export const ModalCreateFile = ({
  isOpen,
  setIsOpen,
  paths,
  updateFolders
}: ModalCreateFileProps) => {
  const submitFormRef = useRef<HTMLButtonElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileListType[]>([]);
  const { user } = useAuth();
  const { toast } = useNotify();

  const resetState = () => {
    setSelectedFiles([]);
  };

  function handleUpload(selectedFiles: File[]) {
    const uploadedFiles: FileListType[] = selectedFiles.map<FileListType>(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size) as string,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      size: file.size
    }));

    setSelectedFiles(prevState => [
      ...prevState,
      ...uploadedFiles
    ])

    uploadedFiles.forEach(processUpload);
  }
  async function processUpload(uploadedFile: FileListType) {
    if (!uploadedFile.file) return;
    if (!user) return;

    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('name', 'Fechamentos Financeiros');
    data.append('external_id', 'Fechamentos Financeiros');
    data.append('type', 'personal');
    try {
      const { data: response } = await api.post("/gallery", data, {
        ...headerBearer(user.token),
        onUploadProgress: e => {
          const progress = e.total ? Math.round(
            (e.loaded * 100) / e.total
          ) : 0;

          setSelectedFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
            ...file,
            progress
          } : file));
        }
      });

      if (!response.result) throw new Error(
        response.response
      );

      setSelectedFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
        ...file,
        uploaded: true,
        id: response.data.id,
        url: `${process.env.REACT_APP_BASE_URL}${response.data.src}`,
      } : file));

    } catch (err) {
      const error = handleErrorResultAndResponse(err, {
        result: false,
        response: `Não foi possível fazer o upload do arquivo ${uploadedFile.name}`
      });

      toast.error(error.response);

      setSelectedFiles((prevState) => prevState.map((file) => file.id === uploadedFile.id ? {
        ...file,
        error: true
      } : file));
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!user) return;

    if (paths.length === 0) {
      toast.error("Não é possível criar arquivos na raiz");
      return;
    }

    if(selectedFiles.some(file => !file.uploaded && !file.error)){
      toast.error("Ainda existe arquivos em processo de upload")
      return
    }
    const filePayload = selectedFiles.filter((file) => !!file.url).map((file) => ({
      type: "file" as const,
      src: file.url!,
      size: file.size,
      title: file.name,
    }));

    if(filePayload.length === 0){
      toast.error("Não existe nenhum arquivo para adicionar a pasta.")
      return
    }
    const res = await appendClosingFolder(paths, filePayload, user.token);

    if (res.result) {
      toast.success(res.response);
      updateFolders();
      setIsOpen(false);
    } else {
      toast.error(res.response);
    }
  }

  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);

  const handleDeleteSelectedFile = (id: string) => {
    const updatedFiles = selectedFiles.filter(file => file.id !== id);
    setSelectedFiles(updatedFiles);
  };


  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{
        title: "Criar Arquivo",
        size: "sm:w-full sm:max-w-md",
        cancelButton: true,
        cancelButtonText: "Cancelar",
        actionButton: {
          theme: "primary",
          text: "Finalizar",
          onClick: () => {
            if (submitFormRef.current) submitFormRef.current.click();
          },
          autoClose: false,
        },
      }}
    >
      <form className="mb-4" onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={submitFormRef} />
        <div className="mb-2">
          <Label
            className="text-xs pt-3 pb-1 text-gray-700 focus:outline-none"
            value="Insira o arquivo que deseja para sua pasta:"
          />
        </div>
        <Dropzone onDropAccepted={handleUpload}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              {...getRootProps()}
              className={`
              dropzone
              border border-dashed 
              ${isDragActive ? 'border-emerald-600' : isDragReject ? 'border-red-400' : 'border-gray-400'}
              rounded cursor-pointer
            `}
              style={{ transition: "height 0.2s ease" }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className="flex justify-center items-center text-sm py-8 text-emerald-600/80">Solte os arquivos aqui</div>
              ) : isDragReject ? (
                <div className="flex justify-center items-center text-sm py-8 text-red-500/80">Arquivo não suportado</div>
              ) : (
                <div className="flex justify-center items-center text-sm py-8 text-gray-500/80">Arraste arquivos aqui...</div>
              )}
            </div>
          )}
        </Dropzone>
        {selectedFiles.length > 0 && <FileList files={selectedFiles} onDelete={handleDeleteSelectedFile} />}
      </form>
    </Modal>
  );
};

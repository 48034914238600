import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
// COMPONENTS
import { Loading } from '../../components/Loading';
import { OrderDetails } from '../../components/Order/OrderDetails';
import { OrderTable, OrderTableHeader } from '../../components/Order/OrderTable';
import { Wrapper } from '../../shared-components/Wrapper';
import { useAuth } from '../../contexts/AuthContext';
import { useNotify } from '../../contexts/NotifyContext';
// SERVICES
import { deleteOrder, formatRows, getOrders } from '../../services/order';
import { getStages } from '../../services/stage';
import { OrderFormattedType, StatusType } from '../../types';
import { Filter } from '../../components/Filter';

interface CacheRequestTableType{
  token: string,
  filters: any,
  excludeIds: string[],
  hasError?: boolean
}
const cacheRequestTable : CacheRequestTableType[] = [];
export const PaymentModule = () => {
  const { user } = useAuth();
  const { toast, showMessage } = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderFormattedType>();

  const [orders, setOrders] = useState<OrderFormattedType[]>([]);
  const [totalOrdersInDB, setTotalOrdersInDB] = useState(0);
  
  // #region HANDLE FILTER
  const [filterStatus, setFilterStatus] = useState<Array<string> | undefined>(undefined);
  const [filterDate, setFilterDate] = useState<DateValueType>({
    startDate: null,
    endDate: null
  });

  const [filterRequester, setFilterRequester] = useState('');
  const [filterNumberPO, setFilterNumberPO] = useState('');
  // #endregion HANDLE FILTER
  
  const [stageList, setStageList] = useState<StatusType[]>();

  // #region PAGINATION
  const [pageIndex, setPageIndex] = useState(0);
  const [perPage, _] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const goToPage = async (index: number) => {
    let totalPagesInDB = Math.ceil(totalOrdersInDB / perPage);

    if(index >= totalPagesInDB || index < 0) return;
    if(index >= totalPages) await loadOrders();
  
    setPageIndex(() => index);
  }
  // #endregion PAGINATION
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasSelectedForDelete, setHasSelectedForDelete] = useState(false);

  useEffect(() => {
    if (!user) return;
    console.log('[init-table-orders]');
    let poId = searchParams.get('poId');
    loadOrders(true, poId);
    if(poId) setSearchParams(undefined);
    findStatus(user.token);
  }, [user]);
  useEffect(() => {
    if(!orders) return;
    
    let poId = searchParams.get('poId');
    if(poId){
      let findedPO = orders.find(po => po.poId === poId);
      if(findedPO) handleShowDetails(findedPO);
      setSearchParams(undefined);
    }
  }, [orders]);
  useEffect(() => {
    setIsFiltering(!!(
      filterDate?.startDate || filterDate?.endDate || 
      filterNumberPO || 
      filterRequester || 
      (filterStatus && filterStatus.filter(s => s != 'Todos').length > 0)
    ));
  },[filterDate, filterNumberPO, filterRequester, filterStatus]);

  async function findStatus(token: string){
    try{
      setLoading(true);
    
      const responseStatus = await getStages(token);
      
      setStageList(responseStatus.sort((a,b) => {return a.step-b.step}));
      setLoading(false);
    }catch(error){
      console.error(error);
    }
  }
  async function loadOrders(force: boolean = false, poId: string | null = null){
    if(!user) return;
    if(orders && orders.length >= totalOrdersInDB && !force) return;

    console.log('[load-orders]');
    let excludedIds = !orders || force ? undefined : orders.map(o => o.id);
    const formatFilterDateIfExists = (date: string | undefined) => {
      if(!date) return undefined;
      let [y,m,d] = date.split('-');
      let filterDate = [y, m.padStart(2, '0'), d.padStart(2,'0')].join('-');
      return filterDate;
    }
    const options = {
      filter_date_start: formatFilterDateIfExists(filterDate?.startDate as string),
      filter_date_end: formatFilterDateIfExists(filterDate?.endDate as string),
      excludedIds,
      filter_num_po: filterNumberPO && filterNumberPO.length > 0  ? filterNumberPO : undefined,
      filter_status: filterStatus ?? undefined,
      filter_user: filterRequester && filterRequester.length > 0 ? filterRequester : undefined
    };

    const optionsStringified = JSON.stringify(options);
    try{
      setLoading(true);
      if(cacheRequestTable.length > 0){
        let finded = cacheRequestTable.find(cached => (
          cached.filters == optionsStringified &&
          cached.excludeIds.join(',') === excludedIds?.join(',') &&
          cached.token === user.token
        ));
        if(finded){
          console.log('[orders-already-requested]');
          setLoading(false);
          return;
        }
      }

      const {response, count} = await getOrders(user.token, options);
      console.log('[request-orders]');

      cacheRequestTable.push({
        excludeIds: [
          ...excludedIds ?? [],
          ...response.map(r => r.id)
        ],
        filters: optionsStringified,
        token: user.token,
      });

      if(totalOrdersInDB !== count) setTotalOrdersInDB(count);
      const parsedOrders = formatRows(response);

      if(poId){
        let findedPO = parsedOrders.find(po => po.poId === poId);
        if(findedPO) handleShowDetails(findedPO);
      }

      if(parsedOrders.length > 0) setOrders((prevState) => {
        if(!prevState || force) return parsedOrders;

        let excludedIds = prevState.map((ps) => ps.id);
        parsedOrders.forEach((parsed) => {
          if(!excludedIds.includes(parsed.id)){
            prevState.push(parsed);
          }
        });

        return prevState;
      });
      else if(force) setOrders([]);

      setLoading(false);
    }catch(err){
      console.error(err);
      toast.error('Houve um erro ao carregar os dados da tabela');

      cacheRequestTable.push({
        excludeIds: [
          ...excludedIds ?? [],
        ],
        filters: optionsStringified,
        token: user.token,
        hasError: true
      });

      if(force) setOrders([]);
      setLoading(false);
    }
    
    if(force) goToPage(0);
  }
  function handleShowDetails(order: OrderFormattedType){
    setCurrentOrder(order);
    setDetailsIsOpen(true);
  }
  function handleUpdateOrder(order: OrderFormattedType){
    setCurrentOrder(order);
    setOrders((orders) => {
      if(!orders) return orders;

      let index = orders?.findIndex(o => o.id === order.id);
      if(index > -1) orders[index] = order;

      return orders;
    });
  }
  function handleCloseOrderDetails(){
    setCurrentOrder(undefined);
    setDetailsIsOpen(false);
  }
  // #region HANDLE DELETE
  let inDeleteMultiple = false;
  async function handleDeleteMultiple(){
    if(!user) return;

    if(inDeleteMultiple){
      toast.warning('Já existe uma fila de exclusão em andamento. Aguarde a finalização para iniciar uma nova.');
      return;
    }
    let checkeds = Array.from(
      document.querySelectorAll('.check-row-home-table:checked') as NodeListOf<HTMLInputElement>
    );
    let total = checkeds.length;
    if(total === 0){
      toast.warning('Selecione pelo menos 1 item na tabela');
      return;
    }

    const token = user.token;
    if(!token){
      toast.error('Houve um erro ao recuperar seu token de acesso');
      return;
    }

    const makeDeletion = async () => await toast.promise(new Promise(async (resolve) => {
      inDeleteMultiple = true;
      try{
        let el = document.getElementById('toast-control-queue-delete-multiple');
        do{
          let check = checkeds.shift();
          if(check){
            let id = check.value;
            let po = check.dataset.name ?? '';
            await handleDeleteItem(id, po, token, false);
          }
          if(el){
            el.innerHTML = String(total - checkeds.length);
          }
        }while(checkeds.length > 0);
        let chs = document.querySelectorAll('.check-row-home-table:checked') as NodeListOf<HTMLInputElement>;
        chs.forEach(ch => ch.checked = false);
        resolve('success');

        inDeleteMultiple = false;
      }catch(e){
        console.error(e);
        inDeleteMultiple = false;
      }
      setHasSelectedForDelete(false);
    }),{
      pending: {
        render(){
          return <>
            <span>Excluindo...</span><br/>
            <span id="toast-control-queue-delete-multiple">0</span> de {total}
          </>;
        }
      }
    });

    showMessage((
      <p>Tem certeza que deseja excluir {
        total === 1 ? 'esse registro?' : `${total} registros?`
      }</p>
    ),{
      title: 'Confirmar Exclusão',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeDeletion,
        text: 'Sim',
        theme: 'red'
      }
    });    
  }
  async function handleDeleteItem(
    id: string,
    po: string,
    token: string | null = null,
    confirm: boolean = true
  ){
    if(!user) return;
    
    if(!token){
      token = user.token

      if(!token){
        toast.error('Houve um erro ao recuperar seu token de acesso');
        return;
      }
    }

    const makeDeletion = async () => {
      let data = await deleteOrder(id, token ?? user.token, po);
            
      if(data.result){
        toast.success(data.response);
        setOrders((prevState) => {
          if(!prevState) return prevState;
          return prevState.filter(o => o.id !== id);
        });

        if(currentOrder) handleCloseOrderDetails();
      }
      else toast.error(data.response);
    }

    if(confirm) showMessage((<p>Tem certeza que deseja excluir esse registro?</p>),{
      title: 'Confirmar Exclusão',
      cancelButtonText: 'Não',
      actionButton: {
        onClick: makeDeletion,
        text: 'Sim',
        theme: 'red'
      }
    });
    else makeDeletion();
  }
  // #endregion HANDLE DELETE
  
  if(loading) return <Loading></Loading>;
  return(
    <Wrapper
      asideActive={["Contas a Pagar", "Contas a Pagar"]}
      breadcrumbs={[{
        'name': 'Contas a Pagar',
        'href': '#'
      }]}
      module_name="Ivrim Flows"
    >
      <div className="w-full min-h-full">
        <div className="rounded-lg min-h-[20rem]">
          <Filter {...{
            stageList, 
            filterStatus, filterDate, filterRequester, filterNumberPO,
            setFilterStatus, setFilterDate, setFilterRequester, setFilterNumberPO,
            isFiltering, handleDeleteMultiple, hasSelectedForDelete
          }}/>
          <OrderTable
            filters={{
              status: filterStatus,
              setStatus: setFilterStatus, 
              date: filterDate,
              requester: filterRequester,
              numberPO: filterNumberPO,
              statusList: stageList,
            }}
            orders={orders}
            totalOrders={totalOrdersInDB}
            onDeleteItem={handleDeleteItem}
            onDetails={handleShowDetails}
            onLoad={loadOrders}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            goToPage={goToPage}
            perPage={perPage}
            isFiltering={isFiltering}
            setHasSelectedForDelete={setHasSelectedForDelete}
          />
        </div>
      </div>
      <OrderDetails
        isOpen={detailsIsOpen}
        onClose={handleCloseOrderDetails}
        order={currentOrder}
        onChange={handleUpdateOrder}
        onDelete={handleDeleteItem}
        
      />
    </Wrapper>
  )
}
// REGRAS DE MASCARA https://imobiliarias.superlogica.com/hc/pt-br/articles/360042873194-Padr%C3%A3o-de-contas-banc%C3%A1rias-Split-de-pagamentos
export const cpfOrCnpjMask = (value: string) => {
  let clearValue = value.replace(/[^0-9]/g,'');

  if(clearValue.length <= 11) return cpfMask(value);
  return cnpjMask(value);
}
export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export const cnpjMask = (value: string) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
}
export const phoneMask = (value: string) => {
  if (!value) return ""
  value = value.substr(0, 15)
  value = value.replace(/\D/g,'')
  value = value.replace(/(\d{2})(\d)/,"($1) $2")
  value = value.replace(/(\d)(\d{4})$/,"$1-$2")
  return value.substr(0, 15);
}
export const availableBanks = [
    { code: '001', name: 'Banco do Brasil'},
    { code: '003', name: 'Banco da Amazônia'},
    { code: '004', name: 'Banco do Nordeste'},
    { code: '021', name: 'Banestes'},
    { code: '025', name: 'Banco Alfa'},
    { code: '027', name: 'Besc'},
    { code: '029', name: 'Banerj'},
    { code: '031', name: 'Banco Beg'},
    { code: '033', name: 'Banco Santander Banespa'},
    { code: '036', name: 'Banco Bem'},
    { code: '037', name: 'Banpará'},
    { code: '038', name: 'Banestado'},
    { code: '039', name: 'BEP'},
    { code: '040', name: 'Banco Cargill'},
    { code: '041', name: 'Banrisul'},
    { code: '044', name: 'BVA'},
    { code: '045', name: 'Banco Opportunity'},
    { code: '047', name: 'Banese'},
    { code: '062', name: 'Hipercard'},
    { code: '063', name: 'Ibibank'},
    { code: '065', name: 'Lemon Bank'},
    { code: '066', name: 'Banco Morgan Stanley Dean Witter'},
    { code: '069', name: 'BPN Brasil'},
    { code: '070', name: 'Banco de Brasília – BRB'},
    { code: '072', name: 'Banco Rural'},
    { code: '073', name: 'Banco Popular'},
    { code: '074', name: 'Banco J. Safra'},
    { code: '075', name: 'Banco CR2'},
    { code: '076', name: 'Banco KDB'},
    { code: '077', name: 'Banco Inter'},
    { code: '096', name: 'Banco BMF'},
    { code: '104', name: 'Caixa Econômica Federal'},
    { code: '107', name: 'Banco BBM'},
    { code: '116', name: 'Banco Único'},
    { code: '151', name: 'Nossa Caixa'},
    { code: '175', name: 'Banco Finasa'},
    { code: '184', name: 'Banco Itaú BBA'},
    { code: '204', name: 'American Express Bank'},
    { code: '208', name: 'Banco Pactual'},
    { code: '212', name: 'Banco Matone'},
    { code: '213', name: 'Banco Arbi'},
    { code: '214', name: 'Banco Dibens'},
    { code: '217', name: 'Banco Joh Deere'},
    { code: '218', name: 'Banco Bonsucesso'},
    { code: '222', name: 'Banco Calyon Brasil'},
    { code: '224', name: 'Banco Fibra'},
    { code: '225', name: 'Banco Brascan'},
    { code: '229', name: 'Banco Cruzeiro'},
    { code: '230', name: 'Unicard'},
    { code: '233', name: 'Banco GE Capital'},
    { code: '237', name: 'Bradesco'},
    { code: '241', name: 'Banco Clássico'},
    { code: '243', name: 'Banco Stock Máxima'},
    { code: '246', name: 'Banco ABC Brasil'},
    { code: '248', name: 'Banco Boavista Interatlântico'},
    { code: '249', name: 'Investcred Unibanco'},
    { code: '250', name: 'Banco Schahin'},
    { code: '252', name: 'Fininvest'},
    { code: '254', name: 'Paraná Banco'},
    { code: '263', name: 'Banco Cacique'},
    { code: '265', name: 'Banco Fator'},
    { code: '266', name: 'Banco Cédula'},
    { code: '300', name: 'Banco de la Nación Argentina'},
    { code: '318', name: 'Banco BMG'},
    { code: '320', name: 'Banco Industrial e Comercial'},
    { code: '356', name: 'ABN Amro Real'},
    { code: '341', name: 'Itau'},
    { code: '347', name: 'Sudameris'},
    { code: '351', name: 'Banco Santander'},
    { code: '353', name: 'Banco Santander Brasil'},
    { code: '366', name: 'Banco Societe Generale Brasil'},
    { code: '370', name: 'Banco WestLB'},
    { code: '376', name: 'JP Morgan'},
    { code: '389', name: 'Banco Mercantil do Brasil'},
    { code: '394', name: 'Banco Mercantil de Crédito'},
    { code: '399', name: 'HSBC'},
    { code: '409', name: 'Unibanco'},
    { code: '412', name: 'Banco Capital'},
    { code: '422', name: 'Banco Safra'},
    { code: '453', name: 'Banco Rural'},
    { code: '456', name: 'Banco Tokyo Mitsubishi UFJ'},
    { code: '464', name: 'Banco Sumitomo Mitsui Brasileiro'},
    { code: '477', name: 'Citibank'},
    { code: '479', name: 'Itaubank (antigo Bank Boston)'},
    { code: '487', name: 'Deutsche Bank'},
    { code: '488', name: 'Banco Morgan Guaranty'},
    { code: '492', name: 'Banco NMB Postbank'},
    { code: '494', name: 'Banco la República Oriental del Uruguay'},
    { code: '495', name: 'Banco La Provincia de Buenos Aires'},
    { code: '505', name: 'Banco Credit Suisse'},
    { code: '600', name: 'Banco Luso Brasileiro'},
    { code: '604', name: 'Banco Industrial'},
    { code: '610', name: 'Banco VR'},
    { code: '611', name: 'Banco Paulista'},
    { code: '612', name: 'Banco Guanabara'},
    { code: '613', name: 'Banco Pecunia'},
    { code: '623', name: 'Banco Panamericano'},
    { code: '626', name: 'Banco Ficsa'},
    { code: '630', name: 'Banco Intercap'},
    { code: '633', name: 'Banco Rendimento'},
    { code: '634', name: 'Banco Triângulo'},
    { code: '637', name: 'Banco Sofisa'},
    { code: '638', name: 'Banco Prosper'},
    { code: '643', name: 'Banco Pine'},
    { code: '652', name: 'Itaú Holding Financeira'},
    { code: '653', name: 'Banco Indusval'},
    { code: '654', name: 'Banco A.J. Renner'},
    { code: '655', name: 'Banco Votorantim'},
    { code: '707', name: 'Banco Daycoval'},
    { code: '719', name: 'Banif'},
    { code: '721', name: 'Banco Credibel'},
    { code: '734', name: 'Banco Gerdau'},
    { code: '735', name: 'Banco Pottencial'},
    { code: '738', name: 'Banco Morada'},
    { code: '739', name: 'Banco Galvão de Negócios'},
    { code: '740', name: 'Banco Barclays'},
    { code: '741', name: 'BRP'},
    { code: '743', name: 'Banco Semear'},
    { code: '745', name: 'Banco Citibank'},
    { code: '746', name: 'Banco Modal'},
    { code: '747', name: 'Banco Rabobank International'},
    { code: '748', name: 'Banco Cooperativo Sicredi'},
    { code: '749', name: 'Banco Simples'},
    { code: '751', name: 'Dresdner Bank'},
    { code: '752', name: 'BNP Paribas'},
    { code: '753', name: 'Banco Comercial Uruguai'},
    { code: '755', name: 'Banco Merrill Lynch'},
    { code: '756', name: 'Banco Cooperativo do Brasil'},
    { code: '757', name: 'KEB'}
];
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getPublishedFlows } from "../../../shared-components/services/workflow"
import { useNotify } from "../../../contexts/NotifyContext";
import { MoneyIcon, WorkflowIcon } from "../../../shared-components/utils/icons";
import { WorkflowType } from "../../../shared-types";
import { shortclass } from "../../../styles/styles";
import { NotificationToType } from "../../../shared-types/notification.type";

interface FSSelectWorkflowProps{
  toType?: NotificationToType,
  flowId?: string,
  setFlowId: React.Dispatch<React.SetStateAction<string | undefined>>
}
export const FSSelectWorkflow = ({ toType, flowId, setFlowId }:FSSelectWorkflowProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [workflows, setWorkflows] = useState<WorkflowType[]>([]);

  useEffect(() => { loadWorkflow() },[user, toType])

  async function loadWorkflow(){
    if(!user) return;

    const res = await getPublishedFlows(user.token);

    if(!res.result){
      toast.error(res.response)
      return;
    }

    if(!res.data) return;

    setWorkflows(
      toType === 'flow_perms' ? res.data : res.data.filter((wf) => !!wf.resume?.auth)
    )
  }
  return (
    <div className="mb-4">
      <h3 className="mb-4 text-lg font-semibold">Selecione o Workflow:</h3>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
        {workflows.map((workflow) => (
          <div className="bg-gradient-light rounded-lg px-4 pt-3 pb-4 text-gray-700 flex flex-col" key={workflow._id}>
            <header className="flex gap-2 items-center justify-between text-xs">
              <span className="flex items-center text-primary-700 font-semibold gap-1">
                {
                  workflow.theme === 'Cobrança' ? <MoneyIcon w={12} h={12}/> :
                  workflow.theme === 'Comercial' ? <WorkflowIcon w={12} h={12}/> : 
                  workflow.theme === 'Financeiro' ? <MoneyIcon w={12} h={12}/> : ''
                } {workflow.theme}
              </span>

              {!!workflow.resume?.auth && (
                <span className="
                  border border-primary-300/20 rounded-lg bg-primary-300/10 px-2
                  text-[10px] lowercase font-semibold text-primary-400
                ">
                  Tem Autenticação
                </span>
              )}
            </header>
            <div 
              className="text-sm pb-4 pt-2 flex-1 cursor-pointer"
              onClick={() => setFlowId(workflow._id)}
            >
              <div>
                <strong className="text-primary-800">Título: </strong>
                <span className="text-primary-400">{workflow.title}</span>
              </div>
              <div>
                <strong className="text-primary-800">Descrição: </strong>
                <span className="text-primary-400">{workflow.description}</span>
              </div>
            </div>
            {flowId === workflow._id ? (
              <button type="button" className={`
                ${shortclass.button.primary}
                font-semibold text-xs py-1 uppercase
              `}>
                Selecionado
              </button>
            ):(
              <button type="button" onClick={() => setFlowId(workflow._id)} className={`
                ${shortclass.button.normal}
                !border-gray-50 bg-primary-300/10 text-primary-800/70 font-semibold text-xs py-1 uppercase
              `}>
                Selecionar
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
import { useEffect, useState } from "react";
import { ShortUser } from "../../../shared-types";
import { getClient } from "../../../services/client";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { searchUserByEmail } from "../../../services/user";
import { NotificationToType } from "../../../shared-types/notification.type";
import { Radio, TextInput } from "flowbite-react";
import { shortclass } from "../../../styles/styles";
import { CloseIcon, InfoIcon, SearchIcon } from "../../../shared-components/utils/icons";
import { getFlowAuths } from "../../../shared-components/services/workflow";

interface UserWithThisClient extends ShortUser{
  is_from_this_client?: boolean,
  is_flow_auth?: boolean,
  /** Só existe em usuários do workflow */
  auth_email?: string
}
interface FSSelectUserProps{
  isHub: boolean,
  flowId?: string,
  toType?: NotificationToType,
  userIds: string[],
  setUserIds: React.Dispatch<React.SetStateAction<string[]>>,
  setIgnoreClientId: React.Dispatch<React.SetStateAction<boolean>>,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}
export const FSSelectUser = ({ isHub, userIds, setIgnoreClientId, setUserIds, isLoading, setIsLoading, flowId, toType }:FSSelectUserProps) => {
  const { user } = useAuth();
  const { toast } = useNotify();

  const [mode, setMode] = useState<'client' | 'hub' | 'flow'>();
  const [availableUsers, setAvailableUsers] = useState<UserWithThisClient[]>();
  const [filterNameOrEmail, setFilterNameOrEmail] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [showAvailableUsers, setShowAvailableUsers] = useState<UserWithThisClient[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(toType === 'flow_auth_ids') setMode('flow')
    else if(!isHub) setMode('client')
  },[isHub, toType])

  useEffect(() => {
    if(mode) setIgnoreClientId(mode === 'hub')
    setUserIds([])
  },[mode])

  useEffect(() => {
    if(user) loadUsers()
  },[user, mode])

  useEffect(() => {
    setShowAvailableUsers(handleFilter(
      availableUsers ? (
        mode === 'flow' ? availableUsers.filter((user) => !!user.is_flow_auth) : (
          isHub && mode === 'hub' ? availableUsers.filter((user) => !user.is_flow_auth) : availableUsers.filter(
            (user) => !!user.is_from_this_client && !user.is_flow_auth
          )
        )
      ):[]
    ))
  },[availableUsers, setAvailableUsers, isHub, mode, filterNameOrEmail])

  function handleFilter(users: UserWithThisClient[]){
    if(filterNameOrEmail) return users.filter((available) => available.name.toLowerCase().includes(
      filterNameOrEmail.toLowerCase()
    ) || (
      mode === 'flow' ? (available.auth_email ?? '').toLowerCase().includes(
        filterNameOrEmail.toLowerCase()
      ) : available.email.toLowerCase().includes(
        filterNameOrEmail.toLowerCase()
      )
    ))

    return users
  }
  async function loadUsers(){
    if(!user) return;

    if(mode === 'flow'){
      loadFlowAuths(true)
      return;
    }

    const client = await getClient(user.token)
    if(client && client.users){
      setAvailableUsers(
        client.users.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          picture: user.picture,
          whatsapp: user.whatsapp,
          is_from_this_client: true,
          is_flow_auth: false
        }))
      )
      setTotal(client.users.length)
    }
  }
  async function loadFlowAuths(reset = false, search = false){
    if(!user || isLoading) return;
    if(!flowId){
      toast.error('Selecione primeiro o fluxo que deseja buscar')
      return;
    }
    if(!reset && availableUsers && availableUsers.length === total) return;
    if(search){
      if(reset){
        toast.error('Não é possível fazer uma busca em uma inicialização')
        return;
      }
      if(!searchEmail){
        toast.error('Digite uma chave de login válida para poder buscar')
        return;
      }
    }
    
    setIsLoading(true)
    await (async () => {
      const exclude_ids = reset || !availableUsers ? []: availableUsers.map((available) => available.id)
      const res = await getFlowAuths(user.token, flowId, exclude_ids, search  ? searchEmail : undefined)
      if(!res.result){
        toast.error(res.response)
        return;
      }
      if(!res.data) return;

      const newUsers = res.data.users.map((flowAuth) => ({
        id: flowAuth._id,
        name: flowAuth.name,
        email: flowAuth.email ?? '',
        picture: flowAuth.picture ?? 'https://ivrim.tech/api/uploads/galleries/default-user.png',
        whatsapp: flowAuth.whatsapp,
        is_from_this_client: true,
        is_flow_auth: true,
        auth_email: flowAuth.auth_email
      }))

      setAvailableUsers((prevState) => {
        if(reset) return newUsers

        const ids = newUsers.map((newUser) => newUser.id)
        return [
          ...(prevState ? prevState.filter((state) => !ids.includes(state.id)) : []),
          ...newUsers
        ]
      })
      setTotal(res.data.total)

      if(search){
        setFilterNameOrEmail(searchEmail)
        setSearchEmail('')
      }
    })()
    setIsLoading(false)
  }
  async function searchUser(){
    if(!user || isLoading) return;

    setIsLoading(true)
    await (async () => {
      if(mode === 'client' || (mode === 'hub' && !isHub)){
        toast.error('A busca só pode ser realizada para usuários do hub, quando você tem permissão de administração da plataforma')
        return;
      }
  
      if(mode !== 'flow'){
        const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if(!regexEmail.test(searchEmail)){
          toast.error('Digite um email válido')
          return;
        }
      }

      const findedEmailOnLocal = (availableUsers ?? []).find((available) => available[
        mode === 'flow' ? 'auth_email' : 'email'
      ] === searchEmail)
      if(findedEmailOnLocal){
        setFilterNameOrEmail(searchEmail)
        return
      }
  
      if(mode === 'flow'){
        loadFlowAuths(false, true)
        return;
      }

      const data = await searchUserByEmail(searchEmail, user.token)
      if(!data.result){
        toast.error(data.response)
        return
      }
  
      setFilterNameOrEmail(searchEmail)
      setSearchEmail('')
  
      if(data.data) setAvailableUsers((prevState) => {
        const newState = [
          ...(prevState ?? []), {
            email: data.data!.email,
            id: data.data!.id,
            name: data.data!.name,
            picture: data.data!.picture,
            whatsapp: data.data!.whatsapp,
            is_from_this_client: false
          }
        ]

        setTotal((prevState) => {
          if(prevState < newState.length) return newState.length;
          return prevState
        })
        
        return newState;
      })
    })()
    setIsLoading(false)
  }

  if(!mode) return (
    <div className="mb-4">
      <h3 className="mb-4 text-lg font-semibold">Quais usuários deseja selecionar?</h3>
      <div className="flex flex-col gap-3">
        <label className="text-sm text-gray-600 flex items-center gap-2">
          <Radio
            onChange={() => setMode('client')}
            checked={mode === 'client'}
          />
          <span className="pb-0.5">Apenas da minha empresa</span>
        </label>
        <label className="text-sm text-gray-600 flex items-center gap-2">
          <Radio
            onChange={() => setMode('hub')}
            checked={mode === 'hub'}
          />
          <span className="pb-0.5">Qualquer usuário do hub</span>
        </label>
      </div>
    </div>
  )

  return (
    <div className="mb-4">
      <div className="mb-4 flex flex-col sm:flex-row gap-0.5 justify-between sm:items-center">
        <h3 className="text-lg font-semibold">Selecione os usuários:</h3>
        {(isHub && mode !== 'flow') && (
          <div className="flex gap-3">
            <label className="text-sm text-gray-600 flex items-center gap-2">
              <Radio
                onChange={() => setMode('client')}
                checked={mode === 'client'}
              />
              <span className="pb-0.5">da empresa</span>
            </label>
            <label className="text-sm text-gray-600 flex items-center gap-2">
              <Radio
                onChange={() => setMode('hub')}
                checked={mode === 'hub'}
              />
              <span className="pb-0.5">do hub</span>
            </label>
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-2">
        <div className="relative flex-1">
          <TextInput
            type="text"
            value={filterNameOrEmail}
            onChange={(e) => setFilterNameOrEmail(e.target.value)}
            placeholder={
              mode === 'flow' ? 'Filtrar por nome ou chave de login':'Filtrar por nome ou email'}
          />
          {filterNameOrEmail.length > 0 ? (
            <button
              type="button"
              className="absolute inset-y-0 right-2 my-auto text-gray-700 hover:text-gray-500"
              onClick={() => setFilterNameOrEmail('')}
            ><CloseIcon w={18} h={18}/></button>
          ) : (
            <SearchIcon
              className="absolute inset-y-0 right-2 my-auto text-gray-700"
              w={18}
              h={18}
            />
          )}
        </div>
        {mode !== 'client' && (
          <div className="flex-1 flex flex-col sm:flex-row">
            <div className="relative flex-1">
              {mode === 'flow' && (
                <button
                  type="button"
                  className="absolute inset-y-0 left-2 my-auto text-gray-600 hover:text-gray-500"
                  onClick={() => toast.info(
                    <p>
                      Chave de login é o que o usuário usa para logar na aplicação, e pode variar em cada fluxo.<br/><br/>
                      Em alguns fluxos pode ser email, outros CPF/CNPJ, ou telefone, etc.
                    </p>
                  )}
                ><InfoIcon w={18} h={18}/></button>
              )}
              <input
                type={"email"}
                value={searchEmail}
                className={`
                  block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900
                  focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400
                  dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm
                  max-sm:rounded-b-none sm:rounded-r-none ${mode === 'flow' ? 'pl-7':''}
                `}
                onChange={(e) => setSearchEmail(e.target.value)}
                placeholder={mode === 'flow' ? "Buscar outros usuários por chave de login" : "Buscar outros usuários por email"}
              />
            </div>
            <button
              type="button"
              className={`
                ${shortclass.button.primary}
                max-sm:rounded-t-none sm:rounded-l-none
              `}
              onClick={searchUser}
            >Buscar</button>
          </div>
        )}
      </div>
      {showAvailableUsers.length === 0 ? (
        <p className="text-center text-sm text-gray-500 w-full flex-1 bg-gray-300/30 rounded-lg py-14 mt-4">
          {filterNameOrEmail ? 'Não foi encontrado nenhum usuário com esse filtro' : 
            mode === 'flow' ? 
            'Não existe usuários nesse fluxo':
            mode === 'client' ?
            'Não foi possível obter as usuários dessa empresa':
            mode === 'hub' ?
            'Não foi possível obter as usuários dessa empresa. Busque por outro usuários utilizando o email':
            '...'
          }
        </p>
      ):(
        <>
          <div className="py-4 text-gray-500 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
            {showAvailableUsers.map(availableUser => (
              <label
                className="
                  cursor-pointer
                  flex items-center justify-between rounded-lg gap-4
                  border border-gray-300 shadow bg-primary-300/10 hover:bg-primary-300/20 p-4
                "
                key={availableUser.id}
              >
                <div className="flex items-center">
                  <img
                    alt={availableUser.name}
                    className="rounded-full object-cover w-8 h-8"
                    src={availableUser.picture}
                  />
                  <div className="ml-3 flex-1 flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <span className="whitespace-nowrap text-sm text-primary-700/80 leading-none">{availableUser.name}</span>
                      <span className="text-xs text-primary-400 block">{availableUser.auth_email ?? availableUser.email}</span>
                      {(mode === 'hub' && availableUser.is_from_this_client) && (
                        <span className="
                          border border-primary-300/20 rounded-lg bg-primary-300/10 px-2
                          text-[10px] lowercase font-semibold text-primary-400 block  mt-1
                        ">
                          Desta empresa
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <Radio
                  onClick={() => setUserIds((prevState) => {
                    if(prevState.includes(availableUser.id)) return [...prevState.filter(
                      (state) => state !== availableUser.id
                    )]
                    return [
                      ...prevState,
                      availableUser.id
                    ]
                  })}
                  checked={userIds.includes(availableUser.id)}
                />
              </label>
            ))}
          </div>
          {(mode === 'flow' && !filterNameOrEmail && total > (availableUsers ?? []).length) && (
            <button
              type="button"
              className={`
                ${shortclass.button.normal}
                border-primary-300/20 rounded-lg bg-primary-300/10 hover:bg-primary-300/20 px-6 py-0.5
                text-[10px] lowercase font-semibold text-primary-400 block  mt-1 mx-auto
              `}
              onClick={() => loadFlowAuths()}
            >Ver mais</button>
          )}
        </>
      )}
    </div>
  )
}
import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { PossiblePermissions } from "../../shared-types";
import { IsMaintenance } from "../../routes";
import { useEffect, useState } from "react";
import { Loading } from "../Loading";
import { cachedUser } from "../../shared-components/services/authenticate";

interface RequireAuthProps{
  isAdmin?: boolean,
  moduleName?: PossiblePermissions | PossiblePermissions[],
  children: JSX.Element
}
export function RequireAuth({ children, isAdmin = false, moduleName }: RequireAuthProps) {
  const { isAuthenticated, user, refreshUser, updateToken } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(isAuthenticated);
  const [hasPermission, setHasPermission] = useState<boolean>();

  let location = useLocation();

  let maintenance = IsMaintenance();
  
  useEffect(() => {
    if(maintenance) return;

    handleRefreshUser()
  },[])
  useEffect(() => {
    if(!user || maintenance) return;

    let tempHasPermission = true
    try {      
      let permitions = user.permitions_slug ?? [];
      
      if (isAdmin) {
        if (!permitions.includes(PossiblePermissions.ADMIN)) tempHasPermission = false;
      }
      if (moduleName) {
        if(Array.isArray(moduleName)){
          if(moduleName.every((module) => !permitions.includes(module))) tempHasPermission = false;
        }else
        if(!permitions.includes(moduleName)) tempHasPermission = false;
      }
    } catch (e) {
      console.error(e)
      tempHasPermission = false;
    }
    
    setHasPermission(tempHasPermission);
  },[user, moduleName, isAdmin])

  if (maintenance) return maintenance;

  async function handleRefreshUser(){
    let token = searchParams.get('token') || cachedUser.get()?.token;
    if (searchParams.get('token') && token) updateToken(token);

    searchParams.delete('token');

    setSearchParams(searchParams);

    if(token){
      if(!isAuthenticated() || user?.token !== token){
        console.log('[refresh-user]');
        const res = await refreshUser(token);
        if(res.result){
          setIsAuth(true);
        }
      }
    }
    
    setIsLoading(false);
  }

  if(isLoading) return <Loading/>;

  if (!isAuth) return <Navigate to="/login" state={{ from: location }} replace />;

  if (isAdmin || moduleName) {
    if(hasPermission === undefined) return <Loading className="h-screen w-screen"/>;
    else if(hasPermission === false) return <Navigate to="/" state={{ from: location }} replace/>;
  }

  return children;
}
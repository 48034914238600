import { Radio } from "flowbite-react"
import { NotificationToType } from "../../shared-types/notification.type"

interface CardWhoNotifyProps{
  title: string,
  description: string
  value: NotificationToType,
  toType: NotificationToType | undefined,
  setToType: React.Dispatch<React.SetStateAction<NotificationToType | undefined>>
}
export const CardWhoNotify = ({ title, description, value, toType, setToType }:CardWhoNotifyProps) => {
  return (
    <label className="
      cursor-pointer
      flex items-center justify-between rounded-lg gap-4
      border border-gray-300 shadow bg-primary-300/10 hover:bg-primary-300/20 p-4
    ">
      <div>
        <span
          className="font-semibold peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base"
        >{ title }</span>
        <p className="text-[0.8rem] text-gray-400">
          { description }
        </p>
      </div>
      
      <Radio
        onChange={() => setToType(value)}
        checked={value === toType}
      />
    </label>
  )
}
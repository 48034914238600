import { useEffect, useRef, useState } from "react";
import { Modal } from "../../shared-components/utils/Modal"
import { Conciliacao } from "../../pages/ReceivementModule/Reconciliation";
import { Label, TextInput } from "flowbite-react";
import Select from "../utils/Select";
import { useNotify } from "../../contexts/NotifyContext";
import { ChevronDownIcon, CloseIcon } from "../../shared-components/utils/icons";
import { useAuth } from "../../contexts/AuthContext";
import { updateConciliation } from "../../services/reconciliation";
import { createConciliationJustification, getConciliationJustifications } from "../../services/conciiliationJustification";

const moneyFormatter = (value?: number) => (typeof value === 'number' || (
  typeof value === 'string' && !isNaN(Number(value))
) ? Number(value):  0).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL'
}).replace('R$','').trim();

interface ModalJustifyConciliationProps{
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  conciliation?: Conciliacao,
  onSubmit: () => void
}
export const ModalJustifyConciliation = ({
  isOpen, setIsOpen, conciliation, onSubmit
}:ModalJustifyConciliationProps) => {
  const { user } = useAuth()
  const { toast } = useNotify()

  const btnSubmitForm = useRef<HTMLButtonElement>(null)
  
  const [typesOfJustification, setTypesOfJustification] = useState<string[]>([])
  const [newType, setNewType] = useState<string>('')
  const [enableNewType, setEnableNewType] = useState(false)
  const [selectedType, setSelectedType] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [readjustment, setReadjustment] = useState<string>('')

  useEffect(() => {
    loadTypesOfJustification() 
    setReadjustment('')
  },[user, conciliation])

  async function loadTypesOfJustification(){
    if(!user) return;

    const data = await getConciliationJustifications(user.token)
    if(!data.result){
      toast.error(data.response)
      return
    }

    if(data.data) setTypesOfJustification(
      data.data.map(d => d.type)
    )
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault()

    if(!conciliation || !user) return;

    const numReadjustment = Number(readjustment ?? 0)

    const error = [
      [(
        (enableNewType && !newType) ||
        (typesOfJustification.length === 0 && !newType) ||
        (!enableNewType && typesOfJustification.length > 0 && !selectedType)
      ), 'Selecione o motivo da justificativa'],
      [!description,  'Adicione uma justificativa'],
      [isNaN(numReadjustment), 'Adicione um valor de reajuste válido']
    ].find(([verification]) => !!verification)
    if(error){
      toast.error(error[1])
      return;
    }

    const data = {
      status_dinheiro: 3,
      id: conciliation.id,
      type_of_justification: (enableNewType || typesOfJustification.length === 0) ? newType : selectedType,
      vl_reajuste: numReadjustment,
      vl_dinheiro: (conciliation.vl_dinheiro ?? 0) + numReadjustment,
      observacao: description,
    }

    if(enableNewType || typesOfJustification.length === 0){
      // enviar requisição em segundo plano
      // VER AS CONSEQUÊNCIA DE ADICIONAR 3 COMO CONCILIADO COM JUSTIFICATIVA
      const res = await createConciliationJustification(newType, user.token);
      if(!res.result) toast.warning(res.response)
    }

    try{
      await updateConciliation(data, conciliation.nome_fantasia!, user.token);

      toast.success("Status alterado")
    } catch (error) {
      toast.error("Não foi possível alterar status da conciliação");
    }

    onSubmit()
  }
  
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={{ 
        title: `Justificar Conciliação${conciliation && conciliation.dt_conciliacao ?
          ` - ${conciliation.dt_conciliacao.slice(0, 10).split("-").reverse().join("/")}`:''
        }`,
        actionButton: {
          theme: 'primary',
          autoClose: false,
          text: 'Justificar',
          onClick: () => btnSubmitForm.current?.click()
        }
      }}
    >
      <form className="pt-2" onSubmit={handleSubmit}>
        <button type="submit" className="hidden" ref={btnSubmitForm}/>

        <div className="mb-4">
          <div className="mb-2 block">
            <Label
              className="block text-sm font-medium !text-gray-700"
              htmlFor={`justify-type`}
              value={'Motivo'}
            />
          </div>
          {(enableNewType || typesOfJustification.length === 0) ? (
            <div className="flex gap-2 items-center justify-between">
              <TextInput
                id={`justify-new-type`}
                type={'text'}
                className="flex-1"
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                placeholder={"Adicione um valor para reajuste"}
                required
              />
              {typesOfJustification.length > 0 && (
                <button
                  type="button"
                  className="
                    text-red-500/80 ml-1.5 cursor-pointer
                    w-4 h-4 flex items-center justify-center
                    rounded-lg border leading-none
                    shadow-sm hover:bg-gray-200
                    text-gray-500 font-semibold
                    focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                  "
                  onClick={() => setEnableNewType(false)}
                ><CloseIcon w={16} h={16} className="text-danger-500"/></button>
              )}
            </div>
          ):(
            <>
              <Select
                selectedRaw={<span>Selecione o motivo...</span>}
                selectedId={selectedType}
                styles={{ list: { zIndex: 50, position: 'relative'} }}
                onChange={(op) => {
                  if(!op?.id) setSelectedType(undefined);
                  else if(typeof op.id === 'string')  setSelectedType(op.id);
                }}
                options={typesOfJustification.map(t => ({
                  id: t, html: t
                }))}
              />
              <button
                type="button"
                className="text-xs text-gray-500 hover:underline opacity-75 hover:opacity-95"
                onClick={() => setEnableNewType(true)}
              >clique aqui para adicionar um novo motivo</button>
            </>
          )}
        </div>

        <div className="mb-4">
          <div className="mb-2 block">
            <Label
              className="block text-sm font-medium !text-gray-700"
              htmlFor={`justify-description`}
              value={'Descrição'}
            />
          </div>
          <div className="relative">
            <textarea
              className="
                block w-full border 
                disabled:cursor-not-allowed disabled:opacity-50 
                bg-gray-50 border-gray-300 text-gray-900 
                focus:border-blue-500 focus:ring-blue-500 
                dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 
                dark:focus:border-blue-500 dark:focus:ring-blue-500
                rounded-lg p-2.5 text-sm
              "
              id={`justify-description`}
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={'Descreva a justificativa desta conciliação...'}
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <div className="mb-2 block">
            <Label
              className="block text-sm font-medium !text-gray-700"
              htmlFor={`justify-readjustment`}
              value={'Reajuste'}
            />
          </div>
          <TextInput
            id={`justify-readjustment`}
            step={0.01}
            type={'number'}
            className="flex-1"
            value={readjustment}
            onChange={(e) => setReadjustment(e.target.value)}
            placeholder={"Adicione um valor para reajuste"}
          />
        </div>

        {conciliation && conciliation.vl_dinheiro !== undefined && (
          <div className="flex gap-4 justify-between items-center text-gray-600 text-sm bg-gray-100 p-2 pb-1">
            <div className="flex flex-col">
              <span className="text-xs uppercase font-semibold">Dinheiro</span>
              {moneyFormatter(conciliation.vl_dinheiro + Number(readjustment ?? 0))}
            </div>
            <div className={`flex flex-col text-end ${
              Number(readjustment ?? 0) > 0 ? 'text-emerald-700': Number(readjustment ?? 0) < 0 ? 'text-red-600':''
            }`}>
              <span className="text-xs uppercase font-semibold flex gap-0.5 items-center">
                {Number(readjustment ?? 0) !== 0 && (
                  <ChevronDownIcon
                    className={`-mt-1 ${Number(readjustment ?? 0) > 0 ? 'rotate-180':''}`}
                    w={18} h={18}
                  />
                )}{' '}Reajuste
              </span>
              {moneyFormatter(Number(readjustment ?? 0))}
            </div>
          </div>
        )}
      </form>
    </Modal>
  )
}
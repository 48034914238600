import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { useAuth } from "../../../contexts/AuthContext";
import { useNotify } from "../../../contexts/NotifyContext";
import { Wrapper } from "../../../shared-components/Wrapper";
import { NotificationToType, NotificationTypeType } from "../../../shared-types/notification.type";
import { shortclass } from "../../../styles/styles";
import { FSSelectToType } from "../../../components/Notification/FormSteps/FSSelectToType";
import { FSSelectWorkflow } from "../../../components/Notification/FormSteps/FSSelectWorkflow";
import { FSSelectPermission } from "../../../components/Notification/FormSteps/FSSelectPermission";
import { FSSelectUser } from "../../../components/Notification/FormSteps/FSSelectUser";
import { FSSetting } from "../../../components/Notification/FormSteps/FSSetting";
import { FSTemplate } from "../../../components/Notification/FormSteps/FSTemplate";
import { PossiblePermissions } from "../../../shared-types";
import { CreateNotificationResquest, createNotification } from "../../../services/notification";
import { AvailableIcons } from "../../../shared-types/icon.type";

type AvailableStepTypes = 'select-to-type' | 'select-workflow' | 'select-user' | 'select-permission' | 'setting' | 'template'

export const CreateNotification = () => {
  const { user } = useAuth()
  const { toast } = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<AvailableStepTypes>('select-to-type');
  const [flowId, setFlowId] = useState<string>();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [ignoreClientId, setIgnoreClientId] = useState(false);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isHub, setIsHub] = useState(false)
  const [icon, setIcon] = useState<AvailableIcons>()
  const [priority, setPriority] = useState(3)
  const [withTemplate, setWithTemplate] = useState(false)
  const [templates, setTemplates] = useState<{ template_id: string, data: any }[]>([])
  const [toType, setToType] = useState<NotificationToType>()
  const [redirectTo, setRedirectTo] = useState<string>()
  const [type, setType] = useState<NotificationTypeType>()
  const [schedule, setSchedule] = useState<Date>();

  useEffect(() => {
    setIsHub(!!user?.permitions_slug?.includes(
      PossiblePermissions.ADMIN_HUB
    ))
  },[user])
  //#region VALIDATE
  const validateSelectToType = () => {
    if(!toType){
      toast.error('É obrigatório selecionar a quem deseja notificar')
      return false;
    }
    if(toType === 'broadcast_hub' && !(user?.permitions_slug ?? []).includes(
      PossiblePermissions.ADMIN_HUB
    )){
      toast.error('Você não tem permissão para criar esse tipo de notificação')
      return false;
    }
    
    return true
  }
  const validateSelectWorkflow = () => {
    if(!flowId){
      toast.error('Selecione um Workflow')
      return false
    }

    return true
  }
  const validateSelectPermission = () => {
    if(permissions.length === 0){
      toast.error('Selecione pelo menos 1 permissão')
      return false;
    }
    if(ignoreClientId && !(user?.permitions_slug ?? []).includes(
      PossiblePermissions.ADMIN_HUB
    )){
      toast.error('Você não tem permissão para criar esse tipo de notificação')
      return false;
    }
    return true
  }
  const validateSelectUser = () => {
    if(userIds.length === 0){
      toast.error('Selecione pelo menos um usuário')
      return  false
    }
    if(ignoreClientId && !(user?.permitions_slug ?? []).includes(
      PossiblePermissions.ADMIN_HUB
    )){
      toast.error('Você não tem permissão para criar esse tipo de notificação')
      return false;
    }
    return true
  }
  const validateSetting = () => {
    if(!title || !description || !type){
      toast.error('É obrigatório adicionar título, descrição e tipo de notificação')
      return false;
    }
    if(priority > 5 || priority < 1){
      toast.error('A prioridade deve ser um número entre 1 e 5')
      return false
    }
    if((['license','update'].includes(type) || isHub) && !(user?.permitions_slug ?? []).includes(
      PossiblePermissions.ADMIN_HUB
    )){
      toast.error('Você não tem permissão para criar esse tipo de notificação')
      return false;
    }
    if(schedule){
      if(schedule <= new Date()){
        toast.warning(
          'Não é possível agendar uma notificação para o passado'
        )
        return;
      }
    }
    return true
  }
  const validateTemplate = () => {
    if(templates.length === 0){
      toast.error('Adicione pelo menos um template')
      return false
    }
    return true
  }
  const validateAll = () : [boolean, Partial<CreateNotificationResquest>] => {
    let data : Partial<CreateNotificationResquest> = {}
    //#region TO TYPE
    if(step === 'select-to-type'){
      if(!validateSelectToType()) return [false, data];
      
      if(['broadcast_flow_auth','flow_auth_ids','flow_perms'].includes(
        toType as string
      )) setStep('select-workflow')
      else if(toType === 'hub_ids') setStep('select-user')
      else if(toType === 'hub_perms') setStep('select-permission')
      else setStep('setting')
      
      return [false, data];
    }
    if(!validateSelectToType()){
      setStep('select-to-type')
      return [false, data];
    }
    data.to = toType
    //#endregion TO TYPE
    //#region WORKFLOW
    if(step === 'select-workflow'){
      if(!validateSelectWorkflow()) return [false,data];
      
      if(toType === 'flow_auth_ids') setStep('select-user')
      else if(toType === 'flow_perms') setStep('select-permission')
      else setStep('setting')
      
      return [false,data];
    }
    if(['broadcast_flow_auth','flow_perms','flow_auth_ids'].includes(toType as string)){
      if(!validateSelectWorkflow()){
        setStep('select-workflow')
        return [false, data];
      }

      data.flow_id = flowId
    }
    //#endregion WORKFLOW
    //#region PERMISSION OR USER
    if(step === 'select-permission'){
      if(!validateSelectPermission()) return [false,data];
      
      setStep('setting')
      
      return [false, data];
    }
    if(step === 'select-user'){
      if(!validateSelectUser()) return [false,data];
      
      setStep('setting')

      return [false,data];
    }
    if(['flow_auth_ids','hub_ids'].includes(toType as string)){
      if(!validateSelectUser()){
        setStep('select-user')
        return [false, data];
      }

      data.data = userIds
      data.ignore_client_id = ignoreClientId
    }
    if(['hub_perms','flow_perms'].includes(toType as string)){
      if(!validateSelectPermission()){
        setStep('select-permission')
        return [false, data];
      }

      data.data = permissions
      data.ignore_client_id = ignoreClientId
    }
    //#endregion PERMISSION OR USER
    //#region SETTING
    if(step === 'setting'){
      if(!validateSetting()) return [false, data];
      
      if(withTemplate){
        setStep('template')
        return [false, data]
      }
    }
    else if(!validateSetting()){
      setStep('setting')
      return [false, data];
    }
    data = {
      ...data,
      title,
      description,
      icon,
      is_hub: isHub,
      priority,
      redirect_to: redirectTo,
      type: type,
      schedule: schedule ? schedule.toISOString() : undefined
    }
    if(step === 'setting') return [true, data]
    //#endregion SETTING
    //#region TEMPLATE 
    if(step === 'template'){
      if(!validateTemplate()) return [false, data];

      // [ ] ADICIONAR TRATAMENTO PARA MULTIPLOS TEMPLATES
      toast.error('Ainda não há tratamento para templates')
      
      // return [true, data]
    }
    //#endregion TEMPLATE

    return [false, data]
  }
  //#endregion VALIDATE
  function handleReset(){
    setFlowId(undefined)
    setPermissions([])
    setUserIds([])
    setTitle('')
    setDescription('')
    setIsHub(false)
    setIcon(undefined)
    setPriority(3)
    setWithTemplate(false)
    setTemplates([])
    setToType(undefined)
    setStep('select-to-type')
    setRedirectTo(undefined)
    setType(undefined)
    setIgnoreClientId(false)
    setSchedule(undefined)
  }
  function handlePrevStep(){
    if(step === 'select-to-type') return;
    if(step === 'select-workflow' || 
      (step === 'select-user' && toType === 'hub_ids') ||
      (step === 'select-permission' && toType === 'hub_perms')
    ){
      setFlowId(undefined)
      setStep('select-to-type')
      return
    }
    if(
      (step === 'select-user' && toType === 'flow_auth_ids') ||
      (step === 'select-permission' && toType === 'flow_perms')
    ){
      setUserIds([])
      setPermissions([])
      setStep('select-workflow')
      return
    }
    if(step === 'setting'){
      setSchedule(undefined)
      if(['broadcast','broadcast_hub'].includes(toType as string)){
        setStep('select-to-type')
        return
      }
      if(['flow_auth_ids','hub_ids'].includes(toType as string)){
        setStep('select-user')
        return
      }
      if(['flow_perms','hub_perms'].includes(toType as string)){
        setStep('select-permission')
        return
      }
      if(toType === 'broadcast_flow_auth'){
        setStep('select-workflow')
        return
      }
    } 
    if(step === 'template'){
      setWithTemplate(false)
      setStep('setting')
      return
    }

    toast.error('Não foi possível identificar a etapa anterior')
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>){
    e.preventDefault();

    if(!user) return
    
    setIsLoading(true);
    const [canSubmit, data] = validateAll()
    if(canSubmit){
      const res = await createNotification(data as CreateNotificationResquest, user.token);
      if(!res.result) toast.error(res.response)
      else{
        toast.success(res.response)
        handleReset()
      }
    }

    setIsLoading(false)
  }

  // [ ] ADICIONAR UM ETAPA ANTERIOR À INICIAL QUE IRA MOSTRAR UMA TABELA COM AS NOTIFICAÇÃO AINDA NÃO DISPARADAS
  // [ ] NESSA TELA DEVERÁ HAVER A CAPACIDADE DE EXCLUIR E/OU ALTERAR AS NOTIFICAÇÕES AGENDADAS
  // [ ] DEVERÁ HAVER UM BOTÃO PARA INICIAR A CRIAÇÃO

  return (
    <Wrapper
      module_name="Configurações"
      asideActive={['Notificações', 'Criar Notificações']}
      breadcrumbs={[
        { name: 'Notificações', href: '#', subtitle: `Criar Notificação${user ? ` - ${user.client_name}`:''}`}
      ]}
    >
      <form className="text-gray-700 mb-10" onSubmit={handleSubmit}>
        <div className="min-h-[25rem]">
          {
            step === 'select-to-type' ? <FSSelectToType {...{ setToType, toType }}/> :
            step === 'select-workflow' ? <FSSelectWorkflow {...{
              flowId,
              setFlowId,
              toType,
            }}/> :
            step === 'select-permission' ? <FSSelectPermission {...{
              isHub,
              toType,
              flowId,
              permissions,
              setPermissions,
              setIgnoreClientId,
            }}/> :
            step === 'select-user' ? <FSSelectUser {...{
              isHub,
              flowId,
              toType,
              userIds,
              isLoading,
              setUserIds,
              setIsLoading,
              setIgnoreClientId,
            }}/> :
            step === 'setting' ? <FSSetting {...{
              title,
              setTitle,
              description,
              setDescription,
              type,
              setType,
              priority,
              setPriority,
              isHub,
              setIsHub,
              redirectTo,
              setRedirectTo,
              icon,
              setIcon,
              schedule,
              setSchedule
            }}/> : 
            step === 'template' ?          <FSTemplate/> : <></>
          }
        </div>
        <div className="flex flex-col xs:flex-row justify-center gap-4 mt-8">
          {step !== 'select-to-type' && (
            <button
              type="button"
              className={`${shortclass.button.secondary} min-w-[8rem] flex items-center`}
              onClick={handlePrevStep}
            >Voltar</button>
          )}
          {/* {step === 'setting' && (
            <button
              type="submit"
              className={`${shortclass.button.primary} min-w-[8rem] flex items-center`}
              onClick={() => setWithTemplate(true)}
            >Adicionar Modelo</button>  
          )} */}
          <button
            type="submit"
            className={`${shortclass.button.primary} min-w-[8rem] flex items-ce'nter`}
          >
            {['setting','template'].includes(step) ? 'Cadastrar' :'Próximo'}
          </button>
        </div>

        {isLoading && <Loading className="absolute inset-0 z-50 bg-gray-50/75"/>}
      </form>
    </Wrapper>
  )
}
import { Card } from "flowbite-react";

import { Loading } from "../../components/Loading";
import { Wrapper } from "../../shared-components/Wrapper";

import { useAuth } from "../../contexts/AuthContext";
import { useNotify } from "../../contexts/NotifyContext";

import logo from '../../assets/default-client-logo.png';
import { shortclass } from "../../styles/styles";
import { useEffect, useState } from "react";
import { ModalProfile } from "../../components/Profile/ModalProfile";
import { useSearchParams } from "react-router-dom";
import { ModalChangePassword } from "../../components/Profile/ModalChangePassword";
import { hubRoutes } from "../../shared-types/utils/routes";
import { Avatar } from "../../shared-components/utils/Avatar";
import { InformationData, Table } from "../../components/Table";
import { getAllSession } from "../../shared-components/services/session";
import { CellPhoneIcon, DesktopIcon, IconProps, TabletIcon, TvIcon, WearableIcon } from "../../shared-components/utils/icons";
import { Session } from "../../shared-types/session.type";

export const Profile = () => {
  const { user, changeClient } = useAuth();
  const { toast } = useNotify();
  
  const [permissions, setPermissions] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalProfileIsOpen, setModalProfileIsOpen] = useState(false);
  const [modalChangePasswordIsOpen, setModalChangePasswordIsOpen] = useState(false);
  const [sessions, setSessions] = useState<Record<string, any>>({});
  const [showSession, setShowSession] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(searchParams.get('call') === 'edit-profile'){
      setModalProfileIsOpen(true);
      searchParams.delete('call')
      setSearchParams(searchParams);
    }
  },[searchParams]);
  useEffect(() => {
    if(user && user.permitions_slug) setPermissions(user.permitions_slug);
    onLoad()
  },[user]);

  async function handleChangeClient(client_id: string, client_name: string){
    if(!user) return;
    toast.promise(changeClient(client_id, client_name, user.token), {
      'pending': 'Alterando empresa',
      'error': 'Houve um erro ao alterar a empresa',
      'success': 'Empresa alterada com sucesso'
    });
  }

  const device: any = {
    'Computador': <DesktopIcon />,
    'Celular': <CellPhoneIcon />,
    'Tablet': <TabletIcon />,
    'Smart TV': <TvIcon />,
    'Vestível': <WearableIcon />,
    'Sistema integrado': <DesktopIcon />,
  }

  const normalizedTableData = (
    { count, data }:
    { count: number, data: Session[] }
  ) => {
    const normalizedData = data.map((item: Session) => {
      let location = '';
      if(
        !item.city ||
        !item.state ||
        !item.country
      ) location = 'Localhost';
      else {
        location = `${item.city} - ${item.state}/${item.country}`
      }

      const obj: InformationData = {
        title: `${item.ip} ${item.device}`,
        description: location,
        date: item.last_access,
        icon: device[item.device],
      };

      return obj;
    })

    return { count, data: normalizedData };
  }

  async function onLoad(){
    if(!user || isLoading) return;

    setIsLoading(true);
    await (async () => {
      const res = await getAllSession(user.token);
      if(!res.data.length){
        toast.error(res.response)
        return;
      }

      setSessions(normalizedTableData(res));
    })()
    setIsLoading(false)
  }

  return (
    <Wrapper breadcrumbs={[{
      name: 'Perfil',
      href: hubRoutes.profile.home()
    }]} module_name="Configurações" asideActive="Perfil">
      {!user ? <Loading className="h-[calc(100vh-14.5rem)] rounded-xl"/> : (
        <div className="w-full">
          <Card className="mb-6 !bg-primary-300/10 !backdrop-blur-[25px] !border-0">
            <div className="flex items-center pb-4 gap-4">
              <Avatar size="lg" picture={user.picture}/>
              <div>
                <h5 className="mb-0 text-xl leading-none font-semibold text-primary-800">
                  {user.name}
                </h5>
                <span className="text-sm text-primary-400 dark:text-gray-400">
                  {user.email}
                </span>
              </div>
            </div>
            <div>
              {!showSession ? (
                <div className="flex space-x-3">
                  <button
                    type="button"
                    className={`${shortclass.button.normal} hover:bg-gray-50 font-semibold text-primary-700 bg-gradient-light`}
                    onClick={() => setModalProfileIsOpen(true)}
                  >Editar</button>
                  <button
                    type="button"
                    className={`${shortclass.button.normal} hover:bg-gray-50 font-semibold text-primary-700 bg-gradient-light`}
                    onClick={() => setModalChangePasswordIsOpen(true)}
                  >Alterar Senha</button>
                  <button
                    type="button"
                    className={`${shortclass.button.normal} hover:bg-gray-50 font-semibold text-primary-700 bg-gradient-light`}
                    onClick={() => setShowSession(true)}
                  >Histórico de acessos</button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-lg py-2 px-4 text-center text-sm font-semibold text-primary-400 hover:bg-gray-100/30 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                    onClick={() => toast.warning('Em desenvolvimento')}
                  >Desativar</button>
                </div>
              ):(
                <div className="flex space-x-3">
                  <button
                    type="button"
                    className={`${shortclass.button.normal} hover:bg-gray-50 font-semibold text-primary-700 bg-gradient-light`}
                    onClick={() => setShowSession(false)}
                  >Voltar</button>
                </div>
              )}
            </div>
          </Card>

          {!showSession ? (
            <div className="flex flex-col lg:flex-row gap-4 items-start">
              <>
                <Card className="mb-6 w-full lg:w-[28rem] min-h-[15rem] !bg-primary-300/10 !backdrop-blur-[25px] !border-0">
                  <h5 className="text-lg font-semibold text-primary-800 mb-2">Empresas</h5>
                  <div className="flex flex-col -mt-2">
                    {user.clients && user.clients.map(client => (
                      <div
                        className={`border-b border-gray-200/25 last:border-b-0 ${client.id === user.current_client ? '':'cursor-pointer hover:bg-gray-100/10'}`}
                        onClick={() => client.id === user.current_client ? {} : handleChangeClient(client.id, client.nome_fantasia)}
                        key={client.id}
                      >
                        <div className="flex items-center py-2.5">
                          <img
                            alt={client.nome_fantasia}
                            className="rounded-full object-cover w-8 h-8 bg-white"
                            src={client.picture ?? logo}
                            onError={(e) => {
                              let img = e.target as HTMLImageElement;
                              if(img.src !== logo) img.src = logo;
                            }}
                          />
                          <div className="ml-3 flex-1 flex justify-between">
                            <span className="whitespace-nowrap font-semibold text-sm text-ellipsis overflow-hidden max-w-[calc(100%-7rem)] block text-primary-700/90">{client.nome_fantasia}</span>
                            {client.id === user.current_client && (
                              <span className="inline-flex items-center justify-center rounded bg-gray-200/25 px-2 py-0.5 text-xs font-semibold text-primary-400 dark:bg-gray-700 dark:text-gray-400">
                                Selecionado
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
                <Card className="mb-6 w-full min-h-[15rem] !bg-primary-300/10 !backdrop-blur-[25px] !border-0">
                <div>
                  <h5 className="text-lg font-semibold text-primary-800 leading-none">
                    Permissões
                    <span className="text-sm font-normal text-primary-400 block">na empresa selecionada</span>
                  </h5>
                </div>
                <ul className="grid grid-cols-2 gap-1">
                  {permissions.map((permition, i) => (
                    <li className="p-2 my-1 text-sm rounded-lg shadow bg-gray-100/20 text-primary-400 font-semibold hover:bg-gray-200/50" key={`${permition}-${i}`}>{permition}</li>
                  ))}
                </ul>
                </Card>
              </>
            </div>
          ):(
            <Table
              data={sessions}
              perPage={5}
              icons={device}
              onLoad={onLoad}
              loading={isLoading}
            />
          )}
        </div>
      )}
      <ModalProfile
        isOpen={modalProfileIsOpen}
        setIsOpen={setModalProfileIsOpen}
      />
      <ModalChangePassword
        isOpen={modalChangePasswordIsOpen}
        setIsOpen={setModalChangePasswordIsOpen}
      />
    </Wrapper>
  );
}